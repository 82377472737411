import { Theme } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import cancellationGoodImage from '../../../assets/cancelation-good.png';
import cancellationHighImage from '../../../assets/cancelation-high.png';
import cancellationVeryHighImage from '../../../assets/cancelation-very-high.png';

export enum ProviderAttendanceStatusId {
  GOOD_STANDING = 1,
  HIGH_CANCELER = 2,
  VERY_HIGH_CANCELER = 3,
}

export enum ClientAttendanceStatusId {
  GOOD_STANDING = 4,
  HIGH_CANCELER = 5,
  VERY_HIGH_CANCELER = 6,
}

const attendanceStatusesData = (theme: Theme) => ({
  GOOD_STANDING: {
    colors: theme.palette.kyoDollarBill,
    imageSrc: cancellationGoodImage,
  },
  HIGH_CANCELER: {
    colors: theme.palette.kyoPastelOrange,
    imageSrc: cancellationHighImage,
  },
  VERY_HIGH_CANCELER: {
    colors: theme.palette.kyoOrangeRed,
    imageSrc: cancellationVeryHighImage,
  },
});

export const getAttendanceStatusData = (theme: Theme) => (
  statusId: ProviderAttendanceStatusId | ClientAttendanceStatusId
): {
  colors: PaletteColor;
  imageSrc: string;
} => {
  const data = {
    [ProviderAttendanceStatusId.GOOD_STANDING]: attendanceStatusesData(theme)
      .GOOD_STANDING,
    [ProviderAttendanceStatusId.HIGH_CANCELER]: attendanceStatusesData(theme)
      .HIGH_CANCELER,
    [ProviderAttendanceStatusId.VERY_HIGH_CANCELER]: attendanceStatusesData(
      theme
    ).VERY_HIGH_CANCELER,
    [ClientAttendanceStatusId.GOOD_STANDING]: attendanceStatusesData(theme)
      .GOOD_STANDING,
    [ClientAttendanceStatusId.HIGH_CANCELER]: attendanceStatusesData(theme)
      .HIGH_CANCELER,
    [ClientAttendanceStatusId.VERY_HIGH_CANCELER]: attendanceStatusesData(theme)
      .VERY_HIGH_CANCELER,
  };

  return data[statusId];
};

export const KYO_POLICY_TEXT =
  'Per Kyo policy, staff may be reassigned if their work schedule is continually disrupted.';

export const getAttendanceStatusText = (
  threshold?: number,
  showPolicyText?: boolean
) => {
  return {
    GOOD_STANDING_CLIENT: `Thanks for keeping cancels to a minimum. To stay under Kyo's cancellation limit please try to give more than two weeks notice when removing sessions.`,
    GOOD_STANDING_PROVIDER: `Great work keeping cancels to a minimum! Your cancel rate is within Kyo's threshold.`,
    NEAR_TO_HIGH_CANCELER_CLIENT: `Your cancellation rate is nearing Kyo's ${threshold}% cancellation limit. ${
      showPolicyText ? KYO_POLICY_TEXT : ''
    }`,
    HIGH_CANCELER_CLIENT: `Your cancellation rate is > ${threshold}%. ${KYO_POLICY_TEXT}`,

    // for modal text (not only)
    GOOD_STANDING:
      "Keep it up! Your cancellation rate is within Kyo's threshold.",
    HIGH_CANCELER: `Your cancellation rate is > ${threshold}%. Work on reducing it to ensure you can take advantage of all the perks that are available to you!`,
    VERY_HIGH_CANCELER: `Your cancellation rate is > ${threshold}%. Scheduling is paused for new sessions. Continue to attend your scheduled sessions to reduce your cancellation rate and take advantage of all the perks that are available to you.`,
  };
};

import queryString from 'query-string';
import requester from './requester';
import { Invoice, PaginatedData, PaginationDataMeta } from '../types/types';

export interface FetchInvoicesFilters {
  clientId?: number;
  page?: number;
  rowsPerPage?: number;
}

export interface InvoicesPaginatedData<T>
  extends Omit<PaginatedData<T>, 'items'> {
  data: T[];
}

export interface InvoicesDataMeta
  extends Omit<PaginationDataMeta, 'totalPages'> {
  total: number;
}

const fetchInvoices = async (
  filters: FetchInvoicesFilters
): Promise<InvoicesPaginatedData<Invoice>> => {
  const { clientId, ...restFilters } = filters;

  const query = queryString.stringify(restFilters);
  const url = `v2/clients/${clientId}/invoices?${query}`;

  return requester
    .get(url, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => response.json())
    .then((response) => response);
};

const getInvoiceAttachmentUrl = async (clientId: number, invoiceId: number) => {
  const url = `v2/clients/${clientId}/invoices/${invoiceId}/attachments`;

  return requester
    .get(url, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => response.json())
    .then((response) => response);
};

export default { fetchInvoices, getInvoiceAttachmentUrl };

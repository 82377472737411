import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ProviderCardHeader from '../../components/ProviderCardHeader';
import ProviderLanguages from '../../components/ProviderCardLanguages';
import ProviderCardBio from '../../components/ProviderCardBio';
import { ProviderChatUserType } from '../types';
import ProviderMobileNumber from '../../components/ProviderMobileNumber';

const useStyles = makeStyles((theme) => ({
  contents: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  providerInfoHeader: {
    [theme.breakpoints.up('sm')]: {
      position: 'relative',

      '& .MuiAvatar-root+div': {
        display: 'flex',

        '& .MuiBox-root': {
          margin: theme.spacing(0.5, 0.5),
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      '&>div:first-child': {
        flexGrow: 1,
      },
    },
  },
  mentionName: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: theme.spacing(0.5),
      left: theme.spacing(6),
    },
  },
}));

interface ProviderInfoProps {
  provider: ProviderChatUserType;
}

const ProviderInfo = (props: ProviderInfoProps) => {
  const classes = useStyles();
  const { provider } = props;

  return (
    <Box pt={2}>
      <Box className={classes.contents}>
        <Box flexGrow={1} className={classes.providerInfoHeader}>
          <ProviderCardHeader provider={provider} />

          <Box className={classes.mentionName}>
            <Typography variant="body2" color="textSecondary">
              {`@${provider.name}`}
            </Typography>
          </Box>
        </Box>
      </Box>

      <ProviderMobileNumber mobile={provider.mobile} />

      <Box>
        <ProviderLanguages
          providerAttributes={provider.attributes}
          providerLanguages={provider.languages}
        />
        <ProviderCardBio text={provider.attributes?.bio} />
      </Box>
    </Box>
  );
};

export default ProviderInfo;

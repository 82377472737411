import React from 'react';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';

import Matrix, { MatrixProps } from '../../shared/matrix/ui';
import {
  BlockCoords,
  ClientSchedule,
  DayOfWeekId,
  ProviderSchedule,
} from '../../types/types';
import { AvailabilityBlock } from '../../redux/modules/availability';
import useSettings from '../../hooks/useSettings';
import useCellStyles, { JSS } from '../../shared/matrix/ui/cell/styles';
import {
  getTimeBlockDayOfWeekKey,
  sortAvailabilityBlocks,
} from '../../shared/lib/availability';
import { useHorizontalLabelStylesVariant1 } from '../../shared/matrix/ui/horizontalLabelWeekDay/styles';
import { DayOfWeekValues } from '../../shared/matrix/ui/horizontalLabelWeekDay/lib';
import HorizontalLabelWeekDay from '../../shared/matrix/ui/horizontalLabelWeekDay';
import MatrixCell from '../../shared/matrix/ui/cell';

export enum AvailabilityMatrixState {
  Available = 'available',
  NotAvailable = 'notAvailable',
  Booked = 'Booked',
  OpenSessionRequest = 'openSessionRequest',
}

export const getBlockStyles = (cellSizes: number) => (theme: Theme) => ({
  [AvailabilityMatrixState.Available]: {
    backgroundColor: theme.palette.kyoBlue2.main,
  },
  [AvailabilityMatrixState.NotAvailable]: {
    backgroundColor: theme.palette.kyoGray.main,
  },
  [AvailabilityMatrixState.Booked]: {
    '&::before': {
      content: '" "',
      display: 'inline-block',
      width: theme.spacing(cellSizes + 1.5),
      height: theme.spacing(cellSizes + 1.5),
      borderRadius: '50%',
      border: `${theme.spacing(0.375)}px solid #F7716E`,
      position: 'relative',
      left: theme.spacing(-0.75),
      top: theme.spacing(-0.75),
    },
  },
  [AvailabilityMatrixState.OpenSessionRequest]: {
    backgroundColor: theme.palette.kyoGreen.main,
  },
});

interface AvailabilityMatrixProps extends Partial<MatrixProps<number, number>> {
  schedule?: ProviderSchedule[] | ClientSchedule[];
  availabilityBlocks?: Partial<AvailabilityBlock>[];
  cellStyleOverride?: { [key in AvailabilityMatrixState | 'main']?: string };
  cellSizes: number;
  showAvailability?: boolean;
  disableWeekends?: boolean;
  showBooked?: boolean;
  showDemand?: boolean;
  getBlockMainStyles?: (theme: Theme) => JSS;
  onCellClick?: (args: {
    key: number;
    isAvailable: boolean;
    horizontalVerticalValues: {
      horizontalValue: DayOfWeekId;
      verticalValue: number;
    };
  }) => void;
  unavailable?: boolean;
  CellWrapper?: (args: {
    horizontalValue: DayOfWeekId;
    verticalValue: number;
    showDemand: boolean;
    demandScore: number;
    demandRank: number;
    children: React.ReactNode;
  }) => JSX.Element;
  openSessionRequests?: BlockCoords[];
}

const AvailabilityMatrix = ({
  schedule,
  availabilityBlocks,
  cellSizes,
  cellStyleOverride = {},
  showAvailability = true,
  disableWeekends = false,
  showBooked = true,
  showDemand = false,
  getBlockMainStyles,
  onCellClick,
  unavailable = false,
  CellWrapper = ({ children }) => <>{children}</>,
  openSessionRequests,
  ...matrixProps
}: AvailabilityMatrixProps) => {
  const cellClasses = useCellStyles({
    cellSizes,
    addMainStyles: getBlockMainStyles,
    restStyles: getBlockStyles(cellSizes),
  })();
  const { minOccupiedLength } = useSettings();
  const horizontalLabelStyles = useHorizontalLabelStylesVariant1();

  const StatusInfo = {
    [AvailabilityMatrixState.Available]: {
      className:
        cellStyleOverride.available ||
        cellClasses[AvailabilityMatrixState.Available],
    },
    [AvailabilityMatrixState.NotAvailable]: {
      className:
        cellStyleOverride.notAvailable ||
        cellClasses[AvailabilityMatrixState.NotAvailable],
    },
    [AvailabilityMatrixState.Booked]: {
      className:
        cellStyleOverride.Booked || cellClasses[AvailabilityMatrixState.Booked],
    },
    [AvailabilityMatrixState.OpenSessionRequest]: {
      className: cellClasses[AvailabilityMatrixState.OpenSessionRequest],
    },
  };

  const getSortedSchedule = () => {
    return cloneDeep(schedule || []).sort(
      (
        a: ProviderSchedule | ClientSchedule,
        b: ProviderSchedule | ClientSchedule
      ) => a.dayOfWeekId - b.dayOfWeekId || a.timeBlockId - b.timeBlockId
    );
  };

  const getStatuses = (
    isAvailable: boolean,
    occupied2: number,
    hasOpenSessionRequest: boolean
  ) => {
    const statuses = [
      isAvailable && !unavailable
        ? AvailabilityMatrixState.Available
        : AvailabilityMatrixState.NotAvailable,
    ];

    if (showBooked && occupied2 >= minOccupiedLength) {
      statuses.push(AvailabilityMatrixState.Booked);
    }

    if (hasOpenSessionRequest) {
      statuses.push(AvailabilityMatrixState.OpenSessionRequest);
    }

    return statuses;
  };

  const checkHasOpenSessionRequest = (timeBlock: number, dayOfWeek: number) => {
    const openSessionRequestInBlock = openSessionRequests?.filter(
      (os) => os.dayOfWeekId === dayOfWeek && os.timeBlockId === timeBlock
    );
    return !!openSessionRequestInBlock?.length;
  };

  const availabilityRenderer = (
    horizontalValue: DayOfWeekId,
    verticalValue: number
  ): React.ReactNode => {
    const key = getTimeBlockDayOfWeekKey({
      timeBlockId: verticalValue,
      dayOfWeekId: horizontalValue,
    });
    const sortedSchedule = getSortedSchedule();
    const sortedAvailabilityBlocks = sortAvailabilityBlocks(
      availabilityBlocks || []
    );
    const { isAvailable: scheduleIsAvailable = false, occupied2 = 0 } =
      sortedSchedule[key] || {};
    const { demandScore = 0, demandRank = 0 } = (sortedSchedule[key] ||
      {}) as ProviderSchedule;
    const { isAvailable = scheduleIsAvailable } =
      sortedAvailabilityBlocks[key] || {};
    const hasOpenSessionRequest = checkHasOpenSessionRequest(
      verticalValue,
      horizontalValue
    );

    return (
      <CellWrapper
        horizontalValue={horizontalValue}
        verticalValue={verticalValue}
        showDemand={showDemand}
        demandScore={demandScore}
        demandRank={demandRank}
      >
        <MatrixCell
          className={clsx(
            cellClasses.main,
            ...getStatuses(isAvailable, occupied2, hasOpenSessionRequest).map(
              (status) => StatusInfo[status].className
            ),
            cellStyleOverride.main
          )}
          onClick={() => {
            return (
              onCellClick &&
              onCellClick({
                key,
                isAvailable: !isAvailable,
                horizontalVerticalValues: {
                  horizontalValue,
                  verticalValue,
                },
              })
            );
          }}
        />
      </CellWrapper>
    );
  };

  return (
    <Matrix
      horizontalValues={DayOfWeekValues}
      verticalValues={[1, 2, 3]}
      HorizontalLabel={({ value }) => (
        <HorizontalLabelWeekDay
          value={value}
          styles={horizontalLabelStyles}
          disableWeekends={disableWeekends}
        />
      )}
      render={availabilityRenderer}
      {...matrixProps}
    />
  );
};

export default AvailabilityMatrix;

import React from 'react';
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface ButtonPropsWithCustomColors extends ButtonProps {
  customColor?:
    | 'kyoGreen'
    | 'kyoTeal'
    | 'kyoYellow'
    | 'kyoRed'
    | 'kyoMagenta'
    | 'kyoPurple'
    | 'kyoDarkGreen'
    | 'kyoDarkestGreen'
    | 'kyoLightRed'
    | 'kyoOrange'
    | 'kyoBlue'
    | 'kyoLightGreen'
    | 'kyoBlue2';
}

const useStyles = makeStyles((theme: Theme) => ({
  styledButton: {
    minHeight: 40,
    borderRadius: 8,
    textTransform: 'none',
  },
  sizeLarge: {
    minHeight: 48,
  },
}));

const useCustomColor = makeStyles((theme: Theme) => ({
  customColor: (props: ButtonPropsWithCustomColors) => {
    if (props.variant === 'outlined') {
      return props.customColor
        ? {
            borderColor: theme.palette[props.customColor].main,
            color: theme.palette[props.customColor].main,
            '&:hover': {
              borderColor: theme.palette[props.customColor].main,
            },
          }
        : {};
    }
    if (props.variant === 'text') {
      return props.customColor
        ? {
            color: theme.palette[props.customColor].main,
          }
        : {};
    }
    return props.customColor
      ? {
          color: theme.palette[props.customColor].contrastText,
          backgroundColor: theme.palette[props.customColor].main,
          '&:hover': {
            backgroundColor: theme.palette[props.customColor].dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: theme.palette[props.customColor].main,
            },
          },
          '&:disabled': {
            backgroundColor: theme.palette.grey[100],
          },
        }
      : {};
  },
}));

const StyledButton = (props: ButtonPropsWithCustomColors) => {
  const { className, customColor, ...otherProps } = props;
  const classes = useStyles(props);
  const colorClasses = useCustomColor(props);
  return (
    <Button
      className={clsx(
        className,
        classes.styledButton,
        colorClasses.customColor
      )}
      classes={{
        sizeLarge: classes.sizeLarge, // class name, e.g. `classes-nesting-label-x`
      }}
      {...otherProps}
    />
  );
};

export default StyledButton;

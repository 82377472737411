import { AccessControl } from 'accesscontrol';

export enum Role {
  admin = 'admin',
  UserMgr = 'UserMgr',
  SCH = 'SCH',
  'HR/CRED/CS' = 'HR/CRED/CS',
  'CLIN-RD/Con' = 'CLIN-RD/Con',
  'CLIN-Staff' = 'CLIN-Staff',
  Client = 'Client',
  'CLIN-BCBA' = 'CLIN-BCBA',
  'CRED/CS' = 'CRED/CS',
  CS = 'CS',
  'SCH-BTonly' = 'SCH-BTonly',
  RevenueTeam = 'RevenueTeam',
  'Educator-Admin' = 'Educator-Admin',
  'TAL/HR' = 'TAL/HR',
}

export enum Resources {
  userAttributes = 'userAttributes',
  clientRoutes = 'clientRoutes',
  providerRoutes = 'providerRoutes',
  educatorPortal = 'educatorPortal',
  switchRole = 'switchRole',
}

export enum BookingAttr {
  action = 'action',
  completion = 'completion',
}

export enum AttributePermission {
  general = 'general',
  telehealthWaiver = 'telehealthWaiver',
  bio = 'bio',
  preferredServiceZip = 'preferredServiceZip',
  singleBookingAvailability = 'singleBookingAvailability',
}

const grantsObject: { [key in Role]: object } = {
  admin: {
    [Resources.userAttributes]: {
      'read:any': ['*'],
      'update:any': [
        AttributePermission.general,
        AttributePermission.telehealthWaiver,
        AttributePermission.preferredServiceZip,
        AttributePermission.singleBookingAvailability,
      ],
    },
    [Resources.providerRoutes]: {
      'read:any': ['*'],
    },
    [Resources.switchRole]: {
      'read:any': ['*'],
    },
  },
  UserMgr: {
    [Resources.switchRole]: {
      'read:any': ['*'],
    },
  },
  SCH: {
    [Resources.userAttributes]: {
      'read:any': ['*'],
      'update:any': [
        AttributePermission.general,
        AttributePermission.preferredServiceZip,
        AttributePermission.singleBookingAvailability,
      ],
    },
    [Resources.providerRoutes]: {
      'read:any': ['*'],
    },
    [Resources.switchRole]: {
      'read:any': ['*'],
    },
  },
  CS: {
    [Resources.userAttributes]: {
      'read:any': ['*'],
      'update:any': [
        AttributePermission.general,
        AttributePermission.telehealthWaiver,
        AttributePermission.preferredServiceZip,
      ],
    },
    [Resources.switchRole]: {
      'read:any': ['*'],
    },
  },
  'CLIN-RD/Con': {
    [Resources.userAttributes]: {
      'read:any': ['*'],
      'update:any': [
        AttributePermission.general,
        AttributePermission.preferredServiceZip,
        AttributePermission.singleBookingAvailability,
      ],
    },
    [Resources.providerRoutes]: {
      'read:any': ['*'],
    },
    [Resources.switchRole]: {
      'read:any': ['*'],
    },
  },
  'CLIN-Staff': {
    [Resources.userAttributes]: {
      'update:own': [
        AttributePermission.bio,
        AttributePermission.preferredServiceZip,
        AttributePermission.singleBookingAvailability,
      ],
    },
    [Resources.providerRoutes]: {
      'read:any': ['*'],
    },
  },
  Client: {
    [Resources.clientRoutes]: {
      'read:any': ['*'],
    },
  },
  'CLIN-BCBA': {
    [Resources.userAttributes]: {
      'update:own': [
        AttributePermission.bio,
        AttributePermission.preferredServiceZip,
      ],
    },
    [Resources.providerRoutes]: {
      'read:any': ['*'],
    },
    [Resources.switchRole]: {
      'read:any': ['*'],
    },
  },
  'TAL/HR': {
    [Resources.switchRole]: {
      'read:any': ['*'],
    },
    [Resources.userAttributes]: {
      'read:any': ['*'],
      'update:any': [
        AttributePermission.general,
        AttributePermission.bio,
        AttributePermission.preferredServiceZip,
      ],
    },
  },
  'CRED/CS': {},
  'HR/CRED/CS': {},
  'SCH-BTonly': {},
  RevenueTeam: {},
  'Educator-Admin': {
    [Resources.educatorPortal]: {
      'read:any': ['*'],
    },
  },
};

export const ac = new AccessControl(grantsObject);

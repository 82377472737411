import { FeedbackTag } from '../types/types';
import requester from './requester';

const fetchFeedbackTags = async (): Promise<FeedbackTag[]> => {
  return requester
    .get('feedback-tags')
    .then((response) => response.json())
    .then((response) => response?.data);
};

export default { fetchFeedbackTags };

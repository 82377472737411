import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import StyledButton from './buttons/StyledButton';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(1.5),
      background: 'rgba(230, 230, 230, 0.5)',
      minHeight: '60px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5,
      color: theme.palette.common.black,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color="textSecondary">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface ConfirmationDialogTemplateProps extends DialogProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: 550 },
}));

export default function ConfirmationDialogTemplate(
  props: ConfirmationDialogTemplateProps
) {
  const classes = useStyles();
  const { open, handleClose, title, children, actions } = props;
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}

const dialogActionStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: 127,
  },
}));

export const ConfirmationDialogActionsTemplate = (props: {
  confirm: () => void;
  confirmLabel: string;
  cancel: () => void;
  cancelLabel: string;
}) => {
  const { confirm, cancel, confirmLabel, cancelLabel } = props;
  const classes = dialogActionStyles();

  return (
    <>
      <StyledButton
        className={classes.button}
        customColor="kyoLightRed"
        onClick={cancel}
      >
        {cancelLabel}
      </StyledButton>
      <StyledButton
        className={classes.button}
        customColor="kyoLightGreen"
        onClick={confirm}
      >
        {confirmLabel}
      </StyledButton>
    </>
  );
};

import { Middleware } from 'redux';
import { forEach } from 'lodash';

const addNameProperty = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map((item) => addNameProperty(item));
  }

  if (
    typeof data === 'object' &&
    data !== null &&
    data.firstName &&
    data.lastName &&
    !('name' in data)
  ) {
    Object.defineProperty(data, 'name', {
      get() {
        return `${this.firstName} ${this.lastName}`;
      },
      enumerable: true,
      configurable: true,
    });
  }

  if (typeof data === 'object' && data !== null) {
    forEach(data, (item) => {
      if (typeof item === 'object' && item !== null) {
        addNameProperty(item);
      }
    });
  }

  return data;
};

const namePropertyMiddleware: Middleware = () => (next) => (action) => {
  const modifiedData = addNameProperty(action.payload);

  return next({ ...action, payload: modifiedData });
};

export default namePropertyMiddleware;

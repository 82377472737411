import queryString from 'query-string';
import requester from './requester';
import {
  FetchBookingFilters,
  Request,
  RequestStatus,
  RetractedSessionsResponseType,
} from '../types/types';
import { SessionPlacement } from '../redux/modules/adminSettings';

const fetchBookings = async (
  filters: FetchBookingFilters
): Promise<{ rows: Request[]; count: number } | Request[]> => {
  const query = queryString.stringify(
    {
      ...filters,
      typedStatuses: JSON.stringify(filters.typedStatuses),
    },
    { arrayFormat: 'bracket' }
  );

  return requester
    .get(`v2/bookings?${query}`)
    .then((resp) => resp.json())
    .then((resp) => resp.data);
};

const createSingleTermBookRequest = async (
  clientId: number,
  providerId: number,
  selectedOptions: {
    schedDate: string;
    sessionPlacement: SessionPlacement;
  }[]
) => {
  return requester
    .post(`v2/bookings/single-term-book-requests`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        clientId,
        providerId,
        selectedOptions,
      }),
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

const retractMTSessions = async (
  openSessionId: number,
  sessionIds?: number[]
) => {
  return requester
    .put(`v2/bookings/mid-term-book-requests/${openSessionId}`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ statusId: RequestStatus.RETRACTED, sessionIds }),
    })
    .then((response) => response.json())
    .then((response) => response.data as RetractedSessionsResponseType);
};

const retractSTSessions = async (requestId: number, sessionIds: number[]) => {
  return requester
    .put(`v2/bookings/${requestId}/requestStatus`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        newStatus: RequestStatus.RETRACTED,
        sessionIds,
        shouldNotify: true,
      }),
    })
    .then((response) => response.json())
    .then((response) => response.data as RetractedSessionsResponseType);
};

export default {
  fetchBookings,
  createSingleTermBookRequest,
  retractMTSessions,
  retractSTSessions,
};

import React, { useState } from 'react';
import { IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';

import EmptyAvailabilityInfo from '../../components/Availability/EmptyAvailabilityInfo';
import { RootState } from '../../redux/modules/rootReducer';
import ModifiedAvailabilityList from '../../components/Availability/ModifiedAvailabilityList';
import { maxWidth } from './MyAvailability';
import { useAppDispatch } from '../../redux/store';
import {
  AVAILABILITY_ACTION_STATUS,
  setActionStatus,
} from '../../redux/modules/availability';
import { AvailabilityStatus } from '../../shared/types';
import StyledButtonLink from '../../components/buttons/StyledButtonLink';
import ModifiedAvailabilityContentFooterWrapper from '../../components/Availability/ModifiedAvailabilityFooterWrapper';
import InfoCircleBlue from '../../assets/info-circle-blue.svg';
import ModifiedAvailabilityInfoModal from './ModifiedAvailabilityInfoModal';

interface ModifiedAvailabilityProps {
  disabled?: boolean;
  styles?: Partial<Record<'emptyAvailabilityWrapper', string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    ...maxWidth(theme),
  },
  availabilityListWrapper: {
    ...maxWidth(theme),
    marginTop: theme.spacing(1.5),
  },
  listItem: {
    border: '1px solid #EEEEEE',
    boxShadow:
      '0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionsWrapper: {
    marginTop: theme.spacing(2),
  },
  visibleContent: {
    padding: theme.spacing(1.375, 2.5),
  },
}));

const ModifiedAvailability = ({
  disabled,
  styles,
}: ModifiedAvailabilityProps) => {
  const [isInfoOpen, setIsOpenInfo] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stAvailability = useSelector(
    (state: RootState) => state.availability.stAvailability
  );
  const currentAvailability = useSelector((state: RootState) =>
    state.availability.ltAvailability.find(
      (avail) => avail.status === AvailabilityStatus.Current
    )
  );

  const sortedAvailabilityList = orderBy(stAvailability, (a) => a.startDate);
  const hasAvailability = !!stAvailability.length;

  const handleAdd = () => {
    dispatch(
      setActionStatus({
        status: AVAILABILITY_ACTION_STATUS.ADD_TEMPORARY,
        availability: currentAvailability,
      })
    );
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h6" color="textSecondary">
          Upcoming Temporary Changes
          <IconButton onClick={() => setIsOpenInfo(true)}>
            <img src={InfoCircleBlue} alt="info icon" />
          </IconButton>
        </Typography>
        {hasAvailability && !disabled && (
          <StyledButtonLink onClick={handleAdd}>
            Add Temporary Change
          </StyledButtonLink>
        )}
      </div>
      {(!hasAvailability || disabled) && (
        <div className={styles?.emptyAvailabilityWrapper}>
          <EmptyAvailabilityInfo
            imageSrc={require('../../assets/editWithCalendar.png')}
            handleButtonClick={handleAdd}
            buttonLabel="+ Temporary Change"
          />
        </div>
      )}
      {!disabled && (
        <div className={classes.availabilityListWrapper}>
          <ModifiedAvailabilityList
            availabilities={sortedAvailabilityList}
            styles={{
              listItem: classes.listItem,
              visibleContent: classes.visibleContent,
            }}
            ItemContentFooter={({ availability }) => (
              <ModifiedAvailabilityContentFooterWrapper
                availability={availability}
                hasLinks={false}
              />
            )}
          />
        </div>
      )}
      <ModifiedAvailabilityInfoModal
        open={isInfoOpen}
        onClose={() => setIsOpenInfo(false)}
      />
    </>
  );
};

export default ModifiedAvailability;

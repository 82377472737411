import { useAuth } from './useAuth';
import { ac, Resources } from '../accessControl/acl';

type PermissionType = 'readAny' | 'readOwn' | 'updateAny' | 'updateOwn';

const getPermission = (
  permissionType: PermissionType,
  groups: string[],
  resource: Resources
) => {
  switch (permissionType) {
    case 'readOwn':
      return ac.can(groups).readOwn(resource);
    case 'updateAny':
      return ac.can(groups).updateAny(resource);
    case 'updateOwn':
      return ac.can(groups).updateOwn(resource);
    case 'readAny':
    default:
      return ac.can(groups).readAny(resource);
  }
};

export default function usePermission() {
  const { user } = useAuth();
  const checkPermission = (
    resource: Resources,
    permissionType: PermissionType = 'readAny',
    permissionAttributes?: string[]
  ) => {
    const groups = user?.getSignInUserSession()?.getIdToken().payload[
      'cognito:groups'
    ];

    if (!groups) {
      return false;
    }

    const permission = getPermission(permissionType, groups, resource);

    if (!permission) {
      return false;
    }

    if (permissionAttributes) {
      return permissionAttributes.every(
        (attr) => permission && permission.attributes.includes(attr)
      );
    }

    return permission.granted;
  };

  return checkPermission;
}

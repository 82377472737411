import moment from 'moment';
import React, { useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import {
  Collapse,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { useConfirm } from 'material-ui-confirm';
import { DATE_FORMAT_MONTH_NAME_2 } from '../../utils/helpers';
import { AvailabilityWrapperProps } from '../../providerPortal/myAvailability/MyAvailability';
import AvailabilityMatrix from '../Matrix/AvailabilityMatrix';
import MatrixInfo from '../Matrix/MatrixInfo';
import { useAppDispatch } from '../../redux/store';
import {
  AVAILABILITY_ACTION_STATUS,
  deleteAvailability,
  setActionStatus,
} from '../../redux/modules/availability';
import AvailabilityHeader from './AvailabilityHeader';
import { matrixInfoStyles } from './AvailabilityStyles';
import DemandInfo from '../Matrix/DemandInfo';
import CreatedAvailabilityActions from './CreatedAvailabilityActions';
import IncreaseAvailabilityBadge from '../../providerPortal/providerProfile/IncreaseAvailabilityBadge';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: theme.palette.kyoGray.light,
    borderRadius: theme.spacing(0.75),
    margin: theme.spacing(3.75, 0, 5.25),
    maxWidth: theme.spacing(60),
  },
  headerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2),
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerTitle: {
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
  },
  headerSubTitle: {
    ...theme.typography.subtitle2,
  },
  extendIcon: {
    display: 'flex',
    color: theme.palette.kyoGray63.dark,
  },
  extendedIcon: {
    transform: 'rotate(180deg)',
  },
  availabilityMatrix: {
    marginTop: theme.spacing(1),
  },
  matrixInfo: {
    ...matrixInfoStyles(theme),
    paddingRight: theme.spacing(2),
  },
  cellWrapper: {
    position: 'relative',
  },
  demandImg: {
    position: 'absolute',
    top: -2,
    right: 3,
  },
}));

const UpcomingStandardAvailability = ({
  availability,
  showBooked = true,
  showDemand = false,
  preferredTimes,
  schedule,
  showLowAvailWarning = false,
}: AvailabilityWrapperProps) => {
  const { startDate, availabilityBlocks, id } = availability;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const [extended, setExtended] = useState(false);

  const onDelete = () => {
    confirm({
      description: 'Are you sure you want to delete availability',
    }).then(() => dispatch(deleteAvailability({ id })));
  };

  const onEdit = () => {
    dispatch(
      setActionStatus({ status: AVAILABILITY_ACTION_STATUS.EDIT, availability })
    );
  };

  return (
    <div className={classes.wrapper}>
      <AvailabilityHeader
        title="Upcoming Standard Availability Change"
        subTitle={`Effective date: ${moment(startDate).format(
          DATE_FORMAT_MONTH_NAME_2
        )}`}
        onClick={() => setExtended((state) => !state)}
        action={
          <ExpandMore
            className={clsx(
              classes.extendIcon,
              extended && classes.extendedIcon
            )}
          />
        }
        classes={{
          root: classes.headerRoot,
          title: classes.headerTitle,
          subTitle: classes.headerSubTitle,
        }}
      />
      {showLowAvailWarning && extended && (
        <div style={{ padding: '0 16px' }}>
          <IncreaseAvailabilityBadge />
        </div>
      )}
      <Collapse in={extended}>
        <AvailabilityMatrix
          cellSizes={isMobile ? 4 : 4.5}
          schedule={schedule}
          availabilityBlocks={availabilityBlocks}
          disableWeekends
          styles={{
            root: classes.availabilityMatrix,
          }}
          showBooked={showBooked}
          showDemand={showDemand}
          CellWrapper={DemandInfo}
        />
        <MatrixInfo
          rootClassName={classes.matrixInfo}
          showBooked={showBooked}
          showDemand={showDemand}
        />
        {preferredTimes}
        <CreatedAvailabilityActions onEdit={onEdit} onDelete={onDelete} />
      </Collapse>
    </div>
  );
};

export default UpcomingStandardAvailability;

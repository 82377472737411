import React from 'react';
import { Box, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { PhoneOutlined } from '@material-ui/icons';
import { phoneFormat } from '../utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.grey[400],
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

interface ProviderMobileNumberProps {
  mobile?: string | null;
}

const ProviderMobileNumber = (props: ProviderMobileNumberProps) => {
  const { mobile } = props;
  const classes = useStyles();

  if (!mobile) {
    return null;
  }

  return (
    <Box className={classes.icon} display="flex" alignItems="center" pl="47px">
      <Box>
        <PhoneOutlined fontSize="small" />
      </Box>
      <Box pb={1}>
        <Link href={`tel:${mobile}`} className={classes.link}>
          <Typography variant="body2" color="textSecondary">
            {phoneFormat(mobile)}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default ProviderMobileNumber;

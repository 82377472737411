import React from 'react';
import { Theme, Typography, makeStyles } from '@material-ui/core';

import ActionsControlButton from '../../../components/buttons/ActionsControlButton';
import SimpleDialog from '../../../components/Dialogs/SimpleDialog';
import BookedSrc from '../../../assets/bookingDetails/booked.svg';
import { BookedOption } from '../NewSessions';
import BookedOptionDetails from '../BookedOptionDetails';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      width: theme.spacing(50),
    },
  },
  dialogContent: {
    padding: theme.spacing(2, 4),
    overflowX: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#F7716E',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

interface SelectTimeDialogProps {
  bookedOptions: BookedOption[];
  onClose: () => void;
}

const BookDetailsDialog = ({
  bookedOptions,
  onClose,
}: SelectTimeDialogProps) => {
  const classes = useStyles();

  return (
    <SimpleDialog
      title="Booking Details"
      onClose={onClose}
      styles={{ root: classes.dialog, content: classes.dialogContent }}
    >
      <div className={classes.header}>
        <img src={BookedSrc} alt="calendar icon" />
        <Typography>Booked</Typography>
      </div>

      <div>
        {bookedOptions.map((bookedOption) => {
          return (
            <BookedOptionDetails
              key={`${bookedOption.startTime.toISOString()}`}
              bookedOption={bookedOption}
            />
          );
        })}
      </div>

      <ActionsControlButton
        handleClick={{
          cancelButton: () => {
            onClose();
          },
        }}
        label={{
          cancelButton: 'Cancel',
        }}
        hideButtons={{ successButton: true }}
      />
    </SimpleDialog>
  );
};

export default BookDetailsDialog;

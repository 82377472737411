import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { every, isEmpty, keyBy } from 'lodash';
import { Formik, FormikProps } from 'formik';

import { Button, Divider, Typography } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';

import useTemporaryAvailabilityActions, {
  InitialValues,
} from '../Availability/hooks/useAvailabilityActionsTemporary';
import useStyles from './TemporaryAvailabilityAtionsStyles';
import { RootState } from '../../redux/modules/rootReducer';
import useSettings from '../../hooks/useSettings';
import useModifiedActionsViewState from '../Availability/hooks/useModifiedActionsViewState';
import useResponsiveMatrix from '../Availability/hooks/useResponsiveMatrix';

import {
  AVAILABILITY_ACTION_STATUS,
  Availability,
  AvailabilityBlock,
  fetchPreferredTimeBlockOptions,
  setActionStatus,
} from '../../redux/modules/availability';
import { ModifiedAvailabilityViewState } from '../Availability/constants';
import ContainerWithImage from '../ContainerWithImage';
import AvailabilityActionsHeader from '../Availability/AvailabilityActions/AvailabilityActionsHeader';
import InfoContainer, { InfoContainerTypes } from '../InfoContainer';
import OverlappingSessionsWrapper, {
  OverlappingSessionWarning,
} from '../Availability/OverlappingSessionsWrapper';
import AvailabilityDatesPicker from '../Availability/AvailabilityActions/AvailabilityDatesPicker';
import AvailabilityWeekPicker from '../Availability/AvailabilityActions/AvailabilityWeekPicker';
import RadioView from '../RadioView';
import AvailabilityRecommendations from '../Availability/AvailabilityRecommendations';
import CopyAvailability from '../Availability/CopyAvailability/CopyAvailability';
import AvailabilityMatrix from '../Matrix/AvailabilityMatrix';
import StripedCellWrapper from '../Matrix/StripedCellWrapper';
import MatrixInfo from '../Matrix/MatrixInfo';
import SucceedDialog from '../Availability/AvailabilityActions/SucceedDialog';
import PreferenceSelect from '../../clientPortal/myAvailability/components/PreferenceSelect';
import TemporaryChangesReviewDialogProvider from '../../providerPortal/myAvailability/TemporaryAvailabilityActionsProvider/TemporaryChangeReviewDialog';
import TemporaryChangesReviewDialogClient from '../../clientPortal/myAvailability/TemporaryAvailabilityActionsClient/TemporaryChangesReviewDialog';

import { temporaryAvailabilityValidationSchema } from '../../validation/validation';
import {
  editMatrixBlock,
  getDefaultAvailabilityBlocks,
  isPastBlock,
  resetPastBlocksToInitial,
} from '../../shared/lib/availability';
import { Client, Provider } from '../../types/types';
import {
  unavailableEntirePeriodTexts,
  unavailableSomeDaysTexts,
} from './helper';
import Warning from '../../assets/warning.svg';
import LightIdeaSvg from '../../assets/lightIdea.svg';
import VerticalLabelTimeBlock from '../../shared/matrix/ui/verticalLabelTimeBlock';
import ActionsControlButton from '../buttons/ActionsControlButton';
import {
  checkIsPreferenceSelectDisabled,
  daysToMilliseconds,
  diffOfDatesInDayMilliseconds,
} from '../../utils/helpers';

type AvailabilityActionsClientProps = Partial<
  Pick<Client | Provider, 'schedule'>
> & {
  handleSuccess?: () => void;
  type: 'provider' | 'user';
};

const TemporaryAvailabilityActions = ({
  schedule,
  handleSuccess,
  type,
}: AvailabilityActionsClientProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<InitialValues>>(null);

  const [reviewChanges, setReviewChanges] = useState(false);

  useEffect(() => {
    if (type === 'user') {
      dispatch(fetchPreferredTimeBlockOptions());
    }
  }, [dispatch, type]);
  const preferredTimeBlockOptions = useSelector(
    (state: RootState) => state.availability.preferredTimeBlockOptions
  );

  const stAvailability = useSelector(
    (state: RootState) => state.availability.stAvailability
  );

  const copyAvailabilitySuggestions = stAvailability.filter(
    (avail) => !every(avail.availabilityBlocks, ['isAvailable', false])
  );

  const {
    status: actionStatus,
    availability,
    loading,
    succeeded,
  } = useSelector((state: RootState) => state.availability.action);
  const { timeBlocks } = useSettings();
  const { viewState, setViewState } = useModifiedActionsViewState(
    actionStatus,
    availability,
    formikRef
  );
  const { cellSize: matrixCellSize, isMobile } = useResponsiveMatrix();
  const actionsData = useTemporaryAvailabilityActions({
    availability,
    viewState,
  });
  const emptyAvailabilityBlocks = useMemo(
    () => getDefaultAvailabilityBlocks(),
    []
  );

  const emptyPreferences = {
    preferredBlock1Start: undefined,
    preferredBlock3Start: undefined,
    preferredBlock3End: undefined,
  };

  const onAvailabilitySelect = useCallback(
    (
      avail: Availability,
      setValues: (
        values: React.SetStateAction<InitialValues>,
        shouldValidate?: boolean | undefined
      ) => void,
      startDate: string,
      initialAvailabilityBlocks: Partial<AvailabilityBlock>[]
    ) => {
      const initAvailabilityBlocks = resetPastBlocksToInitial(
        startDate,
        formikRef.current?.values.availabilityBlocks,
        initialAvailabilityBlocks,
        timeBlocks
      );
      const newAvailabilityBlock = avail.availabilityBlocks.map((block) => {
        const isPast = isPastBlock(startDate, timeBlocks, {
          horizontalValue: block.timeBlockDayOfWeek.dayOfWeek.id,
          verticalValue: block.timeBlockDayOfWeek.timeBlock.id,
        });
        if (isPast) {
          return initAvailabilityBlocks?.find(
            (item) => item.timeBlockDayOfWeekId === block.timeBlockDayOfWeekId
          );
        }
        return block;
      });

      setValues((prevState) => ({
        ...prevState,
        availabilityBlocks: newAvailabilityBlock as AvailabilityBlock[],
        preferredBlock1Start: avail.preferredBlock1Start,
        preferredBlock3Start: avail.preferredBlock3Start,
        preferredBlock3End: avail.preferredBlock3End,
      }));
    },
    [timeBlocks]
  );

  if (!actionsData) {
    return <></>;
  }
  const statusData = actionsData[actionStatus];
  const resetPastAvailBlocks = (startDate: string) => {
    const newAvailabilityBlocks = resetPastBlocksToInitial(
      startDate,
      formikRef.current?.values.availabilityBlocks,
      statusData.initialValues.availabilityBlocks,
      timeBlocks
    );
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        'availabilityBlocks',
        newAvailabilityBlocks
      );
    }
  };
  const isUnavailableEntirePeriod =
    viewState === ModifiedAvailabilityViewState.UnavailableEntirePeriod;

  const options = [
    {
      value: ModifiedAvailabilityViewState.UnavailableSomeDays,
      label: (
        <>
          <Typography>
            {unavailableSomeDaysTexts[type].adjustAvailabilityRequest}
          </Typography>
          {(isUnavailableEntirePeriod || !isMobile) && (
            <div className={classes.labelInfoAvailable}>
              <ContainerWithImage imgSrc={LightIdeaSvg} alt="idea">
                <Typography className={classes.labelInfoAvailableHint}>
                  {unavailableSomeDaysTexts[type].addAvailabilityRequest}
                </Typography>
              </ContainerWithImage>
              <ContainerWithImage imgSrc={LightIdeaSvg} alt="idea">
                <Typography className={classes.labelInfoAvailableHint}>
                  {unavailableSomeDaysTexts[type].timeOffRequest}
                </Typography>
              </ContainerWithImage>
            </div>
          )}
        </>
      ),
    },
    {
      value: ModifiedAvailabilityViewState.UnavailableEntirePeriod,
      label: (
        <Typography>
          {unavailableEntirePeriodTexts[type].timeOffRequest}
        </Typography>
      ),
    },
  ];

  const TemporaryChangesReviewDialog =
    type === 'user'
      ? TemporaryChangesReviewDialogClient
      : TemporaryChangesReviewDialogProvider;

  return (
    <>
      <AvailabilityActionsHeader
        handleGoBack={() => {
          dispatch(
            setActionStatus({ status: AVAILABILITY_ACTION_STATUS.PASSIVE })
          );
        }}
        label="Availability Change - Temporary"
      />
      <Divider className={classes.divider} />
      <InfoContainer
        className={clsx(classes.pageMaxSize, classes.info)}
        type={InfoContainerTypes.Warning}
      >
        <Typography variant="body2">
          Tell us about upcoming time off or weeks when your schedule is
          changing.
        </Typography>
      </InfoContainer>

      <RadioView
        styles={{ root: classes.pageMaxSize }}
        options={options}
        value={viewState}
        onChange={(value) => setViewState(value)}
      >
        <Formik
          initialValues={statusData.initialValues}
          validationSchema={() => {
            return temporaryAvailabilityValidationSchema({
              viewState,
              availabilityMaxDuration: type === 'user' ? 56 : 21,
            });
          }}
          innerRef={formikRef}
          onSubmit={(values) => {
            statusData.onSubmit({
              ...values,
              ...(isUnavailableEntirePeriod && {
                availabilityBlocks: emptyAvailabilityBlocks,
                ...(type === 'user' ? emptyPreferences : undefined),
              }),
            });
          }}
        >
          {({
            values,
            setValues,
            errors,
            handleReset,
            setFieldValue,
            submitForm,
          }) => (
            <OverlappingSessionsWrapper
              stAvails={stAvailability}
              startDate={values.startDate}
              endDate={values.endDate}
              ignoreId={
                actionStatus === AVAILABILITY_ACTION_STATUS.EDIT_TEMPORARY
                  ? availability?.id
                  : undefined
              }
            >
              {(overlappingSession) => (
                <>
                  <div className={classes.formContent}>
                    {isUnavailableEntirePeriod ? (
                      <AvailabilityDatesPicker
                        onChange={setFieldValue}
                        startDate={values.startDate}
                        endDate={values.endDate}
                        startDates={{
                          min: statusData.minTimeOffStartDate,
                          max: statusData.maxTimeOffStartDate,
                        }}
                        errors={errors}
                        duration={{
                          min: { value: 1, unit: 'day' },
                          max: {
                            value: type === 'user' ? 8 : 3,
                            unit: 'weeks',
                          },
                        }}
                      />
                    ) : (
                      <AvailabilityWeekPicker
                        startDate={values.startDate}
                        endDate={values.endDate}
                        setValues={(args) => {
                          setValues({ ...values, ...args });
                        }}
                        error={errors.startDate || errors.endDate}
                        startDates={{
                          min: statusData.minStartDate,
                          max: statusData.maxStartDate,
                        }}
                        resetPastAvailBlocks={resetPastAvailBlocks}
                      />
                    )}
                    {overlappingSession && (
                      <ContainerWithImage
                        imgSrc={Warning}
                        alt="warning"
                        styles={{ root: classes.overlapSessionError }}
                      >
                        <OverlappingSessionWarning
                          startDate={overlappingSession.startDate}
                          endDate={overlappingSession.endDate}
                        />
                      </ContainerWithImage>
                    )}
                    {type === 'user' &&
                      values.startDate &&
                      values.endDate &&
                      diffOfDatesInDayMilliseconds(
                        values.startDate,
                        values.endDate
                      ) >= daysToMilliseconds(3 * 7) && (
                        <InfoContainer type={InfoContainerTypes.Error}>
                          <div className={classes.sessionImpactWarningContent}>
                            <img src={Warning} alt="warning" />
                            <Typography variant="body2">
                              Time Off requests that are longer than three weeks
                              may result in the reassignment of your Care Team.
                            </Typography>
                          </div>
                        </InfoContainer>
                      )}

                    {!isUnavailableEntirePeriod && (
                      <>
                        {isMobile && <Divider className={classes.divider} />}
                        <AvailabilityRecommendations
                          className={classes.recommendations}
                        />
                        <Divider className={classes.matrixDivider} />

                        {copyAvailabilitySuggestions.length > 0 && (
                          <div>
                            <CopyAvailability
                              availability={copyAvailabilitySuggestions}
                              setValues={setValues}
                              startDate={values.startDate}
                              onSelect={onAvailabilitySelect}
                              initialAvailabilityBlocks={
                                statusData.initialValues.availabilityBlocks
                              }
                            />
                            <Divider className={clsx(classes.mb, classes.mt)} />
                          </div>
                        )}

                        <AvailabilityMatrix
                          schedule={schedule}
                          availabilityBlocks={values.availabilityBlocks}
                          cellSizes={matrixCellSize}
                          VerticalLabel={({ value }) => (
                            <VerticalLabelTimeBlock
                              value={value}
                              timeBlock={keyBy(timeBlocks, 'id')[value]}
                              styles={{
                                root: classes.matrixVerticalLabel,
                                name: classes.matrixVerticalLabelName,
                                content: classes.matrixVerticalLabelContent,
                              }}
                              periodNameLength={2}
                            />
                          )}
                          useVerticalLabelsInsideRows={isMobile}
                          disableWeekends
                          onCellClick={({
                            key,
                            isAvailable,
                            horizontalVerticalValues,
                          }) => {
                            const inPast = isPastBlock(
                              values.startDate,
                              timeBlocks,
                              horizontalVerticalValues
                            );

                            if (inPast) {
                              return;
                            }

                            setFieldValue(
                              'availabilityBlocks',
                              editMatrixBlock(
                                key,
                                isAvailable,
                                values.availabilityBlocks
                              )
                            );
                          }}
                          CellWrapper={(args) => (
                            <StripedCellWrapper
                              {...args}
                              startDate={values.startDate}
                            />
                          )}
                        />
                        <div className={classes.infoAndResetRow}>
                          <MatrixInfo rootClassName={classes.matrixInfo} />
                          <Button
                            startIcon={<ReplayIcon />}
                            className={clsx(
                              classes.textCapitalize,
                              classes.mainColor
                            )}
                            variant="text"
                            onClick={handleReset}
                          >
                            <Typography variant="body2">
                              Reset to current
                            </Typography>
                          </Button>
                        </div>
                        {type === 'user' && (
                          <>
                            <PreferenceSelect
                              label="I cannot start my day before"
                              value={values.preferredBlock1Start}
                              onChange={(newValue) => {
                                setFieldValue('preferredBlock1Start', newValue);
                              }}
                              options={
                                preferredTimeBlockOptions?.block1StartOptions
                              }
                              disabled={checkIsPreferenceSelectDisabled(
                                1,
                                values.availabilityBlocks
                              )}
                            />
                            <PreferenceSelect
                              label="My child is home from school by"
                              value={values.preferredBlock3Start}
                              onChange={(newValue) => {
                                setFieldValue('preferredBlock3Start', newValue);
                              }}
                              options={
                                preferredTimeBlockOptions?.block3StartOptions
                              }
                              disabled={checkIsPreferenceSelectDisabled(
                                3,
                                values.availabilityBlocks
                              )}
                              error={!!errors.preferredBlock3End}
                            />
                            <PreferenceSelect
                              label="I cannot accommodate sessions past"
                              value={values.preferredBlock3End}
                              onChange={(newValue) => {
                                setFieldValue('preferredBlock3End', newValue);
                              }}
                              options={
                                preferredTimeBlockOptions?.block3EndOptions
                              }
                              disabled={checkIsPreferenceSelectDisabled(
                                3,
                                values.availabilityBlocks
                              )}
                              error={!!errors.preferredBlock3End}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <ActionsControlButton
                    disabled={{
                      successButton:
                        loading || !isEmpty(errors) || !!overlappingSession,
                      cancelButton: loading,
                    }}
                    handleClick={{
                      successButton: () => setReviewChanges(true),
                      cancelButton: statusData.handleClose,
                    }}
                    label={{
                      successButton: 'Review and Save',
                      cancelButton: 'Cancel',
                    }}
                    styles={{
                      buttonRoot: classes.actionButton,
                      buttonWrapper: classes.actionButtons,
                    }}
                  />
                  {reviewChanges && (
                    <TemporaryChangesReviewDialog
                      handleClose={() => setReviewChanges(false)}
                      handleSave={submitForm}
                      schedule={schedule}
                      availabilityBlocks={
                        !isUnavailableEntirePeriod
                          ? values.availabilityBlocks
                          : emptyAvailabilityBlocks
                      }
                      startDate={values.startDate}
                      endDate={values.endDate}
                      preferences={
                        type === 'user'
                          ? {
                              preferredBlock1Start: values.preferredBlock1Start,
                              preferredBlock3Start: values.preferredBlock3Start,
                              preferredBlock3End: values.preferredBlock3End,
                            }
                          : undefined
                      }
                      unavailableEntirePeriod={isUnavailableEntirePeriod}
                      isCancellableByClient={type === 'user'}
                      isCancellableByProvider={type === 'provider'}
                    />
                  )}
                </>
              )}
            </OverlappingSessionsWrapper>
          )}
        </Formik>
      </RadioView>
      {succeeded && <SucceedDialog handleClose={handleSuccess} />}
    </>
  );
};

export default TemporaryAvailabilityActions;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import maintenanceApi from '../../api/maintenanceApi';

export interface MaintenanceState {
  status: boolean;
}

const initialState: MaintenanceState = {
  status: false,
};

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setMaintenanceStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      status: action.payload,
    }),
  },
});

export const { setMaintenanceStatus } = maintenanceSlice.actions;

export default maintenanceSlice.reducer;

export const setMaintenance = (status: boolean): AppThunk => (
  dispatch,
  getState
) => {
  const isMaintenanceOn = getState().maintenance.status;
  if (isMaintenanceOn !== status) {
    dispatch(setMaintenanceStatus(status));
  }
};

export const fetchMaintenance = (): AppThunk => async (dispatch) => {
  try {
    const { underMaintenance } = await maintenanceApi.fetchMaintenance();
    dispatch(setMaintenanceStatus(underMaintenance));
  } catch (e: any) {
    console.error(e);
  }
};

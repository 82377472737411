import React from 'react';
import { Avatar, Box, Typography, makeStyles, Theme } from '@material-ui/core';
import CovidBadgeIcon from '../badges/CovidBadge/CovidBadgeIcon';
import StyledButtonLink from '../buttons/StyledButtonLink';
import MyCaseTeamBadgeIcon from '../badges/MyCaseTeamBadge/MyCaseTeamBadgeIcon';
import { Provider, SessionStatus } from '../../types/types';
import ProviderBCBABadge from '../badges/BCBABadge/ProviderBCBABadge';

const useStyles = makeStyles((theme: Theme) => ({
  providerCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  viewDetails: {
    fontWeight: 400,
  },
  badgeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    '& > *': {
      marginTop: 2,
    },
  },
  cancelled: {
    whiteSpace: 'nowrap',
    color: theme.palette.kyoGray63.main,
    fontSize: 10,
  },
}));

interface ProviderCellProps {
  provider: Pick<
    Provider,
    'firstName' | 'lastName' | 'profilePhoto' | 'covidVaccinatedDate' | 'title'
  >;
  isToggled?: boolean;
  onToggle?: Function;
  isMyTeam?: boolean;
  sessionStatus?: SessionStatus;
}

const ProviderCell = (props: ProviderCellProps) => {
  const { provider, isToggled, onToggle, isMyTeam, sessionStatus } = props;
  const classes = useStyles();
  return (
    <Box className={classes.providerCell}>
      <Avatar src={provider.profilePhoto} className={classes.avatar} />
      <Box>
        <Typography>{`${provider.firstName} ${provider.lastName}`}</Typography>
        <div className={classes.badgeContainer}>
          {provider.covidVaccinatedDate && <CovidBadgeIcon />}
          {isMyTeam && <MyCaseTeamBadgeIcon />}
          {sessionStatus && sessionStatus === SessionStatus.Cancelled && (
            <Typography className={classes.cancelled}>Cancelled</Typography>
          )}
          <ProviderBCBABadge provider={provider} />
        </div>
        {onToggle && (
          <div>
            <StyledButtonLink
              onClick={() => {
                onToggle();
              }}
              className={classes.viewDetails}
            >
              {isToggled ? 'Hide details' : 'View details'}
            </StyledButtonLink>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default ProviderCell;

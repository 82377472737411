import { useMemo } from 'react';
import { useAppSelector } from '../redux/store';

interface AnalyticsData {
  isReady: boolean;
  selectedClientId: number | null;
  providerId: number | null;
}

const useAnalyticsData = (): AnalyticsData => {
  // provides data needed for every analytics request

  const selectedClientId = useAppSelector(
    (state) => state.client.currentClient?.id
  );
  const providerId = useAppSelector((state) => state.provider.provider?.id);

  const isReady = !!selectedClientId || !!providerId;

  const data = useMemo(
    () => ({
      isReady,
      selectedClientId: selectedClientId || null,
      providerId: providerId || null,
    }),
    [selectedClientId, providerId, isReady]
  );

  return data;
};

export default useAnalyticsData;

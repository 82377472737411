import {
  Box,
  Button,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { orderBy, some } from 'lodash';
import { useDebounce } from 'use-debounce';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { Link } from 'react-router-dom';
import { DateObject } from 'react-multi-date-picker';
import clsx from 'clsx';

import StyledButton from '../../components/buttons/StyledButton';
import CustomTableFooter from '../../components/table/CustomTableFooter';
import ProviderCell from '../../components/table/ProviderCell';
import HintText from '../../components/HintText/HintText';
import { RootState } from '../../redux/modules/rootReducer';
import infoCircleSrc from '../../assets/info-circle.svg';
import {
  DATE_FORMAT,
  DATE_FORMAT_MONTH_NAME,
  TIME_FORMAT,
  TIME_FORMAT_24HR,
} from '../../utils/helpers';
import ScreenTitle from '../../components/ScreenTitle';
import {
  fetchClientSingleTermBookingAvailability,
  setCreateSessionSuccess,
  setSelectedProvider,
  setToInitialState,
} from '../../redux/modules/newSessions';
import useAddSessions from '../../hooks/useAddSessions';
import { ProviderWithBookDetails } from '../../types/types';
import CustomLoading from '../../components/CustomLoading/CustomLoading';
import warningDiamondIcon from '../../assets/warning-diamond-icon.svg';
import AddSessionFilters from './AddSessionFIlters';
import NewSessions from './NewSessions';
import InfoPopup from './dialogs/InfoPopup';
import useSessionTrackerId from '../../hooks/useSessionTrackerId';
import AnalyticsTracker, {
  TrackerNames,
} from '../../analytics/AnalyticsTracker';

const useStyles = makeStyles((theme: Theme) => ({
  expandedTableCell: {
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cancelButton: {
    marginTop: theme.spacing(2),
  },
  section: {
    margin: theme.spacing(2, 0),
  },
  warningText: {
    display: 'flex',
    gap: theme.spacing(1.5),
  },
  infoSection: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1.5),
  },
  title: {
    display: 'flex',
  },
}));

const getColumns = (
  providers: ProviderWithBookDetails[],
  expandedRow: number | null,
  onExpand: (dataIndex: number, isExpanded: boolean) => void,
  isSmallScreen: boolean,
  handleAddSession: (provider: ProviderWithBookDetails) => void,
  showPreferredDates = false
): MUIDataTableColumn[] => [
  {
    name: 'ProviderName',
    label: 'Provider',
    options: {
      display: isSmallScreen ? 'excluded' : true,
      customBodyRenderLite: (dataIndex) => {
        const provider = providers[dataIndex];
        const isExpanded = expandedRow === dataIndex;
        return (
          <div style={{ minHeight: 60 }}>
            <ProviderCell
              provider={provider}
              isToggled={isExpanded}
              onToggle={() => {
                onExpand(dataIndex, isExpanded);
              }}
              isMyTeam={provider.bookDetails.inCaseTeam}
            />
          </div>
        );
      },
    },
  },
  {
    name: 'rebookOptions',
    label: showPreferredDates ? 'Preferred Date Avail.' : 'Next Avail. Date',
    options: {
      display: isSmallScreen ? 'excluded' : true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        const provider = providers[dataIndex];

        if (
          showPreferredDates &&
          provider.bookDetails.preferredDatesAvailability
        ) {
          const { preferredDatesAvailability } = provider.bookDetails;
          const text = preferredDatesAvailability
            .map((d: string) => moment.utc(d).format(DATE_FORMAT_MONTH_NAME))
            .join(', ');
          return (
            <div>
              <Typography>{text}</Typography>
            </div>
          );
        }

        const { bookOptions } = provider.bookDetails;
        let sessionPlacement;
        let schedDate;
        if (bookOptions && bookOptions.length > 0) {
          const earliest = orderBy(bookOptions, 'timeBlockStartTime', 'asc')[0];
          schedDate = earliest.scheduledDate;
          const { sessionPlacements } = earliest;

          if (sessionPlacements && sessionPlacements.length > 0) {
            [sessionPlacement] = sessionPlacements;
          }
        }

        return (
          <div>
            <Typography>
              {moment.utc(schedDate).format(DATE_FORMAT_MONTH_NAME)}
            </Typography>
            {sessionPlacement && (
              <Typography variant="body2" color="textSecondary">
                {moment
                  .utc(sessionPlacement.startTime, TIME_FORMAT_24HR)
                  .format(TIME_FORMAT)}
              </Typography>
            )}
          </div>
        );
      },
    },
  },
  {
    name: 'potentialSessions',
    label: 'Potential Sessions',
    options: {
      display: isSmallScreen ? 'excluded' : true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        const provider = providers[dataIndex];
        return (
          <div>
            <Typography>
              {provider.bookDetails.numOfPotentialSessions}
            </Typography>
          </div>
        );
      },
    },
  },
  {
    name: '',
    label: 'Add Sessions',
    options: {
      sort: false,
      display: isSmallScreen ? 'excluded' : true,
      customBodyRenderLite: (dataIndex: number) => {
        const provider = providers[dataIndex];
        return (
          <StyledButton onClick={() => handleAddSession(provider)}>
            <Typography variant="body2" style={{ color: '#1672EC' }}>
              Add Sessions
            </Typography>
          </StyledButton>
        );
      },
    },
  },
  {
    name: '',
    label: '',
    options: {
      sort: false,
      display: isSmallScreen ? true : 'excluded',
      customBodyRenderLite: (dataIndex: number) => {
        const provider = providers[dataIndex];
        const isExpanded = expandedRow === dataIndex;
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              minHeight: 60,
            }}
          >
            <ProviderCell
              provider={provider}
              isToggled={isExpanded}
              onToggle={() => {
                onExpand(dataIndex, isExpanded);
              }}
              isMyTeam={provider.bookDetails.inCaseTeam}
            />
            <StyledButton
              onClick={() => handleAddSession(provider)}
              style={{ padding: 0, minHeight: 'unset' }}
            >
              <Typography variant="body2" style={{ color: '#1672EC' }}>
                Add Sessions
              </Typography>
            </StyledButton>
          </div>
        );
      },
    },
  },
];

const AddSessions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef(true);
  const firstRender = ref.current;

  const { startTracking, stopTracking } = useSessionTrackerId();

  const isMobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const isBetweenSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('sm')
  );

  const currentClient = useSelector(
    (state: RootState) => state.client.currentClient
  );

  const currentClientLoading = useSelector(
    (state: RootState) => state.client.currentClientLoading
  );

  const clientSTBRAvailability = useSelector(
    (state: RootState) => state.newSessions.clientSTBRAvailability
  );
  const clientSTBRAvailabilityLoading = useSelector(
    (state: RootState) => state.newSessions.clientSTBRAvailabilityLoading
  );

  const selectedProvider = useSelector(
    (state: RootState) => state.newSessions.selectedProvider
  );

  const createSessionSuccess = useSelector(
    (state: RootState) => state.newSessions.createSessionSuccess
  );

  const [showInfo, setShowInfo] = useState(false);

  const [expandedRows, setExpandedRows] = useState<{
    allProviders: number | null;
    providersOnPreferredDates: number | null;
    providersOnOtherDates: number | null;
  }>({
    allProviders: null,
    providersOnOtherDates: null,
    providersOnPreferredDates: null,
  });

  const [preferredDates, setPreferredDates] = useState<DateObject[]>([]);

  const [
    showOptionsOutsideOfMyAvailability,
    setShowOptionsOutsideOfMyAvailability,
  ] = useState(false);

  const [filtersListOpen, setFiltersListOpen] = useState(false);

  const [providersOnPreferredDates, setProvidersOnPreferredDates] = useState<
    ProviderWithBookDetails[]
  >([]);

  const [providersOnOtherDates, setProvidersOnOtherDates] = useState<
    ProviderWithBookDetails[]
  >([]);

  const [debouncedShowOptionsOutsideOfMyAvailability] = useDebounce(
    showOptionsOutsideOfMyAvailability,
    800
  );
  const {
    singleSessionProviders,
    getSingleSessionProviders,
    singleSessionProvidersLoading,
  } = useAddSessions();

  const { canClientBook } = clientSTBRAvailability;
  const {
    id: currentClientId,
    attributes: { pause = false, assignmentApprovalRequired = false } = {},
  } = currentClient || {};

  const [isShowMyTeam, setShowMyTeam] = useState(assignmentApprovalRequired);

  const [debouncedShowMyTeam] = useDebounce(isShowMyTeam, 800);

  const clientCanBook = !!canClientBook && !pause;

  const notEnoughAvailableBlocks = !singleSessionProviders?.length;

  const fetchSingleSessionProviders = useCallback(() => {
    if (currentClientId && clientCanBook) {
      getSingleSessionProviders({
        caseTeam: debouncedShowMyTeam ? 'only' : undefined,
        ignoreAvailability: debouncedShowOptionsOutsideOfMyAvailability,
      });
    }
  }, [
    currentClientId,
    clientCanBook,
    getSingleSessionProviders,
    debouncedShowMyTeam,
    debouncedShowOptionsOutsideOfMyAvailability,
  ]);

  const handleGoBack = () => {
    dispatch(setSelectedProvider(null));
    dispatch(setCreateSessionSuccess(false));
  };

  useEffect(
    () => () => {
      dispatch(setToInitialState());
    },
    [dispatch]
  );

  useEffect(() => {
    startTracking();

    return () => {
      stopTracking();
    };
  }, [startTracking, stopTracking]);

  useEffect(() => {
    if (currentClientId && !createSessionSuccess) {
      // dont make this request on success screen
      dispatch(fetchClientSingleTermBookingAvailability(currentClientId));
      const isShowTeam = assignmentApprovalRequired;
      setShowMyTeam(isShowTeam);
    }
  }, [
    dispatch,
    currentClientId,
    assignmentApprovalRequired,
    createSessionSuccess,
  ]);

  useEffect(() => {
    if (
      currentClientId &&
      !currentClientLoading &&
      !firstRender &&
      !clientSTBRAvailabilityLoading
    ) {
      fetchSingleSessionProviders();
    }

    if (currentClientLoading) {
      setPreferredDates([]);
      setShowOptionsOutsideOfMyAvailability(false);
    }
  }, [
    firstRender,
    fetchSingleSessionProviders,
    currentClientId,
    currentClientLoading,
    clientSTBRAvailabilityLoading,
  ]);

  useEffect(() => {
    if (!currentClient) {
      dispatch(setToInitialState());
    }
    ref.current = false;
  }, [currentClient, dispatch]);

  useEffect(() => {
    if (preferredDates.length && singleSessionProviders) {
      const filteredProviders: ProviderWithBookDetails[] = [];
      const otherProviders: ProviderWithBookDetails[] = [];
      singleSessionProviders.forEach((provider) => {
        const preferredDatesAvailability: string[] = [];
        preferredDates.forEach((preferredDate) => {
          const dateIsAvailable = some(
            provider.bookDetails.bookOptions,
            (bookOption) => {
              return (
                moment(bookOption.scheduledDate.split('T')[0]).format(
                  DATE_FORMAT
                ) === preferredDate.format(DATE_FORMAT)
              );
            }
          );
          if (dateIsAvailable) {
            preferredDatesAvailability.push(preferredDate.format(DATE_FORMAT));
          }
        });

        if (preferredDatesAvailability.length) {
          filteredProviders.push({
            ...provider,
            bookDetails: {
              ...provider.bookDetails,
              preferredDatesAvailability,
            },
          });
        } else {
          otherProviders.push({
            ...provider,
            bookDetails: {
              ...provider.bookDetails,
              preferredDatesAvailability: [],
            },
          });
        }
      });

      setProvidersOnPreferredDates(filteredProviders);
      setProvidersOnOtherDates(otherProviders);
    }
  }, [preferredDates, singleSessionProviders]);

  const handleRowExpand = (type: keyof typeof expandedRows) => (
    dataIndex: number
  ) => {
    setExpandedRows((currentState) => {
      const newState = { ...currentState };
      if (currentState[type] === dataIndex) {
        newState[type] = null;
      } else {
        newState[type] = dataIndex;
      }
      return newState;
    });
  };

  const handleAddSession = (provider: ProviderWithBookDetails) => {
    dispatch(setSelectedProvider(provider));
  };

  const getTableOptions: (
    providerList: ProviderWithBookDetails[] | null,
    expandedRow: number | null
  ) => MUIDataTableOptions = (providerList, expandedRow) => ({
    viewColumns: false,
    selectableRows: 'none',
    search: false,
    print: false,
    filter: false,
    download: false,
    elevation: 0,
    responsive: 'standard',
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15],
    rowHover: false,
    expandableRows: true,
    expandableRowsHeader: false,
    renderExpandableRow: (_, rowMeta) => {
      const { dataIndex } = rowMeta;
      if (!providerList) {
        return null;
      }

      const provider = providerList[dataIndex];

      const languages = [
        'English',
        provider.attributes?.spanish && 'Spanish',
        provider.attributes?.mandarin && 'Mandarin',
        provider.attributes?.cantonese && 'Cantonese',
      ];

      const languageString = `Languages: ${languages
        .filter(Boolean)
        .join(', ')}`;

      return (
        <TableRow>
          <TableCell colSpan={1000} className={classes.expandedTableCell}>
            <div
              style={{
                paddingLeft: isBetweenSmallScreen ? 16 : 0,
                marginLeft: 48,
              }}
            >
              <Typography variant="body2">{languageString}</Typography>
              {provider.attributes?.bio && (
                <Typography variant="body2">
                  {provider.attributes.bio}
                </Typography>
              )}
            </div>
          </TableCell>
        </TableRow>
      );
    },
    rowsExpanded: [expandedRow],
  });

  const showLoading =
    singleSessionProvidersLoading ||
    currentClientLoading ||
    clientSTBRAvailabilityLoading;

  const clientSTBRAvailabilityLoaded =
    !currentClientLoading && !clientSTBRAvailabilityLoading;

  const showPreferredDateSection = !!preferredDates.length;

  const infoIcon = <img src={infoCircleSrc} alt="infoIcon" />;

  const hintText = (
    <HintText
      clientCanRebook={clientCanBook}
      notEnoughAvailableBlocks={notEnoughAvailableBlocks}
      clientSTBRAvailability={clientSTBRAvailability}
      assignmentApprovalRequired={assignmentApprovalRequired}
      includeEnoughOptionsHint={!isMobileScreen}
    />
  );

  if (selectedProvider) {
    const matchedPreferredDates = preferredDates.filter((preferredDate) =>
      selectedProvider.bookDetails.bookOptions.some(
        (bookOption) =>
          preferredDate.format(DATE_FORMAT) ===
          bookOption.scheduledDate.split('T')[0]
      )
    );

    const smallestPreferredDate = (() => {
      if (!matchedPreferredDates.length) {
        return undefined;
      }

      if (
        matchedPreferredDates.length === 1 ||
        matchedPreferredDates[0].valueOf() < matchedPreferredDates[1].valueOf()
      ) {
        return matchedPreferredDates[0];
      }

      return matchedPreferredDates[1];
    })();

    return (
      <NewSessions
        goBack={handleGoBack}
        preferredDate={smallestPreferredDate}
      />
    );
  }

  return (
    <>
      <Box>
        {clientSTBRAvailabilityLoaded && clientCanBook && (
          <AnalyticsTracker name={TrackerNames.AddSessionsSelectProvider} />
        )}
        {clientSTBRAvailabilityLoaded &&
          (!clientCanBook || notEnoughAvailableBlocks) && (
            <AnalyticsTracker name={TrackerNames.AddSessionsTryLater} />
          )}
        <ScreenTitle
          title="Add Sessions"
          Comp={
            isMobileScreen && clientCanBook ? (
              <Button onClick={() => setShowInfo(true)}>{infoIcon}</Button>
            ) : (
              <></>
            )
          }
          className={classes.title}
        />
        {clientSTBRAvailabilityLoaded && (!isMobileScreen || !clientCanBook) && (
          <div className={clsx(classes.section, classes.infoSection)}>
            {clientCanBook && infoIcon}
            <div>{hintText}</div>
          </div>
        )}
        {clientSTBRAvailabilityLoaded && clientCanBook && (
          <AddSessionFilters
            preferredDates={preferredDates}
            setPreferredDates={setPreferredDates}
            isShowMyTeam={isShowMyTeam}
            setShowMyTeam={setShowMyTeam}
            showOptionsOutsideOfMyAvailability={
              showOptionsOutsideOfMyAvailability
            }
            setShowOptionsOutsideOfMyAvailability={
              setShowOptionsOutsideOfMyAvailability
            }
            filtersListOpen={filtersListOpen}
            setFiltersListOpen={setFiltersListOpen}
            clientAttributes={currentClient?.attributes}
          />
        )}
        {showLoading ? (
          <CustomLoading />
        ) : (
          <>
            {clientCanBook ? (
              <>
                {showPreferredDateSection && (
                  <>
                    <div className={classes.section}>
                      {providersOnPreferredDates.length ? (
                        <>
                          <Typography variant="body1">
                            These therapists are available on your preferred
                            date:
                          </Typography>
                          <MUIDataTable
                            options={getTableOptions(
                              providersOnPreferredDates,
                              expandedRows.providersOnPreferredDates
                            )}
                            title=""
                            data={providersOnPreferredDates || []}
                            columns={getColumns(
                              providersOnPreferredDates || [],
                              expandedRows.providersOnPreferredDates,
                              handleRowExpand('providersOnPreferredDates'),
                              isSmallScreen,
                              handleAddSession,
                              true
                            )}
                            components={{
                              TableFooter: CustomTableFooter,
                            }}
                          />
                        </>
                      ) : (
                        <div className={classes.warningText}>
                          <img
                            src={warningDiamondIcon}
                            height={24}
                            width={24}
                            alt="warning icon"
                          />
                          <Typography variant="body1" color="error">
                            Sorry - no therapists are available on your
                            preferred day
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div className={classes.section}>
                      <Typography variant="body1">
                        Therapists with availability on other dates:
                      </Typography>
                      <MUIDataTable
                        options={getTableOptions(
                          providersOnOtherDates,
                          expandedRows.providersOnOtherDates
                        )}
                        title=""
                        data={providersOnOtherDates || []}
                        columns={getColumns(
                          providersOnOtherDates || [],
                          expandedRows.providersOnOtherDates,
                          handleRowExpand('providersOnOtherDates'),
                          isSmallScreen,
                          handleAddSession
                        )}
                        components={{
                          TableFooter: CustomTableFooter,
                        }}
                      />
                    </div>
                  </>
                )}
                {!showPreferredDateSection && (
                  <MUIDataTable
                    options={getTableOptions(
                      singleSessionProviders,
                      expandedRows.allProviders
                    )}
                    title=""
                    data={singleSessionProviders || []}
                    columns={getColumns(
                      singleSessionProviders || [],
                      expandedRows.allProviders,
                      handleRowExpand('allProviders'),
                      isSmallScreen,
                      handleAddSession
                    )}
                    components={{
                      TableFooter: CustomTableFooter,
                    }}
                  />
                )}
              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Link to="/home" style={{ textDecoration: 'none' }}>
                  <StyledButton
                    className={classes.cancelButton}
                    customColor="kyoLightGreen"
                    endIcon={<ArrowRightAltIcon />}
                  >
                    Return Home
                  </StyledButton>
                </Link>
              </div>
            )}
          </>
        )}
      </Box>
      {showInfo && clientCanBook && (
        <InfoPopup
          text={hintText}
          handleClose={() => setShowInfo(false)}
          handleShowOutsideMyAvailabilityTimes={() => {
            setShowInfo(false);
            setFiltersListOpen(true);
          }}
        />
      )}
    </>
  );
};

export default AddSessions;

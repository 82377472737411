import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import helpApi, { HelpRequestBody } from '../../api/helpApi';
import { RootState } from './rootReducer';

export interface HelpState {
  creatingHelpRequest: boolean;
  creatingHelpRequestSucceed: boolean;
}

const initialState: HelpState = {
  creatingHelpRequest: false,
  creatingHelpRequestSucceed: false,
};

export const createHelpRequest = createAsyncThunk<
  void,
  Omit<HelpRequestBody, 'clientId'>,
  { state: RootState }
>('help/createHelpRequest', async (data, thunkApi) => {
  const clientId = thunkApi.getState().client.currentClient?.id;

  if (clientId) {
    return helpApi.createHelpRequest({ ...data, clientId });
  }
  return undefined;
});

const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    resetHelpRequestState: (state) => {
      state.creatingHelpRequest = false;
      state.creatingHelpRequestSucceed = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createHelpRequest.pending, (state) => {
        state.creatingHelpRequest = true;
        state.creatingHelpRequestSucceed = false;
      })
      .addCase(createHelpRequest.fulfilled, (state) => {
        state.creatingHelpRequest = false;
        state.creatingHelpRequestSucceed = true;
      })
      .addCase(createHelpRequest.rejected, (state) => {
        state.creatingHelpRequest = false;
      });
  },
});

export const { resetHelpRequestState } = helpSlice.actions;

export default helpSlice.reducer;

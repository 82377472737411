import { Divider, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[700],
  },
  divider: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

interface ScreenTitleProps {
  title: string;
  Comp?: React.ReactElement;
  className?: string;
}

const ScreenTitle = (props: ScreenTitleProps) => {
  const { title, Comp, className } = props;
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" className={clsx(classes.heading, className)}>
        {title} {Comp}
      </Typography>
      <Divider className={classes.divider} />
    </>
  );
};

export default ScreenTitle;

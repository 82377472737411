import React from 'react';
import { Box } from '@material-ui/core';
import CancellationInfo from '../../clientPortal/sessions/cancellationInfo';
import useSettings from '../../hooks/useSettings';
import { useAppSelector } from '../../redux/store';
import { isBT } from '../../utils/helpers';

import { ProviderAttendanceStatusId } from '../../components/AttendanceStatus/constants/constants';

const ProviderCancellationInfo = () => {
  const currentProvider = useAppSelector((state) => state.provider.provider);
  const { attendanceStatusThresholds } = useSettings();

  const {
    applicableCancelRate = 0,
    providerAttendanceStatusId: attendanceStatusId,
  } = currentProvider?.calculations || {};

  const { lower = 0, upper = 0 } =
    attendanceStatusThresholds.find((s) => s.id === attendanceStatusId) || {};

  const isHighCanceler =
    attendanceStatusId === ProviderAttendanceStatusId.HIGH_CANCELER ||
    attendanceStatusId === ProviderAttendanceStatusId.VERY_HIGH_CANCELER;
  const isNearHighCanceler =
    attendanceStatusId === ProviderAttendanceStatusId.GOOD_STANDING &&
    upper - 1 <= applicableCancelRate;

  if (
    attendanceStatusId === undefined ||
    !isBT(currentProvider) ||
    (!isHighCanceler && !isNearHighCanceler)
  ) {
    return null;
  }

  return (
    <Box>
      <CancellationInfo
        attendanceStatusId={attendanceStatusId}
        lowerCancelThreshold={lower}
        upperCancelThreshold={upper}
      />
    </Box>
  );
};

export default ProviderCancellationInfo;

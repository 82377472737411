import { JiraTicketType } from '../types/types';
import requester from './requester';

const fetchJiraTicketTypes = async (): Promise<JiraTicketType[]> => {
  return requester
    .get('jira-ticket-types')
    .then((response) => response.json())
    .then((response) => response?.data);
};

export default { fetchJiraTicketTypes };

import React, { useState } from 'react';
import { Divider, makeStyles, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import clsx from 'clsx';
import moment from 'moment';

import { RootState } from '../../redux/modules/rootReducer';
import StyledButtonLink from '../buttons/StyledButtonLink';
import { ScheduleSegment, SessionStatus } from '../../types/types';
import ContainerWithImage from '../ContainerWithImage';
import BookRedSvg from '../../assets/book_red.svg';

const useSessionStyles = makeStyles((theme: Theme) => ({
  loadMoreButton: {
    margin: '0 auto',
    display: 'block',
  },
  sessionsSectionHeader: {
    margin: theme.spacing(1, 0),
  },
  timeOffSessionHeader: {
    color: '#616161',
  },
  cancelledSessionsHeader: {
    color: '#F5413D',
  },
  sessionHeader: {
    fontWeight: theme.typography.subtitle1.fontWeight,
    fontSize: theme.typography.subtitle1.fontSize,
  },
}));

const ScheduleSessionsListWrapper = ({
  children,
}: {
  children: (session: ScheduleSegment) => React.ReactNode;
}) => {
  const classes = useSessionStyles();
  const scheduledSessions = useSelector((state: RootState) => {
    return state.availability.scheduledSessions;
  });

  if (!scheduledSessions) {
    return <></>;
  }

  const {
    cancelledSessions,
    timeOffSessions,
    removedSessions,
  } = scheduledSessions.reduce(
    (acc, session) => {
      if (
        moment(session.segmentStartUtc).isAfter(moment.utc().add(2, 'weeks'))
      ) {
        acc.timeOffSessions.push(session);
        return acc;
      }
      if (session.status === SessionStatus.Pending) {
        acc.removedSessions.push(session);
        return acc;
      }
      acc.cancelledSessions.push(session);
      return acc;
    },
    {
      cancelledSessions: [] as ScheduleSegment[],
      timeOffSessions: [] as ScheduleSegment[],
      removedSessions: [] as ScheduleSegment[],
    }
  );

  return (
    <>
      <div>
        <SessionsList
          sessions={cancelledSessions}
          headerClassName={clsx(
            classes.cancelledSessionsHeader,
            classes.sessionHeader
          )}
          headerText="will be Cancelled"
        >
          {children}
        </SessionsList>
      </div>
      <div>
        <SessionsList
          sessions={timeOffSessions}
          headerClassName={clsx(
            classes.timeOffSessionHeader,
            classes.sessionHeader
          )}
          headerText="will be removed as Time Off"
        >
          {children}
        </SessionsList>
      </div>
      <div>
        <SessionsList
          sessions={removedSessions}
          headerClassName={clsx(
            classes.timeOffSessionHeader,
            classes.sessionHeader
          )}
          headerText="requests will be removed"
          status={SessionStatus.Pending}
        >
          {children}
        </SessionsList>
      </div>
    </>
  );
};

export default ScheduleSessionsListWrapper;

const SessionsList = ({
  children,
  sessions,
  headerText,
  headerClassName,
  status,
}: {
  children: (session: ScheduleSegment) => React.ReactNode;
  sessions: ScheduleSegment[];
  headerText: string;
  headerClassName: string;
  status?: SessionStatus;
}) => {
  const classes = useSessionStyles();
  const defaultShowingSessionSCount = 3;
  const [toShowSessionsCount, setToShowSessionsCount] = useState(
    defaultShowingSessionSCount
  );

  const sortedScheduleSession = orderBy(sessions, ['segmentStartUtc']);

  const sessionsLength = sessions.length;
  const showLoadMoreButton = sessionsLength > toShowSessionsCount;
  const humanizedSessions = `${sessionsLength} Session${
    sessionsLength > 1 ? 's' : ''
  }`;

  const humanizedPendingSessions = `${sessionsLength} Pending session${
    sessionsLength > 1 ? 's' : ''
  }`;

  if (!sessions.length) {
    return <></>;
  }

  return (
    <>
      <ContainerWithImage
        imgSrc={BookRedSvg}
        alt="book"
        styles={{ root: [classes.sessionsSectionHeader, headerClassName] }}
      >
        {status === SessionStatus.Pending
          ? humanizedPendingSessions
          : humanizedSessions}{' '}
        {headerText}
      </ContainerWithImage>
      {sortedScheduleSession
        .slice(0, toShowSessionsCount)
        ?.map((session) => children(session))}
      {showLoadMoreButton && (
        <>
          <Divider />
          <StyledButtonLink
            className={classes.loadMoreButton}
            onClick={() => setToShowSessionsCount(sessionsLength)}
          >
            Load {sessionsLength - defaultShowingSessionSCount} more sessions
          </StyledButtonLink>
        </>
      )}
    </>
  );
};

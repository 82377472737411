import React from 'react';
import { Link } from 'react-router-dom';

import type { LinkProps } from 'react-router-dom';

interface CustomLinkProps extends Omit<LinkProps, 'to' | 'children'> {
  pathname: string;
  label: string;
}

const CustomLink = ({ pathname, label, ...linkProps }: CustomLinkProps) => {
  return (
    <Link to={pathname} {...linkProps}>
      {label}
    </Link>
  );
};

export default CustomLink;

import { makeStyles, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import clsx from 'clsx';
import { DateObject } from 'react-multi-date-picker';
import DateRangeIcon from '@material-ui/icons/DateRange';

import StyledMultipleDatePicker from '../../styledMultipleDatePicker/StyledMultipleDatePicker';
import {
  DATE_FORMAT_US,
  getFormattedWeekPickerDates,
} from '../../../utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.kyoGray.main}`,
    backgroundColor: 'white',
    padding: theme.spacing(1, 2),
  },
  dateIcon: {
    fontSize: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(2.5),
    },
  },
  datePicker: {
    display: 'flex',
    alignItems: 'center',
    color: `#5096F1`,
    gap: theme.spacing(1),

    '&  .rmdp-input': {
      background: 'white !important',
      color: `#5096F1 !important`,
      width: `${theme.spacing(25)}px !important`,
      fontSize: `${theme.spacing(2)}px !important`,
    },

    [theme.breakpoints.down('xs')]: {
      gap: 0,

      '&  .rmdp-input': {
        width: `${theme.spacing(22)}px !important`,
        fontSize: `${theme.spacing(1.75)}px !important`,
      },
    },
  },
  datePickerError: {
    '&  .rmdp-input': {
      color: `${theme.palette.kyoRed.main} !important`,
    },
  },
}));

interface AvailabilityWeekPickerProps {
  setValues: (fields: { startDate: string; endDate: string }) => void;
  startDate: string;
  endDate: string;
  error?: string;
  startDates: Record<'min' | 'max', string>;
  resetPastAvailBlocks: (startDate: string) => void;
}

const AvailabilityWeekPicker = ({
  setValues,
  startDate,
  endDate,
  error,
  startDates,
  resetPastAvailBlocks,
}: AvailabilityWeekPickerProps) => {
  const classes = useStyles();

  const handleDateChange = (value: DateObject | DateObject[] | null) => {
    const [formattedStartDate, formattedEndDate] =
      getFormattedWeekPickerDates(value, DATE_FORMAT_US) || [];

    setValues({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
    if (Array.isArray(value) && value[0].toDate() < new Date()) {
      resetPastAvailBlocks(formattedStartDate);
    }
  };

  return (
    <div className={classes.root}>
      <Typography>Select week</Typography>
      <div
        className={clsx(classes.datePicker, error && classes.datePickerError)}
      >
        <StyledMultipleDatePicker
          editable={false}
          value={[startDate, endDate]}
          weekPicker
          format={DATE_FORMAT_US}
          onChange={handleDateChange}
          portal
          minDate={moment(startDates.min).toDate()}
          maxDate={moment(startDates.max).toDate()}
        />
        <DateRangeIcon className={classes.dateIcon} />
      </div>
    </div>
  );
};

export default AvailabilityWeekPicker;

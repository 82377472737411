import queryString from 'query-string';

import {
  NotificationPreference,
  Provider,
  ProviderAddress,
  ProviderAttendanceAwardVisibilityStatus,
  ProviderAttributes,
  ProviderLevel,
  ProviderWithBookDetails,
  ProviderWithMentees,
  SingleSessionProviderFilters,
} from '../types/types';
import requester from './requester';

const fetchCurrentProvider = async (): Promise<ProviderWithMentees> => {
  return requester
    .get(`v2/providers/me`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchProviderAttributes = async (
  providerId: number
): Promise<ProviderAttributes> => {
  const url = `providers/${providerId}/attributes`;

  return requester
    .get(url)
    .then((response) => response.json())
    .then((response) => response.data);
};

const updateProviderAttributes = async (
  providerId: number,
  newAttributes: Partial<ProviderAttributes>
): Promise<ProviderAttributes> => {
  const url = `providers/${providerId}/attributes`;

  return requester
    .post(url, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ newAttributes }),
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchProviderFullSchedule = async (
  providerId: number,
  numOfWeeks?: number,
  limit?: number,
  includePending?: boolean
) => {
  const query = queryString.stringify({ numOfWeeks, limit, includePending });

  return requester
    .get(`providers/${providerId}/fullSchedule?${query}`)
    .then((response) => response.json());
};

const fetchSwapProviders = async (
  subjectProviderId: number,
  radius: string
) => {
  const query = queryString.stringify({ radius });
  return requester
    .get(`providers/${subjectProviderId}/swaps?${query}`)
    .then((response) => response.json());
};

const fetchProviderNotificationPreferences = async (
  providerId: number
): Promise<NotificationPreference[]> => {
  return requester
    .get(`notificationPreferences/provider/${providerId}`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const replaceProviderNotificationPreferences = async (
  notificationPreferences: NotificationPreference[],
  providerId: number
): Promise<void> => {
  return requester
    .put(`notificationPreferences/provider/${providerId}`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ notificationPreferences }),
    })
    .then((response) => response.json());
};

const updateProviderAttendanceAward = async (
  awardId: number
): Promise<ProviderAttendanceAwardVisibilityStatus> => {
  return requester
    .put(`provider-attendance-awards/${awardId}`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ isSeen: true }),
    })
    .then((response) => response.json())
    .then((res) => res.data);
};

// TODO move from here
const fetchProvidersForSingleBook = async (
  clientId: number,
  filters: SingleSessionProviderFilters = {},
  signal?: AbortSignal
): Promise<ProviderWithBookDetails[]> => {
  const query = queryString.stringify({
    ...filters,
    clientId,
  });
  const url = `short-term-book-request/user-api/clients/${clientId}?${query}`;
  return requester
    .get(url, { signal })
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchMenteeProviders = async (
  providerId: number
): Promise<Array<ProviderWithMentees>> => {
  const query = queryString.stringify({
    mentorId: providerId,
    includeSchedule: true,
    includeCalculations: true,
    includeStats: true,
    includeLevel: true,
  });

  const url = `providers?${query}`;

  return requester
    .get(url)
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchProviderById = (providerId: number): Promise<Provider> =>
  requester
    .get(`providers/${providerId}`)
    .then((response) => response.json())
    .then((response) => response.data);

const fetchRelatedClientIds = async (providerId: number) => {
  const url = `providers/${providerId}/related-clients`;

  return requester.get(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<{ data: number[] }>;
  });
};

const updateProviderAddress = async (
  providerId: number,
  providerAddress: ProviderAddress
) => {
  const { zip, city, state, street } = providerAddress;

  const url = `v2/providers/${providerId}/address`;

  return requester
    .put(url, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ zip, city, state, street }),
    })
    .then((response) => response.json() as Promise<{ data: ProviderAddress }>)
    .then((res) => res.data);
};

const fetchProviderProjectedLevel = async (
  providerId: number
): Promise<ProviderLevel> => {
  const url = `v2/providers/${providerId}/projected-level`;

  return requester
    .get(url)
    .then((response) => response.json())
    .then((response) => response.data);
};

export default {
  fetchCurrentProvider,
  fetchProviderAttributes,
  updateProviderAttributes,
  fetchProviderFullSchedule,
  fetchSwapProviders,
  fetchProviderNotificationPreferences,
  replaceProviderNotificationPreferences,
  updateProviderAttendanceAward,
  fetchProvidersForSingleBook,
  fetchMenteeProviders,
  fetchProviderById,
  fetchRelatedClientIds,
  updateProviderAddress,
  fetchProviderProjectedLevel,
};

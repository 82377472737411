import { cloneDeep, max } from 'lodash';
import moment from 'moment';
import { TimeBlock } from '../../redux/modules/adminSettings';
import { BlockCoords } from '../../types/types';
import {
  DATE_FORMAT,
  DATE_FORMAT_US,
  TIME_FORMAT_24HR,
} from '../../utils/helpers';
import { DayOfWeekId } from '../constants';
import { AvailabilityBlock, AvailabilityStatus } from '../types';

export const getTimeBlockDayOfWeekKey = ({
  timeBlockId,
  dayOfWeekId,
}: BlockCoords) => {
  return dayOfWeekId * 3 + (timeBlockId - 1);
};

export const sortAvailabilityBlocks = (
  availabilityBlocks: Partial<AvailabilityBlock>[]
) => {
  const sortedAvailabilityBlocks: Partial<AvailabilityBlock>[] = Array(21);

  availabilityBlocks.forEach((block) => {
    if (typeof block.timeBlockDayOfWeekId === 'number') {
      sortedAvailabilityBlocks[block.timeBlockDayOfWeekId] = block;
    }
  });

  return sortedAvailabilityBlocks;
};

export const getDefaultAvailabilityBlocks = () => {
  const matrixCellCount = 21;
  return Array.from(Array(matrixCellCount), (_, index) => {
    return {
      timeBlockDayOfWeekId: index,
      isAvailable: false,
    };
  });
};

export const getStartDateAllowedPoint = (
  futureSearchPeriod: number,
  status: AvailabilityStatus
) => {
  const weekDaysCount = 7;
  return moment()
    .add(
      futureSearchPeriod * weekDaysCount +
        (status === AvailabilityStatus.Upcoming ? 1 : 0),
      'days'
    )
    .format(DATE_FORMAT);
};

export const editMatrixBlock = (
  key: number,
  isAvailable: boolean,
  matrixBlocks: Partial<AvailabilityBlock>[]
) => {
  const matrixBlocksCopy = cloneDeep(matrixBlocks);
  const block = matrixBlocksCopy[key];
  block.isAvailable = isAvailable;
  return matrixBlocksCopy;
};

export const getLastPastBlock = (
  startDate: string,
  timeBlocks: TimeBlock[]
): BlockCoords => {
  const startDateMoment = moment(startDate, DATE_FORMAT_US).startOf('week');

  if (moment().startOf('week') < startDateMoment) {
    return { dayOfWeekId: 0, timeBlockId: 0 };
  }

  const weekDay = moment().weekday();

  const matchedTimeBlocks =
    timeBlocks.filter((timeBlock) => {
      return moment().format(TIME_FORMAT_24HR) >= timeBlock.start;
    }) || [];

  const timeBlockIds = matchedTimeBlocks.map((timeBlock) => timeBlock.id);

  return { dayOfWeekId: weekDay, timeBlockId: max(timeBlockIds) || 0 };
};

export const isPastBlock = (
  startDate: string,
  timeBlocks: TimeBlock[],
  {
    horizontalValue,
    verticalValue,
  }: {
    horizontalValue: DayOfWeekId;
    verticalValue: number;
  }
) => {
  const lastPastBlock = getLastPastBlock(startDate, timeBlocks);

  if (horizontalValue > lastPastBlock.dayOfWeekId) {
    return false;
  }

  if (horizontalValue < lastPastBlock.dayOfWeekId) {
    return true;
  }

  if (verticalValue <= lastPastBlock.timeBlockId) {
    return true;
  }

  return false;
};

export const resetPastBlocksToInitial = (
  startDate: string,
  availabilityBlocks: Array<Partial<AvailabilityBlock>> | undefined,
  initialAvailabilityBlocks: Array<Partial<AvailabilityBlock>>,
  timeBlocks: Array<TimeBlock>
) => {
  const newAvailabilityBlocks: Array<Partial<AvailabilityBlock>> = [];

  if (!availabilityBlocks || !startDate) {
    return;
  }
  for (const block of availabilityBlocks) {
    if (
      block.timeBlockDayOfWeek &&
      isPastBlock(startDate, timeBlocks, {
        horizontalValue: block.timeBlockDayOfWeek.dayOfWeekId,
        verticalValue: block.timeBlockDayOfWeek.timeBlockId,
      })
    ) {
      const initialBlock = initialAvailabilityBlocks.find(
        (b) => b.timeBlockDayOfWeekId === block.timeBlockDayOfWeekId
      );
      newAvailabilityBlocks.push({
        ...block,
        isAvailable: initialBlock?.isAvailable,
      });
    } else {
      newAvailabilityBlocks.push({ ...block });
    }
  }

  return newAvailabilityBlocks;
};

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { fetchAndUpdateCurrentUser } from '../redux/modules/users';

type AuthStage =
  | 'SignedIn'
  | 'SignedOut'
  | 'ForgotPassword'
  | 'NewPassword'
  | 'ConfirmSignIn';

interface CognitoUserWithChallenge extends CognitoUser {
  challengeName: string;
  challengeParam: { CODE_DELIVERY_DESTINATION: string };
}

type UserInfo = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
};

interface AuthErrors {
  signInError: string | null;
  registrationError: string | null;
  confirmationError: string | null;
}

export enum UserType {
  Client = 'Client',
  Provider = 'Provider',
}

const LAST_LOGGED_IN_USER_TYPE = 'lastLoggedInUserType';

interface AuthContext {
  isAuthenticated: boolean;
  user: CognitoUserWithChallenge | null;
  initialLoading: boolean;
  loading: boolean;
  signOut: () => void;
  signIn: () => void;
  signInForClients: (username: string, password: string) => void;
  authStage: string;
  changeTemporaryPassword: (
    newPassword: string,
    firstName: string,
    lastName: string,
    mobileNumber: string
  ) => Promise<void>;
  forgotPassword: (username: string) => void;
  resetPassword: (
    username: string,
    verificationCode: string,
    newPassword: string
  ) => void;
  confirmSignIn: (code: string) => void;
  errors: AuthErrors;
  clearError: (err: keyof AuthErrors) => void;
  lastLoggedInUserType: UserType;
  setLastLoggedInUserType: (userType: UserType) => void;
}

type Props = {
  children: React.ReactNode;
};

export const AuthContext = React.createContext<AuthContext>({} as AuthContext);

export const useAuth = () => useContext(AuthContext);

const clearAllCookies = () => {
  const cookies = document.cookie.split(';');

  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;

    document.cookie = `${name}=;Max-Age=0;path=/`;
  });
};

export const AuthProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  // TODO REMOVE isAuthenticated and use authStage to track auth state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<CognitoUserWithChallenge | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [authStage, setAuthStage] = useState<AuthStage>('SignedOut');
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [errors, setErrors] = useState<AuthErrors>({
    signInError: null,
    registrationError: null,
    confirmationError: null,
  });

  const handleChallenge = (cognitoUser: CognitoUserWithChallenge) => {
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setUser(cognitoUser);
      setAuthStage('NewPassword');
    } else if (cognitoUser.challengeName === 'SMS_MFA') {
      setUser(cognitoUser);
      setAuthStage('ConfirmSignIn');
    } else {
      setUser(cognitoUser);
      setAuthStage('SignedIn');
      setIsAuthenticated(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn_failure':
          setIsAuthenticated(false);
          setAuthStage('SignedOut');
          setUser(null);
          break;
        case 'signOut':
          setIsAuthenticated(false);
          setAuthStage('SignedOut');
          setUser(null);
          break;
        case 'tokenRefresh_failure':
          setIsAuthenticated(false);
          setAuthStage('SignedOut');
          setUser(null);
          break;
        default:
          break;
      }
    });
    setInitialLoading(true);

    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        handleChallenge(cognitoUser);
      })
      .catch(() => {
        setIsAuthenticated(false);
        setAuthStage('SignedOut');
      })
      .finally(() => {
        setLoading(false);
        setInitialLoading(false);
      });
  }, []);

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut();
      setErrors({
        signInError: null,
        registrationError: null,
        confirmationError: null,
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }, []);

  const signIn = async () => {
    clearAllCookies(); // make sure cookies are removed before sign-in;
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  const signInForClients = async (username: string, password: string) => {
    setLoading(true);
    setErrors((curr) => ({ ...curr, signInError: null }));
    try {
      clearAllCookies(); // make sure cookies are removed before sign-in
      const cognitoUser = await Auth.signIn(username, password);
      handleChallenge(cognitoUser);
    } catch (e: any) {
      setErrors((curr) => ({ ...curr, signInError: e.message }));
    } finally {
      setLoading(false);
    }
  };

  const changeTemporaryPassword = async (
    newPassword: string,
    firstName: string,
    lastName: string,
    mobileNumber: string
  ) => {
    setLoading(true);
    setErrors((curr) => ({ ...curr, registrationError: null }));
    setUserInfo({ firstName, lastName, mobileNumber });

    try {
      /* eslint-disable @typescript-eslint/camelcase */
      const updatedUser = await Auth.completeNewPassword(user, newPassword, {
        name: `${firstName} ${lastName}`,
        family_name: lastName,
        phone_number: mobileNumber,
      });
      /* eslint-enable @typescript-eslint/camelcase */
      handleChallenge(updatedUser);
      setLoading(false);
    } catch (e: any) {
      setErrors((curr) => ({ ...curr, registrationError: e.message }));
    }
  };

  const forgotPassword = async (username: string) => {
    try {
      await Auth.forgotPassword(username);
    } catch (e: any) {
      console.log(e);
      throw e;
    }
  };

  const resetPassword = async (
    username: string,
    verificationCode: string,
    newPassword: string
  ) => {
    try {
      await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
    } catch (e: any) {
      console.log(e);
      throw e;
    }
  };

  const confirmSignIn = async (code: string) => {
    setLoading(true);
    try {
      const loggedUser = await Auth.confirmSignIn(
        user,
        code.trim(), // Confirmation code
        'SMS_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );
      delete loggedUser.challengeName;
      if (userInfo) {
        const { firstName, lastName, mobileNumber } = userInfo;
        await dispatch(
          fetchAndUpdateCurrentUser({ firstName, lastName, mobileNumber })
        );
        setUserInfo(null);
      }
      handleChallenge(loggedUser);
    } catch (e: any) {
      setErrors((curr) => ({ ...curr, confirmationError: e.message }));

      if (e.code === 'NotAuthorizedException') {
        setAuthStage('SignedOut');
        setIsAuthenticated(false);
        setUserInfo(null);
      }
    } finally {
      setLoading(false);
    }
  };

  const clearError = useCallback((errorName: keyof AuthErrors) => {
    setErrors((curr) => ({ ...curr, [errorName]: null }));
  }, []);

  const setLastLoggedInUserType = useCallback((userType: UserType) => {
    localStorage.setItem(LAST_LOGGED_IN_USER_TYPE, userType);
  }, []);

  const getLastLoggedInUserType = () => {
    const storedUserType = localStorage.getItem(LAST_LOGGED_IN_USER_TYPE);

    if (storedUserType) {
      return storedUserType as UserType;
    }
    return UserType.Client;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authStage,
        user,
        initialLoading,
        loading,
        signOut,
        signIn,
        signInForClients,
        changeTemporaryPassword,
        forgotPassword,
        resetPassword,
        confirmSignIn,
        errors,
        clearError,
        lastLoggedInUserType: getLastLoggedInUserType(),
        setLastLoggedInUserType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

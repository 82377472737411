import React, { useEffect } from 'react';
import {
  ListSubheader,
  Box,
  Typography,
  Hidden,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import {
  HomeOutlined as HomeOutlinedIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
  ExitToApp as ExitToAppIcon,
  HelpOutline as HelpOutlineIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  ChatBubbleOutlineOutlined as ChatBubbleIcon,
  NotificationsNone as AlertIcon,
  SettingsOutlined as SettingsIcon,
  DateRangeOutlined as AvailabilityIcon,
  AccountCircle as UserMyProfileIcon,
  MailOutline as MailOutlineIcon,
  DateRange as DateRangeOutlinedIcon,
  // ReceiptOutlined as BillingIcon,
  InsertInvitationOutlined as StandardIcon,
  EventBusy as CancelIcon,
} from '@material-ui/icons';
import 'moment-timezone';

import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { ReactComponent as ProfileIcon } from '../assets/person-bounding-box.svg';
import { ReactComponent as ProvidersIcon } from '../assets/person-lines-fill.svg';
import { ReactComponent as ClimbIcon } from '../assets/climb_icon.svg';
import { ReactComponent as ScheduleIcon } from '../assets/my-schedule-icon.svg';
import { Resources } from '../accessControl/acl';
import usePermission from '../hooks/usePermission';
import { MY_AVAILABILITY_PAGE } from '../utils/constants';
import { RootState } from '../redux/modules/rootReducer';
import { fetchUnhandledRequests } from '../redux/modules/requests';
import { isBT } from '../utils/helpers';
import { PageModeParam } from '../commonPages/MySchedule/types';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    drawerToolbar: {
      width: drawerWidth,
      background: theme.palette.grey[100],
      height: '100%',
      [theme.breakpoints.up('lg')]: {
        borderTopRightRadius: theme.spacing(2),
      },
    },
    toolbar: {
      minHeight: theme.spacing(20),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3.5),
        minHeight: theme.spacing(11.5),
        justifyContent: 'space-between',
      },
      justifyContent: 'space-around',
    },
    listSubHeaderRoot: {
      /* To change the font, use the fontFamily rule */
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.overline.fontWeight,
      fontSize: theme.typography.pxToRem(10),
    },
    addTimeBox: {
      backgroundColor: '#e8e8e8',
      padding: theme.spacing(1.25, 0),
    },
    addTimeText: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
    },
    scheduleBox: {
      padding: theme.spacing(1.25, 0),
    },
    scheduleText: {
      padding: theme.spacing(0, 0, 1, 2),
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
    },
    scheduleHr: {
      width: '96%',
      backgroundColor: theme.palette.kyoBlue1.main,
    },
    scheduleTitle: {
      fontSize: 14,
      fontWeight: 600,
    },
    scheduleItemText: {
      margin: 0,
    },
  });
});
interface Props {
  signOut: () => void;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDrawerOpen: boolean;
}

const DrawerItemsList: React.FC<Props> = (props: Props) => {
  const { setIsDrawerOpen, signOut } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const checkPermission = usePermission();
  const navigate = useNavigate();
  const clientRoutesAccess = checkPermission(Resources.clientRoutes);
  const providerRoutesAccess = checkPermission(Resources.providerRoutes);
  const menteeProviders = useSelector(
    (state: RootState) => state.menteeProviders.menteeProviders
  );
  const unhandledRequestCount = useSelector(
    (state: RootState) => state.requests.unhandledRequestsCount
  );

  const currentProvider = useSelector(
    (state: RootState) => state.provider.provider
  );

  const isShowClimb = isBT(currentProvider);

  useEffect(() => {
    if (providerRoutesAccess) {
      dispatch(fetchUnhandledRequests());
    }
  }, [providerRoutesAccess, dispatch]);

  return (
    <>
      <Hidden lgUp implementation="css">
        <div className={classes.toolbar} />
      </Hidden>
      <div className={classes.drawerToolbar}>
        <Hidden lgUp implementation="css">
          <Divider />
        </Hidden>
        <List
          subheader={
            <ListSubheader
              disableSticky
              classes={{ root: classes.listSubHeaderRoot }}
            >
              MY KYO
            </ListSubheader>
          }
        >
          <Hidden lgUp implementation="css">
            <DrawerItem
              to="/home"
              title="Home"
              icon={<HomeOutlinedIcon />}
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            />
          </Hidden>
          <Hidden mdDown implementation="css">
            <DrawerItem to="/home" title="Home" icon={<HomeOutlinedIcon />} />
          </Hidden>

          {providerRoutesAccess && (
            <>
              <DrawerItem
                to="/my-profile"
                title="My Profile"
                icon={<ProfileIcon />}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              />
              <div className={classes.addTimeBox}>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  className={classes.addTimeText}
                >
                  Add Time
                </Typography>
                <DrawerItem
                  to="/requests"
                  title="Requests"
                  icon={
                    <Badge
                      badgeContent={unhandledRequestCount}
                      color="error"
                      overlap="circle"
                      variant="dot"
                    >
                      <DescriptionOutlinedIcon />
                    </Badge>
                  }
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                />
                <DrawerItem
                  to="/openSessions"
                  title="OpenSessions"
                  icon={<DateRangeOutlinedIcon />}
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                />
              </div>
            </>
          )}

          {providerRoutesAccess && (
            <DrawerItem
              to="/schedule"
              title="My Schedule"
              icon={<ScheduleIcon />}
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            />
          )}
          {clientRoutesAccess && (
            <div className={classes.addTimeBox}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.scheduleText}
              >
                Manage Schedule
              </Typography>
              <DrawerItem
                to={`/schedule?page-mode=${PageModeParam.schedule}`}
                title="View My Schedule"
                icon={<ScheduleIcon />}
                onClick={() => {
                  navigate(`/schedule?page-mode=${PageModeParam.schedule}`);
                }}
              />
              <DrawerItem
                to={`/schedule?page-mode=${PageModeParam.remove}`}
                title="Remove Sessions"
                icon={<CancelIcon />}
                onClick={() => {
                  navigate(`/schedule?page-mode=${PageModeParam.remove}`);
                }}
              />
              <DrawerItem
                to={`/schedule?page-mode=${PageModeParam.temporary}`}
                title="Temporary Availability"
                icon={<AvailabilityIcon />}
                onClick={() => {
                  navigate(`/schedule?page-mode=${PageModeParam.temporary}`);
                }}
              />
              <DrawerItem
                to={`/schedule?page-mode=${PageModeParam.standard}`}
                title="Standard Availability"
                icon={<StandardIcon />}
                onClick={() => {
                  navigate(`/schedule?page-mode=${PageModeParam.standard}`);
                }}
              />
            </div>
          )}
          {clientRoutesAccess && (
            <>
              <DrawerItem
                to="/add-sessions"
                title="Add Sessions"
                icon={<AddBoxOutlinedIcon />}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              />
              <DrawerItem
                to="/chat"
                title="Team Chat"
                icon={<ChatBubbleIcon />}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              />
              <DrawerItem
                to="/requests"
                title="My Requests"
                icon={<DescriptionOutlinedIcon />}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              />
            </>
          )}
          {providerRoutesAccess && (
            <>
              <DrawerItem
                to="/chat"
                title="Team Chat"
                icon={<ChatBubbleIcon />}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              />
              <DrawerItem
                to="/alerts"
                title="Alerts"
                icon={<AlertIcon />}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              />
            </>
          )}
          {clientRoutesAccess && (
            <DrawerItem
              to="/my-profile"
              title="My Profile"
              icon={<UserMyProfileIcon />}
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            />
          )}
          {providerRoutesAccess && (
            <DrawerItem
              to={`/${MY_AVAILABILITY_PAGE}`}
              title="My Availability"
              icon={<AvailabilityIcon />}
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            />
          )}
          {isShowClimb && (
            <DrawerItem
              to="/climb"
              title="Kyo Climb"
              icon={<ClimbIcon />}
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            />
          )}
          {!!menteeProviders.length && (
            <DrawerItem
              to="/mentees"
              title="Mentees"
              icon={<ProvidersIcon />}
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            />
          )}
        </List>
        <Divider />
        <List>
          {(clientRoutesAccess || providerRoutesAccess) && (
            <DrawerItem
              to="/settings"
              title="Settings"
              icon={<SettingsIcon />}
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            />
          )}
          {clientRoutesAccess && (
            <>
              {/* <DrawerItem
                to="/billing"
                title="Billing"
                icon={<BillingIcon />}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              /> */}
              <DrawerItem
                to="/alerts"
                title="Alerts"
                icon={<AlertIcon />}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              />
            </>
          )}
          {clientRoutesAccess && (
            <DrawerItem
              to="/contact-scheduling"
              title="Contact Us"
              icon={<MailOutlineIcon />}
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            />
          )}
          <DrawerItem
            to="/help"
            title="Help"
            icon={<HelpOutlineIcon />}
            onClick={() => {
              setIsDrawerOpen(false);
            }}
          />
          <DrawerItem
            title="Signout"
            icon={<ExitToAppIcon />}
            onClick={() => {
              setIsDrawerOpen(false);
              signOut();
            }}
          />
        </List>
      </div>
    </>
  );
};

interface DrawerItemProps {
  to?: string;
  title: string;
  icon: JSX.Element;
  onClick?: () => void;
}

const useDrawerItemStyles = makeStyles((theme: Theme) => ({
  selectedItem: {
    backgroundColor: '#C5DCFA',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    color: theme.palette.kyoBlue1.dark,
    '&:hover': {
      backgroundColor: '#C5DCFA',
    },
  },
  unselectedItem: {
    backgroundColor: 'unset',
    color: theme.palette.kyoGray63.dark,
    '&:hover': {
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
  icon: { display: 'flex' },
  selectedIcon: {
    color: theme.palette.kyoBlue1.dark,
  },
  unselectedIcon: { color: 'unset' },
  title: {
    fontSize: 14,
  },
}));

const DrawerItem: React.FC<DrawerItemProps> = (props: DrawerItemProps) => {
  const classes = useDrawerItemStyles();
  const location = useLocation();
  const { to, title, icon, onClick } = props;

  const { pathname, search } = location;
  const isActiveRoute = to === location.pathname || to === pathname + search;

  return (
    <ListItem
      button
      component={to ? Link : Box}
      to={to}
      className={clsx(
        isActiveRoute ? classes.selectedItem : classes.unselectedItem
      )}
      onClick={onClick}
    >
      <ListItemIcon>
        <div
          className={`${classes.icon} ${
            isActiveRoute ? classes.selectedIcon : classes.unselectedIcon
          }`}
        >
          {icon}
        </div>
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="subtitle1"
            color="inherit"
            className={classes.title}
          >
            {title}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default DrawerItemsList;

import React, { HTMLAttributes } from 'react';
import { Tooltip } from '@material-ui/core';

export interface MatrixCellProps {
  tooltipTitle?: string;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  rootProps?: HTMLAttributes<HTMLDivElement>;
}

const MatrixCell = ({
  tooltipTitle = '',
  className,
  onClick,
  children,
  rootProps,
}: MatrixCellProps) => (
  <Tooltip title={tooltipTitle} placement="top">
    <div
      className={className}
      onClick={onClick}
      onKeyPress={undefined}
      tabIndex={0}
      aria-label="Mute volume"
      role="button"
      {...rootProps}
    >
      {children}
    </div>
  </Tooltip>
);

export default MatrixCell;

import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export enum InfoContainerTypes {
  Warning = 'Warning',
  Error = 'Error',
  Success = 'Success',
}

interface InfoContainerProps {
  type: InfoContainerTypes;
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  [InfoContainerTypes.Warning]: {
    borderLeftColor: '#DBCE97',
  },
  [InfoContainerTypes.Error]: {
    borderLeftColor: '#DA100B',
  },
  [InfoContainerTypes.Success]: {
    borderLeftColor: '#82E780',
  },
  root: {
    backgroundColor: '#F8F8F6',
    borderLeftWidth: theme.spacing(0.375),
    borderLeftStyle: 'solid',
  },
}));

const InfoContainer = ({ type, children, className }: InfoContainerProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes[type], classes.root, className)}>
      {children}
    </div>
  );
};

export default InfoContainer;

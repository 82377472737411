import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import availabilityCalendarInfo from '../../assets/availabilityCalendarInfo.png';
import lightIdeaIcon from '../../assets/lightIdea.svg';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: theme.spacing(80),
    margin: 'auto',
  },
  dialogPaper: {
    borderRadius: 12,
  },
  title: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.kyoGray.light,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  closeButton: {
    width: '100%',
    textTransform: 'capitalize',
    color: theme.palette.kyoLightGreen.main,
    borderColor: theme.palette.kyoLightGreen.main,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderRadius: 8,
  },
  infoText: {
    marginBottom: theme.spacing(2),
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  infoWrapper: {
    paddingInline: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingInline: 0,
    },
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
  },
}));

interface ModifiedAvailabilityInfoModalProps {
  open: boolean;
  onClose: () => void;
}

const ModifiedAvailabilityInfoModal = ({
  open,
  onClose,
}: ModifiedAvailabilityInfoModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ root: classes.dialog, container: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6" color="textSecondary">
          Upcoming Temporary Changes Info
        </Typography>
        <IconButton onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.iconWrapper}>
          <img
            src={availabilityCalendarInfo}
            alt="availability info icon"
            width={121}
            height={82}
          />
        </div>
        <div className={classes.infoWrapper}>
          <Typography className={classes.infoText}>
            Add a temporary change for upcoming weeks when your schedule is
            changing or you need time off.
          </Typography>
          <Typography className={classes.infoText}>
            <img
              src={lightIdeaIcon}
              alt="light idea icon"
              className={classes.infoIcon}
            />
            I can add availability during winter break.
          </Typography>
          <Typography className={classes.infoText}>
            <img
              src={lightIdeaIcon}
              alt="light idea icon"
              className={classes.infoIcon}
            />
            I need to take afternoons off during finals week.
          </Typography>
        </div>
        <Button
          className={classes.closeButton}
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ModifiedAvailabilityInfoModal;

import queryString from 'query-string';
import requester from './requester';
import {
  ApiPagination,
  ApiSorting,
  CancellationEvent,
  CancellationEventsFilters,
  CancellationEventsSort,
  CancelledSegmentsResponseType,
  PaginatedData,
  ScheduleSegment,
} from '../types/types';

// for cancelling multiple sessions
async function cancelSessions(
  clientId: number | null,
  providerId: number | null,
  sessions: ScheduleSegment[]
): Promise<Array<CancelledSegmentsResponseType>> {
  const body = {
    segmentIds: sessions.map((s) => s.segmentId),
    clientId,
    providerId,
  };

  return requester
    .post(`v2/cancellation`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
    .then((response) => response.json())
    .then((response) => response.data);
}

const fetchCancellationEvents = async (
  filters: Partial<CancellationEventsFilters>,
  sorting: ApiSorting<CancellationEventsSort>,
  paging: ApiPagination
): Promise<PaginatedData<CancellationEvent>> => {
  const query = queryString.stringify({ ...filters, ...paging, ...sorting });
  const url = `v2/cancellation/events?${query}`;

  return requester
    .get(url, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

export default {
  cancelSessions,
  fetchCancellationEvents,
};

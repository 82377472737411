import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Divider,
  Link,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { uniq } from 'lodash';

import Image from '../../assets/bg.svg';
import Greetings from '../../components/Greetings';
import StyledButton from '../../components/buttons/StyledButton';
import infoIcon from '../../assets/info-icon.svg';
import SessionCard from '../../components/SessionCard/SessionCard';
import CovidBadgeIcon from '../../components/badges/CovidBadge/CovidBadgeIcon';
import { RootState } from '../../redux/modules/rootReducer';
import {
  fetchClientFullSchedule,
  fetchClientGoals,
} from '../../redux/modules/client';
import InfoCard from '../../components/InfoCard/InfoCard';
import {
  resetSessionRemoval,
  setSessionsToRemove,
} from '../../redux/modules/sessionRemoval';
import ClientSessionDetails from '../../components/SessionCard/ClientSessionDetails';
import Achievements from './achievements/Achievements';
import StyledButtonLink from '../../components/buttons/StyledButtonLink';
import ContactScheduling from './contactScheduling/ContactScheduling';
import CustomLoading from '../../components/CustomLoading/CustomLoading';
import { ScheduleSegment } from '../../types/types';
import { fetchClientSingleTermBookingAvailability } from '../../redux/modules/newSessions';
import { fetchNotice } from '../../redux/modules/notices';
import Notice from '../../components/Notice/Notice';
import { getNoticeText, toRevenuePortal } from '../../utils/helpers';
import RatingProviders from './ratingProviders/RatingProviders';
import AnalyticsTracker, {
  TrackerNames,
} from '../../analytics/AnalyticsTracker';
import { PageModeParam } from '../../commonPages/MySchedule/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Image})`,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      backgroundImage: 'none',
    },
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
    alignItems: 'flex-start',
  },
  sessionPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    boxShadow:
      '0px 4px 8px 0px rgb(97 97 97 / 18%), 0px 2px 4px 0px rgb(97 97 97 / 18%)',
    borderRadius: 12,
  },
  navButton: {
    marginTop: theme.spacing(2),
  },
  notePaper: {
    marginTop: theme.spacing(2),
  },
  noteText: {
    paddingRight: 24,
  },
  covidBadgeIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  infoIcon: {
    overflow: 'visible',
  },
  divider: {
    marginLeft: 20,
  },
  parentContainer: {
    width: '100%',
  },
  main: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  leftContainer: {
    maxWidth: 375,
    flexShrink: 0,
  },
  rightContainer: {
    width: '100%',
    maxWidth: theme.spacing(81),
    paddingTop: theme.spacing(2),
    margin: theme.spacing(0, 4),

    [theme.breakpoints.down('sm')]: {
      maxWidth: 375,
      margin: 0,
      paddingTop: theme.spacing(1.5),
    },
  },
  topDivider: {
    width: '100%',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  titleContainer: {
    width: 375,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  sessionViewDetails: {
    ...theme.typography.body2,
    paddingTop: theme.spacing(0.5),
  },
  addSessionInfo: {
    maxWidth: '120px',
    textAlign: 'right',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      textAlign: 'center',
    },
  },
  addSessionsCount: {
    whiteSpace: 'pre',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'normal',
    },
  },
  addSession: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  blockTitleWrapper: {
    display: 'flex',
  },
  noticeActionBtn: {
    backgroundColor: theme.palette.kyoPastelRed.light,
    borderRadius: '200px',
    border: '1px solid #DFA19F',
    textTransform: 'none',
    padding: theme.spacing(0.5, 3),
    whiteSpace: 'nowrap',
    color: theme.palette.kyoOrangeRed.contrastText,
    marginRight: theme.spacing(1),
    ...theme.typography.body2,
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      marginTop: theme.spacing(0.8),
    },
  },
  openRatingSessionButton: {
    marginTop: theme.spacing(2),
  },
  ratingsLoadingIcon: {
    margin: theme.spacing(2, 0, 4),
  },
  notificationContainer: {
    width: '90%',
  },
}));

export const getSessionTitle = (
  session: ScheduleSegment,
  providerFirstName: string
) => {
  const providersNames: string[] = [];
  if (session.overlappingSessions?.length > 0) {
    providersNames.push(session.provider?.firstName || '');
    session.overlappingSessions.forEach((s) => {
      providersNames.push(s.provider?.firstName || '');
    });
  }

  const uniqueProviderNames = uniq(providersNames);

  let sessionWith: string;
  if (session.overlappingSessions?.length > 0) {
    if (
      uniqueProviderNames.length === session.overlappingSessions.length + 1 ||
      uniqueProviderNames.length === 0
    ) {
      sessionWith = `${session.overlappingSessions.length + 1} providers`;
    } else {
      sessionWith = uniqueProviderNames.join(', ');
    }
  } else {
    sessionWith = providerFirstName;
  }

  return sessionWith;
};

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const currentUserLoading = useSelector(
    (state: RootState) => state.users.currentUserLoading
  );

  const clientSTBRAvailability = useSelector(
    (state: RootState) => state.newSessions.clientSTBRAvailability
  );

  const clientSTBRAvailabilityLoading = useSelector(
    (state: RootState) => state.newSessions.clientSTBRAvailabilityLoading
  );

  const currentClient = useSelector(
    (state: RootState) => state.client.currentClient
  );

  const currentClientId = useSelector(
    (state: RootState) => state.client.currentClient?.id
  );

  const currentClientLoading = useSelector(
    (state: RootState) => state.client.currentClientLoading
  );

  const clientFullSchedule = useSelector(
    (state: RootState) => state.client.clientFullSchedule
  );
  const scheduleLoading = useSelector(
    (state: RootState) => state.client.clientFullScheduleLoading
  );

  const clientGoals = useSelector((state: RootState) => state.client.goals);
  const clientGoalsLoading = useSelector(
    (state: RootState) => state.client.goalsLoading
  );

  const {
    canClientBook,
    maxNumberOfSessionsCanBooked,
  } = clientSTBRAvailability;

  const { token: noticeToken, requestType } =
    useSelector((state: RootState) => state.notices.notice) || {};

  const clientCanBook =
    Boolean(canClientBook) && !currentClient?.attributes?.pause;

  const handleCancel = (session: ScheduleSegment) => {
    dispatch(setSessionsToRemove([session]));
    navigate(`/schedule?page-mode=${PageModeParam.remove}`);
  };

  useEffect(() => {
    if (currentClientId) {
      dispatch(fetchClientSingleTermBookingAvailability(currentClientId));
      dispatch(
        fetchClientFullSchedule({
          clientId: currentClientId,
          numOfWeeks: 8,
          limit: 3,
          includePending: true,
        })
      );
      dispatch(fetchClientGoals({ clientId: currentClientId, limit: 4 }));
      dispatch(fetchNotice({ clientId: currentClientId }));
    }
  }, [currentClientId, dispatch]);

  useEffect(() => {
    dispatch(resetSessionRemoval());
  }, [dispatch]);

  const noticeText = useMemo(() => getNoticeText(requestType), [requestType]);

  if (currentUserLoading) {
    return <CustomLoading />;
  }

  return (
    <div className={classes.root}>
      <AnalyticsTracker name={TrackerNames.Home} />
      <Box className={classes.titleContainer}>
        <Greetings
          userName={currentUser?.firstName}
          userLoading={currentUserLoading}
          clientProps={{
            clientName: currentClient?.clientName,
            clientLoading: currentClientLoading,
          }}
        />
      </Box>
      <Divider className={classes.topDivider} />

      <Box className={classes.parentContainer}>
        {noticeToken && (
          <Box className={classes.notificationContainer}>
            <Notice notice={noticeText}>
              <Link
                href={toRevenuePortal(
                  `/retry-or-change-cc-info/?token=${noticeToken}`
                )}
                target="_blank"
                className={classes.noticeActionBtn}
              >
                Update Now
              </Link>
            </Notice>
          </Box>
        )}
        <Box className={classes.main}>
          <Box className={classes.leftContainer}>
            <RatingProviders
              classNames={{
                rateRecentButtonRoot: classes.openRatingSessionButton,
                loadingIcon: classes.ratingsLoadingIcon,
              }}
            />
            <Paper className={classes.sessionPaper}>
              <div className={classes.blockTitleWrapper}>
                <Typography variant={isMobile ? 'h6' : 'h5'}>
                  Upcoming sessions
                </Typography>
                <StyledButtonLink
                  className={classes.sessionViewDetails}
                  onClick={() => navigate('/schedule')}
                >
                  View All
                </StyledButtonLink>
              </div>
              {scheduleLoading ? (
                <CustomLoading />
              ) : (
                currentClient &&
                clientFullSchedule &&
                clientFullSchedule.slice(0, isMobile ? 2 : 3).map((session) => {
                  const { provider } = session;

                  const sessionWith = provider
                    ? getSessionTitle(session, provider.firstName)
                    : '';
                  const isBookRequestRetractable =
                    session.isBookRequest &&
                    !!session.id &&
                    session.isRetractableByClient;
                  const isSegmentCancellable =
                    !!session.segmentId &&
                    session.isCancellableByClient &&
                    !session.cancellationEvent?.id;

                  return (
                    <React.Fragment key={session.segmentId}>
                      <SessionCard
                        session={session}
                        sessionWith={sessionWith}
                        showCancelLink={
                          isBookRequestRetractable || isSegmentCancellable
                        }
                        onCancel={handleCancel}
                        userType="Client"
                      >
                        <ClientSessionDetails
                          client={currentClient}
                          provider={provider}
                          session={session}
                          hideButtons={!!session.cancellationEvent?.id}
                        />
                      </SessionCard>
                      <Divider className={classes.divider} />
                    </React.Fragment>
                  );
                })
              )}
              {!scheduleLoading &&
                clientFullSchedule &&
                !clientFullSchedule.length && (
                  <Typography variant="body2">
                    Oops, we can&apos;t find any upcoming sessions within next 2
                    weeks
                  </Typography>
                )}
              {clientCanBook && !clientSTBRAvailabilityLoading && (
                <Box className={classes.addSession}>
                  <Typography
                    variant="body2"
                    className={classes.addSessionInfo}
                  >
                    You can add{' '}
                    <Typography
                      component="span"
                      className={classes.addSessionsCount}
                    >
                      {maxNumberOfSessionsCanBooked} more sessions
                    </Typography>
                  </Typography>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    className={classes.navButton}
                    endIcon={<ArrowRightAltIcon />}
                    customColor="kyoTeal"
                    onClick={() => {
                      navigate('/add-sessions');
                    }}
                  >
                    Add New Sessions
                  </StyledButton>
                </Box>
              )}
            </Paper>
            {!isMobile && (
              <>
                <ContactScheduling />
                <InfoCard
                  icon={
                    <img
                      src={infoIcon}
                      height={24}
                      width={24}
                      alt="info icon"
                    />
                  }
                  className={classes.notePaper}
                >
                  <Typography variant="body1" className={classes.noteText}>
                    Many of our providers are COVID vaccinated. Search for this
                    badge in their profile:{' '}
                    <CovidBadgeIcon className={classes.covidBadgeIcon} />
                  </Typography>
                </InfoCard>
              </>
            )}
          </Box>
          <Box className={classes.rightContainer}>
            <Achievements goals={clientGoals} loading={clientGoalsLoading} />
          </Box>
          {isMobile && <ContactScheduling />}
        </Box>
      </Box>
    </div>
  );
};

export default Home;

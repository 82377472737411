import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { ItemContentFooter } from '../../components/Availability/ModifiedAvailabilityList';
import { Preference } from './UserAvailability';
import ModifiedAvailabilityContentFooterWrapper from '../../components/Availability/ModifiedAvailabilityFooterWrapper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, 2.5, 2),
  },
  preference: {
    fontSize: theme.spacing(1.75),
  },
  preferenceValue: {
    borderBottom: '1px dashed',
    fontWeight: 700,
  },
}));

const ModifiedAvailabilityContentFooter = ({
  availability,
}: ItemContentFooter) => {
  const classes = useStyles();

  return (
    <>
      <ModifiedAvailabilityContentFooterWrapper availability={availability}>
        <Preference
          styles={{
            root: classes.root,
            preference: classes.preference,
            preferenceValue: classes.preferenceValue,
          }}
          preferences={availability}
        />
      </ModifiedAvailabilityContentFooterWrapper>
    </>
  );
};

export default ModifiedAvailabilityContentFooter;

import requester from './requester';

const fetchNotice = ({ clientId }: { clientId: number }): Promise<string> => {
  return requester
    .get(`revenue-requests/notices/${clientId}`)
    .then((response) => response.json())
    .then((response) => response.data);
};

export default { fetchNotice };

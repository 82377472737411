import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { MUISortOptions } from 'mui-datatables';
import requestsApi, {
  UpdateSubOfferSessionStatusArgs,
  UpdateRequestSessionStatusArgs,
} from '../../api/requestsApi';
import bookingApi from '../../api/bookingApi';
import {
  FetchBookingFilters,
  Request,
  RequestStatus,
  RequestType,
} from '../../types/types';

export interface RequestsListState {
  isInitial: boolean;
  requestsList: Request[];
  requestsLoading: boolean;
  totalCount: number;
  unhandledRequestsCount: number;
  requestsListRequestId: string | undefined;
  updatingSubOfferSessions: number[];
}

const initialState: RequestsListState = {
  isInitial: true,
  requestsList: [],
  requestsLoading: false,
  totalCount: 0,
  unhandledRequestsCount: 0,
  requestsListRequestId: undefined,
  updatingSubOfferSessions: [],
};

export const fetchUnhandledRequests = createAsyncThunk(
  'requests/fetchOpenRequests',
  () => {
    const limit = null;
    const offset = null;
    const filters = {
      statusId: [RequestStatus.PENDING],
    };
    return requestsApi.fetchAllRequests(
      filters,
      false,
      true,
      [RequestType.SingleTerm, RequestType.LongTerm],
      limit,
      offset,
      {
        name: 'createdAt',
        direction: 'desc',
      }
    );
  }
);

export const fetchRequestsList = createAsyncThunk(
  'requests/fetchRequestsList',
  (arg: {
    filters: { statusId: RequestStatus[]; excludeStatus?: RequestStatus[] };
    onlyMyRequest: boolean;
    onlyIncompleteRequests: boolean;
    requestType?: RequestType | RequestType[] | null;
    limit?: number;
    offset?: number;
    sortOrder?: MUISortOptions;
  }) => {
    const {
      filters,
      onlyMyRequest,
      onlyIncompleteRequests,
      requestType = null,
      limit = 10,
      offset = 0,
      sortOrder = {
        name: 'statusUpdatedAt',
        direction: 'desc',
      },
    } = arg;

    return requestsApi.fetchAllRequests(
      filters,
      onlyMyRequest,
      onlyIncompleteRequests,
      requestType,
      limit,
      offset,
      sortOrder
    );
  }
);

export const fetchClientRequests = createAsyncThunk(
  'requests/fetchClientRequests',
  async (filters: FetchBookingFilters) => {
    return bookingApi.fetchBookings(filters);
  }
);

export const updateRequestStatus = createAsyncThunk(
  'requests/updateRequestStatus',
  async (
    arg: {
      requestId: number;
      newStatus: RequestStatus;
      shouldNotify: boolean;
      selectedOption?: number;
      updateAvailability?: boolean;
    },
    { rejectWithValue }
  ) => {
    const {
      requestId,
      newStatus,
      shouldNotify,
      selectedOption,
      updateAvailability,
    } = arg;
    return requestsApi
      .updateRequestStatus(
        requestId,
        newStatus,
        shouldNotify,
        selectedOption,
        updateAvailability
      )
      .catch((e) => rejectWithValue(e));
  }
);

export const updateRequestSessionStatus = createAsyncThunk(
  'requests/updateRequestSessionStatus',
  async (arg: UpdateRequestSessionStatusArgs, { rejectWithValue }) => {
    return requestsApi
      .updateRequestSessionStatus(arg)
      .catch((e) => rejectWithValue(e));
  }
);

export const updateSubOfferSessionStatus = createAsyncThunk(
  'requests/updateSubOfferSessionStatus',
  async (arg: UpdateSubOfferSessionStatusArgs, { rejectWithValue }) => {
    return requestsApi
      .updateSubOfferSessionStatus(arg)
      .catch((e) => rejectWithValue(e));
  }
);

const requestsListSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    setRequestsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      requestsLoading: action.payload,
    }),
    requestsReceived: (
      state,
      action: PayloadAction<{ rows: Request[]; count: number }>
    ) => ({
      ...state,
      requestsLoading: false,
      requestsList: action.payload.rows,
      totalCount: action.payload.count,
    }),
    clearRequestsList: (state) => {
      state.requestsList = [];
      state.totalCount = 0;
      state.isInitial = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnhandledRequests.fulfilled, (state, action) => {
        return {
          ...state,
          requestsLoading: false,
          unhandledRequestsCount: action.payload?.length,
        };
      })
      .addCase(fetchClientRequests.pending, (state, action) => {
        return {
          ...state,
          isInitial: false,
          requestsLoading: true,
        };
      })
      .addCase(fetchClientRequests.rejected, (state, action) => {
        return {
          ...state,
          requestsLoading: false,
        };
      })
      .addCase(fetchClientRequests.fulfilled, (state, action) => {
        const { payload } = action;
        state.requestsLoading = false;

        if (Array.isArray(payload)) {
          state.requestsList = payload;
          state.totalCount = payload.length;
        } else {
          state.requestsList = payload.rows;
          state.totalCount = payload.count;
        }
      })
      .addCase(updateRequestStatus.pending, (state, action) => {
        return {
          ...state,
          requestsLoading: true,
        };
      })
      .addCase(updateRequestStatus.rejected, (state, action) => {
        return {
          ...state,
          requestsLoading: false,
        };
      })
      .addCase(updateRequestStatus.fulfilled, (state, action) => {
        return {
          ...state,
          requestsLoading: false,
        };
      })
      .addCase(fetchRequestsList.pending, (state, action) => {
        return {
          ...state,
          isInitial: false,
          requestsLoading: true,
          requestsListRequestId: action.meta.requestId,
          requestsList: [],
          totalCount: 0,
        };
      })
      .addCase(fetchRequestsList.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.requestsListRequestId &&
          state.requestsListRequestId === requestId
        ) {
          return {
            ...state,
            requestsLoading: false,
          };
        }
        return state;
      })
      .addCase(fetchRequestsList.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.requestsListRequestId &&
          state.requestsListRequestId === requestId
        ) {
          return {
            ...state,
            requestsLoading: false,
            requestsList: action.payload.rows,
            totalCount: action.payload.count,
          };
        }
        return state;
      })
      .addCase(updateRequestSessionStatus.pending, (state) => {
        return {
          ...state,
          requestsLoading: true,
        };
      })
      .addCase(updateRequestSessionStatus.fulfilled, (state, action) => {
        return {
          ...state,
          requestsLoading: false,
        };
      })
      .addCase(updateRequestSessionStatus.rejected, (state) => {
        return {
          ...state,
          requestsLoading: false,
        };
      })
      .addCase(updateSubOfferSessionStatus.pending, (state, action) => {
        state.updatingSubOfferSessions = [
          ...state.updatingSubOfferSessions,
          ...action.meta.arg.sessionIds,
        ];
      })
      .addMatcher(
        isAnyOf(
          updateSubOfferSessionStatus.fulfilled,
          updateSubOfferSessionStatus.rejected
        ),
        (state, action) => {
          const filterOnlyUpdatingSessionsList = state.updatingSubOfferSessions.filter(
            (sessionId) => !action.meta.arg.sessionIds.includes(sessionId)
          );

          state.updatingSubOfferSessions = [...filterOnlyUpdatingSessionsList];
        }
      );
  },
});

export const {
  setRequestsLoading,
  requestsReceived,
  clearRequestsList,
} = requestsListSlice.actions;

export default requestsListSlice.reducer;

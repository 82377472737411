import requester from './requester';

const getToken = async (): Promise<string> => {
  const url = `chat/token`;
  return requester
    .post(url, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.text());
};

export default {
  getToken,
};

import React, { useContext, useState } from 'react';
import moment from 'moment';
import {
  Link,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { NEW_UI_DATE_TIME_FORMAT } from '../../utils/helpers';
import StyledButtonLink from '../buttons/StyledButtonLink';
import {
  RemovedBy,
  RequestType,
  ScheduleSegment,
  SessionStatus,
} from '../../types/types';
import Badge from '../badges/Badge';
import { ReactComponent as Observation } from '../../assets/observation.svg';
import { ReactComponent as Training } from '../../assets/training.svg';
import { ReactComponent as Mentorship } from '../../assets/mentorship.svg';
import {
  acceptedStatusInfo,
  confirmedStatusInfo,
  getSessionStatus,
  holdStatusInfo,
  pendingOsStatusInfo,
  pendingStatusInfo,
} from '../../utils/StatusInfo';
import useOptOutAllowedUntil from '../../providerPortal/requests/hooks/useOptOutAllowedUntil';
import OptOutAllowedBadgeIcon from '../badges/OptOutAllowedBadge/OptOutAllowedBadgeIcon';
import { RemoveSessionVariantText } from '../../shared/constants';
import StyledButton from '../buttons/StyledButton';
import { MyScheduleContext } from '../../commonPages/MySchedule/context/MyScheduleContext';
import { SessionContext } from '../../commonPages/MySchedule/context/SessionContext';
import { PageMode, PageModeParam } from '../../commonPages/MySchedule/types';

const useStyles = makeStyles((theme: Theme) => ({
  sessionCard: {
    marginTop: theme.spacing(1.5),
  },
  sessionInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sessionIndicator: {
    height: 70,
    width: 8,
    borderRadius: 4,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  headerWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  sessionViewDetails: {
    marginRight: theme.spacing(2.5),
    fontWeight: 400,
  },
  sessionCancel: {
    fontWeight: 400,
  },
  sessionStatus: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  sessionStatusCircle: {
    background: theme.palette.kyoPastelGreen.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  confirmed: {
    background: confirmedStatusInfo.color,
    color: confirmedStatusInfo.color,
  },
  cancelled: {
    background: theme.palette.kyoGray63.main,
    color: theme.palette.kyoGray63.main,
  },
  timeOff: {
    background: theme.palette.kyoGray63.main,
    color: theme.palette.kyoGray63.main,
  },
  pending: {
    background: pendingStatusInfo.color,
    color: pendingStatusInfo.color,
  },
  accepted: {
    backgroundColor: acceptedStatusInfo.color,
    color: acceptedStatusInfo.color,
  },
  hold: {
    backgroundColor: pendingStatusInfo.color,
    color: pendingStatusInfo.color,
  },
  sessionStatusText: {
    background: 'none',
    flex: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  requestsLink: {
    color: theme.palette.kyoBlue.main,
    fontSize: '10px',
    marginLeft: '24px',
  },
}));
export type SessionStatusType = 'confirmed' | 'pending' | 'cancelRequested';

interface SessionCardProps {
  session: Partial<ScheduleSegment>;
  sessionWith?: string;
  showCancelLink: boolean;
  onViewDetails?: (newState: boolean) => void;
  showDetailsDefault?: boolean;
  onCancel?: (session: ScheduleSegment) => void;
  children?: JSX.Element;
  showStatusCircles?: boolean;
  showStatusOnDetails?: boolean;
  styles?: Partial<Record<'headerWrapper' | 'extendedRootHeader', string>>;
  userType: 'Client' | 'Provider';
}

type GetSessionStatusTextProps = {
  sessionStatus: SessionStatus;
  removedBy?: RemovedBy;
  isRemovedByYou?: boolean;
  requestType?: RequestType;
  isBookRequest?: boolean;
};

export const getSessionStatusText = ({
  sessionStatus,
  removedBy,
  isRemovedByYou,
  requestType,
  isBookRequest,
}: GetSessionStatusTextProps) => {
  switch (sessionStatus) {
    case SessionStatus.Confirmed:
      return confirmedStatusInfo.title;
    case SessionStatus.Pending:
      return requestType === RequestType.MidTerm
        ? pendingOsStatusInfo.title
        : pendingStatusInfo.title;
    case SessionStatus.Accepted:
      return acceptedStatusInfo.title;
    case SessionStatus.Cancelled:
      if (isBookRequest) {
        return 'Retracted';
      }
      if (isRemovedByYou) {
        return 'Cancelled by you';
      }
      if (removedBy) {
        return `Removed by ${removedBy}`;
      }
      return '';
    case SessionStatus.TimeOff:
      if (isRemovedByYou) {
        return 'Time Off requested by you';
      }
      if (removedBy) {
        return `Removed by ${removedBy}`;
      }
      return '';
    case SessionStatus.Hold:
      return holdStatusInfo.title;
    default:
      return confirmedStatusInfo.title;
  }
};

export const renderBadge = (theme: Theme, billingCode: string) => {
  let text: string;
  let Icon: JSX.Element;
  let textColor: string;
  switch (billingCode) {
    case '026':
      text = 'Observation';
      Icon = <Observation />;
      textColor = theme.palette.kyoBlue.main;
      break;
    case '020':
    case '120':
    case '021':
      text = 'Training';
      Icon = <Training />;
      textColor = theme.palette.kyoTeal.main;
      break;
    case 'M101':
    case 'M102':
      text = 'Mentorship';
      Icon = <Mentorship />;
      textColor = theme.palette.kyoPurple.main;
      break;
    default: {
      return null;
    }
  }

  return <Badge text={text} icon={Icon} textColor={textColor} />;
};

const SessionCard = (props: SessionCardProps) => {
  const {
    session,
    sessionWith,
    onViewDetails,
    showDetailsDefault,
    showCancelLink,
    onCancel,
    children,
    showStatusCircles = true,
    showStatusOnDetails = false,
    styles,
    userType,
  } = props;

  const {
    segmentStartUtc,
    billingCode,
    pendingLongTermBookRequestWithZipCode,
  } = session;
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const { pageMode, hideSessionsDetails } = useContext(MyScheduleContext);
  const { onSessionsSelect, isSessionCancellable } = useContext(SessionContext);

  const [showDetails, setShowDetail] = useState(showDetailsDefault);
  const isSubOfferSession = session.bookRequestType === RequestType.SubOffer;
  const sessionStatus = getSessionStatus(session);

  const isRemovedByYou =
    [SessionStatus.Cancelled, SessionStatus.TimeOff].includes(sessionStatus) &&
    (userType === 'Client'
      ? session.removedBy === RemovedBy.Client
      : session.removedBy === RemovedBy.Provider);

  const canShowStatus = !showStatusOnDetails || showDetails;

  const optOutAllowedUntil = useOptOutAllowedUntil(session.createdAt || '');
  const showOptOutAllowed =
    isSubOfferSession &&
    userType === 'Provider' &&
    moment().isSameOrBefore(optOutAllowedUntil) &&
    session.status;

  const showToRequestsLink =
    session.bookRequestType === RequestType.MidTerm &&
    session.status === SessionStatus.Pending;

  const { segmentId } = session;

  const showRemoveButton =
    userType === 'Client' &&
    pageMode === PageMode.Schedule &&
    showDetails &&
    isSessionCancellable(session as ScheduleSegment);

  const handleRemoveButtonClick = () => {
    navigate(`/schedule?page-mode=${PageModeParam.remove}`);
    hideSessionsDetails();
    if (segmentId) {
      onSessionsSelect([segmentId], true);
    }
  };

  return (
    <div className={classes.sessionCard}>
      <div
        className={clsx(
          classes.sessionInfo,
          showDetails && styles?.extendedRootHeader
        )}
      >
        {showStatusCircles && (
          <div
            className={clsx(classes.sessionIndicator, classes[sessionStatus])}
          />
        )}
        <div className={clsx(styles?.headerWrapper, classes.headerWrapper)}>
          <Typography variant="subtitle1" color="initial">
            {`${moment(segmentStartUtc).format(NEW_UI_DATE_TIME_FORMAT)} ${
              pendingLongTermBookRequestWithZipCode ? 'in' : ''
            } ${sessionWith ? `with ${sessionWith}` : ''}`}
          </Typography>

          <div>
            {(children || onViewDetails) && (
              <div className={classes.row}>
                <StyledButtonLink
                  className={classes.sessionViewDetails}
                  onClick={() => {
                    if (onViewDetails) {
                      onViewDetails(!showDetails);
                    }
                    setShowDetail(!showDetails);
                  }}
                >
                  {showDetails ? 'Hide details' : 'View details'}
                </StyledButtonLink>
                {userType === 'Provider' &&
                  billingCode &&
                  renderBadge(theme, billingCode)}
                {showOptOutAllowed && session.status ? (
                  <OptOutAllowedBadgeIcon statusId={session.status} />
                ) : (
                  <></>
                )}
              </div>
            )}
            {showCancelLink && (
              <StyledButtonLink
                className={classes.sessionCancel}
                onClick={() => {
                  if (onCancel) {
                    onCancel(session as ScheduleSegment);
                  }
                }}
              >
                {session.removeSessionVariant &&
                  RemoveSessionVariantText[session.removeSessionVariant]}
              </StyledButtonLink>
            )}
          </div>
          {canShowStatus && (
            <div className={classes.sessionStatus}>
              {showStatusCircles && (
                <div
                  className={clsx(
                    classes.sessionStatusCircle,
                    classes[sessionStatus]
                  )}
                />
              )}
              <Typography
                variant="subtitle2"
                className={clsx(
                  classes[sessionStatus],
                  classes.sessionStatusText
                )}
              >
                {`${getSessionStatusText({
                  sessionStatus,
                  removedBy: session.removedBy,
                  isRemovedByYou,
                  requestType: session.bookRequestType,
                  isBookRequest: session.isBookRequest,
                })} ${
                  session.visibleToClient === false
                    ? '(Not Visible to Client)'
                    : ''
                }`}
              </Typography>
            </div>
          )}
          {!(children || onViewDetails) &&
            userType === 'Provider' &&
            billingCode &&
            renderBadge(theme, billingCode)}
          {showOptOutAllowed && session.status && (
            <OptOutAllowedBadgeIcon statusId={session.status} />
          )}
        </div>
      </div>
      {showToRequestsLink && (
        <Link
          href={`/requests?id=${session.bookRequestId}`}
          underline="hover"
          className={classes.requestsLink}
        >
          Change in My Requests
        </Link>
      )}
      {showDetails && children}
      {showRemoveButton && (
        <StyledButton
          variant="outlined"
          customColor="kyoLightGreen"
          style={{
            width: '100%',
            marginTop: '8px',
          }}
          onClick={handleRemoveButtonClick}
        >
          Remove
        </StyledButton>
      )}
    </div>
  );
};

export default SessionCard;

import requester from './requester';

export interface CreateRatingDto {
  clientId: number;
  providerId: number;
  segmentId: number;
  value: number;
  feedbackTagIds: number[];
}

const createRating = async (ratingDto: CreateRatingDto) => {
  return requester
    .post(`ratings`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ratingDto),
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

export default {
  createRating,
};

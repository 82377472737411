import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import SimpleDialog from '../../../components/Dialogs/SimpleDialog';
import StyledButtonLink from '../../../components/buttons/StyledButtonLink';
import BTAvailabilityInfoDialog from '../../../components/HintText/BTAvailabilityInfoDialog';

const useStyles = makeStyles((theme) => ({
  enoughOptions: {
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1.5, 0),
  },
  showTimesButton: {
    marginLeft: theme.spacing(0.5),
  },
  filterIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.25),
    backgroundColor: '#F0F0F0',
    borderRadius: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));

const InfoPopup = ({
  text,
  handleClose,
  handleShowOutsideMyAvailabilityTimes,
}: {
  text: React.ReactNode;
  handleClose: () => void;
  handleShowOutsideMyAvailabilityTimes: () => void;
}) => {
  const classes = useStyles();

  return (
    <SimpleDialog title="Information" onClose={handleClose}>
      {text}
      <Typography className={classes.enoughOptions}>
        <b>Not seeing enough options?</b> <br /> Search for therapists and times
        outside of your regular availability:
      </Typography>
      <div className={classes.row}>
        <b>1.</b> Click on Filters icon{' '}
        <div className={classes.filterIcon}>
          <FilterListIcon fontSize="small" />
        </div>
      </div>
      <div className={classes.row}>
        <b>2.</b> Turn on
        <StyledButtonLink
          className={classes.showTimesButton}
          onClick={handleShowOutsideMyAvailabilityTimes}
        >
          Show Times Outside My Availability
        </StyledButtonLink>
      </div>
      <BTAvailabilityInfoDialog />
    </SimpleDialog>
  );
};

export default InfoPopup;

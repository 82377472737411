import clsx from 'clsx';
import React from 'react';
import moment from 'moment';

import { DayOfWeekId } from '../../../constants';
import { DayOfWeekLabels, DayOfWeekLabelsVariant1, WeekendIds } from './lib';
import { DATE_FORMAT_US } from '../../../../utils/helpers';

export enum StyleClassNames {
  MAIN = 'main',
  NORMAL = 'normal',
  DISABLED = 'disabled',
  LABEL = 'label',
  DAY = 'day',
}

interface HorizontalLabelWeekDayProps {
  value: DayOfWeekId;
  labels?: DayOfWeekLabels;
  disableWeekends?: boolean;
  styles?: Partial<{
    [StyleClassNames.MAIN]: string;
    [StyleClassNames.NORMAL]: string;
    [StyleClassNames.DISABLED]: string;
    [StyleClassNames.LABEL]: string;
    [StyleClassNames.DAY]: string;
  }>;
  startDate?: string;
}

const HorizontalLabelWeekDay = ({
  value,
  labels = DayOfWeekLabelsVariant1,
  disableWeekends = false,
  styles,
  startDate,
}: HorizontalLabelWeekDayProps) => {
  const isDisabled = disableWeekends && WeekendIds.includes(value);
  const momentStartDate = moment(startDate, DATE_FORMAT_US);

  return (
    <div
      className={clsx(
        styles?.main,
        isDisabled ? styles?.disabled : styles?.normal
      )}
    >
      <div className={styles?.label}>{labels[value]}</div>
      {startDate && (
        <div className={styles?.day}>
          {momentStartDate.add(value, 'days').date()}
        </div>
      )}
    </div>
  );
};

export default HorizontalLabelWeekDay;

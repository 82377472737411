import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import clientApi from '../../api/clientApi';
import { clearSearchResult } from './action';
import { fetchCurrentUser } from './users';
import * as SessionRemovalModule from './sessionRemoval';
import {
  Client,
  Goal,
  Provider,
  RemoveSessionVariant,
  ScheduleSegment,
} from '../../types/types';
import { getDiffInDays, getFirstSessionAfter } from '../../utils/helpers';
import { TWO_WEEKS } from '../../utils/constants';

export interface ClientState {
  caseTeam: Provider[] | null;
  caseTeamLoading: boolean;
  clientFullSchedule: ScheduleSegment[] | null;
  clientFullScheduleLoading: boolean;
  clientFullScheduleExtended: ScheduleSegment[] | null;
  clientFullScheduleExtendedLoading: boolean;
  clientFullScheduleRequestId: string | undefined;
  currentClient: Client | null;
  currentClientLoading: boolean;
  goals: Goal[];
  goalsLoading: boolean;
}

const initialState: ClientState = {
  caseTeam: null,
  caseTeamLoading: false,
  clientFullSchedule: null,
  clientFullScheduleLoading: false,
  clientFullScheduleExtended: null,
  clientFullScheduleExtendedLoading: false,
  clientFullScheduleRequestId: undefined,
  currentClient: null,
  currentClientLoading: false,
  goals: [],
  goalsLoading: false,
};

export const fetchClientFullSchedule = createAsyncThunk(
  'clients/fetchClientFullSchedule',
  async (arg: {
    clientId: number;
    numOfWeeks?: number;
    limit?: number;
    includePending?: boolean;
  }) => {
    return clientApi.fetchClientFullSchedule(
      arg.clientId,
      arg.numOfWeeks,
      arg.limit,
      arg.includePending
    );
  }
);

export const fetchClientFullScheduleExtended = createAsyncThunk(
  'clients/fetchClientFullScheduleExtended',
  async (arg: { clientId: number; startDate: string; endDate: string }) => {
    return clientApi.fetchClientFullScheduleExtended(
      arg.clientId,
      arg.startDate,
      arg.endDate
    );
  }
);

export const fetchClientCaseTeam = createAsyncThunk(
  'clients/fetchClientCaseTeam',
  async (clientId: number) => {
    return clientApi.fetchClientCaseTeam(clientId);
  }
);

export const fetchClientGoals = createAsyncThunk(
  'client/fetchClientGoals',
  (arg: { clientId: number; limit?: number }, { rejectWithValue }) => {
    return clientApi.fetchClientGoals(arg.clientId, arg.limit);
  }
);

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setCaseTeamLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      caseTeamLoading: action.payload,
    }),
    setCaseTeam: (state, action: PayloadAction<Provider[] | null>) => ({
      ...state,
      caseTeamLoading: false,
      caseTeam: action.payload,
    }),
    initClientFullSchedule: (state) => {
      state.clientFullSchedule = null;
    },
    setClientFullScheduleLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      clientFullScheduleLoading: action.payload,
    }),
    setCurrentClient: (state, action: PayloadAction<Client | null>) => ({
      ...state,
      currentClient: action.payload,
    }),
    setCurrentClientLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      currentClient: action.payload ? null : state.currentClient,
      currentClientLoading: action.payload,
    }),
    setGoals: (state, action: PayloadAction<Goal[]>) => ({
      ...state,
      goals: action.payload,
    }),
    setGoalsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      goalsLoading: action.payload,
    }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(clearSearchResult, (state, action) => {
        return {
          ...initialState,
          currentClient: state.currentClient,
        };
      })
      .addCase(fetchClientFullSchedule.pending, (state, action) => {
        return {
          ...state,
          clientFullScheduleLoading: true,
          clientFullScheduleRequestId: action.meta.requestId,
        };
      })
      .addCase(fetchClientFullSchedule.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.clientFullScheduleLoading &&
          state.clientFullScheduleRequestId === requestId
        ) {
          const firstTimeOffSession = getFirstSessionAfter({
            sessions: action.payload,
            after: TWO_WEEKS,
          });

          return {
            ...state,
            clientFullScheduleLoading: false,
            clientFullScheduleRequestId: undefined,
            clientFullSchedule: action.payload.map((session) => {
              const diff = getDiffInDays({
                from: new Date().toUTCString(),
                to: session.segmentStartUtc,
              });
              let removeSessionVariant =
                diff > TWO_WEEKS
                  ? RemoveSessionVariant.timeOff
                  : RemoveSessionVariant.cancellation;

              if (session.isBookRequest) {
                removeSessionVariant = RemoveSessionVariant.retraction;
              }

              return {
                ...session,
                removeSessionVariant,
                isFirstTimeOff:
                  session.segmentId === firstTimeOffSession?.segmentId,
              };
            }),
          };
        }
        return state;
      })
      .addCase(fetchClientFullSchedule.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.clientFullScheduleLoading &&
          state.clientFullScheduleRequestId === requestId
        ) {
          return {
            ...state,
            clientFullScheduleLoading: false,
            clientFullScheduleRequestId: undefined,
            clientFullSchedule: [],
            currentClient: null,
          };
        }
        return state;
      })
      .addCase(fetchClientFullScheduleExtended.pending, (state, action) => {
        return {
          ...state,
          clientFullScheduleExtendedLoading: true,
          clientFullScheduleRequestId: action.meta.requestId,
        };
      })
      .addCase(fetchClientFullScheduleExtended.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.clientFullScheduleExtendedLoading &&
          state.clientFullScheduleRequestId === requestId
        ) {
          const firstTimeOffSession = getFirstSessionAfter({
            sessions: action.payload,
            after: TWO_WEEKS,
          });

          return {
            ...state,
            clientFullScheduleExtendedLoading: false,
            clientFullScheduleRequestId: undefined,
            clientFullScheduleExtended: action.payload.map((session) => {
              const diff = getDiffInDays({
                from: new Date().toUTCString(),
                to: session.segmentStartUtc,
              });
              let removeSessionVariant =
                diff > TWO_WEEKS
                  ? RemoveSessionVariant.timeOff
                  : RemoveSessionVariant.cancellation;

              if (session.isBookRequest) {
                removeSessionVariant = RemoveSessionVariant.retraction;
              }

              return {
                ...session,
                removeSessionVariant,
                isFirstTimeOff:
                  session.segmentId === firstTimeOffSession?.segmentId,
              };
            }),
          };
        }

        return state;
      })
      .addCase(fetchClientFullScheduleExtended.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.clientFullScheduleExtendedLoading &&
          state.clientFullScheduleRequestId === requestId
        ) {
          return {
            ...state,
            clientFullScheduleExtendedLoading: false,
            clientFullScheduleRequestId: undefined,
            clientFullScheduleExtended: [],
            currentClient: null,
          };
        }
        return state;
      })
      .addCase(fetchClientCaseTeam.pending, (state, action) => {
        return {
          ...state,
          caseTeamLoading: true,
        };
      })
      .addCase(fetchClientCaseTeam.fulfilled, (state, action) => {
        return {
          ...state,
          caseTeamLoading: false,
          caseTeam: action.payload,
        };
      })
      .addCase(fetchClientCaseTeam.rejected, (state, action) => {
        return {
          ...state,
          caseTeamLoading: false,
        };
      })
      .addCase(fetchClientGoals.pending, (state, action) => {
        return {
          ...state,
          goalsLoading: true,
        };
      })
      .addCase(fetchClientGoals.fulfilled, (state, action) => {
        return {
          ...state,
          goalsLoading: false,
          goals: action.payload,
        };
      })
      .addCase(fetchClientGoals.rejected, (state, action) => {
        return {
          ...state,
          goalsLoading: false,
        };
      }),
});

export const {
  setCaseTeamLoading,
  initClientFullSchedule,
  setClientFullScheduleLoading,
  setCurrentClient,
  setCurrentClientLoading,
  setGoals,
} = clientsSlice.actions;

export default clientsSlice.reducer;

export const fetchCurrentClientById = createAsyncThunk(
  'clients/fetchCurrentClientById',
  async (clientId: number, { dispatch }) => {
    dispatch(setCurrentClientLoading(true));
    dispatch(SessionRemovalModule.resetSessionRemoval());

    try {
      const client = await clientApi.fetchClientById(clientId);
      dispatch(setCurrentClient(client));
    } catch (e: any) {
      if (e.statusCode === 403) {
        dispatch(setCurrentClient(null));
        dispatch(fetchCurrentUser());
      }
    }

    dispatch(setCurrentClientLoading(false));
  }
);

import React from 'react';

import ClientCanAddSessionHint from './ClientCanAddSessionHint';
import ClientCanNotAddSessionHint from './ClientCanNotAddSessionHint';
import ClientHasNotAvailableBlocksHint from './ClientHasNotAvailableTimeBlocksHint';
import IsPausedHint from './IsPausedHint';
import BadAuthorizationHint from './BadAuthorizationHint';
import ClientActiveDTAuth from './ClientActiveDTAuthHint';
import {
  ClientSTBRAvailability,
  SINGLE_SESSION_FORBID_REASON,
} from '../../types/types';

interface HintTextProps {
  clientCanRebook: boolean;
  notEnoughAvailableBlocks: boolean;
  clientSTBRAvailability: Pick<
    ClientSTBRAvailability,
    'maxNumberOfSessionsCanBooked' | 'forbidReason'
  >;
  assignmentApprovalRequired?: boolean;
  includeEnoughOptionsHint?: boolean;
}

const HintText = ({
  clientCanRebook,
  notEnoughAvailableBlocks,
  clientSTBRAvailability: { maxNumberOfSessionsCanBooked, forbidReason },
  assignmentApprovalRequired,
  includeEnoughOptionsHint = true,
}: HintTextProps) => {
  if (clientCanRebook) {
    return (
      <ClientCanAddSessionHint
        description={`You can add up to ${maxNumberOfSessionsCanBooked} sessions to your regular therapy schedule. Select a therapist and times that work for you. We will confirm the times that your therapist is available. Sessions will be booked at your regular location.`}
        assignmentApprovalRequired={assignmentApprovalRequired}
        includeEnoughOptionsHint={includeEnoughOptionsHint}
      />
    );
  }

  if (clientCanRebook && notEnoughAvailableBlocks) {
    return <ClientHasNotAvailableBlocksHint />;
  }

  if (forbidReason === SINGLE_SESSION_FORBID_REASON.clientPaused) {
    return <IsPausedHint />;
  }

  if (forbidReason === SINGLE_SESSION_FORBID_REASON.badAuthPeriod) {
    return <BadAuthorizationHint />;
  }

  if (forbidReason === SINGLE_SESSION_FORBID_REASON.clientActiveDTAuth) {
    return <ClientActiveDTAuth />;
  }

  if (!clientCanRebook) {
    return <ClientCanNotAddSessionHint />;
  }

  return <></>;
};

export default HintText;

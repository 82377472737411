import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  useMediaQuery,
  Theme,
  Typography,
  CircularProgress,
  Box,
  makeStyles,
  FormControlLabel,
  Link,
} from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';
import { RootState } from '../../redux/modules/rootReducer';
import {
  fetchProviderAttributes,
  fetchProviderFullSchedule,
  setProviderFullSchedule,
} from '../../redux/modules/provider';
import ScreenTitle from '../../components/ScreenTitle';
import StyledSwitch from '../../components/StyledSwitch/StyledSwitch';
import StyledButton from '../../components/buttons/StyledButton';
import {
  resetSessionRemoval,
  setSessionsToRemove,
} from '../../redux/modules/sessionRemoval';
import { ReactComponent as ExternalLink } from '../../assets/external-link.svg';
import { CENTRAL_REACH_URL } from '../../utils/constants';
import ProviderSessionsTable from './ProviderSessionsTable';
import ProviderCancellationInfo from './ProviderCancellationInfo';

const useStyles = makeStyles((theme: Theme) => ({
  controls: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  note: {
    margin: theme.spacing(4.5, 11.5, 3.5, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(4.5, 0, 3.5, 0),
    },
  },
  selectSwitch: {
    flexGrow: 1,
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
  bottomBar: {
    backgroundColor: '#F7E7A1',
    padding: theme.spacing(3),
    textAlign: 'right',
    borderRadius: '24px 24px 0 0',
    position: 'sticky',
    bottom: 0,
    marginBottom: -theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
      marginLeft: -theme.spacing(4),
      marginRight: -theme.spacing(4),
      padding: theme.spacing(2),
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      '&>button': {
        marginTop: theme.spacing(2),
      },
    },
  },
  CRLink: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.kyoBlue.main,
    '& > p:first-child': {
      whiteSpace: 'nowrap',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export const getCentralReachLink = ({
  id,
  date,
  styles,
}: {
  id: number;
  date: string;
  styles: string;
}) => (
  <Link
    href={`${CENTRAL_REACH_URL}?contactIdIncluded=${id}&startdate=${date}&scopeId=list&mode=day`}
    className={styles}
    target="_blank"
  >
    <Typography>View in CR</Typography> <ExternalLink />
  </Link>
);

// TODO reuse Sessions from clientPortal
const Sessions = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const selectedSessions = useSelector(
    (state: RootState) => state.sessionCancellation.selectedSessions
  );
  const [multiSelectMode, setMultiSelectMode] = useState(
    selectedSessions.length > 0
  );
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const currentProvider = useSelector(
    (state: RootState) => state.provider.provider
  );

  const providerFullSchedule = useSelector(
    (state: RootState) => state.provider.providerFullSchedule
  );

  const providerFullScheduleLoading = useSelector(
    (state: RootState) => state.provider.providerFullScheduleLoading
  );

  const currentProviderId = currentProvider?.id;

  const fetchFullSchedule = useCallback(() => {
    if (currentProviderId) {
      dispatch(fetchProviderAttributes(currentProviderId));
      dispatch(
        fetchProviderFullSchedule(currentProviderId, 8, undefined, true)
      );
    }
  }, [currentProviderId, dispatch]);

  useEffect(() => {
    fetchFullSchedule();
  }, [fetchFullSchedule]);

  useEffect(
    () => () => {
      dispatch(setProviderFullSchedule(null));
    },
    [dispatch]
  );

  const onRowSelection = (segmentId: number, checked: boolean) => {
    if (providerFullSchedule === null) {
      return;
    }
    if (checked) {
      const session = providerFullSchedule.find(
        (s) => s.segmentId === segmentId
      );
      if (session) {
        dispatch(setSessionsToRemove([...selectedSessions, session]));
      }
    } else {
      dispatch(
        setSessionsToRemove(
          selectedSessions.filter((s) => s.segmentId !== segmentId)
        )
      );
    }
  };

  const handleSelectMode = (isOn: boolean) => {
    setMultiSelectMode(isOn);
    dispatch(resetSessionRemoval());
  };

  const handleRemoveSessions = () => {
    dispatch(setSessionsToRemove(selectedSessions));
    navigate('/sessions/remove');
  };

  return (
    <>
      {providerFullScheduleLoading ? (
        <Box textAlign="center" p={2}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ScreenTitle title="Sessions" />
          <div className={classes.controls}>
            <FormControlLabel
              control={
                <StyledSwitch
                  checked={multiSelectMode}
                  onChange={(_, newValue) => {
                    handleSelectMode(newValue);
                  }}
                  disabled={false}
                />
              }
              label="Remove Sessions"
              labelPlacement="start"
              className={classes.selectSwitch}
            />
            <p className={classes.note}>
              To limit disruptions to treatment and our clients, please provide
              more than two weeks notice to take Time Off from sessions.
              Removing sessions with less than two weeks notice is considered a
              Cancellation and will affect your Cancel Rate and Attendance
              Status.
            </p>
          </div>
          {multiSelectMode && <ProviderCancellationInfo />}
          <ProviderSessionsTable
            provider={currentProvider}
            data={providerFullSchedule || []}
            onRowSelection={onRowSelection}
            selectMode={multiSelectMode}
            sessionsToRemove={selectedSessions}
            handleRefresh={fetchFullSchedule}
            isSmallScreen={isSmallScreen}
          />
        </>
      )}
      {multiSelectMode &&
        !!selectedSessions.length &&
        !providerFullScheduleLoading && (
          <div className={classes.bottomBar}>
            <div className={classes.buttonContainer}>
              <Typography variant="body2">
                {`You've selected ${selectedSessions.length} session${
                  selectedSessions.length > 1 ? 's' : ''
                } to remove. Select more or continue to Remove.`}
              </Typography>
              <StyledButton
                endIcon={<ArrowRightAlt />}
                customColor="kyoLightGreen"
                style={{ marginLeft: 24 }}
                onClick={handleRemoveSessions}
              >
                Remove Sessions
              </StyledButton>
            </div>
          </div>
        )}
    </>
  );
};

export default Sessions;

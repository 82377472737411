import themeWithOverrides from '../theme/theme';
import {
  Request,
  RequestSession,
  RequestStatus,
  RequestType,
  ScheduleSegment,
  SessionStatus,
  StatusesInfo,
  StatusInfo,
} from '../types/types';

const PENDING_COLOR = themeWithOverrides.palette.kyoBurntOrange.main;
const RETRACTED_COLOR = themeWithOverrides.palette.kyoGray63.main;
const CONFIRMED_COLOR = themeWithOverrides.palette.kyoPastelGreen.main;
const DECLINED_COLOR = themeWithOverrides.palette.kyoRed.main;

const declinedStatusInfo = {
  title: 'Declined',
  color: DECLINED_COLOR,
};

export const pendingStatusInfo = {
  title: 'Pending Provider Response',
  color: PENDING_COLOR,
};

export const acceptedStatusInfo = {
  title: 'Pending Scheduling Review',
  color: PENDING_COLOR,
};

const retractedStatusInfo = {
  title: 'Retracted',
  color: RETRACTED_COLOR,
};

export const confirmedStatusInfo = {
  title: 'Confirmed',
  color: CONFIRMED_COLOR,
};

export const holdStatusInfo = {
  title: 'Pending',
  color: PENDING_COLOR,
};

export const pendingOsStatusInfo = {
  title: 'Pending - OpenSession',
  color: PENDING_COLOR,
};

const LongAndShortTermStatusesInfo: StatusesInfo = {
  [RequestStatus.QUEUED]: pendingStatusInfo,
  [RequestStatus.PENDING]: pendingStatusInfo,
  [RequestStatus.DECLINED]: declinedStatusInfo,
  [RequestStatus.EXPIRED]: declinedStatusInfo,
  [RequestStatus.RETRACTED]: retractedStatusInfo,
  [RequestStatus.CONFIRMED]: confirmedStatusInfo,
  [RequestStatus.ACCEPTED]: acceptedStatusInfo,
  [RequestStatus.DENIED]: {
    title: 'Scheduling Declined',
    color: RETRACTED_COLOR,
  },
};

const getSessionsCount = (sessions: RequestSession[]) => {
  return sessions.filter((s) => s.statusId !== RequestStatus.REMOVED).length;
};

export const hasAnyAcceptedOrConfirmedSession = (
  relatedSessions: Pick<RequestSession, 'id' | 'statusId'>[] | null
): boolean => {
  if (!relatedSessions) {
    return false;
  }

  return relatedSessions.some((rs) =>
    [RequestStatus.ACCEPTED, RequestStatus.CONFIRMED].includes(rs.statusId)
  );
};

const statusColorMap: {
  statuses: RequestStatus[];
  color: string;
}[] = [
  {
    statuses: [RequestStatus.PENDING, RequestStatus.ACCEPTED],
    color: PENDING_COLOR,
  },
  {
    statuses: [RequestStatus.CONFIRMED],
    color: CONFIRMED_COLOR,
  },
  {
    statuses: [
      RequestStatus.EXPIRED,
      RequestStatus.CLOSED,
      RequestStatus.REMOVED,
      RequestStatus.RETRACTED,
      RequestStatus.DENIED,
    ],
    color: RETRACTED_COLOR,
  },
  {
    statuses: [RequestStatus.DECLINED],
    color: DECLINED_COLOR,
  },
];

export const getSessionColor = (session: RequestSession): string => {
  if (
    hasAnyAcceptedOrConfirmedSession(session.relatedSessions) &&
    session.statusId === RequestStatus.CLOSED
  ) {
    return CONFIRMED_COLOR;
  }

  if (
    hasAnyAcceptedOrConfirmedSession(session.relatedSessions) &&
    session.statusId === RequestStatus.PENDING
  ) {
    return '#6fa8dc';
  }

  const statusMapping = statusColorMap.find((mapping) =>
    mapping.statuses.includes(session.statusId)
  );

  return statusMapping?.color || '';
};

export const getSessionStatusTitle = (session: RequestSession): string => {
  const { statusId, relatedSessions } = session;

  if (statusId === RequestStatus.CLOSED && relatedSessions) {
    return hasAnyAcceptedOrConfirmedSession(relatedSessions)
      ? 'Confirmed'
      : 'Removed';
  }

  if (statusId === RequestStatus.PENDING) {
    const validSessions = relatedSessions?.filter((rs) => {
      return ![
        RequestStatus.DENIED,
        RequestStatus.CLOSED,
        RequestStatus.RETRACTED,
        RequestStatus.EXPIRED,
      ].includes(rs.statusId);
    });

    if (validSessions?.some((rs) => rs.statusId === RequestStatus.CONFIRMED)) {
      return 'Confirmed';
    }

    if (validSessions?.some((rs) => rs.statusId === RequestStatus.PENDING)) {
      return 'Pending';
    }
  }

  return RequestStatus[statusId] || '';
};

const closedStatusInfo = ({ sessions, claimedSessionsCount }: Request) => {
  const sessionsCount = getSessionsCount(sessions);
  const title = `Closed - ${claimedSessionsCount} of ${sessionsCount} Claimed`;

  return {
    title,
    color: claimedSessionsCount === 0 ? RETRACTED_COLOR : CONFIRMED_COLOR,
  };
};

const MidTermStatusesInfo: StatusesInfo = {
  [RequestStatus.PENDING]: ({ sessions, confirmedSessionsCount }) => {
    const sessionsCount = getSessionsCount(sessions);
    return {
      title: `Posted - ${confirmedSessionsCount} of ${sessionsCount} Confirmed`,
      color: '#6fa8dc',
    };
  },
  [RequestStatus.RETRACTED]: retractedStatusInfo,
  [RequestStatus.CLOSED]: closedStatusInfo,
  [RequestStatus.EXPIRED]: closedStatusInfo, // acts same as closed
};

const SubOfferStatusesInfo: StatusesInfo = {
  [RequestStatus.PENDING]: confirmedStatusInfo,
  [RequestStatus.DECLINED]: {
    title: `Opted Out`,
    color: RETRACTED_COLOR,
  },
  [RequestStatus.ACCEPTED]: confirmedStatusInfo,
  [RequestStatus.CONFIRMED]: confirmedStatusInfo,
  [RequestStatus.RETRACTED]: retractedStatusInfo,
};

export const getStatusInfo = (request: Request): StatusInfo | undefined => {
  const { type, statusId } = request;

  const statusesInfo = (() => {
    switch (type) {
      case RequestType.MidTerm:
        return MidTermStatusesInfo;
      case RequestType.SubOffer:
        return SubOfferStatusesInfo;
      default:
        return LongAndShortTermStatusesInfo;
    }
  })();

  const statusInfoCreator = statusesInfo[statusId];

  if (typeof statusInfoCreator === 'function') {
    return statusInfoCreator(request);
  }
  return statusInfoCreator;
};

export const getSessionStatus = (session: Partial<ScheduleSegment>) => {
  if (session.bookRequestType === RequestType.SubOffer) {
    return SessionStatus.Confirmed;
  }
  const hasConfirmed = session.overlappingSessions?.some(
    (os) => os.status === SessionStatus.Confirmed
  );
  if (hasConfirmed) {
    return SessionStatus.Confirmed;
  }

  if (
    session.bookRequestType === RequestType.MidTerm &&
    session.status !== SessionStatus.Cancelled
  ) {
    return SessionStatus.Pending;
  }

  return session.status || SessionStatus.Confirmed;
};

export default getStatusInfo;

import { Button, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  actionButtons: {
    width: '100%',
    textTransform: 'capitalize',
    marginTop: theme.spacing(2),
    display: 'block',
    borderRadius: theme.spacing(1),
  },
  cancelButton: {
    color: theme.palette.kyoLightGreen.main,
    borderColor: theme.palette.kyoLightGreen.main,
  },
  successButton: {
    '&, &:hover': {
      backgroundColor: theme.palette.kyoLightGreen.main,
      boxShadow: 'none',
    },
    '&:hover': {
      opacity: 0.9,
    },
    color: 'white',
  },
}));

type CommonButtonProps<T> = { [key in 'successButton' | 'cancelButton']?: T };

interface ActionsControlButtonProps {
  hideButtons?: CommonButtonProps<boolean>;
  disabled?: CommonButtonProps<boolean>;
  handleClick?: CommonButtonProps<() => void>;
  label?: CommonButtonProps<string>;
  styles?: CommonButtonProps<string> &
    Partial<{
      buttonRoot: string;
      buttonWrapper: string;
    }>;
}

const ActionsControlButton = ({
  disabled,
  handleClick,
  label,
  styles,
  hideButtons,
}: ActionsControlButtonProps) => {
  const classes = useStyles();

  return (
    <div className={styles?.buttonWrapper}>
      {!hideButtons?.successButton && (
        <Button
          className={clsx(
            classes.successButton,
            classes.actionButtons,
            styles?.successButton,
            styles?.buttonRoot
          )}
          variant="contained"
          onClick={handleClick?.successButton}
          disabled={disabled?.successButton}
        >
          {label?.successButton}
        </Button>
      )}
      {!hideButtons?.cancelButton && (
        <Button
          className={clsx(
            classes.cancelButton,
            classes.actionButtons,
            styles?.cancelButton,
            styles?.buttonRoot
          )}
          variant="outlined"
          onClick={handleClick?.cancelButton}
          disabled={disabled?.cancelButton}
        >
          {label?.cancelButton}
        </Button>
      )}
    </div>
  );
};

export default ActionsControlButton;

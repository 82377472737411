import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import fetchIntercept from 'fetch-intercept';
import { setMaintenance } from '../redux/modules/maintenance';
import { RootState } from '../redux/modules/rootReducer';

const useMaintenanceCheck = () => {
  const dispatch = useDispatch();
  const maintenanceStatusOn = useSelector(
    (state: RootState) => state.maintenance.status
  );
  useEffect(() => {
    fetchIntercept.register({
      response: (response) => {
        const { status } = response;
        const isMaintenance = status === 503;
        if (isMaintenance) {
          dispatch(setMaintenance(true));
        }
        return response;
      },
    });
  }, [dispatch]);

  return {
    maintenanceStatusOn,
  };
};

export default useMaintenanceCheck;

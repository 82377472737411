import { Auth } from '@aws-amplify/auth';
import { io, Socket } from 'socket.io-client';

import store from '../redux/store';
import configs from '../configs';
import {
  fetchRequestsList,
  fetchUnhandledRequests,
} from '../redux/modules/requests';
import { fetchNotifications } from '../redux/modules/notifications';
import { RequestStatus } from '../types/types';

export const socket: { main: Socket | null } = {
  main: null,
};

const wait = (ms = 10000) => new Promise((resolve) => setTimeout(resolve, ms));

export const closeSocketConnection = () => {
  if (socket.main) {
    socket.main.close();
  }
};

export const openSocketConnection = async () => {
  closeSocketConnection();

  const session = await Auth.currentSession();

  socket.main = await io(configs.apiUrl, {
    query: {
      token: session?.getIdToken().getJwtToken(),
    },
    transports: ['websocket'],
    reconnection: false,
  });

  if (!socket.main) {
    return;
  }

  socket.main.on('connect_error', async () => {
    await wait();
    await openSocketConnection();
  });

  socket.main.on('disconnect', async () => {
    await wait();
    if (!socket.main?.connected) {
      await openSocketConnection();
    }
  });

  // TODO create new action which will handle all of this
  socket.main.on('providerAlert', () => {
    console.info('new request');
    store.dispatch(fetchUnhandledRequests());

    const currentProviderId = store?.getState().provider?.provider?.id;
    if (currentProviderId) {
      const openStatuses = {
        statusId: [RequestStatus.PENDING, RequestStatus.QUEUED],
      };
      const onlyMyRequest = false;
      const onlyIncompleteRequests = true;

      store.dispatch(
        fetchRequestsList({
          filters: openStatuses,
          onlyMyRequest,
          onlyIncompleteRequests,
        })
      );
      store.dispatch(
        fetchNotifications({
          filters: {
            isSeen: false,
          },
          paging: {
            page: 0,
            rowsPerPage: 10,
          },
        })
      );
    }
  });
};

import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab'; // Import using relative path

interface GreetingsProps {
  userName?: string;
  userLoading: boolean;

  clientProps?: {
    clientName?: string;
    clientLoading: boolean;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
}));

const Greetings = (props: GreetingsProps) => {
  const { userName, userLoading, clientProps } = props;
  const classes = useStyles();

  const clientName = clientProps?.clientName;
  const clientLoading = clientProps?.clientLoading;

  return (
    <Box>
      <Typography className={classes.titleContainer} variant="h4" align="left">
        {userLoading ? <Skeleton /> : `Hi${userName ? `, ${userName}` : ''}`}
      </Typography>
      {clientProps && (
        <Typography variant="body1" align="left" color="textSecondary">
          {clientLoading && <Skeleton />}
          {!clientLoading &&
            clientName &&
            `You are viewing details for ${clientName || ''}`}
        </Typography>
      )}
      {!clientProps && (
        <Typography variant="body1" align="left" color="textSecondary">
          {userLoading && <Skeleton />}
          {!userLoading && 'You are logged in as a Provider'}
        </Typography>
      )}
    </Box>
  );
};

export default Greetings;

export const unavailableSomeDaysTexts = {
  user: {
    adjustAvailabilityRequest:
      'We want to adjust our availability for 1 or more weeks and add or remove blocks.',
    addAvailabilityRequest: 'We can add morning sessions during winter break.',
    timeOffRequest:
      'We have camp for 3 weeks and need time off in the afternoons.',
  },
  provider: {
    adjustAvailabilityRequest:
      'Add/Remove Time: I want to adjust availability to add or remove	blocks or days during a period.',
    addAvailabilityRequest: 'I can add availability during winter break.',
    timeOffRequest: 'I need to take afternoons off during finals week.',
  },
};

export const unavailableEntirePeriodTexts = {
  user: {
    timeOffRequest:
      'We have a vacation or other break and will be unavailable the entire period.',
  },
  provider: {
    timeOffRequest: `Time Off: I am taking time off and will be unavailable for these days.	
    (Up to 3 weeks allowed. Contact HR if you need more than 3 weeks off).`,
  },
};

import { Button, Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { FeedbackTag } from '../../../../types/types';

const useSelectorStyles = makeStyles((theme: Theme) => ({
  noTextTransform: {
    textTransform: 'none',
  },
  colorInherit: {
    color: 'inherit',
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(1.75)}px ${theme.spacing(1)}px`,
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  tegButton: {
    borderRadius: theme.spacing(1),
    border: '1px solid #C8D5D7',
    fontWeight: 500,
    width: 'auto',
    padding: theme.spacing(1.25, 1.875),
    lineHeight: '132%',
  },
  selectedTag: {
    border: '1px solid transparent',
    color: 'white',

    '&, &:hover': {
      backgroundColor: theme.palette.kyoGray63.main,
    },
  },
}));

interface FeedbackTagsSelectorProps {
  selectedTags: number[];
  setSelectedTags: (selectedTags: number[]) => void;
  tags: FeedbackTag[];
}

export const FeedbackTagsSelector = ({
  selectedTags,
  setSelectedTags,
  tags,
}: FeedbackTagsSelectorProps) => {
  const classes = useSelectorStyles();

  return (
    <div className={classes.tagsWrapper}>
      {tags.map(({ id, name }) => {
        const tagIndexOnSelectedTags = selectedTags.indexOf(id);
        const isSelected = tagIndexOnSelectedTags !== -1;

        return (
          <Button
            key={id}
            className={clsx(
              classes.tegButton,
              classes.noTextTransform,
              classes.colorInherit,
              isSelected && classes.selectedTag
            )}
            onClick={() => {
              if (!isSelected) {
                setSelectedTags([...selectedTags, id]);
                return;
              }
              const selectedTagsCopy = [...selectedTags];
              selectedTagsCopy.splice(tagIndexOnSelectedTags, 1);
              setSelectedTags(selectedTagsCopy);
            }}
          >
            {name}
          </Button>
        );
      })}
    </div>
  );
};

export default FeedbackTagsSelector;

import { makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { toAbsolutePath } from '../../../utils/helpers';

import HelpSpeechBalloons from '../../../assets/help-speech-balloons.svg';
import CurvedBgLine from '../../../assets/curved-bg-line.svg';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 375,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.kyoSkyBlue.light,
    borderRadius: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
  },
  header: {
    marginBottom: theme.spacing(2),
    color: theme.palette.kyoSkyBlue.dark,
    zIndex: 1,
  },
  link: {
    ...theme.typography.subtitle1,
    color: theme.palette.kyoRed.light,
    textDecoration: 'none',
  },
  text: {
    zIndex: 1,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.subtitle2,
    },
  },
  curvedBgLine: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(-2.75),
    transform: 'scale(1.2)',
  },
  helpSpeechBalloons: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(0.625),
    transform: 'translateY(-50%)',
  },
}));

const ContactScheduling = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.header}>
        Have a Question?
      </Typography>
      <Typography
        className={classes.text}
        variant="subtitle1"
        color="textPrimary"
      >
        Visit{' '}
        <Link className={classes.link} to="/help">
          Help
        </Link>{' '}
        for FAQs and more.
      </Typography>
      <Typography
        className={classes.text}
        variant="subtitle1"
        color="textPrimary"
      >
        Use{' '}
        <Link
          className={classes.link}
          to={toAbsolutePath('/contact-scheduling')}
        >
          Contact Us
        </Link>{' '}
        to submit a ticket.
      </Typography>
      <img
        src={CurvedBgLine}
        className={classes.curvedBgLine}
        alt="Curved background blue line"
      />
      <img
        src={HelpSpeechBalloons}
        className={classes.helpSpeechBalloons}
        alt="Red and yellow balloons with a question mark"
      />
    </Paper>
  );
};

export default ContactScheduling;

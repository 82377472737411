import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  AVAILABILITY_ACTION_STATUS,
  Availability,
} from '../../../redux/modules/availability';
import { ModifiedAvailabilityViewState } from '../constants';
import { InitialValues } from './useAvailabilityActionsTemporary';
import {
  DATE_FORMAT_US,
  ModifiedAvailabilityTimeOffMinStartDate,
} from '../../../utils/helpers';

const getInitialDates = (
  viewState: ModifiedAvailabilityViewState,
  actionStatus: AVAILABILITY_ACTION_STATUS,
  availability?: Pick<Availability, 'startDate' | 'endDate'>
) => {
  const timeOff =
    viewState === ModifiedAvailabilityViewState.UnavailableEntirePeriod;

  if (actionStatus === AVAILABILITY_ACTION_STATUS.EDIT_TEMPORARY) {
    return {
      startDate: moment(availability?.startDate),
      endDate: moment(availability?.endDate),
    };
  }

  if (timeOff) {
    return {
      startDate: ModifiedAvailabilityTimeOffMinStartDate,
      endDate: ModifiedAvailabilityTimeOffMinStartDate,
    };
  }

  const ModifiedAvailabilityMinStartDate = moment().utc().startOf('week');
  return {
    startDate: ModifiedAvailabilityMinStartDate,
    endDate: ModifiedAvailabilityMinStartDate.clone().endOf('week'),
  };
};

const useModifiedActionsViewState = (
  actionStatus: AVAILABILITY_ACTION_STATUS,
  availability:
    | Pick<Availability, 'availabilityBlocks' | 'startDate' | 'endDate'>
    | undefined,
  formRef?: React.RefObject<FormikProps<InitialValues>>
) => {
  const initStateForEditTemporary =
    actionStatus === AVAILABILITY_ACTION_STATUS.EDIT_TEMPORARY &&
    availability?.availabilityBlocks.find((block) => block.isAvailable)
      ? ModifiedAvailabilityViewState.UnavailableSomeDays
      : ModifiedAvailabilityViewState.UnavailableEntirePeriod;

  const [viewState, setViewState] = useState(
    actionStatus !== AVAILABILITY_ACTION_STATUS.EDIT_TEMPORARY
      ? ModifiedAvailabilityViewState.UnavailableSomeDays
      : initStateForEditTemporary
  );

  useEffect(() => {
    if (formRef && formRef.current) {
      const { startDate, endDate } = getInitialDates(
        viewState,
        actionStatus,
        availability
      );

      formRef.current.setValues({
        ...formRef.current.values,
        startDate: startDate.format(DATE_FORMAT_US),
        endDate: endDate.format(DATE_FORMAT_US),
      });
    }
  }, [viewState, actionStatus, availability, formRef]);

  return { viewState, setViewState };
};

export default useModifiedActionsViewState;

import React, { useState } from 'react';
import { Button, Theme, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  noTextTransform: {
    textTransform: 'none',
  },

  feedbackInfo: {
    color: theme.palette.kyoGray63.main,
    marginTop: theme.spacing(2),
  },
  expandedInfo: {
    marginTop: theme.spacing(1),
  },
  viewMoreButton: {
    padding: 0,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.kyoBlue.main,
    ...theme.typography.body2,

    '& .MuiButton-endIcon': {
      margin: 0,
    },
  },
}));

const FeedbackInfo = () => {
  const classes = useStyles();
  const [infoExpanded, setInfoExpanded] = useState(false);

  return (
    <>
      <Typography variant="body2" className={clsx(classes.feedbackInfo)}>
        Your feedback is not visible to the BT
        <Button
          className={clsx(classes.noTextTransform, classes.viewMoreButton)}
          endIcon={infoExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={() => setInfoExpanded(!infoExpanded)}
        >
          View {infoExpanded ? 'Less' : 'More'}
        </Button>
      </Typography>
      {infoExpanded && (
        <Typography
          className={clsx(classes.feedbackInfo, classes.expandedInfo)}
          variant="body2"
        >
          <b>Feedback</b> on your BT is confidential and will not be shared with
          them directly. We aggregate ratings and feedback from all clients and
          ensure there are ample reviews from multiple clients before sharing a
          summary with BTs. Your feedback is important and we use it to improve
          the training and performance of our staff. Thank you for your help in
          making our services better for everyone.
        </Typography>
      )}
    </>
  );
};

export default FeedbackInfo;

import React from 'react';
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  styledButtonLink: {
    color: theme.palette.kyoBlue.main,
    padding: 0,
    textTransform: 'none',
    fontSize: 14,
  },
}));

const StyledButtonLink = <C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }>
) => {
  const { className, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.styledButtonLink, className)}
      {...otherProps}
    />
  );
};

export default StyledButtonLink;

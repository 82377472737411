import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';

import * as serviceWorker from './serviceWorker';
import 'fetch-intercept'; // should be imported before fetch

import './index.css';
import store from './redux/store';
import { AuthProvider } from './hooks/useAuth';
import customTheme from './theme/theme';

const render = () => {
  const App = require('./App').default;
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AuthProvider>
          <BrowserRouter>
            <MuiThemeProvider theme={customTheme}>
              <CssBaseline />
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                maxSnack={1}
              >
                <ConfirmProvider>
                  <App />
                </ConfirmProvider>
              </SnackbarProvider>
            </MuiThemeProvider>
          </BrowserRouter>
        </AuthProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.Ò
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

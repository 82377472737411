import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

import { RootState } from '../../../redux/modules/rootReducer';
import IncreaseAvailabilityBadge from '../../providerProfile/IncreaseAvailabilityBadge';
import ProviderWeeklyHours from '../../weeklyHours/ProviderWeeklyHours';
import WeeklyHoursInfo from '../../weeklyHours/WeeklyHoursInfo';
import WeeklyHoursInfoModal from './WeeklyHoursInfoModal';
import InfoCircleBlue from '../../../assets/info-circle-blue.svg';
import {
  getCurrentAvail,
  getNumberOfHoursBelowRequiredAvail,
} from '../../../utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 380,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    minHeight: theme.spacing(2.875),
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
    fontWeight: 600,
    cursor: 'pointer',
  },
  badge: {
    flexBasis: 160,
  },
  weeklyHours: {
    marginTop: theme.spacing(5),
  },
  divider: {
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(3.5),
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
}));

interface WeeklyHoursMeterInfoProps {
  blocks: {
    dayOfWeekId: number;
    isAvailable: boolean;
  }[];
}

const WeeklyHoursMeterInfo = ({ blocks }: WeeklyHoursMeterInfoProps) => {
  const [avail, setAvail] = useState(0);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const {
    kyoPastelOrange: { main: kyoPastelOrange },
    kyoSilver: { dark: kyoSilver },
  } = theme.palette;

  const currentProvider = useSelector((state: RootState) => {
    return state.provider.provider;
  });

  const numberOfHoursBelowRequiredAvail: number = currentProvider
    ? getNumberOfHoursBelowRequiredAvail({
        schedule: blocks,
        minHrsTarget: currentProvider?.minHrsTarget,
      })
    : 0;

  const isLowProviderRequiredAvailability = numberOfHoursBelowRequiredAvail > 0;

  const indicatorColor = isLowProviderRequiredAvailability
    ? kyoPastelOrange
    : kyoSilver;

  useEffect(() => {
    setAvail(getCurrentAvail(blocks));
  }, [blocks]);

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.title} onClick={() => setShowInfoModal(true)}>
          Weekly Hours
          <img
            src={InfoCircleBlue}
            alt="info icon"
            className={classes.infoIcon}
          />
        </div>
        {isLowProviderRequiredAvailability && (
          <div className={classes.badge}>
            <IncreaseAvailabilityBadge disableHover />
          </div>
        )}
      </header>

      {currentProvider && (
        <div className={classes.weeklyHours}>
          <ProviderWeeklyHours
            provider={currentProvider}
            currentValue={avail}
            indicatorColor={indicatorColor}
            label="Avail"
            variant="labels_are_next_to"
          />
        </div>
      )}

      <Divider className={classes.divider} />

      <WeeklyHoursInfo
        warning={isLowProviderRequiredAvailability}
        shortage={Math.ceil(numberOfHoursBelowRequiredAvail / 3)}
      />
      <WeeklyHoursInfoModal
        open={showInfoModal}
        onClose={() => setShowInfoModal(false)}
      />
    </div>
  );
};

export default WeeklyHoursMeterInfo;

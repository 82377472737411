import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import WarningSvg from '../../../assets/warning.svg';
import SuccessSvg from '../../../assets/success.svg';
import InfoContainer, {
  InfoContainerTypes,
} from '../../../components/InfoContainer';
import { RootState } from '../../../redux/modules/rootReducer';
import SessionCard from '../../../components/SessionCard/SessionCard';
import ClientSessionDetails from '../../../components/SessionCard/ClientSessionDetails';
import { getSessionTitle } from '../../home/Home';
import CustomLoading from '../../../components/CustomLoading/CustomLoading';
import TemporaryChangesReviewDialogWrapper, {
  ScheduleSessionsWarning,
  TemporaryChangesReviewDialogWrapperMainProps,
} from '../../../components/Availability/AvailabilityActions/TemporaryChangesReviewDialogWrapper';
import ScheduleSessionsListWrapper from '../../../components/Availability/ScheduleSessionsListWrapper';
import ContainerWithImage from '../../../components/ContainerWithImage';

const useStyles = makeStyles((theme: Theme) => ({
  breaks: {
    margin: theme.spacing(2, 0),
  },
  sessionImpactWarningContent: {
    padding: theme.spacing(1.5),
  },
  sessionsSectionHeader: {
    margin: theme.spacing(1, 0),
  },
  sessionsSectionWrapper: {
    margin: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const TemporaryChangesReviewDialog = ({
  unavailableEntirePeriod,
  ...props
}: TemporaryChangesReviewDialogWrapperMainProps) => {
  const classes = useStyles();

  const scheduledSessions = useSelector((state: RootState) => {
    return state.availability.scheduledSessions;
  });
  const scheduledSessionsLoading = useSelector((state: RootState) => {
    return state.availability.scheduledSessionsLoading;
  });

  const haveScheduledSessions =
    Array.isArray(scheduledSessions) && scheduledSessions.length > 0;

  const getScheduleSessionInfoCards = () => {
    if (scheduledSessionsLoading) {
      return <></>;
    }

    if (haveScheduledSessions) {
      return (
        <InfoContainer
          className={classes.breaks}
          type={InfoContainerTypes.Error}
        >
          <ContainerWithImage
            imgSrc={WarningSvg}
            alt="warning"
            styles={{ root: classes.sessionImpactWarningContent }}
          >
            <ScheduleSessionsWarning
              unavailableEntirePeriod={unavailableEntirePeriod}
              scheduleSessionsLength={scheduledSessions?.length}
            />
          </ContainerWithImage>
        </InfoContainer>
      );
    }

    return (
      <InfoContainer
        className={classes.breaks}
        type={InfoContainerTypes.Success}
      >
        <ContainerWithImage
          imgSrc={SuccessSvg}
          alt="success"
          styles={{ root: classes.sessionImpactWarningContent }}
        >
          <Typography variant="body2">
            This change doesn’t conflict with any scheduled sessions!
          </Typography>
        </ContainerWithImage>
      </InfoContainer>
    );
  };

  return (
    <>
      <TemporaryChangesReviewDialogWrapper
        {...props}
        unavailableEntirePeriod={unavailableEntirePeriod}
      >
        {getScheduleSessionInfoCards()}
        {scheduledSessionsLoading && <CustomLoading />}

        {haveScheduledSessions && (
          <div className={classes.sessionsSectionWrapper}>
            <SessionsList />
          </div>
        )}
      </TemporaryChangesReviewDialogWrapper>
    </>
  );
};

export default TemporaryChangesReviewDialog;

const useSessionStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    justifyContent: 'space-between',
    width: '100%',
  },
  detailsRoot: {
    marginLeft: 0,
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const SessionsList = () => {
  const classes = useSessionStyles();

  const currentClient = useSelector(
    (state: RootState) => state.client.currentClient
  );

  return (
    <>
      <ScheduleSessionsListWrapper>
        {(session) => {
          const { provider } = session;
          const sessionWith = provider
            ? getSessionTitle(session, provider.firstName)
            : '';

          return (
            currentClient && (
              <SessionCard
                key={session.segmentId}
                session={session}
                sessionWith={sessionWith}
                showCancelLink={false}
                showStatusCircles={false}
                showStatusOnDetails
                styles={{
                  headerWrapper: classes.headerWrapper,
                }}
                userType="Client"
              >
                <ClientSessionDetails
                  client={currentClient}
                  session={session}
                  provider={provider}
                  hideButtons
                  styles={{
                    root: classes.detailsRoot,
                  }}
                />
              </SessionCard>
            )
          );
        }}
      </ScheduleSessionsListWrapper>
    </>
  );
};

import React from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  noSessionsText: {
    marginTop: theme.spacing(2.75),
    marginBottom: theme.spacing(2),
  },
}));

const ClientActiveDTAuth = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.noSessionsText}>
        It looks like we are working on retrieving your new authorization.
      </Typography>
      <Typography>Please check back later to use Add Sessions.</Typography>
    </>
  );
};

export default ClientActiveDTAuth;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FeedbackTag } from '../../types/types';
import feedbackTagsApi from '../../api/feedbackTagsApi';

export interface StatesState {
  loadingFeedbackTags: boolean;
  feedbackTags: FeedbackTag[];
}

const initialState: StatesState = {
  loadingFeedbackTags: false,
  feedbackTags: [],
};

export const fetchFeedbackTags = createAsyncThunk(
  'feedbackTags/fetchFeedbackTags',
  async () => {
    return feedbackTagsApi.fetchFeedbackTags();
  }
);

const statesSlice = createSlice({
  name: 'feedbackTags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedbackTags.pending, (state) => {
        state.loadingFeedbackTags = true;
      })
      .addCase(fetchFeedbackTags.fulfilled, (state, action) => {
        state.feedbackTags = action.payload;
        state.loadingFeedbackTags = false;
      })
      .addCase(fetchFeedbackTags.rejected, (state) => {
        state.loadingFeedbackTags = false;
      });
  },
});

export default statesSlice.reducer;

import React from 'react';
import { Paper, Icon, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  infoCard: {
    display: 'flex',
    padding: theme.spacing(2),
    boxShadow:
      '0px 4px 8px 0px rgb(97 97 97 / 18%), 0px 2px 4px 0px rgb(97 97 97 / 18%)',
    borderRadius: 12,
    gap: theme.spacing(2),
  },
  info: {
    background: '#FAE9B7',
  },
  success: {
    background: '#C5F2C7',
  },
  error: {
    background: '#FCD0CF',
  },
}));

type Variants = 'info' | 'error' | 'success';

export interface InfoCardProps {
  className?: string;
  children?: JSX.Element;
  icon?: JSX.Element;
  variant?: Variants;
  customStyles?: {
    [key in Variants]?: string;
  };
}

const InfoCard = (props: InfoCardProps) => {
  const { children, icon, className, variant = 'info', customStyles } = props;
  const classes = useStyles();
  const customStyle = customStyles?.[variant];
  const hasCustomStyle = !!customStyle;

  return (
    <Paper
      className={clsx(
        className,
        classes.infoCard,
        !hasCustomStyle && classes[variant],
        hasCustomStyle && customStyle
      )}
    >
      {icon && <Icon>{icon}</Icon>}
      {children}
    </Paper>
  );
};

export default InfoCard;

import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import clsx from 'clsx';
import { DATE_FORMAT_TIME_MONTH_WITH_DAY } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  optOutMessage: {
    color: 'black',
  },
  untilOptOutTime: {
    color: '#EB2C4F',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
}));

const SubOfferAllowedUntil = ({
  allowedUntil,
  rootClassName,
}: {
  allowedUntil: moment.Moment;
  rootClassName?: string;
}) => {
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      className={clsx(classes.optOutMessage, rootClassName)}
    >
      Opt-out allowed until <wbr />
      <span className={classes.untilOptOutTime}>
        {allowedUntil.format(DATE_FORMAT_TIME_MONTH_WITH_DAY)}
      </span>
    </Typography>
  );
};

export default SubOfferAllowedUntil;

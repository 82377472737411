import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  pageMaxSize: {
    maxWidth: theme.spacing(126),
  },
  divider: {
    margin: theme.spacing(2, 0, 1),
  },
  matrixDivider: {
    marginBottom: theme.spacing(2.5),
  },
  copyPastAvailabilityInfo: {
    ...theme.typography.caption,
    marginTop: theme.spacing(1),
    color: theme.palette.kyoSilver.dark,
    paddingLeft: theme.spacing(1),
  },
  info: {
    padding: theme.spacing(2),
  },
  labelInfoAvailable: {
    marginTop: theme.spacing(2),
    '& $labelInfoAvailableHint': {
      fontWeight: 400,
    },
  },
  labelInfoAvailableHint: {
    fontWeight: 400,
  },
  formContent: {
    backgroundColor: '#F8F8F6',
    padding: theme.spacing(2.25, 2.8),
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#fff',
      padding: theme.spacing(0),
    },
  },
  sessionImpactWarningContent: {
    display: 'flex',
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(3),
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: theme.spacing(3.125),
    marginTop: theme.spacing(2.75),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
  actionButton: {
    maxWidth: 'max-content',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: 0,

    [theme.breakpoints.down('xs')]: {
      maxWidth: 'none',
    },
  },
  recommendations: {
    margin: theme.spacing(4, 0, 1),
  },
  matrixVerticalLabel: {
    margin: theme.spacing(1, 0),
    minWidth: theme.spacing(6.5),

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      marginLeft: theme.spacing(1.8),
    },
  },
  matrixVerticalLabelName: {
    ...theme.typography.subtitle2,
  },
  matrixVerticalLabelContent: {
    fontSize: 12,
    fontWeight: 600,
  },

  textCapitalize: {
    textTransform: 'capitalize',
  },
  matrixInfo: {
    paddingRight: theme.spacing(1),

    '& > div:first-child': {
      marginLeft: 0,
    },
  },
  mainColor: {
    color: theme.palette.kyoBlue.main,
  },
  infoAndResetRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(4, 0),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: theme.spacing(2),
    },
  },
  overlapSessionError: {
    margin: theme.spacing(2, 0),
    color: theme.palette.kyoRed.main,
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  mb: {
    marginBottom: theme.spacing(2.5),
  },
}));

export default useStyles;

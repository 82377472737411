import { makeStyles, Theme } from '@material-ui/core';
import { AvailabilityStatusColors } from './AvailabilityActionsClient';
import { AvailabilityHoursStatus } from '../../../components/Availability/AvailableHoursWrapper';

const changeSvgCircleColor = (color: string) => ({
  '& circle': {
    stroke: color,
  },
});

export const maxWidthSize = 122;
const maxWidth = (theme: Theme) => ({
  maxWidth: theme.spacing(maxWidthSize),
});

const useStyles = makeStyles((theme: Theme) => ({
  mainColor: {
    color: theme.palette.kyoBlue.main,
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  matrixInfo: {
    paddingRight: theme.spacing(1),

    '& > div:first-child': {
      marginLeft: 0,
    },
  },
  matrixVerticalLabel: {
    margin: theme.spacing(1, 0),
    minWidth: theme.spacing(6.5),
  },
  matrixVerticalLabelName: {
    ...theme.typography.subtitle2,
  },
  matrixVerticalLabelContent: {
    fontSize: 12,
    fontWeight: 600,
  },
  infoAndResetRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(4, 0),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: theme.spacing(2),
    },
  },
  pageMaxWidth: {
    ...maxWidth(theme),
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  temporaryChangeInfo: {
    padding: theme.spacing(1.5),
    ...maxWidth(theme),

    '& > p:first-child': {
      marginBottom: theme.spacing(1),
    },
  },
  mainContent: {
    display: 'flex',
    gap: theme.spacing(4),
    marginTop: theme.spacing(2),
    ...maxWidth(theme),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  editableContent: {
    flex: 5,
  },
  dynamicInfo: {
    flex: 3,
  },
  contactSchedulingLink: {
    textDecoration: 'none',
  },
  preferences: {
    backgroundColor: '#F8F8F6',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    '& > div:last-child': {
      marginBottom: 0,
    },
  },
  preferenceRule: {
    color: theme.palette.error.main,
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  availWeekdayWrapper: {
    padding: theme.spacing(3, 2),
    borderRadius: theme.spacing(1),
    boxShadow:
      '0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)',
    border: `${theme.spacing(0.125)} solid #EEEEEE`,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  bold: {
    fontWeight: 600,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  availHoursInfo: {
    textAlign: 'left',
  },
  noneShadow: {
    boxShadow: 'none',
  },
  circleWrapper: {
    maxWidth: theme.spacing(20),
    maxHeight: theme.spacing(20),
    padding: theme.spacing(0.5),
    border: `4px dotted #F0F0F0`,
    borderRadius: '100%',
    margin: `0 auto`,
    position: 'relative',

    '& .progressText': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(-50%,-50%)`,
    },

    '& circle': {
      r: 3,
      strokeWidth: 2,
    },
  },
  wrapper: {
    flexDirection: 'row',
    boxShadow: 'none',
    border: 'none',
    padding: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cardTitle: {
    textAlign: 'start',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  [AvailabilityHoursStatus.Bad]: {
    ...changeSvgCircleColor(AvailabilityStatusColors.Bad),
  },
  [AvailabilityHoursStatus.Normal]: {
    ...changeSvgCircleColor(AvailabilityStatusColors.Normal),
  },
  [AvailabilityHoursStatus.Good]: {
    ...changeSvgCircleColor(AvailabilityStatusColors.Good),
  },
}));

export default useStyles;

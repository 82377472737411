import { AvailabilityBlock } from '../../../redux/modules/availability';
import { DayOfWeekId } from '../../../types/types';

export interface UseAvailableHoursProps {
  availabilityBlocks: AvailabilityBlock[];
}

export interface AvailableHours {
  weekdayBlocks: number;
}

const useAvailableHours = ({ availabilityBlocks }: UseAvailableHoursProps) => {
  const result = availabilityBlocks.reduce(
    (acc, currAvailBlock) => {
      const tempAcc = { ...acc };

      if (!currAvailBlock.isAvailable) {
        return acc;
      }

      if (
        ![DayOfWeekId.Sunday, DayOfWeekId.Saturday].includes(
          currAvailBlock.timeBlockDayOfWeek.dayOfWeekId
        )
      ) {
        tempAcc.weekdayBlocks += 1;
      }

      return tempAcc;
    },
    {
      weekdayBlocks: 0,
    }
  );

  return result;
};

export default useAvailableHours;

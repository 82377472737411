import React, { memo, useState } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';

import {
  Button,
  Menu,
  MenuItem,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';

import {
  DATE_FORMAT_ABBR_MONTH_NAME_WITH_DAY,
  createWeekString,
} from '../../../utils/helpers';
import { DayOfWeekId } from '../../../types/types';
import {
  Availability,
  AvailabilityBlock,
} from '../../../redux/modules/availability';
import AvailabilityMatrix from '../../Matrix/AvailabilityMatrix';
import { InitialValues } from '../hooks/useAvailabilityActionsTemporary';
import CopyAvailabilitySvg from '../../../assets/copy-availability.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  copyAvailability: {
    display: 'grid',
    cursor: 'pointer',
    gridTemplateColumns: `${theme.spacing(4)}px 1fr`,
    columnGap: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLabel: {
    color: theme.palette.kyoSilver.main,
    ...theme.typography.body1,
  },
  close: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    textTransform: 'none',
    color: theme.palette.kyoBlue2.main,
    ...theme.typography.body2,
  },
  copyAvailabilityLabel: {
    display: 'inline',
    color: theme.palette.kyoLightGreen.main,
    ...theme.typography.subtitle1,
  },
  menu: {
    width: '100%',
    maxHeight: 350,
    overflowY: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow:
      '0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.kyoGray2.dark}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  image: {
    minWidth: theme.spacing(4),
    gridRow: '1 / 3',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(3.5),
    },
  },
}));

interface CopyAvailabilityProps {
  availability: Availability[];
  onSelect: (
    avail: Availability,
    setValues: (
      values: React.SetStateAction<InitialValues>,
      shouldValidate?: boolean | undefined
    ) => void,
    startDate: string,
    initialAvailabilityBlocks: Partial<AvailabilityBlock>[]
  ) => void;
  setValues: (
    values: React.SetStateAction<InitialValues>,
    shouldValidate?: boolean | undefined
  ) => void;
  startDate: string;
  initialAvailabilityBlocks: Partial<AvailabilityBlock>[];
}

const CopyAvailability = ({
  availability,
  onSelect,
  setValues,
  startDate,
  initialAvailabilityBlocks,
}: CopyAvailabilityProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [parentWidth, setParentWidth] = useState<number | null>(null);

  const sortedAvailabilityList = orderBy(
    availability.filter(
      (avail) =>
        moment(avail.startDate).day() === DayOfWeekId.Sunday &&
        moment(avail.endDate).day() === DayOfWeekId.Saturday
    ),
    (a) => !a.createdAt
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setParentWidth(
      event.currentTarget.getBoundingClientRect().width + theme.spacing(4)
    );
  };

  const handleClose = () => setAnchorEl(null);

  const handleMenuItemClick = (avail: Availability) => {
    handleClose();
    onSelect(avail, setValues, startDate, initialAvailabilityBlocks);
  };

  return (
    <div className={classes.root}>
      <Typography onClick={handleClick} className={classes.copyAvailability}>
        <img
          src={CopyAvailabilitySvg}
          alt="Copy Availability"
          className={classes.image}
        />
        <Typography variant="body1" color="textSecondary">
          Apply a Temporary Availability from another week (Optional)
        </Typography>
        <span className={classes.copyAvailabilityLabel}>Copy Availability</span>
      </Typography>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        PaperProps={{
          elevation: 0,
        }}
        classes={{ paper: classes.menu }}
        getContentAnchorEl={null}
        style={{ width: parentWidth || 0 }}
      >
        <div className={classes.header}>
          <Typography className={classes.headerLabel}>
            Copy Availability
          </Typography>
          <Button className={classes.close} disableRipple onClick={handleClose}>
            Close
          </Button>
        </div>
        {sortedAvailabilityList.map((avail) => {
          return (
            <MenuItem
              classes={{ root: classes.menuItem }}
              onClick={() => handleMenuItemClick(avail)}
            >
              <Typography variant="body2">
                {createWeekString(
                  avail.startDate,
                  avail.endDate,
                  DATE_FORMAT_ABBR_MONTH_NAME_WITH_DAY
                )}
              </Typography>

              <AvailabilityMatrix
                availabilityBlocks={avail.availabilityBlocks}
                cellSizes={1.25}
                HorizontalLabel={() => <></>}
                // eslint-disable-next-line no-shadow
                getBlockMainStyles={(theme: Theme) => ({
                  [theme.breakpoints.up('xs')]: {
                    margin: theme.spacing(0.5, 0.25),
                  },
                })}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default memo(CopyAvailability);

import moment from 'moment';
import { useMemo } from 'react';
import {
  Availability,
  AvailabilityBlock,
  AVAILABILITY_ACTION_STATUS,
  createAvailability,
  setActionStatus,
  updateAvailability,
} from '../../../redux/modules/availability';
import { useAppDispatch } from '../../../redux/store';
import { AvailabilityType } from '../../../shared/constants';
import {
  sortAvailabilityBlocks,
  getDefaultAvailabilityBlocks,
} from '../../../shared/lib/availability';
import { DATE_FORMAT } from '../../../utils/helpers';

interface UseAvailabilityActionsArgs {
  upcomingAvailabilityMinPeriod: number;
  availability?: Availability;
}

export interface InitialValues {
  startDate?: string;
  availabilityBlocks: Partial<AvailabilityBlock>[];
  preferredBlock1Start?: string;
  preferredBlock3Start?: string;
  preferredBlock3End?: string;
}

const useAvailabilityActions = ({
  upcomingAvailabilityMinPeriod,
  availability,
}: UseAvailabilityActionsArgs) => {
  const dispatch = useAppDispatch();

  return useMemo(() => {
    const daysInThreeWeek = (upcomingAvailabilityMinPeriod + 1) * 7;
    const hasAvailability = (avail?: Availability): avail is Availability =>
      !!avail?.providerId || !!avail?.clientId;

    const availBlocks = sortAvailabilityBlocks(
      availability?.availabilityBlocks || getDefaultAvailabilityBlocks()
    );

    const minStartDate = moment()
      .utc()
      .add(daysInThreeWeek, 'days')
      .startOf('week')
      .format(DATE_FORMAT);

    const onAddSubmit = ({
      startDate,
      availabilityBlocks,
      preferredBlock1Start,
      preferredBlock3Start,
      preferredBlock3End,
    }: InitialValues) => {
      if (!hasAvailability(availability)) {
        return;
      }

      dispatch(
        createAvailability({
          startDate,
          availabilityBlocks,
          type: AvailabilityType.LongTerm,
          providerId: availability?.providerId,
          clientId: availability?.clientId,
          preferredBlock1Start,
          preferredBlock3Start,
          preferredBlock3End,
        })
      );
    };

    const onEditSubmit = ({
      startDate,
      availabilityBlocks,
      preferredBlock1Start,
      preferredBlock3Start,
      preferredBlock3End,
    }: InitialValues) => {
      if (!hasAvailability(availability)) {
        return;
      }

      dispatch(
        updateAvailability({
          id: availability.id,
          availability: {
            startDate,
            availabilityBlocks,
            status: availability.status,
            preferredBlock1Start,
            preferredBlock3Start,
            preferredBlock3End,
          },
        })
      );
    };

    const handleClose = () => {
      dispatch(setActionStatus({ status: AVAILABILITY_ACTION_STATUS.PASSIVE }));
    };

    const commonData = {
      availabilityBlocks: availBlocks,
      preferredBlock1Start: availability?.preferredBlock1Start,
      preferredBlock3Start: availability?.preferredBlock3Start,
      preferredBlock3End: availability?.preferredBlock3End,
    };

    return {
      [AVAILABILITY_ACTION_STATUS.ADD]: {
        initialValues: {
          startDate: minStartDate,
          ...commonData,
        },
        minStartDate,
        onSubmit: onAddSubmit,
        handleClose,
      },
      [AVAILABILITY_ACTION_STATUS.EDIT]: {
        initialValues: {
          startDate: moment(availability?.startDate).format(DATE_FORMAT),
          ...commonData,
        },
        minStartDate,
        onSubmit: onEditSubmit,
        handleClose,
      },
    } as {
      [key in AVAILABILITY_ACTION_STATUS]: {
        initialValues: InitialValues;
        minStartDate: string;
        onSubmit: (args: InitialValues) => void;
        handleClose: () => void;
      };
    };
  }, [availability, upcomingAvailabilityMinPeriod, dispatch]);
};
export default useAvailabilityActions;

import { useCallback, useRef } from 'react';
import fetchIntercept from 'fetch-intercept';
import { uuid } from 'uuidv4';

const SESSION_TRACKER_HEADER = 'x-session-tracker';

const useSessionTrackerId = () => {
  const interceptorRef = useRef({
    unregister: () => {
      // this function will clear the fetch interceptor
    },
  });

  const startTracking = useCallback(() => {
    const sessionTrackerId = uuid();

    // clean up old tracking
    interceptorRef.current.unregister();

    interceptorRef.current.unregister = fetchIntercept.register({
      request: (url, config) => {
        const updatedConfig = {
          ...config,
        };
        updatedConfig.headers[SESSION_TRACKER_HEADER] = sessionTrackerId;
        return [url, updatedConfig];
      },
    });
  }, []);

  const stopTracking = useCallback(() => {
    interceptorRef.current.unregister();
  }, []);

  return { startTracking, stopTracking };
};

export default useSessionTrackerId;

import React from 'react';
import {
  Container,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';
import 'moment-timezone';

import { useAuth } from '../hooks/useAuth';
import logo from '../assets/Kyo-care-logo.svg';
import rectangles from '../assets/kyo_rectangles.svg';
import AvatarIconMenu from './AvatarIconMenu';
import useMaintenanceCheck from '../hooks/useMaintenanceCheck';
import ChatIconHeader from './ChatIconHeader';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    appBar: {
      background: theme.palette.common.white,
      [theme.breakpoints.down('md')]: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.grey[300],
      },
    },
    appBarZIndex: {
      zIndex: theme.zIndex.drawer + 1,
    },
    grey: {
      [theme.breakpoints.down('md')]: {
        background: theme.palette.grey[100],
      },
    },
    menuButton: {
      color: theme.palette.common.black,
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    toolbar: {
      minHeight: theme.spacing(20),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3.5),
        minHeight: theme.spacing(11.5),
        justifyContent: 'space-between',
      },
      justifyContent: 'space-around',
    },
    logo: {
      width: theme.spacing(23),
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(12.5),
      },
    },
    rectangles: {
      paddingLeft: theme.spacing(2),
      width: theme.spacing(86),
      height: theme.spacing(16),
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    navBarControls: {
      display: 'flex',
      alignItems: 'center',
    },
  });
});

interface Props {
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDrawerOpen: boolean;
}

const AppHeader: React.FC<Props> = (props: Props) => {
  const { isDrawerOpen, setIsDrawerOpen } = props;
  const classes = useStyles();
  const { isAuthenticated, user } = useAuth();
  const { maintenanceStatusOn } = useMaintenanceCheck();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  const idTokenPayload = user?.getSignInUserSession()?.getIdToken()?.payload;

  return (
    <AppBar
      position={isDrawerOpen && isMobile ? 'fixed' : 'relative'}
      elevation={0}
      className={`${classes.appBar} ${
        isDrawerOpen ? [classes.grey, classes.appBarZIndex].join(' ') : ''
      }`}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters className={classes.toolbar}>
          <Link to={isAuthenticated ? '/home' : '/login'}>
            <img src={logo} className={classes.logo} alt="logo" />
          </Link>
          <img
            src={rectangles}
            className={classes.rectangles}
            alt="rectangles"
          />
          {isAuthenticated && !maintenanceStatusOn && (
            <div className={classes.navBarControls}>
              <ChatIconHeader />
              <AvatarIconMenu
                avatarSrc={idTokenPayload?.picture}
                onClick={() => {
                  setIsDrawerOpen(false);
                }}
              />
              <IconButton
                aria-label="open drawer"
                onClick={() => {
                  setIsDrawerOpen((prevState) => !prevState);
                }}
                className={classes.menuButton}
              >
                {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppHeader;

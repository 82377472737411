import { makeStyles, Theme, Typography } from '@material-ui/core';
import moment, { DurationInputArg2 } from 'moment';
import React from 'react';
import clsx from 'clsx';
import { DateObject } from 'react-multi-date-picker';

import StyledMultipleDatePicker from '../../styledMultipleDatePicker/StyledMultipleDatePicker';
import ScheduleAndFlagSvg from '../../../assets/scheduleAndFlag.svg';
import ScheduleAndFinishFlagSvg from '../../../assets/scheduleAndFinishFlag.svg';
import { DATE_FORMAT_US } from '../../../utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  dateLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  labelImg: {
    width: theme.spacing(3),
  },
  datePicker: {
    [theme.breakpoints.up('sm')]: {
      '&  .rmdp-input': {
        background: '#ffffff !important',
      },
    },

    [theme.breakpoints.down('xs')]: {
      '&  .rmdp-input': {
        background: '#F8F8F6 !important',
        color: `${theme.palette.kyoBlue.main} !important`,
      },
    },

    '&  .rmdp-input': {
      width: `${theme.spacing(22)}px !important`,
    },
  },
  datePickerError: {
    '&  .rmdp-input': {
      border: `1px solid ${theme.palette.kyoRed.main} !important`,
    },
  },
}));

interface AvailabilityDatesPickerProps {
  onChange: (key: string, value?: string) => void;
  startDate?: string;
  endDate?: string;
  errors: Partial<{
    startDate?: string;
    endDate?: string;
  }>;
  startDates: Record<'min' | 'max', string>;
  duration: Record<'min' | 'max', { value: number; unit: DurationInputArg2 }>;
}

const AvailabilityDatesPicker = ({
  onChange,
  startDate,
  endDate,
  errors,
  startDates,
  duration,
}: AvailabilityDatesPickerProps) => {
  const classes = useStyles();

  const getFormattedDate = (value: DateObject | DateObject[] | null) => {
    if (!value || Array.isArray(value)) {
      return undefined;
    }

    return value.format(DATE_FORMAT_US);
  };

  const minEndDate = moment(startDate)
    .add(duration.min.value, duration.min.unit)
    .subtract(1, 'day');

  const maxEndDate = moment(startDate)
    .add(duration.max.value, duration.max.unit)
    .subtract(1, 'day');

  return (
    <div className={classes.root}>
      <div className={classes.date}>
        <div className={classes.dateLabel}>
          <img
            src={ScheduleAndFlagSvg}
            alt="date"
            className={classes.labelImg}
          />
          <Typography>From</Typography>
        </div>
        <div
          className={clsx(
            classes.datePicker,
            errors.startDate && classes.datePickerError
          )}
        >
          <StyledMultipleDatePicker
            editable={false}
            value={startDate}
            format={DATE_FORMAT_US}
            onChange={(value) => {
              onChange('startDate', getFormattedDate(value));
            }}
            portal
            minDate={moment(startDates.min).toDate()}
            maxDate={moment(startDates.max).toDate()}
          />
        </div>
      </div>
      <div className={classes.date}>
        <div className={classes.dateLabel}>
          <img
            src={ScheduleAndFinishFlagSvg}
            alt="date"
            className={classes.labelImg}
          />
          <Typography>To</Typography>
        </div>
        <div
          className={clsx(
            classes.datePicker,
            errors.endDate && classes.datePickerError
          )}
        >
          <StyledMultipleDatePicker
            editable={false}
            value={endDate}
            format={DATE_FORMAT_US}
            onChange={(value) => {
              onChange('endDate', getFormattedDate(value));
            }}
            portal
            currentDate={(() => {
              return errors.endDate
                ? new DateObject({
                    date: minEndDate.toDate(),
                  })
                : undefined;
            })()}
            minDate={minEndDate.toDate()}
            maxDate={maxEndDate.toDate()}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailabilityDatesPicker;

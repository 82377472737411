import queryString from 'query-string';

import {
  Availability,
  PartialAvailability,
} from '../redux/modules/availability';
import { AvailabilityType } from '../shared/constants';
import { AvailabilityStatus } from '../shared/types';
import requester from './requester';

export interface GetAvailabilityFilter {
  providerId?: number;
  clientId?: number;
  type?: AvailabilityType;
  excludeStatuses?: AvailabilityStatus[];
}

const fetchAvailability = async (
  filters: GetAvailabilityFilter
): Promise<Availability[]> => {
  const query = queryString.stringify(filters, {
    arrayFormat: 'bracket',
  });

  return requester
    .get(`availability?${query}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

const deleteAvailability = async (id: number) => {
  return requester
    .delete(`availability/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

const createAvailability = async (availability: PartialAvailability) => {
  return requester
    .post(`availability`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(availability),
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

const updateAvailability = async (
  id: number,
  availability: PartialAvailability
) => {
  return requester
    .put(`availability/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(availability),
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchPreferredTimeBlockOptions = async () => {
  return requester
    .get(`availability/preferred-time-block-options`, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

export default {
  fetchAvailability,
  deleteAvailability,
  createAvailability,
  updateAvailability,
  fetchPreferredTimeBlockOptions,
};

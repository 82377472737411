import { Button, Divider, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles((theme: Theme) => ({
  actionWrapper: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.kyoGray.main,
    display: 'flex',
    justifyContent: 'space-around',
  },
  actionsDivider: {
    height: 'auto',
  },
  actionButton: {
    textTransform: 'capitalize',
    padding: theme.spacing(1.5, 1),
    width: '100%',
  },
  editButton: {
    color: theme.palette.kyoGray.dark,
  },
  deleteButton: {
    color: '#EB2C4F',
  },
}));

export type ModifiedAvailabilityFooterActionsStyles = Partial<
  Record<'root', string>
>;
interface CreatedAvailabilityActionsProps {
  onEdit: () => void;
  onDelete: () => void;
  styles?: ModifiedAvailabilityFooterActionsStyles;
}

const CreatedAvailabilityActions = ({
  onEdit,
  onDelete,
  styles,
}: CreatedAvailabilityActionsProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(styles?.root, classes.actionWrapper)}>
        <Button
          variant="text"
          startIcon={<CreateIcon />}
          className={clsx(classes.actionButton, classes.editButton)}
          onClick={onEdit}
        >
          Edit
        </Button>
        <Divider className={classes.actionsDivider} orientation="vertical" />
        <Button
          variant="text"
          startIcon={<DeleteIcon />}
          className={clsx(classes.actionButton, classes.deleteButton)}
          onClick={onDelete}
        >
          Delete
        </Button>
      </div>
    </>
  );
};

export default CreatedAvailabilityActions;

import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const checkBoxStyles = (theme: Theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.kyoBlue.dark,
    },
    '&$disabled': {
      color: theme.palette.grey[400],
    },
  },

  checked: {},
  disabled: {},
});

const StyledCheckbox = withStyles(checkBoxStyles)(Checkbox);
export default StyledCheckbox;

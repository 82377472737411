import { Auth } from '@aws-amplify/auth';
import { merge } from 'lodash';
import configs from '../configs';

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

class Requester {
  constructor(
    private apiUrl: string,
    private defaultRequestInit?: RequestInit
  ) {}

  get = (recoursePath: string, init?: RequestInit) => {
    return this.request(recoursePath, merge(init, { method: 'get' }));
  };

  post = (recoursePath: string, init?: RequestInit) => {
    return this.request(recoursePath, merge(init, { method: 'post' }));
  };

  put = (recoursePath: string, init?: RequestInit) => {
    return this.request(recoursePath, merge(init, { method: 'put' }));
  };

  patch = (recoursePath: string, init?: RequestInit) => {
    return this.request(recoursePath, merge(init, { method: 'patch' }));
  };

  delete = (recoursePath: string, init?: RequestInit) => {
    return this.request(recoursePath, merge(init, { method: 'delete' }));
  };

  async request(recoursePath: string, init?: RequestInit) {
    const requestInit = await this.createRequestInit(init);
    const url = this.makeUrl(recoursePath);

    const response = await fetch(url, requestInit);

    if (!response.ok) {
      throw new ResponseError(response);
    }

    return response;
  }

  private async createRequestInit(
    requestInit?: RequestInit
  ): Promise<RequestInit | undefined> {
    let init: RequestInit = {};
    const authHeader = await this.getAuthorizationHeader();

    if (authHeader) {
      init.headers = { Authorization: authHeader };
    }

    if (this.defaultRequestInit) {
      init = merge(init, this.defaultRequestInit);
    }

    if (requestInit) {
      init = merge(init, requestInit);
    }

    return init;
  }

  private getAuthorizationHeader = async (): Promise<string | undefined> => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    if (!token) {
      return undefined;
    }

    return `Bearer ${token}`;
  };

  private makeUrl = (path: string): string => {
    return `${this.apiUrl}/${path}`;
  };
}

const requester = new Requester(configs.apiUrl);

export default requester;

import React from 'react';
import moment from 'moment';
import {
  Avatar,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { uniqBy } from 'lodash';
import CovidBadgeIcon from '../badges/CovidBadge/CovidBadgeIcon';
import CalendarEvent from '../CalenderEvent/CalendarEvent';
import {
  Client,
  Provider,
  ScheduleSegment,
  SessionStatus,
} from '../../types/types';
import ProviderBCBABadge from '../badges/BCBABadge/ProviderBCBABadge';
import ProviderCell from '../table/ProviderCell';
import { formatSessionDuration } from '../../utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  providerInfo: {
    background: 'rgba(230, 230, 230, 0.5)',
    borderRadius: 8,
    display: 'flex',
    padding: theme.spacing(1, 0, 1, 0),
    '& > div': {
      margin: theme.spacing(1),
    },
    marginBottom: theme.spacing(1),
  },
  sessionExpandedDetails: {
    marginLeft: theme.spacing(3),
  },
  sessionExpandedDetailsLinks: {
    display: 'flex',
  },
  link: {
    color: theme.palette.kyoBlue.main,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  badgeContainer: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  overlapProviderInfo: {
    paddingBottom: theme.spacing(1.5),
    background: 'rgba(230, 230, 230, 0.5)',
    borderRadius: 8,
    marginBottom: theme.spacing(1),
  },
  providerCell: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

type ClientSessionDetailProvider = Pick<
  Provider,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'profilePhoto'
  | 'covidVaccinatedDate'
  | 'title'
>;
interface ClientSessionDetailsProps {
  session: Partial<ScheduleSegment>;
  client: Pick<Client, 'clientName'>;
  provider?: ClientSessionDetailProvider;
  hideButtons?: boolean;
  hideProviders?: boolean;
  styles?: Partial<Record<'root', string>>;
}

const ClientSessionDetails = (props: ClientSessionDetailsProps) => {
  const {
    session,
    provider,
    client,
    hideButtons,
    hideProviders,
    styles,
  } = props;
  const { segmentStartUtc, segmentEndUtc } = session;
  const classes = useStyles();

  const segmentStart = moment(segmentStartUtc);
  const segmentEnd = moment(segmentEndUtc);

  const providers: ClientSessionDetailProvider[] = [];
  const sessionsStatus: { [key: number]: SessionStatus } = {};
  if (provider && session.overlappingSessions?.length) {
    providers[0] = provider;
    if (session.status) {
      sessionsStatus[provider.id] = session.status;
    }
    session.overlappingSessions.forEach((os) => {
      if (os.provider) {
        providers.push(os.provider);
        sessionsStatus[os.provider.id] = os.status;
      }
    });
  }

  const uniqueProviders = uniqBy(providers, (p) => p.id);

  return (
    <div className={clsx(classes.sessionExpandedDetails, styles?.root)}>
      {client.clientName && (
        <Typography variant="body1" color="textSecondary">
          Session for {client.clientName}{' '}
          {!hideProviders && provider ? 'with:' : ''}
        </Typography>
      )}
      {!hideProviders && uniqueProviders.length === 0 && (
        <>
          {provider && (
            <Paper className={classes.providerInfo} elevation={0}>
              <Avatar src={provider.profilePhoto} />
              <div>
                <Typography variant="body1">{`${provider.firstName} ${provider.lastName}`}</Typography>
                <div className={classes.badgeContainer}>
                  {provider.covidVaccinatedDate && <CovidBadgeIcon />}
                  <ProviderBCBABadge provider={provider} />
                </div>
              </div>
            </Paper>
          )}
        </>
      )}
      {!hideProviders && uniqueProviders.length > 0 && (
        <>
          <Paper className={classes.overlapProviderInfo} elevation={0}>
            {uniqueProviders.map((p) => (
              <div key={p.lastName} className={classes.providerCell}>
                <ProviderCell
                  provider={p}
                  sessionStatus={sessionsStatus[p.id]}
                />
              </div>
            ))}
          </Paper>
          <Typography variant="body1" color="textSecondary">
            {formatSessionDuration(session)}
          </Typography>
        </>
      )}
      {!hideButtons && (
        <>
          <div className={classes.sessionExpandedDetailsLinks}>
            <CalendarEvent
              className={classes.link}
              component="button"
              variant="body2"
              title="KyoCare Session"
              startDate={segmentStart && segmentStart.toDate()}
              endDate={segmentEnd && segmentEnd.toDate()}
            >
              Add to iCal
            </CalendarEvent>
            <CalendarEvent
              calendarType="google"
              component="button"
              variant="body2"
              className={classes.link}
              title="KyoCare Session"
              startDate={segmentStart && segmentStart.toDate()}
              endDate={segmentEnd && segmentEnd.toDate()}
            >
              Add to Google
            </CalendarEvent>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientSessionDetails;

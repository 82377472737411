import React from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  noSessionsText: {
    marginTop: theme.spacing(2.75),
    marginBottom: theme.spacing(2),
  },
}));

const BadAuthorizationHint = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.noSessionsText}>
        Because of the way your insurer authorizes your services, you are unable
        to book your own sessions in Care. Please contact Scheduling directly.
      </Typography>
    </>
  );
};

export default BadAuthorizationHint;

import React from 'react';
import { makeStyles, Theme, Switch, SwitchProps } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 64,
    height: 'unset',
    padding: 10,
  },
  switchBase: {
    padding: 10,
  },
  thumb: {
    width: 24,
    height: 24,
    border: `0.5px solid ${theme.palette.grey[300]}`,
    borderRadius: 10,
    boxShadow:
      '0px 2px 4px rgba(97, 97, 97, 0.18), 0px 4px 8px rgba(97, 97, 97, 0.18);',

    '$checked &': {
      border: 'none',
    },
  },
  track: {
    height: 24,
    borderRadius: 10,
    backgroundColor: theme.palette.grey[500],
    '$switchBase$checked + &': {
      backgroundColor: theme.palette.kyoBlue.main,
      opacity: 0.3,
    },
  },
  checked: {
    color: theme.palette.kyoBlue.main,
    '&$disabled': {
      color: theme.palette.kyoBlue.main,
      opacity: 0.4,
    },
  },
  disabled: {
    color: theme.palette.grey[300],
  },
}));

const StyledSwitch = (props: SwitchProps) => {
  const classes = useStyles();
  return <Switch classes={classes} {...props} color="default" />;
};

export default StyledSwitch;

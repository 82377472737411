import { makeStyles, Theme } from '@material-ui/core';

export type JSS = { [key: string]: string | number | JSS };

interface MakeStylesProps {
  cellSizes: number;
  addMainStyles?: (theme: Theme) => JSS;
  restStyles?: (theme: Theme) => { [key: string]: JSS };
}

const useCellStyles = ({
  cellSizes,
  addMainStyles,
  restStyles,
}: MakeStylesProps) =>
  makeStyles<Theme>((theme) => ({
    main: {
      width: theme.spacing(cellSizes),
      height: theme.spacing(cellSizes),
      position: 'relative',
      borderRadius: '50%',
      margin: theme.spacing(1),
      ...(addMainStyles && addMainStyles(theme)),
    },
    ...(restStyles && restStyles(theme)),
  }));

export default useCellStyles;

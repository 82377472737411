import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import { RootState } from '../redux/modules/rootReducer';
import { TimeBlock } from '../redux/modules/adminSettings';
import { AttendanceStatusThreshold } from '../types/types';

export default function useSettings() {
  const settings = useSelector(
    (state: RootState) => state.adminSettings.settings
  );
  const tBlocks = useSelector(
    (state: RootState) => state.adminSettings.timeBlocks
  );
  const thresholds = useSelector(
    (state: RootState) => state.adminSettings.attendanceStatusThresholds
  );

  const [minOccupiedLength, setMinOccupiedLength] = useState(0);
  const [futureSearchPeriod, setFutureSearchPeriod] = useState<number>(3);
  const [swapSessionCount, setSwapSessionCount] = useState<number>(3);
  const [btwSessionBuffer, setBtwSessionBuffer] = useState<number>(60);
  const [
    upcomingAvailabilityMinPeriod,
    setUpcomingAvailabilityMinPeriod,
  ] = useState<number>(2);
  const [inDemandMinScore, setInDemandMinScore] = useState<number>(0);
  const [inDemandMaxRank, setInDemandMaxRank] = useState<number>(0);

  const [timeBlocks, setTimeBlocks] = useState<TimeBlock[]>([]);
  const [attendanceStatusThresholds, setAttendanceStatusThresholds] = useState<
    AttendanceStatusThreshold[]
  >([]);
  const [
    reAuthenticationThreshold,
    setReAuthenticationThreshold,
  ] = useState<number>(5);

  useEffect(() => {
    if (settings) {
      const settingsByKey = keyBy(settings, 'key');

      if (settingsByKey.minOccupiedLength) {
        setMinOccupiedLength(+settingsByKey.minOccupiedLength.value);
      }

      if (settingsByKey.futureSearchPeriod) {
        setFutureSearchPeriod(+settingsByKey.futureSearchPeriod.value);
      }

      if (settingsByKey.swapSessionCount) {
        setSwapSessionCount(+settingsByKey.swapSessionCount.value);
      }

      if (settingsByKey.btwSessionBuffer) {
        setBtwSessionBuffer(+settingsByKey.btwSessionBuffer.value);
      }

      if (settingsByKey.upcomingAvailabilityMinPeriod) {
        setUpcomingAvailabilityMinPeriod(
          +settingsByKey.upcomingAvailabilityMinPeriod.value
        );
      }

      if (tBlocks) {
        setTimeBlocks(tBlocks);
      }

      if (settingsByKey.inDemandMinScore) {
        setInDemandMinScore(+settingsByKey.inDemandMinScore.value);
      }

      if (settingsByKey.inDemandMaxRank) {
        setInDemandMaxRank(+settingsByKey.inDemandMaxRank.value);
      }

      if (thresholds) {
        setAttendanceStatusThresholds(thresholds);
      }

      if (settingsByKey.reAuthenticationThresholdInMinutes) {
        setReAuthenticationThreshold(
          +settingsByKey.reAuthenticationThresholdInMinutes.value
        );
      }
    }
  }, [settings, tBlocks, thresholds]);

  return {
    futureSearchPeriod,
    setFutureSearchPeriod,
    minOccupiedLength,
    setMinOccupiedLength,
    swapSessionCount,
    setSwapSessionCount,
    btwSessionBuffer,
    setBtwSessionBuffer,
    upcomingAvailabilityMinPeriod,
    timeBlocks,
    inDemandMinScore,
    inDemandMaxRank,
    attendanceStatusThresholds,
    reAuthenticationThreshold,
  };
}

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { DayOfWeekId } from '../../types/types';
import StrippedIcon from '../../assets/stripedBlockNotAvailable.png';
import { isPastBlock } from '../../shared/lib/availability';
import useSettings from '../../hooks/useSettings';

const useStyles = makeStyles((theme: Theme) => ({
  cellWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  stripedChildren: {
    opacity: 0.5,
  },
  children: {
    cursor: 'pointer',
  },
  demandImg: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    margin: 'auto',
    width: '70%',
  },
}));

interface StripedCellWrapperProps {
  horizontalValue: DayOfWeekId;
  verticalValue: number;
  children: React.ReactNode;
  startDate: string;
}

const StripedCellWrapper = ({
  children,
  horizontalValue,
  verticalValue,
  startDate,
}: StripedCellWrapperProps) => {
  const classes = useStyles();
  const { timeBlocks } = useSettings();

  const stripBlock = isPastBlock(startDate, timeBlocks, {
    horizontalValue,
    verticalValue,
  });

  return (
    <div className={classes.cellWrapper}>
      <div
        className={clsx(
          stripBlock ? classes.stripedChildren : classes.children
        )}
      >
        {children}
      </div>
      {stripBlock && (
        <img className={classes.demandImg} src={StrippedIcon} alt="stripped" />
      )}
    </div>
  );
};

export default StripedCellWrapper;

import React, { useEffect } from 'react';
import {
  makeStyles,
  MenuItem,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { TIME_FORMAT, TIME_FORMAT_24HR } from '../../../utils/helpers';

export const createTimeBlock = (value: string, index: number) => {
  return {
    id: index,
    value,
    label: moment(value, TIME_FORMAT_24HR).format(TIME_FORMAT),
  };
};

export const preferenceSelectCommonProps = {
  proportionalOptions: {
    count: 3,
    gapInHours: 0.5,
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  textField: {
    maxWidth: theme.spacing(20),
    fontSize: theme.spacing(1.5),
    backgroundColor: 'white',

    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(2, 0, 2, 3.25),
      borderRadius: theme.spacing(1),
    },

    '& .MuiSelect-iconOutlined': {
      display: 'none',
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(18),
    },
  },
}));

interface PreferenceSelectProps {
  label: string;
  value?: string | null;
  onChange: (newValue?: string) => void;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  options?: string[];
  error?: boolean;
}

const PreferenceSelect = ({
  value,
  options,
  onChange,
  textFieldProps,
  label,
  disabled = false,
  error = false,
}: PreferenceSelectProps) => {
  const classes = useStyles();
  const defaultValue = options && options[0];

  const getValue = () => {
    return !value || disabled ? defaultValue : value;
  };

  useEffect(() => {
    if (!disabled && !value) {
      onChange(defaultValue);
    } else if (disabled && value) {
      onChange(undefined);
    }
  }, [disabled, value, onChange, defaultValue]);

  return (
    <div className={classes.root}>
      <Typography variant="body1">{label}</Typography>
      <TextField
        className={classes.textField}
        select
        fullWidth
        variant="outlined"
        {...textFieldProps}
        disabled={disabled}
        value={getValue()}
        onChange={(e) => onChange(e.target.value)}
        error={error}
      >
        {options?.map((option) => (
          <MenuItem value={option} key={option}>
            {moment(option, TIME_FORMAT).format('hh:mm A')}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default PreferenceSelect;

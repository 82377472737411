import { combineReducers } from '@reduxjs/toolkit';
import providerReducer from './provider';
import clientReducer from './client';
import usersReducer from './users';
import requestsReducer from './requests';
import sessionCancellationReducer from './sessionRemoval';
import adminSettingsReducer from './adminSettings';
import notificationReducer from './notifications';
import newSessionReducer from './newSessions';
import maintenanceReducer from './maintenance';
import chatReducer from './chat';
import availabilityReducer from './availability';
import alertReducer from './alert';
import jiraReducer from './jira';
import helpReducer from './help';
import menteeProvidersReducer from './menteeProviders';
import openSessionsReducer from './openSessions';
import statesReducer from './states';
import noticesReducer from './notices';
import feedbackTagsReducer from './feedbackTags';
import ratingsReducer from './ratings';
import billingReducer from './billing';

const rootReducer = combineReducers({
  provider: providerReducer,
  client: clientReducer,
  users: usersReducer,
  requests: requestsReducer,
  adminSettings: adminSettingsReducer,
  sessionCancellation: sessionCancellationReducer,
  notifications: notificationReducer,
  newSessions: newSessionReducer,
  maintenance: maintenanceReducer,
  chat: chatReducer,
  availability: availabilityReducer,
  alert: alertReducer,
  jira: jiraReducer,
  help: helpReducer,
  menteeProviders: menteeProvidersReducer,
  openSessions: openSessionsReducer,
  states: statesReducer,
  notices: noticesReducer,
  feedbackTags: feedbackTagsReducer,
  ratings: ratingsReducer,
  billing: billingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

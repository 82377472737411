import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as InfoCircle } from '../../assets/info-circle-blue-filled.svg';
import StyledButton from '../buttons/StyledButton';

const useStyles = makeStyles((theme: Theme) => ({
  learnAboutAvailability: {
    fontSize: 14,
    padding: 0,
  },
  dialogTitle: {
    backgroundColor: theme.palette.kyoGray2.main,
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: theme.spacing(2),
    },
  },
  dialogCloseIcon: {
    fontSize: theme.spacing(3),
    cursor: 'pointer',
  },
  dialogContent: {
    paddingInline: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingInline: theme.spacing(2),
    },
  },
  infoIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  availInfo: {
    color: theme.palette.kyoGray63.dark,
    fontSize: 14,
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
  closeButton: {
    width: '80%',
  },
}));

const BTAvailabilityInfoDialog = () => {
  const classes = useStyles();
  const [availInfoDialogOpen, setAvailInfoDialogOpen] = useState(false);

  const handleClose = () => setAvailInfoDialogOpen(false);

  return (
    <>
      <StyledButton
        variant="text"
        customColor="kyoBlue"
        size="small"
        className={classes.learnAboutAvailability}
        onClick={() => setAvailInfoDialogOpen(true)}
      >
        Learn more about BT availability
      </StyledButton>
      <Dialog open={availInfoDialogOpen} onClose={handleClose}>
        <DialogTitle className={classes.dialogTitle}>
          BT Availability
          <CloseIcon
            className={classes.dialogCloseIcon}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.infoIconContainer}>
            <InfoCircle />
          </div>
          <Typography className={classes.availInfo}>
            Several factors may affect the number of providers available in your
            area or the times they are available to be scheduled. The options
            you are provided are filtered to adhere to the following:
          </Typography>
          <List className={classes.availInfo}>
            <ListItem>Provider Availability</ListItem>
            <ListItem>Insurer Requirements</ListItem>
            <ListItem>State Meal and Rest Break Regulations</ListItem>
            <ListItem>
              Client Specific Requirements (ie, language requirements, pets in
              home, specific trainings)
            </ListItem>
            <ListItem>Client and Provider Location</ListItem>
          </List>
          <Typography className={classes.availInfo}>
            Please review your profile and availability to ensure it is up to
            date.
          </Typography>
          <div className={classes.closeButtonContainer}>
            <StyledButton
              className={classes.closeButton}
              variant="outlined"
              customColor="kyoLightGreen"
              onClick={handleClose}
            >
              Close
            </StyledButton>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BTAvailabilityInfoDialog;

import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import InfoCard, { InfoCardProps } from '../../InfoCard/InfoCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    alignItems: 'center',
  },
  info: {
    backgroundColor: '#FFF5CA',
    border: '1px solid #DBCE97',
  },
  success: {
    backgroundColor: '#FBFFF9',
    border: '1px solid #82E780',
  },
  error: {
    background: '#FFF4F6',
    border: '1px solid #FF4D4D',
  },
}));

interface CustomStyledInfoCardProps extends InfoCardProps {
  image: string;
  overloadRootStyles?: string;
}

const CustomStyledInfoCard = ({
  image,
  overloadRootStyles,
  ...props
}: CustomStyledInfoCardProps) => {
  const classes = useStyles();
  return (
    <InfoCard
      icon={<img src={image} alt="info icon" />}
      className={clsx(classes.root, overloadRootStyles)}
      customStyles={{
        error: classes.error,
        info: classes.info,
        success: classes.success,
      }}
      {...props}
    />
  );
};

export default CustomStyledInfoCard;

import queryString from 'query-string';

import requester from './requester';
import { ScheduleSegment, UnratedSession } from '../types/types';

export interface FetchScheduledSessionsFilters {
  providerId?: number;
  clientId?: number;
  endDate?: string;
  startDate: string;
  includeSessionBlocks: boolean;
  timeBlockDayOfWeekIds: number[];
  isCancellableByClient?: boolean;
  isCancellableByProvider?: boolean;
}

const fetchScheduledSessions = async (
  filters: FetchScheduledSessionsFilters,
  fetchFromExtended: boolean
) => {
  const query = queryString.stringify(filters, {
    arrayFormat: 'bracket',
  });

  const endpoint = fetchFromExtended
    ? 'scheduled-sessions-extended'
    : 'scheduled-sessions';

  return requester
    .get(`${endpoint}?${query}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json() as Promise<{ data: ScheduleSegment[] }>)
    .then((response) => response.data);
};

export interface FetchUnratedSessionsFilters {
  clientId: number;
}

const fetchUnratedSessions = async (params: FetchUnratedSessionsFilters) => {
  const query = queryString.stringify(params);
  return requester
    .get(`scheduled-sessions/unrated?${query}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json() as Promise<{ data: UnratedSession[] }>)
    .then((response) => response.data);
};

export default {
  fetchScheduledSessions,
  fetchUnratedSessions,
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import openSessionsApi from '../../api/openSessionsApi';
import { MidTermBookRequest, Provider, RequestStatus } from '../../types/types';
import store from '../store';

export interface RequestsListState {
  openSessionsList: MidTermBookRequest[];
  openSessionsLoading: boolean;
  totalCount: number;
  updatedSuccess: boolean;
  updatedError: boolean;
  selectedClientOpenSessions: MidTermBookRequest[];
  selectedClientOpenSessionsLoading: boolean;
  selectedClientId: number | null;
  requestIdentifier: string | null;
}

const initialState: RequestsListState = {
  openSessionsList: [],
  openSessionsLoading: false,
  totalCount: 0,
  updatedSuccess: false,
  updatedError: false,
  selectedClientOpenSessions: [],
  selectedClientOpenSessionsLoading: false,
  selectedClientId: null,
  requestIdentifier: null,
};

export const fetchOpenSessions = createAsyncThunk(
  'requests/fetchOpenSessions',
  async (arg: {
    page: number;
    rowsPerPage: number;
    isFromCaseTeam: boolean;
    ignoreAvailability: boolean;
    currentProvider: Provider;
  }) => {
    const {
      page,
      rowsPerPage,
      isFromCaseTeam,
      ignoreAvailability,
      currentProvider,
    } = arg;
    return openSessionsApi.fetchOpenSessions(
      currentProvider,
      isFromCaseTeam,
      ignoreAvailability,
      page,
      rowsPerPage
    );
  }
);

export const fetchSelectedClientOpenSessions = createAsyncThunk(
  'requests/fetchSelectedClientOpenSessions',
  async (arg: {
    page: number;
    rowsPerPage: number;
    isFromCaseTeam: boolean;
    ignoreAvailability: boolean;
    currentProvider: Provider;
    clientId: number;
  }) => {
    const {
      page,
      rowsPerPage,
      isFromCaseTeam,
      ignoreAvailability,
      currentProvider,
      clientId,
    } = arg;
    return openSessionsApi.fetchOpenSessions(
      currentProvider,
      isFromCaseTeam,
      ignoreAvailability,
      page,
      rowsPerPage,
      clientId
    );
  }
);

export const updateOpenSessionRequest = createAsyncThunk(
  'requests/updateOpenSessionRequest',
  async ({
    requestId,
    sessionIds,
    statusId,
  }: {
    requestId: number;
    sessionIds: Array<number>;
    statusId: RequestStatus;
  }) => {
    const currentProvider = store?.getState().provider?.provider;
    return openSessionsApi.updateOpenSessionRequest(
      requestId,
      sessionIds,
      statusId,
      currentProvider?.id
    );
  }
);

const openSessionsSlice = createSlice({
  name: 'openSessions',
  initialState,
  reducers: {
    resetAcceptedState: (state) => ({
      ...state,
      updatedError: false,
      updatedSuccess: false,
      selectedClientId: null,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpenSessions.pending, (state) => {
        state.openSessionsLoading = true;
        state.openSessionsList = [];
        state.totalCount = 0;
      })
      .addCase(fetchOpenSessions.fulfilled, (state, action) => {
        const openSessionsListWithBlocks = action.payload.items?.map(
          (request: MidTermBookRequest) => {
            const openSessionBlocks: {
              dayOfWeekId: number;
              timeBlockId: number;
            }[] = [];
            request.sessions.forEach((s) => {
              const timeBlocks = s.timeBlockIds
                ?.split(',')
                .map((tb) => Number(tb));
              // eslint-disable-next-line no-unused-expressions
              timeBlocks?.forEach((tb) => {
                openSessionBlocks.push({
                  timeBlockId: tb,
                  dayOfWeekId: s.dayOfWeekId,
                });
              });
            });
            return {
              ...request,
              openSessionBlocks,
            };
          }
        );

        state.openSessionsLoading = false;
        state.openSessionsList = openSessionsListWithBlocks;
        state.totalCount = action.payload.meta.totalItems;
      })
      .addCase(fetchOpenSessions.rejected, (state) => {
        state.openSessionsLoading = false;
      })
      .addCase(updateOpenSessionRequest.pending, (state) => {
        state.openSessionsLoading = true;
      })
      .addCase(updateOpenSessionRequest.fulfilled, (state, action) => {
        state.updatedSuccess = true;
        state.updatedError = false;
        state.openSessionsLoading = false;
        state.selectedClientId = action.payload.clientId;
        state.requestIdentifier = action.meta.requestId;
      })
      .addCase(updateOpenSessionRequest.rejected, (state) => {
        state.updatedError = true;
        state.updatedSuccess = false;
        state.openSessionsLoading = false;
        state.selectedClientId = null;
      })
      .addCase(fetchSelectedClientOpenSessions.pending, (state) => {
        state.selectedClientOpenSessionsLoading = true;
        state.selectedClientOpenSessions = [];
      })
      .addCase(fetchSelectedClientOpenSessions.fulfilled, (state, action) => {
        const openSessionsListWithBlocks = action.payload.items?.map(
          (request: MidTermBookRequest) => {
            const openSessionBlocks: {
              dayOfWeekId: number;
              timeBlockId: number;
            }[] = [];
            request.sessions.forEach((s) => {
              const timeBlocks = s.timeBlockIds
                ?.split(',')
                .map((tb) => Number(tb));
              if (timeBlocks) {
                timeBlocks.forEach((tb) => {
                  openSessionBlocks.push({
                    timeBlockId: tb,
                    dayOfWeekId: s.dayOfWeekId,
                  });
                });
              }
            });
            return {
              ...request,
              openSessionBlocks,
            };
          }
        );

        state.selectedClientOpenSessionsLoading = false;
        state.selectedClientOpenSessions = openSessionsListWithBlocks;
      })
      .addCase(fetchSelectedClientOpenSessions.rejected, (state) => {
        state.selectedClientOpenSessionsLoading = false;
      });
  },
});

export const { resetAcceptedState } = openSessionsSlice.actions;

export default openSessionsSlice.reducer;

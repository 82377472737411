import React, { useState } from 'react';
import {
  Radio,
  Theme,
  ThemeProvider,
  Typography,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import moment from 'moment';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables';
import clsx from 'clsx';

import { STBRBlockOption } from '../../../types/types';
import {
  TIME_FORMAT_24HR,
  TIME_FORMAT_2,
  humanizeDuration,
  DATE_FORMAT_WEEKDAY_MONTH_YEAR,
  DATE_FORMAT,
} from '../../../utils/helpers';
import { SessionPlacement } from '../../../redux/modules/adminSettings';
import ActionsControlButton from '../../../components/buttons/ActionsControlButton';
import SimpleDialog from '../../../components/Dialogs/SimpleDialog';
import CalendarSrc from '../../../assets/defaultOpenSessionCalendarIcon.svg';

const selectTimeTheme = (theme: Theme) =>
  createMuiTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: {
          color: theme.palette.kyoGray63.main,
        },
      },
      MuiPaper: {
        root: {
          '& table tbody': {
            borderBottom: 'none',
          },
        },
      },
      MuiTableBody: {
        root: {
          '& tr td:first-child': {
            paddingLeft: 0,
          },
        },
      },
      MuiTableCell: {
        root: {
          padding: theme.spacing(0.5, 2),
        },
      },
    },
  });

const useStyles = makeStyles((theme: Theme) => ({
  radioButton: {
    color: '#D9D9D9',

    '&.Mui-checked': {
      color: theme.palette.kyoBlue2.main,
    },
  },
  dialog: {
    '& .MuiPaper-root': {
      width: theme.spacing(50),
    },
  },
  dialogContent: {
    padding: theme.spacing(2, 6),
    overflowX: 'auto',
  },
  duration: {
    display: 'flex',
    gap: theme.spacing(0.25),
    color: theme.palette.kyoGray63.main,
  },
  durationIcon: {
    fontSize: theme.spacing(2),
    transform: 'translateY(2px)',
  },
  contentHeader: {
    display: 'flex',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),

    '& img': {
      width: theme.spacing(2),
    },
  },
  scheduleDate: {
    color: 'black',
  },
  time: {
    fontWeight: 700,
    color: theme.palette.kyoGray.dark,
  },
  disabled: {
    opacity: 0.4,
  },
}));

interface SelectTimeDialogProps {
  bookOption: STBRBlockOption;
  onClose: () => void;
  handleSave: (sessionPlacement: SessionPlacement) => void;
  disabledSessionPlacementIds: number[];
}

const SelectTimesDialog = ({
  bookOption,
  onClose,
  handleSave,
  disabledSessionPlacementIds,
}: SelectTimeDialogProps) => {
  const classes = useStyles();

  const [
    selectedSessionPlacement,
    setSelectedSessionPlacement,
  ] = useState<SessionPlacement | null>(null);

  const options: MUIDataTableOptions = {
    viewColumns: false,
    selectableRows: 'none',
    search: false,
    print: false,
    filter: false,
    sort: false,
    pagination: false,
    download: false,
    elevation: 0,
    responsive: 'standard',
    rowHover: false,
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: '',
      label: '',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const sessionPlacement = bookOption.sessionPlacements[dataIndex];
          const isDisabled = disabledSessionPlacementIds.includes(
            sessionPlacement.id
          );

          return (
            <Radio
              disabled={isDisabled}
              className={clsx(
                classes.radioButton,
                isDisabled && classes.disabled
              )}
              checked={sessionPlacement?.id === selectedSessionPlacement?.id}
              onChange={() => {
                setSelectedSessionPlacement(sessionPlacement);
              }}
            />
          );
        },
      },
    },
    {
      name: '',
      label: 'Time',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const sessionPlacement = bookOption.sessionPlacements[dataIndex];
          const isDisabled = disabledSessionPlacementIds.includes(
            sessionPlacement.id
          );

          return (
            <div className={clsx(classes.time, isDisabled && classes.disabled)}>
              {moment(sessionPlacement.startTime, TIME_FORMAT_24HR).format(
                TIME_FORMAT_2
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'Duration',
      label: '',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const sessionPlacement = bookOption.sessionPlacements[dataIndex];

          const humanizedDuration = humanizeDuration(sessionPlacement.duration);

          const isDisabled = disabledSessionPlacementIds.includes(
            sessionPlacement.id
          );

          return (
            <div
              className={clsx(classes.duration, isDisabled && classes.disabled)}
            >
              <TimelapseIcon className={classes.durationIcon} />
              {humanizedDuration}
            </div>
          );
        },
      },
    },
  ];

  return (
    <SimpleDialog
      title="Select a Time"
      onClose={onClose}
      styles={{ root: classes.dialog, content: classes.dialogContent }}
    >
      <div className={classes.contentHeader}>
        <img src={CalendarSrc} alt="calendar icon" />
        <Typography variant="subtitle1" className={classes.scheduleDate}>
          {/**
           * moment parses scheduledDate (2023-05-07T00:00:00.000Z) to client timezone and day is changed
           * now we specify scheduleDate format to avoid timeZone change.
           * TODO: wen eed to return dates in utc or in 'YYYY-MM-DD' format to avoid misunderstanding types like this
           */}
          {moment(bookOption.scheduledDate, DATE_FORMAT).format(
            DATE_FORMAT_WEEKDAY_MONTH_YEAR
          )}
        </Typography>
      </div>
      <ThemeProvider theme={selectTimeTheme}>
        <MUIDataTable
          options={options}
          title=""
          data={bookOption.sessionPlacements || []}
          columns={columns}
        />
      </ThemeProvider>
      <ActionsControlButton
        disabled={{ successButton: !selectedSessionPlacement }}
        handleClick={{
          successButton: () => {
            if (selectedSessionPlacement) {
              handleSave(selectedSessionPlacement);
            }

            onClose();
          },
          cancelButton: () => {
            onClose();
          },
        }}
        label={{
          successButton: 'Add This Time',
          cancelButton: 'Cancel',
        }}
      />
    </SimpleDialog>
  );
};

export default SelectTimesDialog;

import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { SCH_EMAIL } from '../../utils/constants';

const ClientHasNotAvailableBlocks = () => {
  return (
    <Typography>
      We&apos;d like to help you add sessions but it looks like you have no
      available time blocks that are not already occupied. Please contact{' '}
      <Link href={`mailto:${SCH_EMAIL}`}>{SCH_EMAIL}</Link> to increase your
      availability.
    </Typography>
  );
};

export default ClientHasNotAvailableBlocks;

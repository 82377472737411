import { MUISortOptions } from 'mui-datatables';
import queryString from 'query-string';
import requester from './requester';
import { RequestAction, RequestStatus, RequestType } from '../types/types';

const fetchAllRequests = async (
  filters: { statusId: RequestStatus[] },
  onlyMyRequests: boolean,
  onlyIncompleteRequests: boolean,
  bookingType: RequestType | RequestType[] | null,
  limit: number | null,
  offset: number | null,
  sortOrder: MUISortOptions
) => {
  const query = queryString.stringify(
    {
      limit,
      page: offset,
      orderBy: sortOrder.name,
      direction: sortOrder.direction,
      onlyMyRequests,
      onlyIncompleteRequests,
      ...filters,
      bookingType,
    },
    { arrayFormat: 'bracket' }
  );

  return requester
    .get(`v2/bookings/myBookings?${query}`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const updateRequestStatus = async (
  requestId: number,
  newStatus: RequestStatus,
  shouldNotify: boolean,
  selectedOption?: number,
  updateAvailability?: boolean
) => {
  return requester
    .put(`v2/bookings/${requestId}/requestStatus`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        newStatus,
        shouldNotify,
        selectedOption,
        updateAvailability,
      }),
    })
    .then((response) => response.json());
};

export type UpdateRequestSessionStatusArgs = {
  requestId: number;
  actionId: RequestAction;
  sessionIds: number[];
  updateAvailability?: boolean;
};

const updateRequestSessionStatus = async ({
  requestId,
  actionId,
  sessionIds,
  updateAvailability,
}: UpdateRequestSessionStatusArgs) => {
  return requester
    .put(`v2/bookings/single-term-book-requests/${requestId}/sessions`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        actionId,
        sessionIds,
        updateAvailability,
      }),
    })
    .then((response) => response.json());
};

export type UpdateSubOfferSessionStatusArgs = {
  requestId: number;
  statusId: RequestStatus;
  sessionIds: number[];
};

const updateSubOfferSessionStatus = async ({
  requestId,
  statusId,
  sessionIds,
}: UpdateSubOfferSessionStatusArgs) => {
  return requester
    .put(`v2/bookings/sub-offer-book-requests/${requestId}/status`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        statusId,
        sessionIds,
      }),
    })
    .then((response) => response.json());
};

export default {
  fetchAllRequests,
  updateRequestStatus,
  updateRequestSessionStatus,
  updateSubOfferSessionStatus,
};

import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Button,
  ButtonProps,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
import { updateSubOfferSessionStatus } from '../../../redux/modules/requests';
import { RequestStatus } from '../../../types/types';
import SimpleDialog from '../../../components/Dialogs/SimpleDialog';
import StyledButton from '../../../components/buttons/StyledButton';
import infoCircleBlueFilled from '../../../assets/info-circle-blue-filled.svg';
import { RootState } from '../../../redux/modules/rootReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: theme.spacing(15),
    padding: 0,
    fontWeight: 500,
    fontSize: '14px',
    minHeight: 'max-content',
  },
  valid: {
    backgroundColor: 'white',
    '&, &:hover': {
      color: theme.palette.kyoLightGreen.main,
      borderColor: theme.palette.kyoLightGreen.main,
    },
    '&.Mui-disabled': {
      color: '#C0C0C0',
      borderColor: '#C0C0C0',
    },
  },
  optedOut: {
    '&, &:hover, &.Mui-disabled': {
      color: '#EB2C4F',
      backgroundColor: '#EB2C4F1A',
      boxShadow: 'none',
    },
  },
  confirmDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
  dialogPaper: {
    width: '100%',
  },
  mobileDialogPaper: {
    height: 'min-content',
    margin: theme.spacing(0, 1),
  },
  confirmationLabel: {
    color: '#818181',
    maxWidth: theme.spacing(32),
    textAlign: 'center',
  },
  optOutConfirmButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',

    '& button': {
      marginBottom: theme.spacing(2),
      minWidth: theme.spacing(42),
    },
  },
  sessionUpdateLoading: {
    color: theme.palette.kyoGray63.main,
  },
}));

interface OptOutButtonProps {
  optedOut: boolean;
  canBeOptedOut: boolean;
  requestId: number;
  sessionId: number;
  handleSessionUpdate?: () => void;
  buttonClassName?: string;
}

const OptOutButton = ({
  optedOut,
  canBeOptedOut,
  requestId,
  sessionId,
  handleSessionUpdate,
  buttonClassName,
}: OptOutButtonProps) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const isUpdating = useSelector((state: RootState) =>
    state.requests.updatingSubOfferSessions.includes(sessionId)
  );

  const [openOptOutConfirmation, setOpenOptOutConfirmation] = useState(false);

  const buttonAttributes = ((): ButtonProps => {
    if (optedOut) {
      return {
        children: 'Opted Out',
        variant: 'contained',
        className: clsx(classes.root, buttonClassName, classes.optedOut),
        disabled: true,
      };
    }

    return {
      children: 'Opt-Out',
      variant: 'outlined',
      className: clsx(classes.root, buttonClassName, classes.valid),
      disabled: !canBeOptedOut || isUpdating,
    };
  })();

  const handleOptOutSession = async () => {
    await dispatch(
      updateSubOfferSessionStatus({
        requestId,
        sessionIds: [sessionId],
        statusId: RequestStatus.DECLINED,
      })
    );

    if (handleSessionUpdate) {
      handleSessionUpdate();
    }
  };

  return (
    <>
      <Button
        {...buttonAttributes}
        tabIndex={-1}
        onClick={() => setOpenOptOutConfirmation(true)}
        endIcon={
          isUpdating ? (
            <CircularProgress
              disableShrink
              size="0.8rem"
              className={classes.sessionUpdateLoading}
            />
          ) : (
            <></>
          )
        }
      />

      <SimpleDialog
        onClose={() => setOpenOptOutConfirmation(false)}
        title="Opt-Out Confirmation"
        open={openOptOutConfirmation}
        styles={{
          content: classes.confirmDialogContent,
          paper: clsx(
            classes.dialogPaper,
            isMobile && classes.mobileDialogPaper
          ),
        }}
      >
        <img
          src={infoCircleBlueFilled}
          alt="info_icon"
          height={48}
          width={48}
        />
        <Typography variant="body1" className={classes.confirmationLabel}>
          Are you sure you want to Opt-Out of this Sub session?
        </Typography>
        <div className={classes.optOutConfirmButtonsWrapper}>
          <StyledButton
            customColor="kyoLightGreen"
            onClick={async () => {
              setOpenOptOutConfirmation(false);
              await handleOptOutSession();
            }}
          >
            Yes
          </StyledButton>
          <StyledButton
            customColor="kyoLightGreen"
            variant="outlined"
            onClick={() => setOpenOptOutConfirmation(false)}
          >
            Close
          </StyledButton>
        </div>
      </SimpleDialog>
    </>
  );
};

export default OptOutButton;

import { makeStyles, Theme } from '@material-ui/core';

export const editableDateStyles = makeStyles((theme: Theme) => ({
  root: (props: { maxWidth: number }) => ({
    maxWidth: props.maxWidth,
    marginTop: theme.spacing(4),
  }),
  label: {
    color: theme.palette.kyoGray.dark,
  },
  valueWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  value: {
    fontWeight: 600,
  },
  editButton: {
    fontWeight: 400,
  },
  dataPicker: {
    flex: 1,
    paddingBottom: theme.spacing(0.9),

    '& > div, & > div:hover': {
      paddingBottom: theme.spacing(0.5),
      '&::before': {
        borderBottomColor: theme.palette.kyoBlue.main,
      },
    },
    '& button': {
      color: theme.palette.kyoBlue.main,
    },
  },
}));

export const getBlockMainStyles = (theme: Theme) => ({
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

import React from 'react';
import { Theme, makeStyles, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { keyBy, range } from 'lodash';

import Matrix, { MatrixProps } from '../../../shared/matrix/ui';
import { DayOfWeekId, STBRBlockOption } from '../../../types/types';
import useCellStyles from '../../../shared/matrix/ui/cell/styles';
import { getTimeBlockDayOfWeekKey } from '../../../shared/lib/availability';
import { useHorizontalLabelStylesVariant3 } from '../../../shared/matrix/ui/horizontalLabelWeekDay/styles';
import {
  DayOfWeekLabelsVariant3,
  DayOfWeekValues,
} from '../../../shared/matrix/ui/horizontalLabelWeekDay/lib';
import HorizontalLabelWeekDay from '../../../shared/matrix/ui/horizontalLabelWeekDay';
import { BookedOption, WeekRange } from '../NewSessions';
import { RootState } from '../../../redux/modules/rootReducer';
import VerticalLabelTimeBlock from '../../../shared/matrix/ui/verticalLabelTimeBlock';
import AvailableCell from './AvailableCell';
import SelectedCell from './SelectedCell';
import BookedCell from './BookedCell';
import EmptyCell from './EmptyCell';
import { SelectedBookingOption } from '../../../redux/modules/newSessions';

const useStyles = makeStyles((theme: Theme) => ({
  matrixVerticalLabel: {
    margin: theme.spacing(1, 0),
    minWidth: theme.spacing(6.5),

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      marginLeft: '2.5vw',
    },
  },
  matrixVerticalLabelName: {
    ...theme.typography.subtitle2,
  },
  matrixVerticalLabelContent: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

interface AddSessionsMatrixProps extends Partial<MatrixProps<number, number>> {
  cellSizes: number;
  availableCells: Record<number, STBRBlockOption>;
  bookedCells: Record<number, BookedOption[]>;
  selectedCells: Record<number, SelectedBookingOption>;
  weekRange: WeekRange;
  disableAvailableCells?: boolean;
}

const AddSessionsMatrix = ({
  cellSizes,
  weekRange,
  availableCells,
  bookedCells,
  selectedCells,
  disableAvailableCells = false,
  ...matrixProps
}: AddSessionsMatrixProps) => {
  const classes = useStyles();

  const timeBlocks = useSelector(
    (state: RootState) => state.adminSettings.timeBlocks
  );

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const isTabletScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const timeBlocksById = keyBy(timeBlocks, 'id');

  const cellClasses = useCellStyles({
    cellSizes,
    addMainStyles: (theme) => {
      return {
        margin: theme.spacing(0.375),
        borderRadius: theme.spacing(1.25),

        ...(!isTabletScreen && {
          width: '10vw',
          height: '6.5vw',
          maxWidth: theme.spacing(16),
          maxHeight: theme.spacing(10.375),
        }),

        ...(isTabletScreen &&
          !isSmallScreen && {
            width: '9vw',
            height: '7vw',
          }),
      };
    },
  })();
  const horizontalLabelStyles = useHorizontalLabelStylesVariant3();

  const availabilityRenderer = (
    horizontalValue: DayOfWeekId,
    verticalValue: number
  ): React.ReactNode => {
    const key = getTimeBlockDayOfWeekKey({
      timeBlockId: verticalValue,
      dayOfWeekId: horizontalValue,
    });

    const selectedOption = selectedCells[key];
    const bookOption = availableCells[key];
    const bookedOptions = bookedCells[key];

    if (selectedOption) {
      return (
        <SelectedCell
          className={cellClasses.main}
          selectedBookingOption={selectedOption}
        />
      );
    }

    if (bookOption) {
      const dayStartTimeBlockDayOfWeekId = horizontalValue * 3;
      const nextDayTimeBlockDayOfWeekId = dayStartTimeBlockDayOfWeekId + 3;

      const someDaySelectedCellsTimeBlockDayOfWeekIds = range(
        dayStartTimeBlockDayOfWeekId,
        nextDayTimeBlockDayOfWeekId
      )
        .sort((a, b) => a - b)
        .filter((n) => selectedCells[n]);

      const beforeCells = someDaySelectedCellsTimeBlockDayOfWeekIds.filter(
        (n) => n < key
      );
      const afterCells = someDaySelectedCellsTimeBlockDayOfWeekIds.filter(
        (n) => n > key
      );

      return (
        <AvailableCell
          className={cellClasses.main}
          bookOption={bookOption}
          selectedOptionAfter={selectedCells[afterCells[0]]}
          selectedOptionBefore={
            selectedCells[beforeCells[beforeCells.length - 1]]
          }
          disable={disableAvailableCells}
        />
      );
    }

    if (bookedOptions) {
      return (
        <BookedCell
          className={cellClasses.main}
          bookedOptions={bookedOptions}
        />
      );
    }

    return <EmptyCell className={cellClasses.main} />;
  };

  return (
    <Matrix
      horizontalValues={DayOfWeekValues}
      verticalValues={[1, 2, 3]}
      HorizontalLabel={({ value }) => (
        <HorizontalLabelWeekDay
          value={value}
          styles={horizontalLabelStyles}
          labels={DayOfWeekLabelsVariant3}
          startDate={weekRange.weekStart}
        />
      )}
      VerticalLabel={({ value }) => (
        <VerticalLabelTimeBlock
          value={value}
          timeBlock={timeBlocksById[value]}
          styles={{
            root: classes.matrixVerticalLabel,
            name: classes.matrixVerticalLabelName,
            content: classes.matrixVerticalLabelContent,
          }}
          periodNameLength={2}
        />
      )}
      render={availabilityRenderer}
      {...matrixProps}
    />
  );
};

export default AddSessionsMatrix;

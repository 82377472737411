import React from 'react';
import { makeStyles, SvgIcon, SvgIconProps, Theme } from '@material-ui/core';
import { ReactComponent as BCBABadge } from '../../../assets/BCBA-badge.svg';

const useStyles = makeStyles((theme: Theme) => ({
  icon: { height: 17, width: 74 },
}));

const BCBABadgeIcon = (props: SvgIconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      {...props}
      component={BCBABadge}
      viewBox="0 0 74 17"
      classes={{ root: classes.icon }}
    />
  );
};

export default BCBABadgeIcon;

import useAvailableHours, {
  AvailableHours,
  UseAvailableHoursProps,
} from './hooks/useAvailableHours';

export enum AvailabilityHoursStatus {
  Good = 'Good',
  Normal = 'Normal',
  Bad = 'Bad',
}

interface AvailableHoursWrapperProps extends UseAvailableHoursProps {
  children: (
    hours: AvailableHours,
    status: AvailabilityHoursStatus
  ) => JSX.Element;
  weeklyAuthSize: number;
}

const AvailableHoursWrapper = ({
  availabilityBlocks,
  children,
  weeklyAuthSize = 0,
}: AvailableHoursWrapperProps) => {
  const availHours = useAvailableHours({
    availabilityBlocks,
  });

  const getStatus = () => {
    if (availHours.weekdayBlocks < 4) {
      return AvailabilityHoursStatus.Bad;
    }

    if (availHours.weekdayBlocks * 3 < weeklyAuthSize) {
      return AvailabilityHoursStatus.Normal;
    }

    return AvailabilityHoursStatus.Good;
  };

  return children(availHours, getStatus());
};

export default AvailableHoursWrapper;

type AppBarColor =
  | 'primary'
  | 'default'
  | 'inherit'
  | 'transparent'
  | 'secondary'
  | undefined;

interface Config {
  apiUrl: string;
  googleApiKey: string;
  cognito: { Auth: any };
  appBarColor: AppBarColor;
  oldUIUrl: string;
  gtagId: string | null;
  chatToken: string;
  portalUrl: string;
  educatorPortalUrl: string;
  revenuePortalUrl: string;
  chatHistoryDays: string;
}

const {
  REACT_APP_CHAT_TOKEN = '',
  REACT_APP_API_URL = 'http://localhost:3001',
  REACT_APP_PORTAL_URL = 'http://localhost:3000/',
  REACT_APP_EDUCATOR_PORTAL_URL = '',
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USERPOOL,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_USERPOOL_BASE_URI,
  REACT_APP_COGNITO_TOKEN_SCOPES,
  REACT_APP_COGNITO_AUTH_FLOW_TYPE,
  REACT_APP_SCH_PORTAL_URL,
  REACT_APP_COGNITO_COOKIE_DOMAIN,
  REACT_APP_REVENUE_PORTAL_URL = '',
  REACT_APP_CHAT_HISTORY_DAYS = '30',
} = process.env;

const local: Config = {
  apiUrl: 'http://localhost:3001',
  googleApiKey: 'AIzaSyCOwZFM7lnTZ4RcXQE_MQCHz6eMZHk0Djg',
  chatHistoryDays: REACT_APP_CHAT_HISTORY_DAYS,
  cognito: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_j49Qvd6UR',
      userPoolWebClientId: '45u0sa1o2qm3tg89dqq5qemkff',
      oauth: {
        domain: 'kyo-attain-dev.auth.us-east-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: REACT_APP_PORTAL_URL,
        redirectSignOut: REACT_APP_PORTAL_URL,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
      authenticationFlowType: REACT_APP_COGNITO_AUTH_FLOW_TYPE,
    },
  },
  appBarColor: 'primary',
  oldUIUrl: REACT_APP_SCH_PORTAL_URL || '',
  gtagId: null,
  chatToken: REACT_APP_CHAT_TOKEN,
  portalUrl: REACT_APP_PORTAL_URL,
  educatorPortalUrl: REACT_APP_EDUCATOR_PORTAL_URL,
  revenuePortalUrl: REACT_APP_REVENUE_PORTAL_URL,
};

const dev: Config = {
  apiUrl: REACT_APP_API_URL || '',
  googleApiKey: 'AIzaSyCOwZFM7lnTZ4RcXQE_MQCHz6eMZHk0Djg',
  chatHistoryDays: REACT_APP_CHAT_HISTORY_DAYS,
  cognito: {
    Auth: {
      region: REACT_APP_COGNITO_REGION,
      userPoolId: REACT_APP_COGNITO_USERPOOL,
      userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
      oauth: {
        domain: REACT_APP_COGNITO_USERPOOL_BASE_URI,
        scope: REACT_APP_COGNITO_TOKEN_SCOPES?.split(',') || [],
        redirectSignIn: REACT_APP_PORTAL_URL,
        redirectSignOut: REACT_APP_PORTAL_URL,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
      authenticationFlowType: REACT_APP_COGNITO_AUTH_FLOW_TYPE,
    },
  },
  appBarColor: 'default',
  oldUIUrl: REACT_APP_SCH_PORTAL_URL || '',
  gtagId: null,
  chatToken: REACT_APP_CHAT_TOKEN,
  portalUrl: REACT_APP_PORTAL_URL,
  educatorPortalUrl: REACT_APP_EDUCATOR_PORTAL_URL,
  revenuePortalUrl: REACT_APP_REVENUE_PORTAL_URL,
};

const prod: Config = {
  apiUrl: 'https://api.attain.gatewaylg.com',
  googleApiKey: 'AIzaSyCOwZFM7lnTZ4RcXQE_MQCHz6eMZHk0Djg',
  chatHistoryDays: REACT_APP_CHAT_HISTORY_DAYS,
  cognito: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_ogJIHs6DL',
      userPoolWebClientId: '2irlg9fpbvah50269c9j9k4evv',
      oauth: {
        domain: 'attain.auth.us-east-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: 'https://portal.kyocare.com/',
        redirectSignOut: 'https://portal.kyocare.com/',
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
      authenticationFlowType: REACT_APP_COGNITO_AUTH_FLOW_TYPE,
    },
  },
  appBarColor: 'primary',
  oldUIUrl: 'https://attain.gatewaylg.com/',
  gtagId: 'G-CVDF88DBRF',
  chatToken: REACT_APP_CHAT_TOKEN,
  portalUrl: REACT_APP_PORTAL_URL,
  educatorPortalUrl: REACT_APP_EDUCATOR_PORTAL_URL,
  revenuePortalUrl: REACT_APP_REVENUE_PORTAL_URL,
};

// Default to local if not set
let config = local;
if (process.env.REACT_APP_STAGE === 'prod') {
  config = prod;
} else if (process.env.REACT_APP_STAGE === 'dev') {
  config = dev;
}

if (
  REACT_APP_COGNITO_COOKIE_DOMAIN &&
  REACT_APP_COGNITO_COOKIE_DOMAIN !== 'null'
) {
  config.cognito.Auth.cookieStorage = {
    domain: REACT_APP_COGNITO_COOKIE_DOMAIN,
    sameSite: 'strict',
    secure: true,
  };
}

export default {
  ...config,
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Typography, makeStyles, Theme, SvgIcon } from '@material-ui/core';
import {
  VisibilityOffOutlined,
  VisibilityOutlined,
  Visibility,
} from '@material-ui/icons';
import clsx from 'clsx';

import { RootState } from '../redux/modules/rootReducer';
import { fetchCurrentClientById } from '../redux/modules/client';
import StyledButton from './buttons/StyledButton';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: '#fff',
    color: theme.palette.kyoTeal.main,
    borderColor: theme.palette.kyoTeal.main,
    borderWidth: 1,
    borderStyle: 'solid',
    width: '100%',
    margin: theme.spacing(0.5, 0),
    position: 'relative',
    paddingRight: 50,
  },
  withBadge: {
    '&::after': {
      content: '""',
      display: 'block',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      borderRadius: '50%',
      backgroundColor: '#f44336',
      position: 'absolute',
      top: theme.spacing(-0.75),
      right: theme.spacing(-0.75),
    },
  },
  icon: {
    position: 'absolute',
    right: 0,
    marginRight: 10,
    marginLeft: 10,
  },
  clientListContainer: {
    padding: theme.spacing(0.1, 0.6),
    maxHeight: 270,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

interface ClientSelectionComponentProps {
  onAction: Function;
}

const ClientSelectionComponent = (props: ClientSelectionComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const { onAction } = props;

  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const currentClient = useSelector(
    (state: RootState) => state.client.currentClient
  );

  const unreadMessagesCountPerClient = useSelector(
    (state: RootState) => state.chat.unreadMessagesCountPerClient
  );

  const handleSelect = (clientId: number) => {
    dispatch(fetchCurrentClientById(clientId));
  };

  const handleVerify = (clientId: number) => {
    navigate(`/verify-client/${clientId}`);
  };

  const validAssociations = currentUser
    ? currentUser.associations.filter((assoc) => !assoc.dateRevoked)
    : [];

  if (!currentUser || validAssociations.length === 0) {
    return (
      <Typography variant="body2" align="center">
        No clients to show
      </Typography>
    );
  }

  return (
    <Box className={classes.clientListContainer}>
      {validAssociations.map((assoc) => {
        const isVerified = !!assoc.dateVerified;
        const isSelectedClient = +assoc.clientId === currentClient?.id;
        const hasUnreadMessage = unreadMessagesCountPerClient[assoc.clientId];

        const icon = (
          <SvgIcon className={classes.icon}>
            {!isVerified && <VisibilityOffOutlined />}
            {isVerified && isSelectedClient ? (
              <Visibility />
            ) : (
              <VisibilityOutlined />
            )}
          </SvgIcon>
        );

        return (
          <StyledButton
            key={assoc.clientId}
            className={clsx(
              classes.button,
              hasUnreadMessage && classes.withBadge
            )}
            onClick={() => {
              if (!isSelectedClient) {
                if (!isVerified) {
                  handleVerify(+assoc.clientId);
                } else if (!isSelectedClient) {
                  handleSelect(+assoc.clientId);
                }
              }
              onAction();
            }}
          >
            <>
              {assoc.client ? assoc.client.name : assoc.clientId}
              {icon}
            </>
          </StyledButton>
        );
      })}
    </Box>
  );
};

export default ClientSelectionComponent;

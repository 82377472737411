import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import moment from 'moment';

import ActionsControlButton from '../../../components/buttons/ActionsControlButton';
import { AvailabilityBlock } from '../../../redux/modules/availability';
import AvailabilityMatrix from '../../../components/Matrix/AvailabilityMatrix';
import { ClientSchedule } from '../../../types/types';
import useSettings from '../../../hooks/useSettings';
import ScheduleAndCheckboxSvg from '../../../assets/scheduleAndCheckbox.svg';
import ScheduleAndFlagSvg from '../../../assets/scheduleAndFlag.svg';
import WarningSvg from '../../../assets/warning.svg';
import AvailabilityWarningSvg from '../../../assets/availabilityWarning.svg';
import InfoContainer, {
  InfoContainerTypes,
} from '../../../components/InfoContainer';
import DataRow from '../../../components/Availability/AvailabilityActions/DataRow';
import { AVAILABILITY_DATE_PRESENT_FORMAT } from '../../../components/Availability/constants';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: theme.spacing(55),
    margin: 'auto',
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    padding: 0,
  },
  content: {
    padding: theme.spacing(2),
  },
  breaks: {
    margin: theme.spacing(2, 0),
  },
  bold: {
    fontWeight: 600,
  },
  startDateDivider: {
    marginBottom: theme.spacing(2),
  },
  sessionImpactWarningContent: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  sessionImpactWarningDay: {
    color: '#DA100B',
  },
  sessionImpactWarningDays: {
    '& > span::after': {
      content: "', '",
    },

    '& > span:last-child::after': {
      display: 'none',
    },
  },
  cellWrapper: {
    position: 'relative',
  },
  cellWarningImg: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

interface ChangesReviewDialogProps {
  handleClose: () => void;
  handleSave: () => void;
  availabilityBlocks: Partial<AvailabilityBlock>[];
  schedule?: ClientSchedule[];
  availableHours: number;
  startDate?: string;
}

const ChangesReviewDialog = ({
  handleClose,
  handleSave,
  availabilityBlocks,
  schedule,
  availableHours,
  startDate,
}: ChangesReviewDialogProps) => {
  const { minOccupiedLength } = useSettings();
  const classes = useStyles();

  const impactedSessions = availabilityBlocks.filter((block) => {
    return (
      !block.isAvailable &&
      !!schedule?.find(
        (item) =>
          item.dayOfWeekId === block?.timeBlockDayOfWeek?.dayOfWeekId &&
          item.timeBlockId === block?.timeBlockDayOfWeek?.timeBlockId &&
          item.occupied2 &&
          item.occupied2 >= minOccupiedLength
      )
    );
  });

  const formattedStartDate = moment(startDate).format(
    AVAILABILITY_DATE_PRESENT_FORMAT
  );

  return (
    <>
      <Dialog
        open
        onClose={handleClose}
        classes={{ root: classes.dialog, paper: classes.dialogPaper }}
      >
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h6" color="textSecondary">
            Review your Changes
          </Typography>
          <IconButton
            className={classes.closeIcon}
            onClick={handleClose}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Divider />

          <DataRow
            imageSrc={ScheduleAndCheckboxSvg}
            label="New Availability"
            value={availableHours}
          />
          <Divider />

          <DataRow
            imageSrc={ScheduleAndFlagSvg}
            label="Start Date"
            value={formattedStartDate}
          />
          <Divider className={classes.startDateDivider} />

          {!!impactedSessions.length && (
            <InfoContainer
              className={classes.sessionImpactWarningContent}
              type={InfoContainerTypes.Error}
            >
              <img src={WarningSvg} alt="warning" />
              <div>
                <Typography variant="body2">
                  Your new availability will impact the following currently
                  scheduled sessions and they will be removed effective{' '}
                  <span className={classes.bold}>{formattedStartDate}</span>
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.sessionImpactWarningDays}
                >
                  {impactedSessions.map(({ timeBlockDayOfWeek }) => {
                    if (!timeBlockDayOfWeek) {
                      return <></>;
                    }
                    const { dayOfWeek, timeBlockId } = timeBlockDayOfWeek;
                    const value = `${dayOfWeek.name}-Block ${timeBlockId}`;

                    return (
                      <span
                        key={value}
                        className={clsx(
                          classes.bold,
                          classes.sessionImpactWarningDay
                        )}
                      >
                        {value}
                      </span>
                    );
                  })}
                </Typography>
              </div>
            </InfoContainer>
          )}

          <AvailabilityMatrix
            schedule={schedule}
            availabilityBlocks={availabilityBlocks}
            cellSizes={3.5}
            disableWeekends
            CellWrapper={({ horizontalValue, verticalValue, children }) => {
              const showWarning = !!impactedSessions.find(
                ({ timeBlockDayOfWeek }) =>
                  timeBlockDayOfWeek?.dayOfWeekId === horizontalValue &&
                  timeBlockDayOfWeek.timeBlockId === verticalValue
              );

              return (
                <div className={classes.cellWrapper}>
                  {children}
                  {showWarning && (
                    <img
                      className={classes.cellWarningImg}
                      src={AvailabilityWarningSvg}
                      alt="warning"
                    />
                  )}
                </div>
              );
            }}
          />

          <Typography variant="body2" className={classes.breaks}>
            If this is correct, click Save. Or Go Back to adjust your
            availability.
          </Typography>
          <ActionsControlButton
            handleClick={{
              successButton: () => {
                handleSave();
                handleClose();
              },
              cancelButton: handleClose,
            }}
            label={{
              successButton: 'Save',
              cancelButton: 'Go Back',
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangesReviewDialog;

import React from 'react';
import { makeStyles, SvgIcon, SvgIconProps, Theme } from '@material-ui/core';
import { ReactComponent as CovidBadge } from '../../../assets/my-team-badge.svg';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 17,
    width: 77,
  },
}));

const MyCaseTeamBadgeIcon = (props: SvgIconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon
      {...props}
      component={CovidBadge}
      viewBox="0 0 77 17"
      classes={{ root: classes.icon }}
    />
  );
};

export default MyCaseTeamBadgeIcon;

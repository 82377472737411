import requester from './requester';

export interface HelpRequestBody {
  helpWith: number | '';
  relatedToSchedule: boolean;
  details: string;
  clientId: number;
}

const createHelpRequest = async (body: HelpRequestBody) => {
  return requester
    .post('help-requests', {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...body,
        jiraTicketTypeId: body.helpWith,
        helpWith: undefined,
      }),
    })
    .then((response) => response.json())
    .then((response) => response?.data);
};

export default { createHelpRequest };

import { DayOfWeekId } from '../../../constants';

export type DayOfWeekLabels = Record<DayOfWeekId, string>;
export const DayOfWeekValues = Object.values(
  DayOfWeekId
).filter((id): id is DayOfWeekId => Number.isInteger(id));
export const WeekendIds = [DayOfWeekId.Sunday, DayOfWeekId.Saturday];

export const DayOfWeekLabelsVariant1: DayOfWeekLabels = {
  [DayOfWeekId.Sunday]: 'SU',
  [DayOfWeekId.Monday]: 'MO',
  [DayOfWeekId.Tuesday]: 'TU',
  [DayOfWeekId.Wednesday]: 'WE',
  [DayOfWeekId.Thursday]: 'TH',
  [DayOfWeekId.Friday]: 'FR',
  [DayOfWeekId.Saturday]: 'SA',
};

export const DayOfWeekLabelsVariant2: DayOfWeekLabels = {
  [DayOfWeekId.Sunday]: 'S',
  [DayOfWeekId.Monday]: 'M',
  [DayOfWeekId.Tuesday]: 'T',
  [DayOfWeekId.Wednesday]: 'W',
  [DayOfWeekId.Thursday]: 'T',
  [DayOfWeekId.Friday]: 'F',
  [DayOfWeekId.Saturday]: 'S',
};

export const DayOfWeekLabelsVariant3: DayOfWeekLabels = {
  [DayOfWeekId.Sunday]: 'Sun',
  [DayOfWeekId.Monday]: 'Mon',
  [DayOfWeekId.Tuesday]: 'Tue',
  [DayOfWeekId.Wednesday]: 'Wed',
  [DayOfWeekId.Thursday]: 'Thu',
  [DayOfWeekId.Friday]: 'Fri',
  [DayOfWeekId.Saturday]: 'Sat',
};

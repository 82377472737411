import queryString from 'query-string';
import requester from './requester';
import { Notification, NotificationPaginatedData } from '../types/types';

const fetchNotifications = async (
  filters: {
    isSeen?: boolean;
  },
  paging?: {
    page: number;
    rowsPerPage: number;
  }
): Promise<NotificationPaginatedData> => {
  const query = queryString.stringify(
    {
      isSeen: filters.isSeen,
      page: paging?.page,
      limit: paging?.rowsPerPage,
    },
    { arrayFormat: 'bracket' }
  );

  return requester
    .get(`notifications?${query}`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchUnreadCount = async (): Promise<number> => {
  const query = queryString.stringify(
    { isSeen: false },
    { arrayFormat: 'bracket' }
  );

  return requester
    .get(`notifications/count?${query}`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const bulkUpdateIsSeen = async (
  isSeen: boolean,
  ids: number[]
): Promise<Partial<Notification>[]> => {
  return requester
    .put(`notifications`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ isSeen, ids }),
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

export default {
  fetchNotifications,
  fetchUnreadCount,
  bulkUpdateIsSeen,
};

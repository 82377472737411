import React from 'react';
import {
  ClickAwayListener,
  IconButton,
  Avatar,
  Backdrop,
  Box,
} from '@material-ui/core';

import StyledPopper from './StyledPopper/StyledPopper';
import PopupMenu from './PopupMenu';

interface AvatarIconMenuProps {
  avatarSrc?: string;
  onClick?: Function;
}

const AvatarIconMenu = (props: AvatarIconMenuProps) => {
  const { avatarSrc, onClick } = props;
  const [
    popupMenuAnchor,
    setPopupMenuAnchor,
  ] = React.useState<HTMLElement | null>(null);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setPopupMenuAnchor(null);
        }}
      >
        <Box>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              setPopupMenuAnchor((curr) => (curr ? null : e.currentTarget));
              if (onClick) {
                onClick();
              }
            }}
          >
            <Avatar src={avatarSrc} />
          </IconButton>

          <StyledPopper
            open={!!popupMenuAnchor}
            anchorEl={popupMenuAnchor || undefined}
            popperOptions={{
              positionFixed: true,
            }}
            disablePortal
          >
            <PopupMenu
              closePopup={() => {
                setPopupMenuAnchor(null);
              }}
            />
          </StyledPopper>
        </Box>
      </ClickAwayListener>
      <Backdrop open={!!popupMenuAnchor} />
    </>
  );
};

export default AvatarIconMenu;

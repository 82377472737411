import * as React from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState } from '../../redux/modules/rootReducer';
import { AlertMessage } from '../../redux/modules/alert';

const AppErrors = () => {
  const alertMessage: AlertMessage = useSelector(
    (state: RootState) => state.alert.alertMessage
  );
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (typeof alertMessage !== undefined) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [alertMessage]);

  if (!alertMessage) {
    return null;
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={alertMessage.autoHideDuration}
      onClose={() => setVisible(false)}
      open={visible}
    >
      <Alert severity="error">{alertMessage.message}</Alert>
    </Snackbar>
  );
};

export default AppErrors;

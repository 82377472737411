import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { groupBy } from 'lodash';
import cancellationApi from '../../api/cancellationApi';
import { RootState } from './rootReducer';
import {
  ApiSorting,
  CancellationEvent,
  CancellationEventsFilters,
  CancellationEventsSort,
  CancelledSegmentsResponseType,
  PaginatedData,
  RequestStatus,
  RequestType,
  RetractedSessionsResponseType,
  ScheduleSegment,
  SessionStatus,
  SortingDirection,
} from '../../types/types';
import { DATE_FORMAT } from '../../utils/helpers';
import { CANCELLATIONS_LAST_DAYS } from '../../utils/constants';
import { CANCELLTION_EVENTS_PER_PAGE } from '../../shared/constants';
import bookingApi from '../../api/bookingApi';

export interface UserSessionCancellationState {
  selectedSessions: ScheduleSegment[];
  cancellationInProgress: boolean;
  retractionInProgress: boolean;
  cancellationError: SerializedError | null;
  retractionError: SerializedError | null;
  cancellationEvents: Array<CancellationEvent>;
  cancellationPaidEvents: Array<CancellationEvent>;
  loadingCancellationEvents: boolean;
  loadingCancellationPaidEvents: boolean;
  cancellationEventsPage: number;
  cancellationPaidEventsPage: number;
  cancellationEventsTotalCount: number;
  cancellationPaidEventsTotalCount: number;
}

const cancellationState = {
  cancellationEvents: [],
  cancellationPaidEvents: [],
  loadingCancellationEvents: false,
  loadingCancellationPaidEvents: false,
  cancellationEventsPage: 1,
  cancellationPaidEventsPage: 1,
  cancellationEventsTotalCount: 0,
  cancellationPaidEventsTotalCount: 0,
};

const initialState: UserSessionCancellationState = {
  selectedSessions: [],
  cancellationInProgress: false,
  retractionInProgress: false,
  cancellationError: null,
  retractionError: null,
  ...cancellationState,
};

export const cancelScheduledSegments = createAsyncThunk<
  Array<CancelledSegmentsResponseType>,
  void,
  { state: RootState }
>('sessionCancellation/cancelScheduledSegments', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const clientId = state.client?.currentClient?.id || null;
  const providerId = state.provider.provider?.id || null;

  const { [RequestType.LongTerm]: scheduleSegments = [] } = groupBy(
    state.sessionCancellation.selectedSessions,
    (item) => item.bookRequestType || RequestType.LongTerm
  );

  if (!scheduleSegments.length) {
    return [];
  }

  return cancellationApi
    .cancelSessions(clientId, providerId, scheduleSegments)
    .catch((e) => thunkAPI.rejectWithValue(e));
});

async function retractBookingSessions(
  api: typeof bookingApi.retractSTSessions,
  sessions: Array<ScheduleSegment>
) {
  if (!sessions.length) {
    return [];
  }

  const sessionsByRequest = groupBy(sessions, (item) => item.bookRequestId);

  const response = await Promise.all(
    Object.entries(sessionsByRequest).map(([brId, items]) =>
      api(
        +brId,
        items.map((s) => s.id as number)
        // NOTE: catching and returning an empty array of sessions
        // to show exact result of what was successfully retracted and what was failed
        // otherwise SessionsRemovalResults component will show an error for all cancellations if any fail
      ).catch((_err) => ({ sessions: [] }))
    )
  );

  return response
    .flatMap((item) => item.sessions)
    .filter((s) => s.statusId === RequestStatus.RETRACTED);
}

export const cancelBookingSessions = createAsyncThunk<
  RetractedSessionsResponseType['sessions'],
  void,
  { state: RootState }
>('sessionCancellation/cancelBookingSessions', async (_, thunkAPI) => {
  const {
    [RequestType.SingleTerm]: stSessions = [],
    [RequestType.MidTerm]: mtSessions = [],
  } = groupBy(
    thunkAPI.getState().sessionCancellation.selectedSessions,
    (item) => item.bookRequestType || RequestType.LongTerm
  );

  const [stResult, mtResult] = await Promise.all([
    retractBookingSessions(bookingApi.retractSTSessions, stSessions),
    retractBookingSessions(bookingApi.retractMTSessions, mtSessions),
  ]);

  return [...stResult, ...mtResult];
});

export const fetchCancellationEvents = createAsyncThunk<
  PaginatedData<CancellationEvent>,
  {
    filters: Partial<CancellationEventsFilters>;
    sorting?: Partial<ApiSorting<CancellationEventsSort>>;
  },
  { state: RootState }
>(
  'sessionCancellation/fetchCancellationEvents',
  async ({ filters, sorting }, { getState, rejectWithValue }) => {
    const { providerId, clientId } = filters;

    if (!providerId && !clientId) {
      return rejectWithValue(null);
    }

    const { cancellationEventsPage: page } = getState().sessionCancellation;

    const createdFromDate = moment().subtract(CANCELLATIONS_LAST_DAYS, 'days');
    const providerTitles = ['BT', 'SBT'];

    const apiFilters = {
      createdFrom: createdFromDate.format(DATE_FORMAT),
      ...filters,
    };

    if (clientId) {
      apiFilters.providerTitles = providerTitles;
    }

    return cancellationApi.fetchCancellationEvents(
      apiFilters,
      {
        orderBy: CancellationEventsSort.SessionStart,
        direction: SortingDirection.DESC,
        ...sorting,
      },
      {
        page,
        limit: CANCELLTION_EVENTS_PER_PAGE,
      }
    );
  }
);

export const fetchCancellationPaidEvents = createAsyncThunk<
  PaginatedData<CancellationEvent>,
  {
    filters: Partial<CancellationEventsFilters>;
    sorting?: Partial<ApiSorting<CancellationEventsSort>>;
  },
  { state: RootState }
>(
  'sessionCancellation/fetchCancellationPaidEvents',
  async ({ filters, sorting }, { getState, rejectWithValue }) => {
    const { providerId } = filters;

    if (!providerId) {
      return rejectWithValue(null);
    }

    const { cancellationPaidEventsPage: page } = getState().sessionCancellation;

    const createdFromDate = moment().subtract(CANCELLATIONS_LAST_DAYS, 'days');

    const apiFilters = {
      createdFrom: createdFromDate.format(DATE_FORMAT),
      ...filters,
    };

    return cancellationApi.fetchCancellationEvents(
      apiFilters,
      {
        orderBy: CancellationEventsSort.SessionStart,
        direction: SortingDirection.DESC,
        ...sorting,
      },
      {
        page,
        limit: CANCELLTION_EVENTS_PER_PAGE,
      }
    );
  }
);

const sessionCancellationSlice = createSlice({
  name: 'sessionCancellation',
  initialState,
  reducers: {
    setSessionsToRemove: (state, action: PayloadAction<ScheduleSegment[]>) => ({
      ...state,
      selectedSessions: action.payload,
    }),
    resetSessionRemoval: () => ({
      ...initialState,
    }),
    setCancellationEventPage: (state, action: PayloadAction<number>) => {
      state.cancellationEventsPage = action.payload;
    },
    setCancellationPaidEventPage: (state, action: PayloadAction<number>) => {
      state.cancellationPaidEventsPage = action.payload;
    },
    resetCancellationEvents: (state) => {
      state.cancellationEvents = [];
      state.cancellationPaidEvents = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelScheduledSegments.pending, (state) => {
        return {
          ...state,
          cancellationInProgress: true,
          cancellationError: null,
        };
      })
      .addCase(cancelScheduledSegments.fulfilled, (state, action) => {
        const { selectedSessions } = state;

        const updatedSessions = selectedSessions.map((s) => {
          const update = action.payload.find(
            (result) => result.segmentId === s.segmentId
          );

          const updatedOverlapings = s.overlappingSessions?.map(
            (v): ScheduleSegment => {
              const updateSegment = action.payload.find(
                (result) => result.segmentId === v.segmentId
              );
              const { isRTO } = updateSegment || {};
              if (updateSegment) {
                return {
                  ...v,
                  status: isRTO
                    ? SessionStatus.TimeOff
                    : SessionStatus.Cancelled,
                };
              }
              return { ...v };
            }
          );
          if (update) {
            const { isRTO } = update;
            return {
              ...s,
              cancellationEvent: { id: update.cancellationEventId },
              status: isRTO ? SessionStatus.TimeOff : SessionStatus.Cancelled,
              overlappingSessions: updatedOverlapings || s.overlappingSessions,
              removedBy: update.removedBy,
            };
          }
          return {
            ...s,
          };
        });
        return {
          ...state,
          cancellationInProgress: false,
          selectedSessions: updatedSessions,
        };
      })
      .addCase(cancelScheduledSegments.rejected, (state, action) => {
        return {
          ...state,
          cancellationInProgress: false,
          cancellationError: action.error,
        };
      })
      .addCase(cancelBookingSessions.pending, (state) => {
        return {
          ...state,
          retractionInProgress: true,
          retractionError: null,
        };
      })
      .addCase(cancelBookingSessions.fulfilled, (state, action) => {
        const { selectedSessions } = state;
        const retractedSessions = action.payload;

        const updatedSessions = selectedSessions.map((s) => {
          if (!s.isBookRequest || !s.id) {
            return { ...s };
          }

          const retractedUpdate = retractedSessions.find(
            (item) => item.id === s.id
          );
          return {
            ...s,
            status:
              retractedUpdate &&
              retractedUpdate.statusId === RequestStatus.RETRACTED
                ? SessionStatus.Cancelled
                : SessionStatus.Pending,
          };
        });

        return {
          ...state,
          retractionInProgress: false,
          selectedSessions: updatedSessions,
        };
      })
      .addCase(cancelBookingSessions.rejected, (state, action) => {
        return {
          ...state,
          retractionInProgress: false,
          retractionError: action.error,
        };
      })
      .addCase(fetchCancellationEvents.pending, (state) => {
        state.loadingCancellationEvents = true;
      })
      .addCase(fetchCancellationEvents.fulfilled, (state, action) => {
        const {
          items,
          meta: { totalItems },
        } = action.payload;

        const page = state.cancellationEventsPage;
        const previousEvents = state.cancellationEvents;

        state.cancellationEvents =
          page === 1 ? items : previousEvents.concat(items);
        state.cancellationEventsTotalCount = totalItems;
        state.loadingCancellationEvents = false;
      })
      .addCase(fetchCancellationEvents.rejected, (state) => {
        state.loadingCancellationEvents = false;
      })
      .addCase(fetchCancellationPaidEvents.pending, (state) => {
        state.loadingCancellationPaidEvents = true;
      })
      .addCase(fetchCancellationPaidEvents.fulfilled, (state, action) => {
        const {
          items,
          meta: { totalItems },
        } = action.payload;

        const page = state.cancellationPaidEventsPage;
        const previousEvents = state.cancellationPaidEvents;

        state.cancellationPaidEvents =
          page === 1 ? items : previousEvents.concat(items);
        state.cancellationPaidEventsTotalCount = totalItems;
        state.loadingCancellationPaidEvents = false;
      })
      .addCase(fetchCancellationPaidEvents.rejected, (state) => {
        state.loadingCancellationPaidEvents = false;
      });
  },
});

export const {
  setSessionsToRemove,
  resetSessionRemoval,
  setCancellationEventPage,
  setCancellationPaidEventPage,
  resetCancellationEvents,
} = sessionCancellationSlice.actions;

export default sessionCancellationSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import billingApi, {
  FetchInvoicesFilters,
  InvoicesDataMeta,
} from '../../api/billingApi';
import { Invoice } from '../../types/types';

export interface BillingState {
  invoices: Invoice[];
  invoicesMeta: InvoicesDataMeta | null;
  invoicesLoading: boolean;
  attachmentUrls: string[];
  attachmentUrlsLoading: boolean;
}

const initialState: BillingState = {
  invoices: [],
  invoicesMeta: null,
  invoicesLoading: false,
  attachmentUrls: [],
  attachmentUrlsLoading: false,
};

export const fetchInvoices = createAsyncThunk(
  'invoices/fetchInvoices',
  (filters: FetchInvoicesFilters) => billingApi.fetchInvoices(filters)
);

export const fetchInvoiceAttachmentUrl = createAsyncThunk(
  'invoices/fetchInvoiceAttachmentUrl',
  ({ clientId, invoiceId }: { clientId: number; invoiceId: number }) =>
    billingApi.getInvoiceAttachmentUrl(clientId, invoiceId)
);

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    clearAttachmentUrls: (state) => {
      state.attachmentUrls = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch invoices
      .addCase(fetchInvoices.pending, (state) => {
        state.invoicesLoading = true;
      })
      .addCase(fetchInvoices.rejected, (state) => {
        state.invoicesLoading = false;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        const { data, meta } = action.payload;

        state.invoicesLoading = false;
        state.invoices = data;
        state.invoicesMeta = (meta as unknown) as InvoicesDataMeta;
      })

      // fetch invoice attachment url
      .addCase(fetchInvoiceAttachmentUrl.pending, (state) => {
        state.attachmentUrlsLoading = true;
      })
      .addCase(fetchInvoiceAttachmentUrl.rejected, (state) => {
        state.attachmentUrlsLoading = false;
      })
      .addCase(fetchInvoiceAttachmentUrl.fulfilled, (state, action) => {
        state.attachmentUrlsLoading = false;
        state.attachmentUrls = action.payload;
      });
  },
});

export const { clearAttachmentUrls } = billingSlice.actions;

export default billingSlice.reducer;

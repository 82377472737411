import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { AvailabilityMatrixState, getBlockStyles } from './AvailabilityMatrix';
import DemandIcon from '../../assets/demand-icon.svg';

const useStyles = makeStyles((theme: Theme) => ({
  matrixBlockSizes: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
    borderRadius: '50%',
  },
  matrixInfo: {
    color: '#818181',
    display: 'flex',
  },
  infoBlock: {
    display: 'flex',
    marginLeft: theme.spacing(1.4),
    alignItems: 'center',
    '&:first-child': {
      marginLeft: theme.spacing(1.5),
    },
  },
  label: {
    marginLeft: theme.spacing(0.8),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(1.5),
    },
  },
  ...getBlockStyles(10)(theme),
  [AvailabilityMatrixState.Booked]: {
    borderColor: '#F7716E',
    borderStyle: 'solid',
    borderWidth: theme.spacing(0.3),
  },
}));

const BlocksInfo = [
  {
    classKey: AvailabilityMatrixState.Available,
    label: 'Avail.',
  },
  {
    classKey: AvailabilityMatrixState.NotAvailable,
    label: 'Not avail.',
  },
  {
    classKey: AvailabilityMatrixState.Booked,
    label: 'Booked',
  },
];

interface MatrixInfoProps {
  rootClassName?: string;
  showBooked?: boolean;
  showDemand?: boolean;
  dotStyleOverride?: { [key in AvailabilityMatrixState]?: string };
}

const MatrixInfo = ({
  rootClassName,
  showBooked = true,
  showDemand = false,
  dotStyleOverride = {},
}: MatrixInfoProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(rootClassName, classes.matrixInfo)}>
      {BlocksInfo.map((block) => {
        if (!showBooked && block.classKey === AvailabilityMatrixState.Booked) {
          return null;
        }

        return (
          <div key={block.label} className={classes.infoBlock}>
            <div
              className={clsx(
                classes.matrixBlockSizes,
                classes[block.classKey],
                dotStyleOverride[block.classKey]
              )}
            />
            <div className={classes.label}>{block.label}</div>
          </div>
        );
      })}
      {showDemand && (
        <div className={classes.infoBlock}>
          <img src={DemandIcon} alt="demand" />
          <div className={classes.label}>In Demand</div>
        </div>
      )}
    </div>
  );
};

export default MatrixInfo;

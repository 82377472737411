import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import succeedCalendar from '../../../assets/succeedCalendar.png';
import { useAppDispatch } from '../../../redux/store';
import {
  AVAILABILITY_ACTION_STATUS,
  setActionStatus,
} from '../../../redux/modules/availability';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: theme.spacing(57),
    margin: 'auto',
  },
  dialogPaper: {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(10, 4, 5),
    textAlign: 'center',
    gap: theme.spacing(2),
  },
  bold: {
    fontWeight: 700,
  },
  imageWrapper: {
    marginTop: theme.spacing(0.5),
  },
  close: {
    width: '100%',
    textTransform: 'capitalize',
    color: theme.palette.kyoLightGreen.main,
    borderColor: theme.palette.kyoLightGreen.main,
    marginTop: theme.spacing(2),
  },
}));

const SucceedDialog = ({
  children,
  handleClose: parentHandleClose = () => null,
}: {
  children?: React.ReactNode;
  handleClose?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleClose = () => {
    dispatch(setActionStatus({ status: AVAILABILITY_ACTION_STATUS.PASSIVE }));
    parentHandleClose();
  };

  return (
    <>
      <Dialog
        open
        onClose={handleClose}
        classes={{ root: classes.dialog, paper: classes.dialogPaper }}
      >
        <DialogContent className={classes.content}>
          <img src={succeedCalendar} alt="succeed calendar" />
          <Typography variant="subtitle1">
            Thank you for submitting your Availability.
          </Typography>
          <Typography variant="body1">{children}</Typography>
          <Button
            className={classes.close}
            variant="outlined"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SucceedDialog;

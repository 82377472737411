import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import noticesApi from '../../api/noticesApi';
import { Notice, NoticeRequestType } from '../../types/types';

export type NoticesState = {
  notice: Notice | null;
  noticeLoading: boolean;
};

const initialState: NoticesState = {
  notice: null,
  noticeLoading: false,
};

export const fetchNotice = createAsyncThunk(
  'notices/fetchNotices',
  (args: { clientId: number }) => {
    const { clientId } = args;
    return noticesApi.fetchNotice({ clientId });
  }
);

const noticesSlice = createSlice({
  name: 'notices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotice.fulfilled, (state, action) => {
        const token = action.payload;

        state.noticeLoading = false;

        if (!token) {
          state.notice = null;
        } else {
          const { requestType }: { requestType: NoticeRequestType } = jwtDecode(
            token
          );

          state.notice = { requestType, token };
        }
      })
      .addCase(fetchNotice.rejected, (state) => {
        state.noticeLoading = false;
        state.notice = null;
      })
      .addCase(fetchNotice.pending, (state) => {
        state.noticeLoading = true;
      });
  },
});

export default noticesSlice.reducer;

import React from 'react';

export const HashIcon = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36" cy="36" r="36" fill="var(--primary-color)" />
    <path
      d="M34.5368 33.696H38.5688L37.8548 38.904H33.7808L34.5368 33.696ZM29.2028 42.684L28.0268 51H32.1008L33.2768 42.684H37.3088L36.1748 51H40.2488L41.3828 42.684H45.0368V38.904H41.9288L42.6428 33.696H46.2968V29.916H43.1888L44.3228 21.6H40.2488L39.1148 29.916H35.0408L36.2168 21.6H32.1428L30.9668 29.916H26.9768V33.696H30.4628L29.7068 38.904H25.7168V42.684H29.2028Z"
      fill="white"
    />
  </svg>
);
export default HashIcon;

import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    borderRadius: 16,
    padding: theme.spacing(3, 0, 6),
    backgroundColor: theme.palette.grey['50'],
    maxWidth: 560,
    minWidth: 280,
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#fff',
      padding: theme.spacing(1, 0, 2),
    },
  },
  innerContainer: {
    width: '90%',
    margin: 'auto',
    maxWidth: 350,
  },
}));

interface FormContainerProps {
  children: JSX.Element;
  className?: string;
}

const FormContainer = (props: FormContainerProps) => {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <Box className={clsx(classes.formContainer, className)}>
      <Box className={classes.innerContainer}>{children}</Box>
    </Box>
  );
};

export default FormContainer;

import moment, { Moment } from 'moment';
import { orderBy, reduce } from 'lodash';
import { DateObject } from 'react-multi-date-picker';
import {
  ClimbLevels,
  DayOfWeekId,
  DecimalNumber,
  NoticeRequestType,
  NotificationPreference,
  Provider,
  ProviderAcceptanceRate,
  ScheduleSegment,
  TimeDayBlock,
  User,
  ZipCodeData,
} from '../types/types';
import { AvailabilityBlock } from '../redux/modules/availability';
import { SelectedBookingOption } from '../redux/modules/newSessions';
import configs from '../configs';
import { KyoClimbSettings } from '../redux/modules/adminSettings';

export const daysMap: { [key: string]: string } = {
  '0': 'Sun',
  '1': 'Mon',
  '2': 'Tue',
  '3': 'Wed',
  '4': 'Thu',
  '5': 'Fri',
  '6': 'Sat',
};

export const getSelectedTimeBlockText = (timeBlocks: TimeDayBlock[] = []) => {
  const tbs = orderBy(timeBlocks, 'DayOfWeek');
  return reduce(
    tbs,
    (acc: string, tdb: TimeDayBlock, index) => {
      if (index) {
        return `${acc} , ${daysMap[tdb.dayOfWeekId]}${tdb.timeBlockId}`;
      }
      return acc + daysMap[tdb.dayOfWeekId] + tdb.timeBlockId;
    },
    ''
  );
};

export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm A';
export const DATE_TIME_FORMAT_WITH_DAY_NAME = 'YYYY-MM-DD ddd hh:mm A';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_WITH_DAY_NAME = 'YYYY-MM-DD ddd';
export const DATE_FORMAT_US = 'MM/DD/YYYY';
export const NEW_UI_DATE_TIME_FORMAT = 'ddd MMM D, h:mm A';
export const NEW_UI_DATE_FORMAT_WEEKDAY_FIRST = 'ddd, MMM D'; // Sun, Mar 14
export const DATE_FORMAT_MONTH_NAME = 'MMM D, YYYY'; // Mar 14, 2021
export const DATE_FORMAT_MONTH_NAME_2 = 'MMM Do, YYYY'; // Mar 1st, 2021
export const DATE_FORMAT_MONTH_NAME_SHORT = 'MMM D';
export const DATE_FORMAT_MONTH_NAME_WITH_HOUR = 'MMM D h:mmA';
export const DATE_FORMAT_MONTH_NAME_WITH_YEAR = 'MMMM YYYY';
export const DATE_FORMAT_MONTH_NAME_WITH_DAY = 'MMMM DD';
export const DATE_FORMAT_ABBR_MONTH_NAME_WITH_DAY = 'MMM DD';
export const DATE_FORMAT_MONTH_NAME_DAY_YEAR = 'MMMM DD, YYYY';
export const DATE_FORMAT_WEEKDAY_MONTH_YEAR = 'dddd, MMMM D, YYYY'; // Tuesday, March 28, 2023
export const DATE_FORMAT_MONTH_YEAR = 'MMMM D, YYYY';
export const DATE_FORMAT_WEEKDAY_MONTH_YEAR_SHORT = 'ddd MMM DD, YYYY'; // Mon Sep 25, 2024
export const DATE_FORMAT_WEEKDAY_MONTH = 'ddd, MMMM D'; // Tue, March 28

export const DATE_FORMAT_TIME_MONTH_WITH_DAY = 'h:mmA MMM D';

export const TIME_FORMAT = 'h:mm A';
export const TIME_FORMAT_2 = 'hh:mm A';
export const TIME_FORMAT_24HR = 'HH:mm:ss';

export const MS_IN_HOUR = 3600000;

export const getTimeBlockText = (dayOfWeek: number, timeBlock: number) =>
  `${daysMap[dayOfWeek]}${timeBlock}`;

export const convert24hrToAmPm = (timeString: string) => {
  return moment.parseZone(timeString, TIME_FORMAT_24HR).format(TIME_FORMAT);
};

export const getDateTimeWithAMPM = (timeString: string) => {
  return moment
    .parseZone(timeString, TIME_FORMAT_24HR)
    .format(DATE_TIME_FORMAT);
};

export const createUserLogLabel = (user: User) => {
  return `${user.firstName} ${user.lastName} (Client)`;
};

export const isSameDate = (d1: Moment | null, d2: Moment | null) => {
  if (!d1 || !d2) {
    return false;
  }
  return d1.isSame(d2, 'date');
};

export const HASHTAG_REGEX = /((\s|^)#.*?(\s|$))/g;

export const stripCountryCode = (phoneNumber = '', countryCode = 1) => {
  const regex = new RegExp(`^\\+${countryCode}`);
  return phoneNumber.replace(regex, '');
};

export const prependCountryCode = (phoneNumber = '', countryCode = 1) =>
  `+${countryCode}${phoneNumber.trim()}`;

export const zipCodeToLocationParser = (zipCodeData: ZipCodeData) => {
  return {
    latitude: Number(zipCodeData.latitude),
    longitude: Number(zipCodeData.longitude),
  };
};

export const millisecondsToTime = (duration: number) => {
  let minutes: number | string = Math.floor((duration / (1000 * 60)) % 60);
  const hours: number | string = Math.floor((duration / (1000 * 60 * 60)) % 24);

  minutes = minutes <= 0 ? '' : `${minutes}m`;

  return `${hours}h ${minutes}`;
};

export const getAuthFulfillPercentage = (
  hrsWorkedBTSpecificMonth: number | undefined,
  authSpecificMonthBTAdj: number | undefined
) => {
  if (hrsWorkedBTSpecificMonth && authSpecificMonthBTAdj) {
    return (hrsWorkedBTSpecificMonth / authSpecificMonthBTAdj) * 100;
  }
  return 0;
};

export const getUtcDateFromSTBRSession = (
  rebookOption: SelectedBookingOption
) => {
  const { scheduledDate } = rebookOption;
  const time = rebookOption.sessionPlacement.startTime;
  const newDate = moment.utc(scheduledDate);

  const schedDateMoment = moment.utc(newDate);
  const localTime = moment(`${schedDateMoment.format('YYYY-MM-DD')} ${time}`);

  const dateInUtc = localTime.utc().toISOString();
  return dateInUtc;
};

export const sortArrayByDateKey = <T extends { [key: string]: any }>(
  arr: Array<T> = [],
  key: keyof T
) => [...arr].sort((a, b): number => moment(a[key]).diff(moment(b[key])));

export const getWholeChar = (str: string, i: number) => {
  const code = str.charCodeAt(i);

  if (Number.isNaN(code)) return '';

  if (code < 0xd800 || code > 0xdfff) return str.charAt(i);

  if (code >= 0xd800 && code <= 0xdbff) {
    if (str.length <= i + 1) {
      throw new Error('High surrogate without following low surrogate');
    }

    const next = str.charCodeAt(i + 1);

    if (next < 0xdc00 || next > 0xdfff) {
      throw new Error('High surrogate without following low surrogate');
    }

    return str.charAt(i) + str.charAt(i + 1);
  }

  if (i === 0) {
    throw new Error('Low surrogate without preceding high surrogate');
  }

  const prev = str.charCodeAt(i - 1);

  if (prev < 0xd800 || prev > 0xdbff) {
    throw new Error('Low surrogate without preceding high surrogate');
  }

  return '';
};

export function phoneFormat(phone: string) {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phone;
}

export const configureNotificationDataById = (
  requestData: NotificationPreference[]
) => {
  const configuredDataById: {
    [key: string]: {
      atLeastOneIsMandatory: boolean;
      enabledIds: number[];
    };
  } = {};
  requestData.forEach((item) => {
    if (!configuredDataById[item.categoryId]) {
      configuredDataById[item.categoryId] = {
        atLeastOneIsMandatory: Boolean(item.category?.atLeastOneIsMandatory),
        enabledIds: item.isEnabled ? [item.methodId] : [],
      };
    } else if (item.isEnabled) {
      configuredDataById[item.categoryId].enabledIds.push(item.methodId);
    }
  });

  return configuredDataById;
};

export const checkIsPreferenceSelectDisabled = (
  timeBlockId: number,
  availabilityBlocks: Partial<AvailabilityBlock>[]
) => {
  const timeBlocksCount = 3;
  let hasAvailability = false;
  for (
    let i = timeBlockId - 1;
    i < availabilityBlocks.length;
    i += timeBlocksCount
  ) {
    const availabilityBlock = availabilityBlocks[i];

    if (availabilityBlock?.isAvailable) {
      hasAvailability = true;
      break;
    }
  }

  return !hasAvailability;
};

export const daysToMilliseconds = (days: number) => {
  // days * hoursInDay * minutesInHour * secondsInMinute * 1000
  return days * 24 * 60 * 60 * 1000;
};

export const minutesToMilliseconds = (minutes: number) => {
  return minutes * 60 * 1000;
};

export const diffOfDatesInDayMilliseconds = (
  date1: string,
  date2: string,
  format = DATE_FORMAT_US
) => {
  const momentDate1 = moment(date1, format).utc().startOf('day');
  const momentDate2 = moment(date2, format).utc().startOf('day');
  const datesDiff = momentDate2.diff(momentDate1);
  return datesDiff;
};

export const ModifiedAvailabilityTimeOffMinStartDate = moment()
  .add(1, 'week')
  .startOf('week');

export const ModifiedAvailabilityTimeOffMaxStartDate = moment()
  .add(8, 'months')
  .startOf('day');

export const getDiffInDays = ({ from, to }: { from: string; to: string }) => {
  return moment.duration(moment(to).diff(from, 'day'), 'day').asDays() + 1;
};

export const getFirstSessionAfter = ({
  sessions,
  after,
}: {
  sessions: ScheduleSegment[];
  after: number;
}) => {
  return sessions.find((s: ScheduleSegment) => {
    const { segmentStartUtc } = s;
    const diff = getDiffInDays({
      from: new Date().toUTCString(),
      to: segmentStartUtc,
    });

    return diff > after;
  });
};

export const getCurrentAvail = (
  schedule: {
    dayOfWeekId: number;
    isAvailable: boolean;
  }[]
) =>
  // Current avail = available weekday blocks * 3
  schedule.filter(
    (sch) =>
      sch.dayOfWeekId !== DayOfWeekId.Sunday &&
      sch.dayOfWeekId !== DayOfWeekId.Saturday &&
      sch.isAvailable
  ).length * 3;

export const getNumberOfHoursBelowRequiredAvail = ({
  schedule,
  minHrsTarget,
}: {
  schedule: {
    dayOfWeekId: number;
    isAvailable: boolean;
  }[];
  minHrsTarget: number;
}) =>
  // Min avail required = BTMin * 1.25
  Math.max(minHrsTarget * 1.25 - getCurrentAvail(schedule), 0);

export const calcAcceptanceRates = ({
  defaultText,
  acceptanceRate,
}: {
  defaultText: string;
  acceptanceRate?: ProviderAcceptanceRate;
}) => {
  const ltAcceptanceRate =
    !acceptanceRate || acceptanceRate.ltAcceptanceRate === null
      ? defaultText
      : `${Math.round(acceptanceRate.ltAcceptanceRate * 100)}%`;
  const stAcceptanceRate =
    !acceptanceRate || acceptanceRate.stAcceptanceRate === null
      ? defaultText
      : `${Math.round(acceptanceRate.stAcceptanceRate * 100)}%`;

  return {
    ltAcceptanceRate,
    stAcceptanceRate,
  };
};

export const isBCBA = (provider: Pick<Provider, 'title'> | null) =>
  !!provider && ['BCBA', 'CD'].includes(provider.title);

export const isBT = (provider: Pick<Provider, 'title'> | null) =>
  !!provider && ['BT', 'SBT'].includes(provider.title);

export const getAddressString = (
  street?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null
) => {
  let text = '';
  text += street ? `${street}, ` : '';
  text += city ? `${city}, ` : '';
  text += state ? `${state}, ` : '';
  text += zip;

  return text;
};

export const createWeekString = (
  startDate: string,
  endDate: string,
  startDateFormat: string,
  endDateFormat?: string
) => {
  const start = moment(startDate).format(startDateFormat);
  const end = moment(endDate).format(endDateFormat || startDateFormat);
  return `${start} - ${end}`;
};

export const getFormattedWeekPickerDates = (
  value: DateObject | DateObject[] | null,
  format: string
) => {
  if (!value || !Array.isArray(value)) {
    return undefined;
  }

  return value.map((date) => date.format(format));
};

export const humanizeDuration = (
  duration: number | null,
  getDurationPartNames: (
    hours: number,
    minutes: number
  ) => { h: string; m: string } = () => ({ h: 'h', m: 'm' })
) => {
  if (!duration) {
    return '';
  }

  const hours = Math.floor(duration);
  const minutes = Math.round((duration - hours) * 60);
  const { h: hoursLabel, m: minutesLabel } = getDurationPartNames(
    hours,
    minutes
  );
  const humanizedMinutes = minutes ? ` ${minutes}${minutesLabel}` : '';

  return `${hours}${hoursLabel}${humanizedMinutes}`;
};

export const toAbsolutePath = (url: string) =>
  new URL(url, configs.portalUrl).toString();

export const toRevenuePortal = (url: string) => {
  return new URL(url, configs.revenuePortalUrl).toString();
};

const getFullAddress = (
  addressLine1: string | null,
  addressLine2: string | null
) => {
  let street: string | null;

  if (!addressLine1 && addressLine2) {
    street = addressLine2;
    return street;
  }

  street = addressLine1;
  if (addressLine2) {
    street += ` ${addressLine2}`;
  }
  return street;
};

export const normalizeSessionLocation = (session: ScheduleSegment) => {
  const {
    locationCity,
    locationState,
    locationAddressLine1,
    locationAddressLine2,
    locationZip,
  } = session;

  return {
    street: getFullAddress(locationAddressLine1, locationAddressLine2),
    city: locationCity,
    zip: locationZip,
    state: locationState,
  };
};

export const getLevelTitle = (
  settings: KyoClimbSettings[],
  id: ClimbLevels
) => {
  const setting = settings.find((s) => s.id === id);
  return setting ? setting.name : '';
};

export const scrollTo = (el: HTMLDivElement) => {
  el.scrollIntoView({ behavior: 'smooth' });
};

const roundHalf = (num: number) => {
  return Math.round(num * 2) / 2;
};

export const formatSessionDuration = (session: Partial<ScheduleSegment>) => {
  const startTime = moment(session.segmentStartUtc);
  const endTime = moment(session.segmentEndUtc);

  const segmentDuration =
    session.segmentDuration ||
    moment.duration(moment(endTime).diff(moment(startTime))).asHours();
  const halfRoundedDuration = roundHalf(segmentDuration);

  return `${halfRoundedDuration} hour${halfRoundedDuration > 1 ? 's' : ''}`;
};

export const isTodayBetween5thAndEndOfMonth = (): boolean => {
  const today = moment();
  const fifthDayOfMonth = moment().startOf('month').add(4, 'days');
  const lastDayOfMonth = moment().endOf('month');

  return today.isBetween(fifthDayOfMonth, lastDayOfMonth, 'day', '[]');
};

export const getProviderProjectedTenur = (
  providerStartDate: string
): number => {
  const startDate = moment(providerStartDate);
  const nextLevelDate = moment().add(1, 'month').startOf('month');

  return nextLevelDate.diff(startDate, 'days');
};

/**
 * Formats a DecimalNumber object into a string representation with two decimal places.
 *
 * @param {DecimalNumber} amountObj - The object representing the decimal number.
 * @param {1 | -1} amountObj.s - The sign of the number (1 for positive, -1 for negative).
 * @param {number} amountObj.e - The exponent indicating the position of the decimal point.
 * @param {number[]} amountObj.d - The array of digits representing the number.
 * @returns {string} - The formatted number as a string with two decimal places.
 */
export const formatDecimalNumber = (amountObj: DecimalNumber): string => {
  const { s: sign, e: exponent, d: digits } = amountObj;

  // Combine the array of digits into a single string
  const numberStr = digits.map((n) => n.toString()).join('');

  // Calculate where the decimal point should be placed
  const integerPart = numberStr.slice(0, exponent + 1);
  let fractionalPart = numberStr.slice(exponent + 1);

  // Ensure at least two decimal places
  fractionalPart = fractionalPart.padEnd(2, '0');

  // If the fractional part is more than two digits, trim it to two
  fractionalPart = fractionalPart.slice(0, 2);

  // Construct the formatted number
  let formattedNumber = `${integerPart}.${fractionalPart}`;

  // If there's no fractional part, ensure to display '.00'
  if (fractionalPart.length === 0) {
    formattedNumber += '00';
  }

  // Adjust for sign
  if (sign === -1) {
    formattedNumber = `-${formattedNumber}`;
  }

  return formattedNumber;
};

export const downloadFileByUrl = async (url: string, fileName: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getNoticeText = (type?: NoticeRequestType) => {
  let result = '';

  if (!type) {
    return result;
  }

  switch (type) {
    case 'cc_expiration':
      result =
        'The credit card we have on file has expired or will soon expire. ';
      break;
    case 'cc_declined':
      result = 'The credit card we have on file was declined. ';
      break;
    default:
      throw new Error('Unknown Notice type');
  }

  result += 'Please update your payment information';

  return result;
};

import React, { useEffect, useState } from 'react';
import {
  Fade,
  Paper,
  Popper,
  Theme,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import clsx from 'clsx';
import { sortBy } from 'lodash';

import MatrixCell, { MatrixCellProps } from '../../../shared/matrix/ui/cell';
import BookDetailsDialog from '../dialogs/BookDetailsDialog';
import { BookedOption } from '../NewSessions';
import CellInfo from './CellInfo';
import BookedOptionDetails from '../BookedOptionDetails';
import { TIME_FORMAT_2 } from '../../../utils/helpers';

export const BOOKED_ICON_COLOR = '#F7716E';

const useBookedCellCellStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f7716e26',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    cursor: 'pointer',
    color: BOOKED_ICON_COLOR,
    paddingRight: theme.spacing(2),
  },
  rootMobile: {
    alignItems: 'center',
    paddingRight: 0,
  },
  icon: {
    color: BOOKED_ICON_COLOR,
  },
  bookedOptionDetailsWrapper: {
    margin: theme.spacing(1, 2),
  },
  bookedOptionDetailsRow: { '&:last-child': { borderBottom: 'none' } },
  bookingOptionsWrapper: {
    display: 'flex',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.25)',
    borderRadius: theme.spacing(1.5),
  },
}));

const BookedCell = ({
  className,
  bookedOptions,
}: Omit<MatrixCellProps, 'children'> & { bookedOptions: BookedOption[] }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selected, setSelected] = useState(false);

  const classes = useBookedCellCellStyles();

  const isMobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const selectCellIfStillHovering = () => {
      if (anchorEl) {
        setSelected(true);
      }
    };
    const hoverCheckTimeOut = setTimeout(selectCellIfStillHovering, 200);

    return () => {
      clearTimeout(hoverCheckTimeOut);
    };
  }, [anchorEl]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelected(false);
  };

  const sortedBookedOptions = sortBy(bookedOptions, [
    (bookedOption) => {
      return bookedOption.startTime.toISOString();
    },
  ]);
  const { startTime } = sortedBookedOptions[0];
  const { endTime } = sortedBookedOptions[bookedOptions.length - 1];
  const duration = endTime.diff(startTime, 'minutes') / 60;

  return (
    <>
      <MatrixCell
        {...(isMobileScreen
          ? { onClick: () => setSelected(true) }
          : {
              rootProps: {
                onMouseEnter: handlePopoverOpen,
                onMouseLeave: handlePopoverClose,
              },
            })}
        className={clsx(
          classes.root,
          isSmallScreen && classes.rootMobile,
          className
        )}
      >
        <EventAvailableIcon className={classes.icon} />
        {!isSmallScreen && (
          <CellInfo
            startTime={startTime.format(TIME_FORMAT_2)}
            duration={duration}
          />
        )}
        {!isMobileScreen && (
          <Popper
            open={selected}
            anchorEl={anchorEl}
            placement="top-end"
            transition
            disablePortal={false}
            style={{ zIndex: 1400 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={classes.bookingOptionsWrapper}>
                  {bookedOptions.map((bookedOption) => {
                    return (
                      <BookedOptionDetails
                        key={`${bookedOption.startTime.toISOString()}`}
                        classNames={{
                          root: classes.bookedOptionDetailsWrapper,
                          row: classes.bookedOptionDetailsRow,
                        }}
                        bookedOption={bookedOption}
                      />
                    );
                  })}
                </Paper>
              </Fade>
            )}
          </Popper>
        )}
      </MatrixCell>

      {isMobileScreen && selected && (
        <BookDetailsDialog
          bookedOptions={bookedOptions}
          onClose={() => setSelected(false)}
        />
      )}
    </>
  );
};

export default BookedCell;

import React from 'react';
import { Avatar, Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Provider } from '../types/types';
import CovidBadgeIcon from './badges/CovidBadge/CovidBadgeIcon';
import ProviderBCBABadge from './badges/BCBABadge/ProviderBCBABadge';

const useStyles = makeStyles((theme: Theme) => ({
  avatarContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  badgeContainer: {
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

interface ProviderCardHeaderProps {
  provider: Pick<
    Provider,
    'firstName' | 'lastName' | 'profilePhoto' | 'covidVaccinatedDate' | 'title'
  >;
  title?: string;
}

const ProviderCardHeader = (props: ProviderCardHeaderProps) => {
  const classes = useStyles();
  const { provider, title } = props;

  return (
    <>
      {title && (
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      )}
      <Box className={classes.avatarContainer}>
        <Avatar src={provider.profilePhoto} className={classes.avatar} />
        <Box>
          <Typography variant="body1">{`${provider.firstName} ${provider.lastName}`}</Typography>
          <Box className={classes.badgeContainer}>
            {provider.covidVaccinatedDate && <CovidBadgeIcon />}
            <ProviderBCBABadge provider={provider} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProviderCardHeader;

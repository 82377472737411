import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AlertMessage =
  | {
      message: string;
      autoHideDuration: number;
    }
  | undefined;

export interface AlertState {
  alertMessage?: AlertMessage;
}

const initialState: AlertState = {
  alertMessage: undefined,
};

const errorSlice = createSlice({
  name: 'alertMessage',
  initialState,
  reducers: {
    setAlertMessage: (state, action: PayloadAction<AlertMessage>) => ({
      ...state,
      alertMessage: action.payload,
    }),
  },
});

export const { setAlertMessage } = errorSlice.actions;

export default errorSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ratingsApi, { CreateRatingDto } from '../../api/ratingsApi';

export interface StatesState {
  creating: boolean;
  success: boolean;
}

const initialState: StatesState = {
  creating: false,
  success: false,
};

export const createRating = createAsyncThunk(
  'ratings/createRating',
  (payload: CreateRatingDto, { rejectWithValue }) =>
    ratingsApi
      .createRating(payload)
      .catch((e) => rejectWithValue('Creating rating fails'))
);

const statesSlice = createSlice({
  name: 'ratings',
  initialState,
  reducers: {
    resetState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRating.pending, (state) => {
        state.creating = true;
        state.success = false;
      })
      .addCase(createRating.fulfilled, (state, action) => {
        state.creating = false;
        state.success = true;
      })
      .addCase(createRating.rejected, (state) => {
        state.creating = false;
        state.success = false;
      });
  },
});

export const { resetState } = statesSlice.actions;

export default statesSlice.reducer;

import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Language, ProviderAttributes } from '../types/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    color: theme.palette.grey[800],
  },
}));

interface ProviderLanguagesProps {
  providerAttributes: ProviderAttributes | undefined;
  providerLanguages?: Language[];
}

const ProviderLanguages = (props: ProviderLanguagesProps) => {
  const { providerAttributes, providerLanguages } = props;
  const classes = useStyles();

  let languageString = `Languages: `;

  if (providerLanguages) {
    const languages = ['English'];
    providerLanguages.forEach((l) => {
      languages.push(l.name);
    });
    languageString += `${languages.join(', ')}`;
  } else {
    const attrLanguages = [
      'English',
      providerAttributes?.spanish && 'Spanish',
      providerAttributes?.mandarin && 'Mandarin',
      providerAttributes?.cantonese && 'Cantonese',
    ];
    languageString += `${attrLanguages.filter(Boolean).join(', ')}`;
  }

  return (
    <Box className={classes.root}>
      <Typography variant="body2">{languageString}</Typography>
    </Box>
  );
};

export default ProviderLanguages;

import React from 'react';
import { SvgIconProps } from '@material-ui/core';
import { Provider } from '../../../types/types';
import BCBABadgeIcon from './BCBABadgeIcon';
import { isBCBA } from '../../../utils/helpers';

interface ProviderBCBABadgeProps extends SvgIconProps {
  provider: Pick<Provider, 'title'>;
}

const ProviderBCBABadge = (props: ProviderBCBABadgeProps) => {
  const { provider, ...rest } = props;
  const showBadge = isBCBA(provider);

  return showBadge ? <BCBABadgeIcon {...rest} /> : null;
};

export default ProviderBCBABadge;

import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import TapOnBlockSvg from '../../assets/tapOnBlock.svg';
import PersonAndStopWatchSvg from '../../assets/personAndStopWatch.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: theme.spacing(4, 0, 2.5, 0),
    gap: theme.spacing(1),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
      margin: theme.spacing(2, 0, 0, 0),
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2, 2, 2),
      borderBottom: `1px solid ${theme.palette.kyoGray2.dark}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  image: {
    minWidth: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(3.5),
    },
  },
  text: {
    maxWidth: theme.spacing(28),

    [theme.breakpoints.down('xs')]: {
      maxWidth: 'none',
    },
  },
}));

const AvailabilityRecommendations = ({ className }: { className: string }) => {
  const classes = useStyles();

  const recommendations = [
    { svg: TapOnBlockSvg, text: 'Tap on blocks to add or remove them' },
    {
      svg: PersonAndStopWatchSvg,
      text: 'Include blocks where you are available at least 3 hours.',
    },
  ];

  return (
    <div className={clsx(classes.root, className)}>
      {recommendations.map(({ svg, text }) => (
        <div key={svg} className={classes.item}>
          <img src={svg} alt="recommendation" className={classes.image} />
          <Typography
            className={classes.text}
            variant="body1"
            color="textSecondary"
          >
            {text}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default AvailabilityRecommendations;

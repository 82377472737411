import queryString from 'query-string';
import {
  Client,
  ClientSTBRAvailability,
  Goal,
  Provider,
  ScheduleSegment,
} from '../types/types';
import requester from './requester';

const fetchClientCaseTeam = async (clientId: number): Promise<Provider[]> => {
  return requester
    .get(`v2/clients/${clientId}/care-team`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchClientById = async (clientId: number): Promise<Client> => {
  return requester
    .get(`v2/clients/${clientId}`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchClientFullSchedule = async (
  clientId: number,
  numOfWeeks?: number,
  limit?: number,
  includePending?: boolean
): Promise<ScheduleSegment[]> => {
  const query = queryString.stringify(
    {
      clientIds: [clientId],
      numOfWeeks,
      limit,
      includePending,
    },
    { arrayFormat: 'bracket' }
  );
  return requester
    .get(`v2/clients/full-schedule?${query}`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchClientFullScheduleExtended = async (
  clientId: number,
  startDate: string,
  endDate: string
): Promise<ScheduleSegment[]> => {
  const query = queryString.stringify(
    {
      startDate,
      endDate,
    },
    { arrayFormat: 'bracket' }
  );
  return requester
    .get(`sessions/clients/${clientId}?${query}`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchClientGoals = async (
  clientId: number,
  limit?: number
): Promise<Goal[]> => {
  const query = queryString.stringify({ limit });

  return requester
    .get(`clients/${clientId}/goals?${query}`)
    .then((response) => response.json())
    .then((response) => response.data);
};

const fetchClientSingleTermBookingAvailability = async (
  clientId: number
): Promise<ClientSTBRAvailability> => {
  return requester
    .get(`v2/clients/${clientId}/single-term-booking-availability`)
    .then((response) => response.json())
    .then((response) => response.data);
};

export default {
  fetchClientCaseTeam,
  fetchClientById,
  fetchClientFullSchedule,
  fetchClientFullScheduleExtended,
  fetchClientGoals,
  fetchClientSingleTermBookingAvailability,
};

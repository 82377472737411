import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import moment from 'moment/moment';
import { RootState } from '../../../redux/modules/rootReducer';

const useOptOutAllowedUntil = (createdAt: string) => {
  const settings = useSelector(
    (state: RootState) => state.adminSettings.settings
  );

  const getOptOutAllowedInHours = () => {
    const settingsByKey = keyBy(settings, 'key');
    return +settingsByKey.subOfferOptOutAllowedInHours.value;
  };

  const optOutAllowedInHours = getOptOutAllowedInHours();
  return moment(createdAt).add({
    hours: optOutAllowedInHours,
  });
};

export default useOptOutAllowedUntil;

import {
  AttendanceAward,
  SessionPlacement,
} from '../redux/modules/adminSettings';
import {
  ClientAttendanceStatusId,
  ProviderAttendanceStatusId,
} from '../components/AttendanceStatus/constants/constants';

export interface STBRBlockOption {
  providerId: number;
  scheduledDate: string;
  timeBlockId: number;
  rto: boolean;
  sessionPlacements: SessionPlacement[];
  isClientOccupied: boolean;
  dayOfWeekId: number;
}

export interface ProviderSchedule {
  timeBlockId: number;
  providerId: number;
  dayOfWeekId: number;
  isAvailable: boolean;
  occupied1: number;
  occupied2: number;
  id: number;
  demandScore: number;
  demandRank: number;
}

export interface ProviderCalculations {
  applicableCancelRate: number;
  providerAttendanceStatus: string;
  providerAttendanceStatusId: ProviderAttendanceStatusId;
  consecutiveEventsSinceCancelEvent: number;
  impactHours: number;
  ratingAverage: number | null;
}

export interface BookDetails {
  inCaseTeam: boolean;
  bookOptions: STBRBlockOption[];
  preferredDatesAvailability?: string[];
  numOfPotentialSessions: number;
}

export interface ProviderAttendanceAward {
  id: number;
  providerId: number;
  provider: Provider;
  attendanceAward: AttendanceAward;
  createdAt: Date;
  isSeen: boolean;
}

export type ProviderAttendanceAwardVisibilityStatus = Pick<
  ProviderAttendanceAward,
  'id' | 'isSeen'
>;

export type AtFaultParty = 'Client' | 'Provider' | 'None';

export interface ProviderAcceptanceRate {
  ltAcceptanceRate: number | null;
  stAcceptanceRate: number | null;
}

export interface ProviderLevel {
  cancels: number;
  hours: number;
  level: ClimbLevels | null;
  level1Reason: string;
  providerId: number;
  rating: number | null;
  ratingsClientCount: number;
}

export interface Provider {
  id: number;
  name: string;
  lastName: string;
  firstName: string;
  email: string;
  providerZip: string;
  department: string;
  office: string;
  region: string;
  profilePhoto: string;
  title: string;
  homeZip: string;
  futureBTHrsWorked: number;
  minHrsTarget: number;
  maxHrsTarget: number;
  btFulfillmentThreshold: number;
  attendanceAwards: ProviderAttendanceAward[];
  calculations: ProviderCalculations;
  mobile: string;
  isActive: boolean;
  termAnnounceDate: string;
  availSubmissionMostRecent: string | null;
  startDate: string | null;
  isSupervisor: boolean;
  availUpcomingChangeDate: string | null;
  btCredLabelAdjusted: string;
  covidVaccinatedDate: string;
  timezoneIANA: string;
  attributes?: ProviderAttributes;
  schedule?: ProviderSchedule[];
  tenure: number | null;
  mentorId?: number;
  rbtExpirationDate: string | null;
  rbaiOregonExpirationDate: string | null;
  cbtWashingtonExpirationDate: string | null;
  restraintTrainingExpirationDate: string | null;
  acceptanceRate?: ProviderAcceptanceRate;
  languages?: Language[];
  address: ProviderAddress;
  homeAddressStreet: string;
  homeAddressCity: string;
  homeAddressState: string;
  level: ProviderLevel;
  level1Reason?: string;
}

export type Address = {
  zip: string | null;
  street: string | null;
  city: string | null;
  state: string | null;
};

export interface ProviderAddress extends Address {
  zipCodeData?: ZipCodeData;
  providerId?: number;
}

export interface ProviderWithMentees extends Provider {
  mentees: Array<Provider>;
}

export interface User {
  id: number;
  username: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  associations: UserAssociation[];
  adminActionLogs: AdminActionLog[];
  notificationPreferences: NotificationPreference[];
}

export interface RequestActionLog {
  id: number;
  createdAt: Date;
  bookRequestId: number;
  action: { id: number; name: string }; // ActionType = action.name
  requester?: { id: number; name: string }; // Initiator
  notification?: {
    message: string;
    status?: { name: string };
    recipients: any;
  };
}

export interface ZipCodeData {
  latitude: string;
  longitude: string;
  zipCode: string;
  distance?: string;
  timezoneNameIANA: string;
}

export interface ProviderAttributes {
  spanish: boolean;
  mandarin: boolean;
  cantonese: boolean;
  dogAllergy: boolean;
  catAllergy: boolean;
  locationAnomaly: boolean;
  locationNotes: string;
  bio: string | null;
  singleBookingAvailability: SingleBookingAvailabilityType;
  pause: boolean;
}

export interface ProviderWithBookDetails extends Provider {
  bookDetails: BookDetails;
}

export interface SingleSessionProviderFilters {
  caseTeam?: 'excluded' | 'only';
  ignoreAvailability?: boolean;
}

export interface TimeDayBlock {
  id: number;
  timeBlockId: number;
  dayOfWeekId: number;
}

export enum RequestStatus {
  QUEUED = 1,
  PENDING = 2,
  ACCEPTED = 3,
  DECLINED = 4,
  CONFIRMED = 5,
  RETRACTED = 6,
  EXPIRED = 7,
  WAITING_APPROVAL = 8,
  REJECTED = 9,
  DENIED = 11,
  CLOSED = 12,
  REMOVED = 13,
}

export enum SingleTermRequestType {
  Proactive = 'Proactive',
  Credit = 'Credit',
  Rebook = 'Rebook',
}

export interface CancellationEventMapped {
  id: number;
  sessionStart: string;
  sessionEnd: string;
  providerId: number;
  clientId: number;
  isComplete: boolean;
  provider: Provider;
}

export interface ClientSTBRAvailability {
  canClientBook: boolean;
  isProactive: boolean;
  forbidReason: string;
  cancellationIds: number[];
  maxNumberOfSessionsCanBooked: number;
  minNumberOfSessionsCanBooked: number;
  extraSessionsNumberToSelect: number;
}

export enum SINGLE_SESSION_FORBID_REASON {
  clientPaused = 'clientPaused',
  badAuthPeriod = 'badAuthPeriod',
  clientActiveDTAuth = 'clientActiveDTAuth',
}

export enum RequestAction {
  Create = 0,
  Accept = 1,
  Decline = 2,
  Confirm = 3,
  Retract = 4,
  Approve = 5,
  Reject = 6,
  Resend = 7,
  UpdateReferenceInfo = 9,
  SetExpirationDate = 10,
  SendSmsNotification = 11,
  SendEmailNotification = 12,
  SendAlertNotification = 13,
  MarkComplete = 14,
  MarkIncomplete = 15,
  Unknown = 16,
  Processed = 17,
  RemindAfterCreation = 18,
  RemindBeforeExpiration = 19,
  RemindBeforeExpirationMultipleRequests = 20,
  MarkExpired = 21,
  MarkExpiredMultiple = 22,
  Respond = 23,
  RetractWithoutNotification = 24,
  MarkSub = 25,
  MarkOff = 26,
}

export interface SessionMembersInfo {
  providerId: number;
  provider?: {
    name: string;
    address: { zipCode: string; zipCodeData: ZipCodeData };
  }; // old - BookedProviderName, Provider.zipCodeData
  clientId: number;
  clientName?: string | null;
  zipCode: string;
  zipCodeData?: ZipCodeData;
  driveDistance: number;
}

export interface RequestSession extends SessionMembersInfo {
  id: number;
  statusId: RequestStatus;
  createdAt: string;
  sessionStart: string;
  sessionEnd: string;
  duration: number;
  timeBlockIds: string; // opensession
  dayOfWeekId: number; // opensession
  blocks: { id: number; timeBlockId: number; dayOfWeekId: DayOfWeekId }[];
  bookRequestId: number;
  originalSessionId?: number | null;
  relatedSessions: Pick<RequestSession, 'id' | 'statusId'>[] | null;
}

export interface BookRequestBlock {
  timeBlockDayOfWeekId: number;
  timeBlockDayOfWeek: TimeDayBlock;
  startTime: string;
  duration: number;
}

export interface Request extends Partial<SessionMembersInfo> {
  requester: {
    name: string;
  }; // old - RequestorName
  createdAt: string;
  id: number;
  // RequestStatus: RequestStatuses;
  statusId: RequestStatus; // RequestStatus
  referenceInfo: string;
  isComplete: boolean; // RequestComplete
  status: string;
  expirationDate: string | null;
  logs: RequestActionLog[];
  sessions: RequestSession[];
  userId: number | null;
  user?: User;
  type: string;
  subtype: SingleTermRequestType;
  inCaseTeam: boolean;
  cancellationEvent?: CancellationEventMapped;
  cancellationEvents?: CancellationEventMapped[];
  sessionsAllowed: number | null;
  providerStatuses?: { providerId: number; statusId: number }[];
  originalBookRequestId?: number;
  confirmedSessionsCount?: number;
  claimedSessionsCount?: number;
  blocks: BookRequestBlock[];
}

export interface NotificationCategory {
  id: number;
  atLeastOneIsMandatory: boolean;
  name: string;
  topics?: { id: number; name: string }[];
}

export interface NotificationPreference {
  id?: number;
  categoryId: number;
  category?: NotificationCategory;
  methodId: number;
  method?: { id: number; name: string };
  isEnabled: boolean;
  isEditable?: boolean;
  userId?: number | null;
  providerId?: number | null;
  createdAt?: string;
}

export enum SessionStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  TimeOff = 'timeOff',
  Hold = 'hold',
}

export enum RemovedBy {
  Admin = 'admin',
  Provider = 'provider',
  Client = 'client',
}

export interface ScheduleSegmentProvider {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  profilePhoto: string;
  title: string;
  covidVaccinatedDate: string;
  isSupervisor: boolean;
  region?: string;
  attributes: { zipCodeData: { latitude?: number; longitude?: number } };
  address: ProviderAddress;
}

export enum RemoveSessionVariant {
  cancellation = 'cancel',
  timeOff = 'timeOff',
  retraction = 'retract',
}

export interface ScheduleSegment {
  blocks: { id: number }[];
  segmentId: number;
  id?: number;
  bookRequestType?: RequestType;
  bookRequestId?: number;
  createdAt: string;
  providerId?: number;
  scheduledDate: string;
  segmentStart: string;
  segmentEnd: string;
  segmentStartUtc: string;
  segmentEndUtc: string;
  segmentDuration: number;
  clientId: number;
  validBillable: boolean;
  isCancellableByProvider: boolean;
  isCancellableByClient: boolean;
  isRetractableByClient: boolean;
  isFirstTimeOff?: boolean;
  /** this filed value is set locally (in redux file for client) */
  removeSessionVariant?: RemoveSessionVariant;
  distanceInMiles?: number;
  provider?: ScheduleSegmentProvider;
  client: {
    zipCode: number;
    supervisor?: { name?: string; email?: string };
    zipCodeData?: { latitude?: number; longitude?: number };
    attributes: { locationNotes?: string };
  };
  clientName?: string;
  cancellationEvent?: { id?: number };
  status: SessionStatus;
  visibleToClient: boolean;
  overlappingSessions: ScheduleSegment[];
  removedBy?: RemovedBy;
  billingCode?: string;
  isBookRequest?: boolean;
  pendingLongTermBookRequestWithZipCode?: boolean;
  locationAddressLine1: string | null;
  locationAddressLine2: string | null;
  locationCity: string | null;
  locationState: string | null;
  locationZip: string | null;
}

export interface ClientAttributes {
  ClientId: number;
  assignmentApprovalRequired: boolean;
  cantonese: LanguagePreference;
  expectedWeeklyAuth: number | 'NULL';
  hasCat: boolean;
  hasDog: boolean;
  locationAnomaly: boolean;
  mandarin: LanguagePreference;
  overrideCR: boolean;
  overrideReason: string;
  shortSession: boolean;
  spanish: LanguagePreference;
  center: boolean;
  homeSchool: boolean;
  telehealth: boolean;
  restraintTrainingRequired: boolean;
  locationNotes: string;
  telehealthWaiver: boolean;
  pause: boolean;
  suspendedChat: boolean;
}

export interface AttendanceStatusThreshold {
  id: number;
  lower: number;
  upper: number;
  status: string;
  type: 'Provider' | 'Client';
}

export interface Client {
  id: number;
  payer: string;
  gwClientId: string;
  confirmed: boolean;
  nameChange: boolean;
  crcDateC: string;
  isActive: boolean;
  currentClinicalDirectorId: number;
  zipCode: string;
  clientEmail: string;
  clientCity: string;
  distanceInMiles?: number;
  region: string;
  weeklyAuthSize: number;
  attributes?: ClientAttributes;
  authCurrentMonthBTAdj: number;
  authPriorMonthBTAdj: number;
  hrsWorkedBTCurrentMonth: number;
  hrsWorkedBTPriorMonth: number;
  availability: AvailabilityRecord[];
  schedOccupied?: any[];
  applicableCancelRate: number;
  attendanceStatusId: ClientAttendanceStatusId;
  schedule: ClientSchedule[];
  matchedSchedule?: ClientSchedule[];
  futureBTHrsWorked: number;
  futureBTHrsWorkedCurrMonth: number;
  futureAuthFulfillPercentage?: number;
  termAnnounceDate: string | null;
  isAccelerator?: boolean;
  clientSupervisorNameCurrent: string;
  btAuthFrequency: string;
  clientName: string;
  zipCodeData?: ZipCodeData;
  expectedAuthFulfillment: number;
  timezoneIANA: string;
  ClientDischargeDate: string | null;
  DTDischargeDate: string | null;
}

export interface Relationship {
  id: number;
  name: string;
}

export interface UserAssociation {
  id: number;
  userId: number;
  clientId: number;
  dateAdded: string;
  addedBy: string;
  dateRevoked: string | null;
  revokedBy: string | null;
  dateVerified: string | null;
  verified: boolean;
  relationship?: Relationship | null;
  client?: { name: string };
}

export interface AdminActionLog {
  id: number;
  performedBy: string;
  actionDate: string;
  action: {
    name: 'invite' | 'enable' | 'disable';
  };
}

export interface AvailabilityRecord {
  ClientId: number;
  dayOfWeekId: number;
  EndDate: string;
  EndTime: string;
  Name: string;
  StartDate: string;
  StartTime: string;
  id: number;
}

export interface ClientSchedule {
  timeBlockId: number;
  clientId: number;
  dayOfWeekId: number;
  isAvailable: boolean;
  occupied1: number;
  occupied2: number;
  id: number;
  preferredStartTime: string;
  preferredEndTime: string;
}

export interface Goal {
  id: number;
  metDate: string | null;
  name: string;
}

export interface ApiPagination {
  page: number;
  limit: number;
}

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ApiSorting<T> {
  orderBy: T;
  direction: SortingDirection;
}

export interface PaginatedData<T> {
  items: T[];
  meta: PaginationDataMeta;
}

export interface PaginationDataMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface CancellationEvent {
  id: number;
  clientId: number;
  providerId: number;
  provider?: Provider;
  sessionStart: string;
  createdAt: string;
  atFaultParty: string;
  isLateCancellation: boolean;
  isSameDayCancellation: boolean;
  cancelSubmissionOn: string;
}

export enum DayOfWeekId {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum LanguagePreference {
  None = 'None',
  Preferred = 'Preferred',
  Only = 'Only',
}

export interface FetchBookingFilters {
  providerId?: number;
  userId?: number;
  clientId?: number;
  status?: RequestStatus[];
  excludeStatus?: RequestStatus[];
  excludeSessionStatus?: RequestStatus[];
  type?: RequestType[];
  subtype?: SingleTermRequestType[];
  typedStatuses?: TypedStatus[];
  creationDateFrom?: string;
  creationDateTill?: string;
  sessionStartFrom?: string;
  sessionStartTo?: string;
  limit?: number;
  page?: number;
  transformKeys?: boolean;
}

export interface TypedStatus {
  type: RequestType;
  statuses?: RequestStatus[];
  excludeStatuses?: RequestStatus[];
  hasOriginalBookRequestId?: boolean;
}

export type CancelledSegmentsResponseType = {
  segmentId: number;
  isRTO: boolean;
  cancellationEventId: number;
  removedBy: RemovedBy;
};

export type RetractedSessionsResponseType = {
  id: number;
  sessions: Array<{ id: number; statusId: RequestStatus }>;
};

export interface CancellationEventsFilters {
  providerId: number;
  clientId: number;
  atFaultParty: AtFaultParty;
  createdFrom: string;
  providerTitles: string[];
  isRetracted: boolean;
  isPaid?: boolean;
}

export enum CancellationEventsSort {
  SessionStart = 'sessionStart',
  CreatedAt = 'createdAt',
}

export interface NotificationPaginatedData {
  items: any[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface Notification {
  id: number;
  message: string;
  isSeen: boolean;
  createdAt: string;
}

export enum NotificationTab {
  all = 'all',
  unread = 'unread',
  read = 'read',
}

export enum RequestsTab {
  Requests = 'My Requests',
  OpenSessions = 'OpenSessions',
}

export enum JiraTicketTypeId {
  ChangingAvailability = 1,
  ChangeSessionLocation = 2,
  OneTimeChangetoaSession = 3,
  PermanentChangetomySchedule = 4,
  CarePortalOrAppSupport = 5,
  InsuranceSwitch = 6,
  Other = 7,
}

export interface JiraTicketType {
  id: JiraTicketTypeId;
  name: string;
}

export interface Week {
  start: string;
  end: string;
}

export type MidTermBookRequest = Request & {
  week: Week;
  client: Client;
  driveTime?: google.maps.DistanceMatrixResponseElement;
  openSessionBlocks: BlockCoords[];
};

export interface Language {
  id: number;
  name: string;
}

export interface BlockCoords {
  dayOfWeekId: DayOfWeekId;
  timeBlockId: number;
}

export interface State {
  name: string;
  abbreviation: string;
}

export enum RequestType {
  AllRequests = 'all',
  LongTerm = 'LongTermBookRequest',
  SingleTerm = 'SingleTermBookRequest',
  MidTerm = 'MidTermBookRequest',
  SubOffer = 'SubOfferBookRequest',
}

export type CheckedSessions = { [sessionId: number]: boolean };

export interface StatusInfo {
  readonly title: string;
  readonly color: string;
}

export type StatusesInfo = {
  [status in RequestStatus]?: ((request: Request) => StatusInfo) | StatusInfo;
};

export type MainRequestTypes = Exclude<RequestType, RequestType.AllRequests>;

export type NoticeRequestType = 'cc_expiration' | 'cc_declined';

export type Notice = {
  requestType: NoticeRequestType;
  token: string;
};

export enum SingleBookingAvailabilityType {
  AllClients = 'All Clients',
  LTClientsOnly = 'LT Clients Only',
  NoSTBookings = 'No Short Term Bookings',
}

export interface FeedbackTag {
  id: number;
  name: string;
}

export interface UnratedSession {
  clientId: number;
  providerId: number;
  segmentId: number;
  segmentEndUtc: string;
  segmentStartUtc: string;
  profilePhoto: string;
  firstName: string;
  lastName: string;
}

export enum ClimbLevels {
  builder = 1,
  maker = 2,
  achiever = 3,
  leader = 4,
}

export interface InvoiceLog {
  id: number;
  invoiceId: number;
  action: string;
  params: { [key: string]: any } | null;
  createdAt: string;
  createdById: string;
  createdByName: string;
  updatedAt: string;
}

export interface Invoice {
  id: number;
  clientId: number;
  note: string;
  removedById: number | null;
  removedByName: string | null;
  logs: InvoiceLog[];
  status: string;
}

export enum InvoiceStatus {
  PAID = 'paid',
  RETRACTED = 'retracted',
  SCHEDULED = 'scheduled',
  REVIEW = 'review',
}

export type DecimalNumber = {
  s: 1 | -1; // sign
  e: number; // exponent
  d: number[]; // digits
};

import React, { useEffect } from 'react';
import { Channel, useChatContext } from 'stream-chat-react';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import './ChannelContainer.css';
import { ChannelInner } from './ChannelInner';
import { ChannelEmptyState } from '../ChannelEmptyState/ChannelEmptyState';
import { TeamMessage } from '../TeamMessage/TeamMessage';
import { TeamMessageInput } from '../TeamMessageInput/TeamMessageInput';
import {
  TeamAttachmentType,
  TeamChannelType,
  TeamCommandType,
  TeamEventType,
  TeamMessageType,
  TeamReactionType,
  TeamUserType,
} from '../types';
import { CustomAvatar } from '../CustomAvatar/CustomAvatar';

type Props = {
  className: string;
  channelId?: string;
};

const LoadingErrorIndicator = () => {
  return (
    <Box paddingY={2}>
      <Typography>
        You don&apos;t have a Team Chat channel yet. Please check back later.
      </Typography>
    </Box>
  );
};

export const ChannelContainer: React.FC<Props> = (props: Props) => {
  const { className, channelId } = props;
  const { client, setActiveChannel } = useChatContext<
    TeamAttachmentType,
    TeamChannelType,
    TeamCommandType,
    TeamEventType,
    TeamMessageType,
    TeamReactionType,
    TeamUserType
  >();
  useEffect(() => {
    const setChannel = async () => {
      if (channelId) {
        const [existingChannel] = await client.queryChannels({
          type: 'team',
          id: channelId,
        });
        setActiveChannel(existingChannel);
      }
    };
    setChannel();
  }, [channelId, client, setActiveChannel]);

  return (
    <div className={clsx('channel__container', className)}>
      <Channel
        Avatar={CustomAvatar}
        EmptyStateIndicator={ChannelEmptyState}
        Input={TeamMessageInput}
        Message={(messageProps) => <TeamMessage {...messageProps} />}
        TypingIndicator={() => null}
        LoadingErrorIndicator={LoadingErrorIndicator}
      >
        <ChannelInner />
      </Channel>
    </div>
  );
};

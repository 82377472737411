import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import stateApi from '../../api/statesApi';
import { State } from '../../types/types';

export interface StatesState {
  loadingStates: boolean;
  states: State[];
}

const initialState: StatesState = {
  loadingStates: false,
  states: [],
};

export const fetchStates = createAsyncThunk('states/fetchStates', async () => {
  return stateApi.fetchStates();
});

const statesSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStates.pending, (state) => {
        state.loadingStates = true;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.states = action.payload;
        state.loadingStates = false;
      })
      .addCase(fetchStates.rejected, (state) => {
        state.loadingStates = false;
      });
  },
});

export default statesSlice.reducer;

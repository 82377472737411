import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    color: theme.palette.grey[800],
  },
}));

interface ProviderCardBioProps {
  text: string | null | undefined;
}

const ProviderCardBio = (props: ProviderCardBioProps) => {
  const { text } = props;
  const classes = useStyles();

  if (!text) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};

export default ProviderCardBio;

import { makeStyles } from '@material-ui/core';
import { StyleClassNames } from '.';

const commonStyles = {
  [StyleClassNames.MAIN]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [StyleClassNames.DISABLED]: {},
};

export const useHorizontalLabelStylesVariant1 = makeStyles((theme) => ({
  [StyleClassNames.MAIN]: {
    ...commonStyles[StyleClassNames.MAIN],
    fontSize: theme.spacing(2),
    fontWeight: 600,
  },
  [StyleClassNames.DISABLED]: {
    opacity: 0.5,
  },
}));

export const useHorizontalLabelStylesVariant2 = makeStyles((theme) => ({
  [StyleClassNames.MAIN]: {
    ...commonStyles[StyleClassNames.MAIN],
    fontSize: theme.spacing(1.75),
  },
}));

export const useHorizontalLabelStylesVariant3 = makeStyles((theme) => ({
  [StyleClassNames.MAIN]: {
    ...commonStyles[StyleClassNames.MAIN],
    gap: theme.spacing(0.25),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  [StyleClassNames.LABEL]: {
    opacity: 0.5,
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
  },
  [StyleClassNames.DAY]: {
    fontSize: theme.spacing(1.75),
    fontWeight: 700,
  },
}));

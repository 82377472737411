import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { orderBy } from 'lodash';

import { Availability } from '../../redux/modules/availability';
import ModifiedAvailabilityList from '../../components/Availability/ModifiedAvailabilityList';
import ModifiedAvailabilityContentFooter from './ModifiedAvailabilityContentFooter';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  visibleContent: {
    padding: theme.spacing(1.375, 2.5, 0),
  },
  availabilityWrapper: {
    [theme.breakpoints.up('md')]: {
      maxHeight: theme.spacing(50),
    },
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5),
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c9c9c9',
    },
  },
}));

export const UpcomingModifiedAvailability = (props: {
  stAvailability: Availability[];
}) => {
  const classes = useStyles();

  const { stAvailability } = props;

  const sortedAvailabilityList = orderBy(stAvailability, (a) => a.startDate);

  return (
    <div className={classes.availabilityWrapper}>
      <ModifiedAvailabilityList
        availabilities={sortedAvailabilityList}
        styles={{
          listItem: classes.listItem,
          visibleContent: classes.visibleContent,
        }}
        ItemContentFooter={ModifiedAvailabilityContentFooter}
      />
    </div>
  );
};

export default UpcomingModifiedAvailability;

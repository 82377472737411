import React from 'react';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Typography, makeStyles } from '@material-ui/core';
import { AVAILABLE_ICON_COLOR } from './AvailableCell';
import { BOOKED_ICON_COLOR } from './BookedCell';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  info: {
    display: 'flex',
    gap: theme.spacing(0.25),
    alignItems: 'center',
    color: '#818181',

    '& svg': {
      fontSize: '1rem',
    },
  },
  availableIcon: {
    color: AVAILABLE_ICON_COLOR,
  },
  bookedIcon: {
    color: BOOKED_ICON_COLOR,
  },
}));

const AddSessionsMatrixInfo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <AddCircleOutlineIcon className={classes.availableIcon} />
        <Typography variant="body2">Available</Typography>
      </div>
      <div className={classes.info}>
        <EventAvailableIcon className={classes.bookedIcon} />
        <Typography variant="body2">Booked</Typography>
      </div>
    </div>
  );
};

export default AddSessionsMatrixInfo;

import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import providerApi from '../../api/providerApi';

import { Provider } from '../../types/types';
import { getNumberOfHoursBelowRequiredAvail } from '../../utils/helpers';
import { RootState } from './rootReducer';

export interface MenteeProvidersState {
  menteeProviders: Array<Provider>;
  menteeProvidersLoading: boolean;
  selectedMentee: Provider | null;
  selectedMenteeLoading: boolean;
}

const initialState: MenteeProvidersState = {
  menteeProviders: [],
  menteeProvidersLoading: false,
  selectedMentee: null,
  selectedMenteeLoading: false,
};

export const fetchMenteeProviders = createAsyncThunk(
  'menteeProviders/fetchMenteeProviders',
  (providerId: number) => {
    return providerApi.fetchMenteeProviders(providerId);
  }
);

export const fetchSelectedMentee = createAsyncThunk(
  'menteeProviders/fetchSelectedMentee',
  (providerId: number) => providerApi.fetchProviderById(providerId)
);

const menteeProvidersSlice = createSlice({
  name: 'menteeProviders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenteeProviders.pending, (state) => {
        state.menteeProvidersLoading = true;
      })
      .addCase(fetchMenteeProviders.fulfilled, (state, action) => {
        state.menteeProviders = action.payload;
        state.menteeProvidersLoading = false;
      })
      .addCase(fetchMenteeProviders.rejected, (state) => {
        state.menteeProvidersLoading = false;
        state.menteeProviders = [];
      })
      .addCase(fetchSelectedMentee.pending, (state) => {
        state.selectedMenteeLoading = true;
      })
      .addCase(fetchSelectedMentee.fulfilled, (state, action) => {
        state.selectedMentee = action.payload;
        state.selectedMenteeLoading = false;
      })
      .addCase(fetchSelectedMentee.rejected, (state) => {
        state.selectedMenteeLoading = false;
      });
  },
});

export const selectNumberOfHoursBelowRequiredAvail = createSelector(
  (state: RootState) => state.menteeProviders.selectedMentee,
  (provider: Provider | null) =>
    provider?.schedule
      ? getNumberOfHoursBelowRequiredAvail({
          schedule: provider.schedule,
          minHrsTarget: provider.minHrsTarget,
        })
      : 0
);

export const selectMenteesCurrentAvailabilities = createSelector(
  (state: RootState) => state.menteeProviders.menteeProviders,
  (menteeProviders) => {
    return menteeProviders.reduce((acc, provider) => {
      const numberOfHoursBelowRequiredAvail = provider.schedule
        ? getNumberOfHoursBelowRequiredAvail({
            schedule: provider.schedule,
            minHrsTarget: provider.minHrsTarget,
          })
        : 0;
      acc[provider.id] = numberOfHoursBelowRequiredAvail > 0;

      return acc;
    }, {} as Record<number, boolean>);
  }
);

export default menteeProvidersSlice.reducer;

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/modules/rootReducer';
import { fetchSingleSessionProviders } from '../redux/modules/newSessions';
import { SingleSessionProviderFilters } from '../types/types';

const useAddSessions = () => {
  const dispatch = useDispatch();

  const singleSessionProviders = useSelector(
    (state: RootState) => state.newSessions.singleSessionProviders
  );

  const singleSessionProvidersLoading = useSelector(
    (state: RootState) => state.newSessions.singleSessionProvidersLoading
  );

  const getSingleSessionProviders = useCallback(
    (filters: SingleSessionProviderFilters) => {
      dispatch(fetchSingleSessionProviders(filters));
    },
    [dispatch]
  );

  return {
    singleSessionProviders,
    singleSessionProvidersLoading,
    getSingleSessionProviders,
  };
};

export default useAddSessions;

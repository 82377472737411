import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ReactComponent as CalendarExclamation } from '../../assets/calendar-exclamation.svg';
import { ReactComponent as InfoIdea } from '../../assets/info-idea.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ warning }: { warning: boolean }) => ({
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${
      warning
        ? theme.palette.kyoPastelOrange.main
        : theme.palette.kyoPastelGreen.main
    }`,
    backgroundColor: warning
      ? theme.palette.kyoPastelOrange.light
      : theme.palette.kyoPastelGreen.light,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(0.625, 2.5),
    ...theme.typography.subtitle2,
  }),
  svg: {
    width: theme.spacing(3.5),
  },
}));

interface WeeklyHoursInfoProps {
  warning: boolean;
  shortage: number;
}

const WeeklyHoursInfo = ({ warning, shortage }: WeeklyHoursInfoProps) => {
  const classes = useStyles({ warning });
  const Icon = warning ? CalendarExclamation : InfoIdea;
  const text = warning
    ? `Add ${shortage} blocks to your availability to meet your weekly minimum hours`
    : 'You can review your weekly min and max preferences in My Profile';

  return (
    <div className={classes.root}>
      <Icon className={classes.svg} />
      {text}
    </div>
  );
};

export default WeeklyHoursInfo;

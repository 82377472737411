import React from 'react';
import { Box, Typography, Divider, makeStyles, Theme } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import StyledButton from './buttons/StyledButton';
import DividerText from './DividerText/DividerText';
import { useAuth } from '../hooks/useAuth';
import ClientSelectionComponent from './ClientSelectionComponent';
import usePermission from '../hooks/usePermission';
import { Resources, Role } from '../accessControl/acl';
import ProviderRoleSelectionComponent from './ProviderRoleSelectionComponent';
import useGroups from '../hooks/useGroups';

const useStyles = makeStyles((theme: Theme) => ({
  popupMenu: {
    width: 260,
    padding: theme.spacing(1, 2),
    color: theme.palette.common.black,
  },
  button: {
    width: '100%',
    margin: theme.spacing(1, 0),
    height: theme.spacing(5),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

interface PopupMenuProps {
  closePopup: Function;
}

const rolesAllowedToSwitch = [
  Role['CLIN-BCBA'],
  Role['CLIN-RD/Con'],
  Role['HR/CRED/CS'],
  Role.CS,
  Role.SCH,
  Role.UserMgr,
  Role.admin,
];

const PopupMenu = (props: PopupMenuProps) => {
  const { closePopup } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const checkPermission = usePermission();

  const clientRoutesAccess = checkPermission(Resources.clientRoutes);
  const providerRoutesAccess = checkPermission(Resources.providerRoutes);
  const userGroups = useGroups();

  const switchRoleAccess = rolesAllowedToSwitch.some((role) =>
    userGroups?.includes(role)
  );

  return (
    <Box className={classes.popupMenu}>
      <StyledButton
        className={classes.button}
        customColor="kyoGreen"
        onClick={() => {
          navigate(clientRoutesAccess ? '/user-profile' : '/my-profile');
          closePopup();
        }}
      >
        {clientRoutesAccess ? 'User' : 'My'} Profile
      </StyledButton>

      {clientRoutesAccess && (
        <>
          <DividerText>
            <Typography variant="subtitle2">Clients</Typography>
          </DividerText>
          <ClientSelectionComponent
            onAction={() => {
              closePopup();
            }}
          />
        </>
      )}

      <Divider className={classes.divider} />

      {providerRoutesAccess && switchRoleAccess && (
        <ProviderRoleSelectionComponent
          onAction={() => {
            closePopup();
          }}
        />
      )}

      <StyledButton
        className={classes.button}
        customColor="kyoTeal"
        onClick={() => {
          signOut();
          closePopup();
        }}
      >
        Sign out
      </StyledButton>
    </Box>
  );
};

export default PopupMenu;

import React from 'react';
import { Box, CircularProgress, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    display: 'flex',
    height: '100%',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const CustomLoading = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default CustomLoading;

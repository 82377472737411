import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import WarningIcon from '../../assets/cancellation-warning.svg';
import {
  ClientAttendanceStatusId,
  getAttendanceStatusText,
  ProviderAttendanceStatusId,
} from '../../components/AttendanceStatus/constants/constants';

const useStyles = makeStyles((theme: Theme) => ({
  alert: (styles: React.CSSProperties) => ({
    marginTop: styles.marginTop || theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: styles.backgroundColor,
    padding: styles.padding,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      '& .MuiAlert-action': {
        margin: 'auto',
      },
    },
  }),
  close: {
    color: theme.palette.kyoRed.main,
    cursor: 'pointer',
    fontSize: theme.spacing(2.5),
    marginRight: theme.spacing(1),
  },
}));

const getWarningText = (
  statusId: ClientAttendanceStatusId | ProviderAttendanceStatusId,
  lower: number,
  upper: number
) => {
  if (
    statusId === ClientAttendanceStatusId.GOOD_STANDING ||
    statusId === ProviderAttendanceStatusId.GOOD_STANDING
  ) {
    return getAttendanceStatusText(
      upper,
      statusId === ClientAttendanceStatusId.GOOD_STANDING
    ).NEAR_TO_HIGH_CANCELER_CLIENT;
  }

  if (
    statusId === ProviderAttendanceStatusId.HIGH_CANCELER ||
    statusId === ProviderAttendanceStatusId.VERY_HIGH_CANCELER
  ) {
    return getAttendanceStatusText(lower).HIGH_CANCELER;
  }

  return getAttendanceStatusText(lower).HIGH_CANCELER_CLIENT;
};

interface CancellationInfoType {
  attendanceStatusId: ClientAttendanceStatusId | ProviderAttendanceStatusId;
  lowerCancelThreshold: number;
  upperCancelThreshold: number;
  styles?: React.CSSProperties;
}

const CancellationInfo = ({
  attendanceStatusId,
  lowerCancelThreshold,
  upperCancelThreshold,
  styles = {},
}: CancellationInfoType) => {
  const classes = useStyles(styles);

  const warningText = getWarningText(
    attendanceStatusId,
    lowerCancelThreshold,
    upperCancelThreshold
  );

  return (
    <Alert
      icon={<img alt="warning" src={WarningIcon} />}
      severity="error"
      className={classes.alert}
    >
      <Typography variant="subtitle2" color="error">
        {warningText}
      </Typography>
    </Alert>
  );
};

export default CancellationInfo;

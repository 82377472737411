import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { filter, max } from 'lodash';
import bookingApi from '../../api/bookingApi';
import providerApi from '../../api/providerApi';
import clientApi from '../../api/clientApi';
import { RootState } from './rootReducer';
import {
  ClientSTBRAvailability,
  ProviderWithBookDetails,
  ScheduleSegment,
  SingleSessionProviderFilters,
  STBRBlockOption,
  UnratedSession,
} from '../../types/types';
import { SessionPlacement } from './adminSettings';
import scheduleApi, {
  FetchScheduledSessionsFilters,
  FetchUnratedSessionsFilters,
} from '../../api/scheduleApi';
import unratedSuspendedSessions from '../../clientPortal/home/ratingProviders/unrated-suspended-sessions';

export type SelectedBookingOption = Omit<
  STBRBlockOption,
  'sessionPlacements'
> & { sessionPlacement: SessionPlacement };

export const createSingleTermBookRequest = createAsyncThunk<
  void,
  {
    clientId: number;
    providerId: number;
  },
  { state: RootState }
>(
  'newSessions/createSingleTermBookRequest',
  (arg, { rejectWithValue, getState }) => {
    const { selectedBookingOptions } = getState().newSessions;
    const { clientId, providerId } = arg;

    return bookingApi
      .createSingleTermBookRequest(
        clientId,
        providerId,
        selectedBookingOptions.map((selectedOption) => ({
          schedDate: selectedOption.scheduledDate,
          sessionPlacement: selectedOption.sessionPlacement,
        }))
      )
      .then((response) => response)
      .catch((e) => rejectWithValue(e));
  }
);

export const fetchSingleSessionProviders = createAsyncThunk<
  ProviderWithBookDetails[],
  SingleSessionProviderFilters,
  { state: RootState }
>(
  'newSessions/fetchSingleSessionProviders',
  async (filters: SingleSessionProviderFilters, thunkAPI) => {
    const state = thunkAPI.getState();
    const { currentClient } = state.client;
    const { singleSessionProvidersAbortController } = state.newSessions;

    if (!currentClient) {
      return thunkAPI.rejectWithValue(null);
    }

    return providerApi
      .fetchProvidersForSingleBook(
        currentClient.id,
        filters,
        singleSessionProvidersAbortController.signal
      )
      .catch((e) => {
        console.log(e);
        throw new Error(e.name);
      });
  }
);
export const fetchClientSingleTermBookingAvailability = createAsyncThunk(
  'newSessions/fetchClientSingleTermBookingAvailability',
  (clientId: number) => {
    return clientApi.fetchClientSingleTermBookingAvailability(clientId);
  }
);

export const fetchScheduledSessions = createAsyncThunk(
  'newSessions/fetchScheduledSessions',
  (payload: FetchScheduledSessionsFilters, { rejectWithValue }) =>
    scheduleApi
      .fetchScheduledSessions(payload, false)
      .catch((e) => rejectWithValue('Failed to load scheduled sessions'))
);

export const fetchUnratedSessions = createAsyncThunk(
  'newSessions/fetchUnratedSessions',
  (payload: FetchUnratedSessionsFilters, { rejectWithValue }) =>
    scheduleApi
      .fetchUnratedSessions(payload)
      .catch((e) => rejectWithValue('Failed to load unrated sessions'))
);

interface NewSessions {
  selectedProvider: ProviderWithBookDetails | null;
  selectedBookingOptions: SelectedBookingOption[];
  createSessionInProgress: boolean;
  createSessionSuccess: boolean;
  createSessionFailed: boolean;
  singleSessionProvidersLoading: boolean;
  singleSessionProviders: ProviderWithBookDetails[] | null;
  clientSTBRAvailability: ClientSTBRAvailability;
  clientSTBRAvailabilityLoading: boolean;
  clientSTBRAvailabilityError: SerializedError | null;
  maxDate: Date;
  singleSessionProvidersAbortController: AbortController;
  scheduledSessions: ScheduleSegment[] | null;
  scheduledSessionsLoading: boolean;
  unratedSessions: UnratedSession[];
  unratedSessionsLoading: boolean;
  sessionsToRate: UnratedSession[];
  showSuspectedSessions: boolean;
  noLatestSessionsToRate: boolean;
}

const initialState: NewSessions = {
  selectedProvider: null,
  selectedBookingOptions: [],
  createSessionInProgress: false,
  createSessionSuccess: false,
  createSessionFailed: false,
  singleSessionProviders: null,
  singleSessionProvidersLoading: false,
  clientSTBRAvailability: {
    canClientBook: false,
    isProactive: false,
    forbidReason: '',
    maxNumberOfSessionsCanBooked: 0,
    minNumberOfSessionsCanBooked: 0,
    cancellationIds: [],
    extraSessionsNumberToSelect: 1,
  },
  clientSTBRAvailabilityLoading: false,
  clientSTBRAvailabilityError: null,
  maxDate: moment().startOf('day').add(30, 'day').toDate(),
  singleSessionProvidersAbortController: new AbortController(),
  scheduledSessions: null,
  scheduledSessionsLoading: false,
  unratedSessions: [],
  unratedSessionsLoading: false,
  sessionsToRate: [],
  showSuspectedSessions: false,
  noLatestSessionsToRate: false,
};

export const newSessionsSlice = createSlice({
  name: 'newSessions',
  initialState,
  reducers: {
    setSelectedProvider: (
      state,
      action: PayloadAction<NewSessions['selectedProvider']>
    ) => ({
      ...state,
      selectedProvider: action.payload,
    }),
    addSelectedBookingOption: (
      state,
      action: PayloadAction<SelectedBookingOption>
    ) => {
      state.selectedBookingOptions = [
        ...state.selectedBookingOptions,
        action.payload,
      ];
    },
    removeSelectedBookingOption: (
      state,
      action: PayloadAction<{ scheduledDate: string; timeBlockId: number }>
    ) => {
      const { selectedBookingOptions } = state;
      const { scheduledDate, timeBlockId } = action.payload;

      const removeOptionIndex = selectedBookingOptions.findIndex(
        (option) =>
          option.scheduledDate === scheduledDate &&
          option.timeBlockId === timeBlockId
      );

      const clonedSelectedBookingOptions = [...selectedBookingOptions];
      clonedSelectedBookingOptions.splice(removeOptionIndex, 1);

      state.selectedBookingOptions = clonedSelectedBookingOptions;
    },
    clearSelectedBookingOptions: (state) => {
      state.selectedBookingOptions = [];
    },
    setCreateSessionSuccess: (state, action: PayloadAction<boolean>) => {
      state.createSessionSuccess = action.payload;
    },
    setToInitialState: (state) => ({
      ...initialState,
      singleSessionProvidersLoading: state.singleSessionProvidersLoading,
      singleSessionProvidersAbortController:
        state.singleSessionProvidersAbortController,
    }),
    clearScheduledSessions: (state) => {
      state.scheduledSessions = null;
      state.scheduledSessionsLoading = false;
    },
    removeSessionFromRatingQueue: (state, action: PayloadAction<number>) => {
      const restSessions = state.sessionsToRate.filter(
        (s) => s.segmentId !== action.payload
      );
      state.sessionsToRate = restSessions;
      if (restSessions.length === 0) {
        state.showSuspectedSessions = false;
      }
    },
    setShowSuspectedSessions: (state, action: PayloadAction<boolean>) => {
      state.showSuspectedSessions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSingleTermBookRequest.pending, (state) => {
        return {
          ...state,
          createSessionInProgress: true,
        };
      })
      .addCase(createSingleTermBookRequest.fulfilled, (state) => {
        return {
          ...state,
          createSessionInProgress: false,
          createSessionSuccess: true,
          createSessionFailed: false,
        };
      })
      .addCase(createSingleTermBookRequest.rejected, (state) => {
        return {
          ...state,
          createSessionInProgress: false,
          createSessionSuccess: false,
          createSessionFailed: true,
        };
      })
      .addCase(fetchSingleSessionProviders.pending, (state) => {
        if (state.singleSessionProvidersLoading) {
          state.singleSessionProvidersAbortController.abort();
        }

        return {
          ...state,
          singleSessionProvidersLoading: true,
          singleSessionProvidersAbortController: new AbortController(),
        };
      })
      .addCase(fetchSingleSessionProviders.fulfilled, (state, action) => {
        const scheduledDates: Date[] = [];

        action.payload.forEach((p) => {
          p.bookDetails.bookOptions.forEach((bo) =>
            scheduledDates.push(moment(bo.scheduledDate).toDate())
          );
        });

        const maxDate = max(scheduledDates);

        return {
          ...state,
          singleSessionProviders: action.payload,
          singleSessionProvidersLoading: false,
          maxDate: maxDate || moment().startOf('day').add(30, 'day').toDate(),
        };
      })
      .addCase(fetchSingleSessionProviders.rejected, (state, action) => {
        if (action.error.message === 'AbortError') {
          return { ...state };
        }

        return {
          ...state,
          singleSessionProvidersLoading: false,
        };
      })
      .addCase(fetchClientSingleTermBookingAvailability.pending, (state) => {
        return {
          ...state,
          clientSTBRAvailabilityLoading: true,
        };
      })
      .addCase(
        fetchClientSingleTermBookingAvailability.fulfilled,
        (state, action) => {
          return {
            ...state,
            clientSTBRAvailability: action.payload,
            clientSTBRAvailabilityLoading: false,
          };
        }
      )
      .addCase(
        fetchClientSingleTermBookingAvailability.rejected,
        (state, action) => {
          return {
            ...state,
            clientSTBRAvailabilityLoading: false,
            clientSTBRAvailabilityError: action.error,
          };
        }
      )
      .addCase(fetchScheduledSessions.pending, (state) => {
        state.scheduledSessions = null;
        state.scheduledSessionsLoading = true;
      })
      .addCase(fetchScheduledSessions.fulfilled, (state, action) => {
        state.scheduledSessionsLoading = false;
        state.scheduledSessions = action.payload;
      })
      .addCase(fetchScheduledSessions.rejected, (state) => {
        state.scheduledSessionsLoading = false;
      })
      .addCase(fetchUnratedSessions.pending, (state) => {
        state.unratedSessions = [];
        state.sessionsToRate = [];
        state.unratedSessionsLoading = true;
      })
      .addCase(fetchUnratedSessions.fulfilled, (state, action) => {
        const fetchedSessions = action.payload;
        const { clientId } = action.meta.arg;

        state.unratedSessionsLoading = false;
        state.unratedSessions = fetchedSessions;

        if (!state.showSuspectedSessions) {
          state.sessionsToRate = filter(fetchedSessions, (fs) => {
            return !unratedSuspendedSessions
              .getValue(clientId)
              .includes(fs.segmentId);
          });
        } else {
          state.sessionsToRate = fetchedSessions;
        }

        state.noLatestSessionsToRate = fetchedSessions.length === 0;

        const oldUnratedSessions = unratedSuspendedSessions
          .getValue(clientId)
          .filter((suspendedSessionId) => {
            return !fetchedSessions.find(
              (fs) => fs.segmentId === suspendedSessionId
            );
          });

        unratedSuspendedSessions.remove(oldUnratedSessions, clientId);
      })
      .addCase(fetchUnratedSessions.rejected, (state) => {
        state.unratedSessionsLoading = false;
      });
  },
});

export const {
  setSelectedProvider,
  setCreateSessionSuccess,
  setToInitialState,
  addSelectedBookingOption,
  clearSelectedBookingOptions,
  removeSelectedBookingOption,
  clearScheduledSessions,
  removeSessionFromRatingQueue,
  setShowSuspectedSessions,
} = newSessionsSlice.actions;

export default newSessionsSlice.reducer;

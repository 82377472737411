import { RemoveSessionVariant } from '../types/types';

export const TIME_FORMAT = 'HH:mm:ss';

export enum DayOfWeekId {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum AvailabilityType {
  ShortTerm = 'ShortTerm',
  LongTerm = 'LongTerm',
}

export enum availabilityPreferredBlocks {
  start1 = '08:00:00',
  start3 = '15:00:00',
  end3 = '19:00:00',
}

export const CANCELLTION_EVENTS_PER_PAGE = 15;

export const RemoveSessionVariantText: Record<RemoveSessionVariant, string> = {
  [RemoveSessionVariant.cancellation]: 'Cancel',
  [RemoveSessionVariant.timeOff]: 'Time Off',
  [RemoveSessionVariant.retraction]: 'Retract',
};

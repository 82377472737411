import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { SCH_EMAIL } from '../../utils/constants';

const ClientCanNotAddSession = () => {
  return (
    <>
      <Typography>
        Good news - you are fully booked! The Add Sessions feature is only
        available to clients who are receiving services significantly less than
        their authorized amount, or to clients who have recently cancelled a
        session. Please come back to this page if you ever need to rebook a
        cancelled session or add sessions to reach your authorization.
      </Typography>
      <Typography>
        If you have any questions or if you&apos;d like to open up more
        availability, please contact{' '}
        <Link href={`mailto:${SCH_EMAIL}`}>{SCH_EMAIL}.</Link>
      </Typography>
    </>
  );
};

export default ClientCanNotAddSession;

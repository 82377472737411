import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';

import { TimeBlock } from '../../../../redux/modules/adminSettings';
import { TIME_FORMAT_24HR } from '../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    minWidth: 96,
    margin: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1.5, 0.5),
      minWidth: 64,
    },
  },
  timeText: {
    color: theme.palette.kyoGray63.main,
  },
  labelName: {
    fontSize: 16,
    fontWeight: 700,
  },
}));

interface VerticalLabelTimeBlockProps {
  value: number;
  timeBlock?: TimeBlock;
  styles?: Partial<Record<'root' | 'name' | 'content', string>>;
  periodNameLength?: number;
}

const VerticalLabelTimeBlock = (props: VerticalLabelTimeBlockProps) => {
  const { timeBlock, value, styles, periodNameLength = 1 } = props;
  const classes = useStyles();

  return (
    <div className={clsx(styles?.root, classes.labelContainer)}>
      <Typography className={clsx(styles?.name, classes.labelName)}>
        Block {value}
      </Typography>
      {!!timeBlock && (
        <Typography className={clsx(classes.timeText, styles?.content)}>
          <TimeText
            periodNameLength={periodNameLength}
            time={timeBlock.start}
            suffix=" - "
          />
          <TimeText periodNameLength={periodNameLength} time={timeBlock.end} />
        </Typography>
      )}
    </div>
  );
};

interface TimeTextProps {
  time: string;
  suffix?: string;
  periodNameLength?: number;
}

const TimeText = (props: TimeTextProps) => {
  const { time, suffix, periodNameLength = 1 } = props;
  const momentDate = moment(time, TIME_FORMAT_24HR);

  return (
    <>
      {momentDate.format('h:mm')}
      <small>
        <sup>
          {momentDate.format('a').substring(0, periodNameLength).toUpperCase()}
        </sup>
      </small>
      {suffix}
    </>
  );
};

export default VerticalLabelTimeBlock;

import { Button, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { sortBy } from 'lodash';

import { RootState } from '../../redux/modules/rootReducer';
import {
  DATE_FORMAT,
  DATE_FORMAT_WEEKDAY_MONTH,
  TIME_FORMAT_2,
  humanizeDuration,
} from '../../utils/helpers';
import { TIME_FORMAT } from '../../shared/constants';
import { useAppDispatch } from '../../redux/store';
import { removeSelectedBookingOption } from '../../redux/modules/newSessions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  indicator: {
    width: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.kyoBlue2.main,
    marginBottom: theme.spacing(1),
  },
  mainContent: {
    borderBottom: `1px solid ${theme.palette.kyoGray.main}`,
    paddingLeft: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.75),
    width: '100%',
  },
  scheduledDate: { color: theme.palette.kyoGray.dark },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.kyoGray63.main,
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.25),

    '& svg': {
      fontSize: '1rem',
    },
  },
  removeButton: { padding: 0, color: 'inherit' },
}));

const SelectedBooingOptions = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const selectedBookingOptions = useSelector(
    (state: RootState) => state.newSessions.selectedBookingOptions
  );

  const sortedSelectedBookingOptions = sortBy(selectedBookingOptions, [
    'scheduledDate',
    'timeBlockId',
  ]);

  return (
    <>
      {sortedSelectedBookingOptions.map(
        ({
          scheduledDate,
          sessionPlacement: { startTime, duration, timeBlockId },
        }) => (
          <div className={classes.root} key={`${scheduledDate}_${timeBlockId}`}>
            <div className={classes.indicator} />
            <div className={classes.mainContent}>
              <Typography variant="subtitle1" className={classes.scheduledDate}>
                {moment(scheduledDate, DATE_FORMAT).format(
                  DATE_FORMAT_WEEKDAY_MONTH
                )}
                , {moment(startTime, TIME_FORMAT).format(TIME_FORMAT_2)}
              </Typography>
              <div className={classes.footer}>
                <div className={classes.duration}>
                  <TimelapseIcon />
                  <Typography variant="subtitle2">
                    {humanizeDuration(duration)}
                  </Typography>
                </div>
                <Button
                  className={classes.removeButton}
                  onClick={() => {
                    dispatch(
                      removeSelectedBookingOption({
                        scheduledDate,
                        timeBlockId,
                      })
                    );
                  }}
                >
                  <Typography variant="body2">Remove</Typography>
                </Button>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default SelectedBooingOptions;

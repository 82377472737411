import React from 'react';
import { useSelector } from 'react-redux';
import {
  Divider,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import CalendarEvent from '../CalenderEvent/CalendarEvent';
import LocationCell from '../table/LocationCell';
import SessionTimeCell from '../table/SessionTimeCell';
import { RootState } from '../../redux/modules/rootReducer';
import { RequestType, ScheduleSegment, SessionStatus } from '../../types/types';
import { getCentralReachLink } from '../../providerPortal/sessions/SessionsForProvider';
import {
  DATE_FORMAT,
  formatSessionDuration,
  normalizeSessionLocation,
} from '../../utils/helpers';
import useOptOutAllowedUntil from '../../providerPortal/requests/hooks/useOptOutAllowedUntil';
import SubOfferAllowedUntil from '../../providerPortal/requests/subOffer/SubOfferAllowedUntil';
import OptOutButton from '../../providerPortal/requests/subOffer/OptOutButton';
import OptOutAllowedBadgeIcon from '../badges/OptOutAllowedBadge/OptOutAllowedBadgeIcon';
import { renderBadge } from './SessionCard';

const useStyles = makeStyles((theme: Theme) => ({
  sessionExpandedDetails: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'rgba(229, 229, 229, 0.5)',
    width: '100%',
  },
  plr2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
  link: {
    color: theme.palette.kyoBlue.main,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  detail: {
    margin: theme.spacing(1, 0, 2),
  },
  subtitle: {
    color: theme.palette.kyoGray63.main,
    ...theme.typography.subtitle2,
  },
  CRLink: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.kyoBlue.main,
    '& > p:first-child': {
      whiteSpace: 'nowrap',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  mb2: {
    marginBottom: theme.spacing(1.625),
  },

  line: {
    width: '1px',
    backgroundColor: theme.palette.kyoGray.main,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
  body2: {
    color: theme.palette.kyoGray63.main,
    ...theme.typography.body2,
  },
  optOutButtonHM: {
    width: '100%',
    maxWidth: theme.spacing(30.5),
    padding: theme.spacing(0.5, 2),

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: 'min-content',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingLeft: '16px',
  },
  subOfferDetailHM: {
    padding: theme.spacing(0, 2),

    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.kyoGray.main,
      padding: theme.spacing(1, 2),
    },

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  optOutButton: {
    display: 'flex',
    width: 'min-content',
    height: 'min-content',
    maxWidth: theme.spacing(30.5),
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.spacing(0.75),
    color: '#56BDCE',
    border: '1px solid #56BDCE',
  },
  subOfferDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 0, 2),
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  sessionTime: {
    color: theme.palette.grey[600],
    '& > span': {
      color: theme.palette.kyoGray63.main,
    },
  },
  sessionDiration: {
    fontSize: 10,
  },
  optOutBadge: {
    width: 120,
  },
  badgeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
}));

interface ProviderSessionDetailsProps {
  session: ScheduleSegment;
  hideButtons?: boolean;
  extendedDetails?: boolean;
  styles?: Partial<Record<'root' | 'subOfferAllowedUntil', string>>;
  horizontalMode?: boolean;
  refresh?: () => void;
}

const ProviderSessionDetails = (props: ProviderSessionDetailsProps) => {
  const {
    session,
    hideButtons,
    extendedDetails,
    styles,
    horizontalMode = false,
    refresh,
  } = props;
  const {
    segmentStart: sessionStartDate,
    segmentEnd: sessionEndDate,
    providerId,
    segmentStartUtc,
    segmentEndUtc,
    isBookRequest,
  } = session;
  const classes = useStyles();
  const theme = useTheme();
  const isFromCR = !isBookRequest;
  const isSubOfferSession = session.bookRequestType === RequestType.SubOffer;
  const { bookRequestId, id } = session;

  const roundedDistance =
    session.distanceInMiles && Math.round(session.distanceInMiles);

  const currentProvider = useSelector(
    (state: RootState) => state.provider.provider
  );

  const providerZipCode =
    currentProvider?.address?.zip || currentProvider?.homeZip || null;

  const zipCodes = [];
  if (providerZipCode) {
    zipCodes.push(providerZipCode);
  }
  zipCodes.push(String(session.client.zipCode));

  const optOutAllowedUntil = useOptOutAllowedUntil(session.createdAt || '');
  const showOptOutAllowed =
    isSubOfferSession &&
    session.status === SessionStatus.Pending &&
    bookRequestId &&
    id;

  const startTime = moment(segmentStartUtc);
  const endTime = moment(segmentEndUtc);

  const duration = formatSessionDuration(session).replace(/hours|hour/, 'hr');

  return (
    <div className={clsx(styles?.root, classes.sessionExpandedDetails)}>
      {!hideButtons && (
        <>
          <div className={clsx(classes.flex, classes.plr2)}>
            <CalendarEvent
              className={classes.link}
              component="button"
              variant="body2"
              title="KyoCare Session"
              startDate={new Date(sessionStartDate)}
              endDate={new Date(sessionEndDate)}
            >
              Add to iCal
            </CalendarEvent>
            <CalendarEvent
              calendarType="google"
              component="button"
              variant="body2"
              className={classes.link}
              title="KyoCare Session"
              startDate={new Date(sessionStartDate)}
              endDate={new Date(sessionEndDate)}
            >
              Add to Google
            </CalendarEvent>
          </div>
        </>
      )}

      {!horizontalMode && (
        <div className={classes.title}>
          <Typography variant="subtitle2" className={classes.sessionTime}>
            {moment(segmentStartUtc).format('ddd MMM D')},{' '}
            {startTime.format('h:mm')}
            {startTime.format('A')}-{endTime.format('h:mm')}
            {endTime.format('A')} ({duration})
            <br />
            <Typography component="span" variant="subtitle2">
              {` ${session.clientId ? `with ${session.clientId}` : ''}`}
            </Typography>
          </Typography>
          <div className={classes.badgeWrapper}>
            {session.billingCode && renderBadge(theme, session.billingCode)}
            {showOptOutAllowed && (
              <OptOutAllowedBadgeIcon
                className={classes.optOutBadge}
                statusId={session.status}
              />
            )}
          </div>
        </div>
      )}

      {extendedDetails && (
        <div className={horizontalMode ? classes.flex : ''}>
          {showOptOutAllowed ? (
            <>
              <div
                className={clsx(
                  classes.detail,
                  horizontalMode
                    ? classes.subOfferDetailHM
                    : classes.subOfferDetail
                )}
              >
                <SubOfferAllowedUntil
                  allowedUntil={optOutAllowedUntil}
                  rootClassName={clsx(
                    horizontalMode && classes.mb2,
                    styles?.subOfferAllowedUntil,
                    classes.flexColumn
                  )}
                />
                <OptOutButton
                  optedOut={false}
                  requestId={bookRequestId}
                  sessionId={id}
                  handleSessionUpdate={refresh}
                  canBeOptedOut={moment().isSameOrBefore(optOutAllowedUntil)}
                  buttonClassName={
                    horizontalMode
                      ? classes.optOutButtonHM
                      : classes.optOutButton
                  }
                />
              </div>
              {!horizontalMode && <Divider />}
              {horizontalMode && <div className={classes.line} />}
            </>
          ) : (
            <></>
          )}
          <div className={clsx(classes.detail, classes.plr2)}>
            <Typography
              className={clsx(classes.subtitle, horizontalMode && classes.mb2)}
            >
              Care Supervisor
            </Typography>
            <Typography variant="body2">
              {session.client.supervisor?.name}
            </Typography>
            <Typography className={classes.body2}>
              {session.client.supervisor?.email}
            </Typography>
          </div>
          {horizontalMode && <div className={classes.line} />}
          <div className={clsx(classes.detail, classes.plr2)}>
            <Typography
              className={clsx(classes.subtitle, horizontalMode && classes.mb2)}
            >
              Client
            </Typography>
            {session.clientName && (
              <Typography variant="body2">{session.clientName}</Typography>
            )}
            <Typography className={classes.body2}>
              {`ID: ${session.clientId}`}
            </Typography>
          </div>
          {horizontalMode && <div className={classes.line} />}
          <div className={clsx(classes.detail, classes.plr2)}>
            <Typography
              className={clsx(classes.subtitle, horizontalMode && classes.mb2)}
            >
              Location
            </Typography>
            <LocationCell
              zip={session.client.zipCode}
              locationNotes={session.client.attributes?.locationNotes}
              distance={roundedDistance}
              points={[
                {
                  latitude: session.provider?.attributes?.zipCodeData?.latitude,
                  longitude:
                    session.provider?.attributes?.zipCodeData?.longitude,
                },
                {
                  latitude: session.client.zipCodeData?.latitude,
                  longitude: session.client.zipCodeData?.longitude,
                },
              ]}
              zipCodes={zipCodes}
              providerAddress={session.provider?.address}
              sessionAddress={normalizeSessionLocation(session)}
              compact
            />
          </div>
          {!horizontalMode && (
            <div className={clsx(classes.detail, classes.plr2)}>
              <Typography className={classes.subtitle}>Session Time</Typography>
              <SessionTimeCell session={session} />
            </div>
          )}
          {!horizontalMode && isFromCR && providerId && (
            <div className={clsx(classes.detail, classes.plr2)}>
              {getCentralReachLink({
                id: providerId,
                date: moment(segmentStartUtc).format(DATE_FORMAT),
                styles: classes.CRLink,
              })}
            </div>
          )}
        </div>
      )}

      {hideButtons && !extendedDetails && (
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.plr2}
        >
          No details to show
        </Typography>
      )}
    </div>
  );
};

export default ProviderSessionDetails;

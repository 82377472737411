import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import {
  Button,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router';

import { TIME_FORMAT, TIME_FORMAT_24HR } from '../../../utils/helpers';
import { ClientSchedule } from '../../../types/types';
import { PageModeParam } from '../../../commonPages/MySchedule/types';

const useStyles = makeStyles((theme) => ({
  content: {
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  infoText: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: 14,
  },
  preference: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.body2,
    },
  },
  time: {
    borderBottom: '1px dashed #414141',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  textSmall: {
    fontSize: 14,
    '& > span': {
      fontSize: 14,
    },
  },
  linkButton: {
    fontSize: 14,
    textTransform: 'none',
    display: 'block',
    padding: 0,
    color: theme.palette.kyoBlue.main,
    '&:hover': {
      backgroundColor: theme.palette.kyoBlue.contrastText,
    },
  },
}));

const getPreferredTimes = (schedule: ClientSchedule[], timeBlockId: number) => {
  const { preferredStartTime, preferredEndTime } =
    schedule.find(
      (s) =>
        s.timeBlockId === timeBlockId &&
        s.preferredStartTime &&
        s.preferredEndTime
    ) || {};
  return { preferredEndTime, preferredStartTime };
};

const emptyBlockMessage = 'N/A';

interface AvailabilityInfoProps {
  schedule: ClientSchedule[];
  popup?: boolean;
  onTimeClick?: () => void;
}

const AvailabilityInfo = ({
  schedule,
  popup = false,
  onTimeClick,
}: AvailabilityInfoProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    preferredStartTime: block3Start,
    preferredEndTime: block3End,
  } = getPreferredTimes(schedule, 3);

  const showBlockTime = (time?: string) => {
    return time
      ? moment(time, TIME_FORMAT_24HR).format(TIME_FORMAT)
      : emptyBlockMessage;
  };
  const { preferredStartTime: block1Start } = getPreferredTimes(schedule, 1);

  return (
    <DialogContent className={classes.content}>
      {popup && (
        <Typography className={classes.infoText}>
          Your availability should include blocks where you can provide at least
          3 hours of availability.
        </Typography>
      )}
      {popup && (
        <Typography className={classes.infoText}>
          We also use the following preferences for morning and afternoon
          sessions:
        </Typography>
      )}
      <Typography
        className={clsx({
          [classes.preference]: true,
          [classes.textSmall]: popup,
        })}
      >
        I cannot start my day before:{' '}
        <Typography
          component="span"
          onClick={onTimeClick}
          onKeyDown={onTimeClick}
          role="button"
          tabIndex={0}
          className={!popup ? classes.time : ''}
        >
          {showBlockTime(block1Start)}
        </Typography>
      </Typography>

      <Typography
        className={clsx({
          [classes.preference]: true,
          [classes.textSmall]: popup,
        })}
      >
        My child is home from school by:{' '}
        <Typography
          component="span"
          onClick={onTimeClick}
          onKeyDown={onTimeClick}
          role="button"
          tabIndex={0}
          className={!popup ? classes.time : ''}
        >
          {showBlockTime(block3Start)}
        </Typography>
      </Typography>

      <Typography
        className={clsx({
          [classes.preference]: true,
          [classes.textSmall]: popup,
        })}
      >
        I cannot accommodate sessions past:{' '}
        <Typography
          component="span"
          onClick={onTimeClick}
          onKeyDown={onTimeClick}
          role="button"
          tabIndex={0}
          className={!popup ? classes.time : ''}
        >
          {showBlockTime(block3End)}
        </Typography>
      </Typography>
      {popup && (
        <div className={classes.infoText}>
          <Button
            disableRipple
            className={classes.linkButton}
            color="primary"
            onClick={() => {
              navigate(`/schedule?page-mode=${PageModeParam.standard}`);
            }}
          >
            Update My Availability
          </Button>
        </div>
      )}
    </DialogContent>
  );
};

export default AvailabilityInfo;

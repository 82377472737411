import { Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import CustomStyledInfoCard from '../../../components/Availability/AvailabilityActions/CustomStyledInfoCard';
import useStyles from '../AvailabilityActionsClient/AvailabilityActionsClientStyles';
import { AvailabilityHoursStatus } from '../../../components/Availability/AvailableHoursWrapper';
import InfoIdea from '../../../assets/info-idea.svg';
import Warning from '../../../assets/warning.svg';
import WarningYellow from '../../../assets/warningYellow.svg';
import { AvailabilityStatusColors } from '../AvailabilityActionsClient/AvailabilityActionsClient';

const useAvailHoursInfo = () => {
  const classes = useStyles();

  const increaseAvailText = (
    <Typography variant="body2" className={classes.bold}>
      Please increase your availability.
    </Typography>
  );

  return {
    [AvailabilityHoursStatus.Bad]: {
      card: (
        <CustomStyledInfoCard
          className={classes.noneShadow}
          image={Warning}
          variant="error"
        >
          <div className={classes.flexColumn}>
            {increaseAvailText}
            <Typography variant="body2">
              Kyo policy requires a minimum of{' '}
              <span className={classes.bold}>4 weekday blocks</span>
            </Typography>
          </div>
        </CustomStyledInfoCard>
      ),
      circleColors() {
        return {
          startColor: AvailabilityStatusColors.Bad,
          middleColor: AvailabilityStatusColors.Bad,
          endColor: AvailabilityStatusColors.Bad,
        };
      },
    },
    [AvailabilityHoursStatus.Normal]: {
      card: (
        <CustomStyledInfoCard
          className={classes.noneShadow}
          image={WarningYellow}
          variant="info"
        >
          <div className={classes.flexColumn}>
            {increaseAvailText}
            <Typography variant="body2">
              Adding more availability improves chances we can meet your
              authorization.
            </Typography>
          </div>
        </CustomStyledInfoCard>
      ),
      circleColors(progress: number) {
        return {
          startColor:
            progress < 34
              ? AvailabilityStatusColors.Normal
              : AvailabilityStatusColors.Bad,
          middleColor: AvailabilityStatusColors.Normal,
          endColor: AvailabilityStatusColors.Good,
        };
      },
    },
    [AvailabilityHoursStatus.Good]: {
      card: (
        <CustomStyledInfoCard
          className={clsx(classes.noneShadow, classes.availHoursInfo)}
          image={InfoIdea}
          variant="success"
        >
          <Typography variant="body2">
            Thanks for providing availability and improving your chances we can
            meet your authorization.
          </Typography>
        </CustomStyledInfoCard>
      ),
      circleColors() {
        return {
          startColor: AvailabilityStatusColors.Bad,
          middleColor: AvailabilityStatusColors.Normal,
          endColor: AvailabilityStatusColors.Good,
        };
      },
    },
  };
};

export default useAvailHoursInfo;

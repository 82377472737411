import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  Slide,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@material-ui/core/transitions';
import clsx from 'clsx';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: theme.spacing(70),
    margin: 'auto',
  },
  dialogPaper: {
    borderRadius: theme.spacing(1.5),
  },
  title: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.kyoGray.main,
  },
  closeIcon: {
    padding: 0,
    color: 'black',
  },
  content: {
    padding: theme.spacing(2),
    '&::-webkit-scrollbar': {
      width: theme.spacing(1.25),
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.kyoGray.light,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.kyoGray.main,
    },
  },
}));

interface SimpleDialogProps {
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  styles?: Partial<Record<'root' | 'title' | 'content' | 'paper', string>>;
  dialogProps?: Omit<DialogProps, 'open'>;
  open?: boolean;
}

const SimpleDialog = ({
  onClose,
  title = '',
  children,
  styles,
  dialogProps,
  open = true,
}: SimpleDialogProps) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={onClose}
        className={styles?.root}
        classes={{
          root: classes.dialog,
          paper: clsx(classes.dialogPaper, styles?.paper),
        }}
        {...dialogProps}
      >
        <DialogTitle
          disableTypography
          className={clsx(classes.title, styles?.title)}
        >
          <Typography variant="h6" color="textSecondary">
            {title}
          </Typography>
          <IconButton
            className={classes.closeIcon}
            onClick={onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className={clsx(classes.content, styles?.content)}>{children}</div>
      </Dialog>
    </>
  );
};

export default SimpleDialog;

import React from 'react';

import DatePicker, {
  CalendarProps,
  DatePickerProps,
} from 'react-multi-date-picker';
import './rmdp.css';

const StyledMultipleDatePicker = (props: CalendarProps & DatePickerProps) => {
  const { style } = props;
  return (
    <DatePicker
      {...props}
      className="custom"
      showOtherDays
      style={{
        background: '#E0E0E0',
        height: '36px',
        borderRadius: '8px',
        border: '#E0E0E0',
        fontSize: '14px',
        padding: '3px 10px',
        width: 185,
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#414141',
        ...style,
      }}
    />
  );
};

export default StyledMultipleDatePicker;

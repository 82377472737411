import { without } from 'lodash';

class UnratedSuspendedSessions {
  private static instance: UnratedSuspendedSessions;

  private storageKey = 'unratedSuspendedSessions';

  public static getInstance() {
    if (!UnratedSuspendedSessions.instance) {
      return new UnratedSuspendedSessions();
    }
    return UnratedSuspendedSessions.instance;
  }

  public add(segmentId: number, clientId: number) {
    const clientSuspectedSessions = this.getValue(clientId);

    if (clientSuspectedSessions.includes(segmentId)) {
      return this;
    }

    clientSuspectedSessions.push(segmentId);
    this.pullToStorage(clientId, clientSuspectedSessions);
    return this;
  }

  public remove(segmentIds: number[], clientId: number) {
    const clientSuspectedSessions = this.getValue(clientId);

    const newUnratedSessions = without(clientSuspectedSessions, ...segmentIds);

    if (newUnratedSessions.length === 0) {
      localStorage.removeItem(this.generateKey(clientId));
      return this;
    }

    this.pullToStorage(clientId, newUnratedSessions);
    return this;
  }

  public getValue(clientId: number) {
    return (
      localStorage
        .getItem(this.generateKey(clientId))
        ?.split(',')
        .map(Number) || []
    );
  }

  private pullToStorage(clientId: number, ids: number[]) {
    localStorage.setItem(this.generateKey(clientId), ids.toString());
  }

  private generateKey(clientId: number) {
    return `${this.storageKey}_${clientId}`;
  }
}

export default UnratedSuspendedSessions.getInstance();

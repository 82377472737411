import React, { useState } from 'react';
import { Avatar, Typography, Box } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { useChannelStateContext } from 'stream-chat-react';

import './TeamChannelHeader.css';
import type {
  ProviderChatUserType,
  TeamAttachmentType,
  TeamChannelType,
  TeamCommandType,
  TeamEventType,
  TeamMessageType,
  TeamReactionType,
  TeamUserType,
} from '../types';
import ChatMembersModal from './ChatMembersModal';

export const TeamChannelHeader: React.FC = () => {
  const { channel, members } = useChannelStateContext<
    TeamAttachmentType,
    TeamChannelType,
    TeamCommandType,
    TeamEventType,
    TeamMessageType,
    TeamReactionType,
    TeamUserType
  >();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const teamHeader = `${
    channel?.data?.name || channel?.data?.id || 'Care team'
  }`;
  const memberList = members ? Object.values(members) : [];
  const profilePhotos = memberList.map((m) => m?.user?.profilePhoto || '');
  const numOfMembers = memberList.length;
  const memberProfiles = memberList
    .reduce<ProviderChatUserType[]>((prev, current) => {
      if (current.user) {
        prev.push({
          id: current.user.attainProviderId || +current.user.id,
          name: current.user.name || '',
          firstName: current.user.firstName || current.user.name || '',
          lastName: current.user.lastName || '',
          title: current.user.title || '',
          covidVaccinatedDate: current.user.covidVaccinatedDate || '',
          attributes: current.user.attributes,
          profilePhoto: current.user.profilePhoto || '',
          chatUserId: current.user_id!,
          userType: current.user.userType,
        });
      }
      return prev;
    }, [])
    .sort((a, _) => {
      if (a.userType === 'clientUser') {
        return -1;
      }
      if (a.userType === 'provider') {
        return 1;
      }

      return 0;
    });

  return (
    <>
      <div className="team-channel-header__container">
        <div className="team-channel-header__channel-wrapper">
          <Typography variant="subtitle2">{teamHeader}</Typography>
          <Box
            role="alertdialog"
            className="team-channel-header__channel-wrapper-avatars"
            onClick={() => setIsModalOpen(true)}
          >
            <AvatarGroup spacing="small">
              {profilePhotos.map((photo, i) =>
                photo ? (
                  <Avatar src={photo} key={photo} style={{ zIndex: i }} />
                ) : null
              )}
            </AvatarGroup>
            <Typography className="team-channel-header__channel-wrapper-avatars-count">
              {numOfMembers ? `${numOfMembers} members` : ''}
            </Typography>
          </Box>
        </div>
      </div>

      <ChatMembersModal
        isOpen={isModalOpen}
        members={memberProfiles}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

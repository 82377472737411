import moment from 'moment';
import { useMemo } from 'react';
import {
  Availability,
  AvailabilityBlock,
  AVAILABILITY_ACTION_STATUS,
  createAvailability,
  setActionStatus,
  updateAvailability,
} from '../../../redux/modules/availability';
import { useAppDispatch } from '../../../redux/store';
import { AvailabilityType } from '../../../shared/constants';
import { sortAvailabilityBlocks } from '../../../shared/lib/availability';
import {
  DATE_FORMAT,
  DATE_FORMAT_US,
  ModifiedAvailabilityTimeOffMaxStartDate,
  ModifiedAvailabilityTimeOffMinStartDate,
} from '../../../utils/helpers';
import { ModifiedAvailabilityViewState } from '../constants';

interface UseTemporaryAvailabilityActionsArgs {
  availability?: Availability;
  viewState: ModifiedAvailabilityViewState;
}

export interface InitialValues {
  startDate: string;
  endDate: string;
  availabilityBlocks: Partial<AvailabilityBlock>[];
  preferredBlock1Start?: string;
  preferredBlock3Start?: string;
  preferredBlock3End?: string;
}

const useTemporaryAvailabilityActions = ({
  availability,
  viewState,
}: UseTemporaryAvailabilityActionsArgs) => {
  const dispatch = useAppDispatch();

  return useMemo(() => {
    const onAddSubmit = ({
      startDate,
      endDate,
      availabilityBlocks,
      preferredBlock1Start,
      preferredBlock3Start,
      preferredBlock3End,
    }: InitialValues) => {
      dispatch(
        createAvailability({
          startDate: moment(startDate, DATE_FORMAT_US).format(DATE_FORMAT),
          endDate: moment(endDate, DATE_FORMAT_US).format(DATE_FORMAT),
          availabilityBlocks,
          type: AvailabilityType.ShortTerm,
          providerId: availability?.providerId,
          clientId: availability?.clientId,
          preferredBlock1Start,
          preferredBlock3Start,
          preferredBlock3End,
        })
      );
    };

    const onEditSubmit = ({
      startDate,
      endDate,
      availabilityBlocks,
      preferredBlock1Start,
      preferredBlock3Start,
      preferredBlock3End,
    }: InitialValues) => {
      if (!availability) {
        return;
      }

      dispatch(
        updateAvailability({
          id: availability.id,
          availability: {
            startDate: moment(startDate, DATE_FORMAT_US).format(DATE_FORMAT),
            endDate: moment(endDate, DATE_FORMAT_US).format(DATE_FORMAT),
            availabilityBlocks,
            status: availability.status,
            preferredBlock1Start,
            preferredBlock3Start,
            preferredBlock3End,
          },
        })
      );
    };

    if (!availability) {
      return undefined;
    }

    const availBlocks = sortAvailabilityBlocks(availability.availabilityBlocks);

    const minTimeOffStartDate = ModifiedAvailabilityTimeOffMinStartDate.format(
      DATE_FORMAT_US
    );
    const maxTimeOffStartDate = ModifiedAvailabilityTimeOffMaxStartDate.format(
      DATE_FORMAT_US
    );

    const minStartDate = moment().utc().startOf('week');
    const maxStartDate = minStartDate.clone().add(8, 'months').endOf('week');

    const handleClose = () => {
      dispatch(setActionStatus({ status: AVAILABILITY_ACTION_STATUS.PASSIVE }));
    };

    const commonInitialValues = {
      availabilityBlocks: availBlocks,
      preferredBlock1Start: availability?.preferredBlock1Start,
      preferredBlock3Start: availability?.preferredBlock3Start,
      preferredBlock3End: availability?.preferredBlock3End,
    };

    const commonData = {
      handleClose,
      minTimeOffStartDate,
      maxTimeOffStartDate,
      minStartDate: minStartDate.format(DATE_FORMAT_US),
      maxStartDate: maxStartDate.format(DATE_FORMAT_US),
    };

    const isUnavailableEntirePeriod =
      viewState === ModifiedAvailabilityViewState.UnavailableEntirePeriod;

    return {
      [AVAILABILITY_ACTION_STATUS.ADD_TEMPORARY]: {
        initialValues: {
          ...commonInitialValues,
          startDate: isUnavailableEntirePeriod
            ? minTimeOffStartDate
            : commonData.minStartDate,
          endDate: isUnavailableEntirePeriod
            ? minTimeOffStartDate
            : minStartDate.endOf('week').format(DATE_FORMAT_US),
        },
        onSubmit: onAddSubmit,
        ...commonData,
      },
      [AVAILABILITY_ACTION_STATUS.EDIT_TEMPORARY]: {
        initialValues: {
          ...commonInitialValues,
          startDate: moment(availability.startDate).format(DATE_FORMAT_US),
          endDate: moment(availability.endDate).format(DATE_FORMAT_US),
        },
        onSubmit: onEditSubmit,
        ...commonData,
      },
    } as {
      [key in AVAILABILITY_ACTION_STATUS]: {
        initialValues: InitialValues;
        minTimeOffStartDate: string;
        maxTimeOffStartDate: string;
        minStartDate: string;
        maxStartDate: string;
        onSubmit: (args: InitialValues) => void;
        handleClose: () => void;
      };
    };
  }, [availability, viewState, dispatch]);
};
export default useTemporaryAvailabilityActions;

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useSelector } from 'react-redux';

import ActionsControlButton from '../../buttons/ActionsControlButton';
import {
  Availability,
  AvailabilityBlock,
  clearScheduledSessions,
  fetchScheduledSessions,
} from '../../../redux/modules/availability';
import AvailabilityMatrix from '../../Matrix/AvailabilityMatrix';
import { ClientSchedule, ProviderSchedule } from '../../../types/types';
import ScheduleAndCheckboxSvg from '../../../assets/scheduleAndCheckbox.svg';
import ScheduleAndFlagSvg from '../../../assets/scheduleAndFlag.svg';
import ScheduleAndFinishFlagSvg from '../../../assets/scheduleAndFinishFlag.svg';
import DataRow from './DataRow';
import { DATE_FORMAT, DATE_FORMAT_US } from '../../../utils/helpers';
import MatrixInfo from '../../Matrix/MatrixInfo';
import { Preference } from '../../../clientPortal/myAvailability/UserAvailability';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/modules/rootReducer';
import useAvailableHours from '../hooks/useAvailableHours';
import { AVAILABILITY_DATE_PRESENT_FORMAT } from '../constants';
import useSettings from '../../../hooks/useSettings';
import {
  getLastPastBlock,
  getTimeBlockDayOfWeekKey,
} from '../../../shared/lib/availability';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: theme.spacing(70),
    margin: 'auto',
  },
  dialogPaper: {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
  },
  title: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.kyoGray.main,
  },
  closeIcon: {
    padding: 0,
  },
  content: {
    padding: theme.spacing(2),
    '&::-webkit-scrollbar': {
      width: theme.spacing(1.25),
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.kyoGray.light,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.kyoGray.main,
    },
  },
  matrixWrapper: {
    margin: theme.spacing(4, 0, 0),
  },
  matrixInfo: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'end',
  },
  preference: {
    marginTop: theme.spacing(2),
    color: theme.palette.kyoGray63.dark,
    fontSize: theme.spacing(2),
  },
  matrixInfoWrapper: {
    margin: theme.spacing(2, 2.5),
  },
  actionsWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      gap: theme.spacing(2),
    },
  },
}));

export interface TemporaryChangesReviewDialogWrapperMainProps {
  handleClose: () => void;
  handleSave: () => void;
  availabilityBlocks: Partial<AvailabilityBlock>[];
  schedule?: ClientSchedule[] | ProviderSchedule[];
  startDate?: string;
  endDate?: string;
  unavailableEntirePeriod?: boolean;
  preferences?: Pick<
    Availability,
    'preferredBlock1Start' | 'preferredBlock3Start' | 'preferredBlock3End'
  >;
  isCancellableByClient?: boolean;
  isCancellableByProvider?: boolean;
}

interface TemporaryChangesReviewDialogWrapperProps
  extends TemporaryChangesReviewDialogWrapperMainProps {
  children: React.ReactNode;
}

const TemporaryChangesReviewDialogWrapper = ({
  handleClose,
  handleSave,
  availabilityBlocks,
  schedule,
  startDate,
  endDate,
  preferences,
  unavailableEntirePeriod = false,
  isCancellableByClient,
  isCancellableByProvider,
  children,
}: TemporaryChangesReviewDialogWrapperProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const currentClient = useSelector(
    (state: RootState) => state.client.currentClient
  );
  const currentProvider = useSelector(
    (state: RootState) => state.provider.provider
  );

  const { timeBlocks } = useSettings();

  const scheduledSessionsLoading = useSelector((state: RootState) => {
    return state.availability.scheduledSessionsLoading;
  });

  const { weekdayBlocks } = useAvailableHours({
    availabilityBlocks: availabilityBlocks as AvailabilityBlock[],
  });

  const formattedStartDate = moment(startDate, DATE_FORMAT_US).format(
    AVAILABILITY_DATE_PRESENT_FORMAT
  );
  const formattedEndDate = moment(endDate, DATE_FORMAT_US).format(
    AVAILABILITY_DATE_PRESENT_FORMAT
  );

  const clientId = currentClient?.id;
  const providerId = currentProvider?.id;

  const onClose = () => {
    dispatch(clearScheduledSessions());
    handleClose();
  };

  useEffect(() => {
    if ((!clientId && !providerId) || !startDate) {
      return;
    }

    const unavailableBlocks = availabilityBlocks.filter(
      (block) => !block.isAvailable
    );

    const availableWholePeriod = unavailableBlocks.length === 0;
    if (availableWholePeriod) {
      return;
    }

    const lastPastBlock = getLastPastBlock(startDate, timeBlocks);
    const timeBlockDayOfWeekId = getTimeBlockDayOfWeekKey(lastPastBlock);

    const unavailableBlocksTimeBlockDayOfWeekIds = unavailableBlocks
      .filter(
        (block) =>
          block.timeBlockDayOfWeekId &&
          block.timeBlockDayOfWeekId > timeBlockDayOfWeekId
      )
      .map((block) => block.timeBlockDayOfWeekId as number);

    dispatch(
      fetchScheduledSessions({
        clientId,
        providerId,
        startDate: moment(startDate, DATE_FORMAT_US).format(DATE_FORMAT),
        endDate: moment(endDate, DATE_FORMAT_US).format(DATE_FORMAT),
        includeSessionBlocks: true,
        timeBlockDayOfWeekIds: unavailableEntirePeriod
          ? []
          : unavailableBlocksTimeBlockDayOfWeekIds,
        isCancellableByClient,
        isCancellableByProvider,
      })
    );
  }, [
    clientId,
    providerId,
    startDate,
    endDate,
    unavailableEntirePeriod,
    availabilityBlocks,
    timeBlocks,
    isCancellableByClient,
    isCancellableByProvider,
    dispatch,
  ]);

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        classes={{ root: classes.dialog, paper: classes.dialogPaper }}
      >
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h6" color="textSecondary">
            Review your Changes
          </Typography>
          <IconButton
            className={classes.closeIcon}
            onClick={onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DataRow
            imageSrc={ScheduleAndCheckboxSvg}
            label="Availability"
            value={
              unavailableEntirePeriod
                ? 'Not Available'
                : `${weekdayBlocks} weekday blocks`
            }
          />
          {!unavailableEntirePeriod && (
            <div className={classes.matrixWrapper}>
              <AvailabilityMatrix
                schedule={schedule}
                availabilityBlocks={availabilityBlocks}
                cellSizes={isMobile ? 3.5 : 4.5}
                disableWeekends
              />
              <div className={classes.matrixInfoWrapper}>
                <MatrixInfo rootClassName={classes.matrixInfo} />
                {preferences && (
                  <Preference
                    preferences={preferences}
                    styles={{
                      preference: classes.preference,
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <Divider />

          <DataRow
            imageSrc={ScheduleAndFlagSvg}
            label="From"
            value={formattedStartDate}
          />
          <DataRow
            imageSrc={ScheduleAndFinishFlagSvg}
            label="To"
            value={formattedEndDate}
          />
          <Divider />

          {children}

          <ActionsControlButton
            styles={{ buttonWrapper: classes.actionsWrapper }}
            disabled={{
              successButton: scheduledSessionsLoading,
            }}
            handleClick={{
              successButton: () => {
                handleSave();
                onClose();
              },
              cancelButton: () => {
                onClose();
              },
            }}
            label={{
              successButton: 'Looks Good! Submit',
              cancelButton: 'Changed My Mind',
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TemporaryChangesReviewDialogWrapper;

export const ScheduleSessionsWarning = ({
  scheduleSessionsLength,
  unavailableEntirePeriod = false,
}: {
  scheduleSessionsLength?: number;
  unavailableEntirePeriod?: boolean;
}) => {
  return (
    <Typography variant="body2">
      {unavailableEntirePeriod ? (
        <>
          We will remove the following sessions during the time you are
          unavailable. We will not add any new sessions during your time off.
        </>
      ) : (
        <>
          We will need to remove the following{' '}
          <b>{scheduleSessionsLength} sessions</b> from your schedule during
          this period. We will not add any new sessions during your time off.
        </>
      )}
    </Typography>
  );
};

import {
  Divider,
  FormControlLabel,
  makeStyles,
  Radio,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles<
  Theme,
  { optionsLength: number; checkedOptionIndex: number }
>((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    display: 'grid',
    gridTemplateColumns: '39% 59%',
    gap: theme.spacing(2),
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '30% 70%',
    },

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  controlLabel: {
    alignItems: 'start',
  },
  radioButton: {
    color: theme.palette.kyoBlue2.main,
    '&.Mui-checked': {
      color: theme.palette.kyoBlue2.main,
    },
  },
  checked: {
    '&, & p': {
      ...theme.typography.subtitle1,
    },
  },
  main: ({ optionsLength }) => ({
    gridColumnStart: 2,
    gridRow: `1/${optionsLength + 2}`,
  }),
  mobileMain: ({ checkedOptionIndex }) => ({
    gridColumnStart: 1,
    gridRowStart: checkedOptionIndex + 2,
  }),
  divider: {
    marginBottom: theme.spacing(2),
  },
}));

export interface RadioViewProps<T> {
  styles?: Partial<Record<'root' | 'main' | 'checkedOption', string>>;
  options: { value: T; label: React.ReactNode }[];
  value: T;
  onChange: (value: T) => void;
  children: React.ReactNode;
}

const RadioView = <T extends string | number>({
  styles,
  options,
  value,
  onChange,
  children,
}: RadioViewProps<T>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const checkedOptionIndex = options.findIndex(
    ({ value: optionValue }) => optionValue === value
  );
  const classes = useStyles({
    optionsLength: options.length,
    checkedOptionIndex,
  });

  return (
    <div className={clsx(styles?.root, classes.root)}>
      {options.map(({ label, value: optionValue }, index) => {
        const checked = index === checkedOptionIndex;
        return (
          <FormControlLabel
            key={optionValue}
            className={classes.controlLabel}
            checked={checked}
            control={<Radio className={classes.radioButton} />}
            onChange={() => onChange(optionValue)}
            label={
              <div
                className={clsx(
                  checked && styles?.checkedOption,
                  checked && classes.checked
                )}
              >
                {label}
              </div>
            }
          />
        );
      })}
      <main
        className={clsx(
          styles?.main,
          classes.main,
          isMobile && classes.mobileMain
        )}
      >
        {isMobile && <Divider className={classes.divider} />}
        {children}
      </main>
    </div>
  );
};

export default RadioView;

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { Typography, makeStyles, Theme, Link } from '@material-ui/core';
import GreenLamp from '../../assets/lamp-green.svg';
import { KYO_EMAIL } from '../../utils/constants';
import LinkComp from '../Link/LinkComp';

const useStyles = makeStyles((theme: Theme) => ({
  alert: (styles: React.CSSProperties) => ({
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.kyoGreen.light,
    backgroundColor: styles.backgroundColor,
    minHeight: theme.spacing(8.25),
    padding: styles.padding,
    borderRadius: theme.spacing(1),
  }),
  text: {
    color: theme.palette.kyoYellow.contrastText,
    borderRadius: theme.spacing(1.5),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.kyoBlue.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface TimeOffInfoType {
  type: 'client' | 'provider';
  styles?: React.CSSProperties;
}

const TimeOffInfo = ({ type, styles = {} }: TimeOffInfoType) => {
  const classes = useStyles(styles);
  const attr =
    type === 'client'
      ? { to: '/my-availability' }
      : { href: `mailto:${KYO_EMAIL}` };

  return (
    <Alert
      icon={<img alt="warning" src={GreenLamp} />}
      severity="success"
      className={classes.alert}
    >
      <Typography className={classes.text} variant="body2" color="primary">
        <Typography display="inline" variant="subtitle1">
          Taking time off?
        </Typography>{' '}
        If your availability is changing,{' '}
        <LinkComp
          Comp={type === 'client' ? RouterLink : Link}
          className={classes.link}
          attr={attr}
        >
          let us know.
        </LinkComp>{' '}
        We’ll remove sessions and won’t book new sessions during your time off.
      </Typography>
    </Alert>
  );
};

export default TimeOffInfo;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import MatrixCell, { MatrixCellProps } from '../../../shared/matrix/ui/cell';
import SelectTimesDialog from '../dialogs/SelectTimesDialog';
import { STBRBlockOption } from '../../../types/types';
import { SessionPlacement } from '../../../redux/modules/adminSettings';
import { useAppDispatch } from '../../../redux/store';
import {
  SelectedBookingOption,
  addSelectedBookingOption,
} from '../../../redux/modules/newSessions';
import { TIME_FORMAT_24HR } from '../../../utils/helpers';

export const AVAILABLE_ICON_COLOR = '#3D90FB';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E5EFFD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  icon: {
    color: AVAILABLE_ICON_COLOR,
  },
  disabled: {
    opacity: 0.5,
    cursor: 'initial',
  },
}));

const AvailableCell = ({
  className,
  bookOption,
  selectedOptionAfter,
  selectedOptionBefore,
  disable = false,
}: Omit<MatrixCellProps, 'children'> & {
  bookOption: STBRBlockOption;
  selectedOptionAfter?: SelectedBookingOption;
  selectedOptionBefore?: SelectedBookingOption;
  disable?: boolean;
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState(false);

  const handleSave = (sessionPlacement: SessionPlacement) => {
    const selectedBookOption: SelectedBookingOption = cloneDeep({
      ...bookOption,
      sessionPlacement,
      sessionPlacements: undefined,
    });

    dispatch(addSelectedBookingOption(selectedBookOption));
  };

  const getPlacementEndDate = (
    startTime: string | null,
    duration: number | null
  ) => {
    return moment(startTime, TIME_FORMAT_24HR)
      .add((duration || 0) * 60, 'minutes')
      .format(TIME_FORMAT_24HR);
  };

  const sessionPlacementGreaterThan =
    selectedOptionBefore &&
    getPlacementEndDate(
      selectedOptionBefore.sessionPlacement.startTime,
      selectedOptionBefore.sessionPlacement.duration
    );

  const sessionPlacementSmallerThan =
    selectedOptionAfter && selectedOptionAfter.sessionPlacement.startTime;

  const disabledSessionPlacementIds = bookOption.sessionPlacements
    .filter((sessionPlacement) => {
      const { startTime, duration } = sessionPlacement;
      const endTime = getPlacementEndDate(startTime, duration);

      if (
        sessionPlacementGreaterThan &&
        startTime &&
        startTime < sessionPlacementGreaterThan
      ) {
        return true;
      }

      if (
        sessionPlacementSmallerThan &&
        endTime > sessionPlacementSmallerThan
      ) {
        return true;
      }

      return false;
    })
    .map((sessionPlacement) => sessionPlacement.id);

  return (
    <>
      <MatrixCell
        className={clsx(classes.root, className, disable && classes.disabled)}
        onClick={() => !disable && setSelected(true)}
      >
        <AddCircleOutlineIcon className={classes.icon} />
      </MatrixCell>
      {selected && (
        <SelectTimesDialog
          bookOption={bookOption}
          onClose={() => setSelected(false)}
          handleSave={handleSave}
          disabledSessionPlacementIds={disabledSessionPlacementIds}
        />
      )}
    </>
  );
};

export default AvailableCell;

import { useCallback } from 'react';

const PROVIDER_PREVIOUS_PATH_KEY = 'providerPreviousPath';

const getPreviousPathByRoutesAccess = (
  providerRoutesAccess: boolean
): string | undefined => {
  const providerPreviousPath = sessionStorage.getItem(
    PROVIDER_PREVIOUS_PATH_KEY
  );
  if (providerRoutesAccess && providerPreviousPath) {
    return providerPreviousPath;
  }

  return undefined;
};

const setPreviousPathByRoutesAccess = (
  providerRoutesAccess: boolean,
  currentPath: string
): void => {
  if (providerRoutesAccess) {
    sessionStorage.setItem(PROVIDER_PREVIOUS_PATH_KEY, currentPath);
  }
};

const usePreviousPathByRoutesAccess = (providerRoutesAccess: boolean) => {
  const previousPath = getPreviousPathByRoutesAccess(providerRoutesAccess);

  const removePreviousPath = useCallback(() => {
    sessionStorage.removeItem(PROVIDER_PREVIOUS_PATH_KEY);
  }, []);

  const setPreviousPath = (currentPath: string) => {
    setPreviousPathByRoutesAccess(providerRoutesAccess, currentPath);
  };

  return {
    previousPath,
    setPreviousPath,
    removePreviousPath,
  };
};

export default usePreviousPathByRoutesAccess;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { Theme, makeStyles } from '@material-ui/core';
import { Provider } from '../../types/types';

const progressBarHeight = 6;
const overflowProgressBarMargin = 4;
const progressIndicatorSize = 12;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    lineHeight: 1.2,
  },
  progressBar: ({
    progressBarWidth,
  }: {
    progressBarWidth: number | undefined;
    overflowProgressBarWidth: number;
  }) => ({
    width: progressBarWidth || 'auto',
    height: progressBarHeight,
    position: 'relative',
    flexGrow: progressBarWidth ? 0 : 1,
    backgroundColor: theme.palette.kyoGray2.dark,
  }),
  progressIndicator: {
    position: 'absolute',
    width: progressIndicatorSize,
    height: progressIndicatorSize,
    border: '2px solid white',
    borderRadius: '50%',
    top: -(progressIndicatorSize - progressBarHeight) / 2,
    boxShadow: 'inset 0 0 3.5px #ffffff',
  },
  leftComponent: ({
    overflowProgressBarWidth,
  }: {
    progressBarWidth: number | undefined;
    overflowProgressBarWidth: number;
  }) => ({
    width: overflowProgressBarWidth,
    height: progressBarHeight,
    marginRight: overflowProgressBarMargin,
    backgroundColor: theme.palette.kyoGray2.dark,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  }),
  rightComponent: ({
    overflowProgressBarWidth,
  }: {
    progressBarWidth: number | undefined;
    overflowProgressBarWidth: number;
  }) => ({
    width: overflowProgressBarWidth,
    height: progressBarHeight,
    marginLeft: overflowProgressBarMargin,
    backgroundColor: theme.palette.kyoGray2.dark,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  }),
  indicatorTextWrapper: {
    position: 'absolute',
    bottom: theme.spacing(1.5),
    textAlign: 'center',
    fontSize: 12,
    whiteSpace: 'nowrap',
    left: -8.5,
  },
  progressBarTextWrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    top: 8,
    fontSize: 12,
  },
  textLight: {
    color: '#C0C0C0',
  },
  mr: {
    marginRight: theme.spacing(1.5),
  },
  ml: {
    marginLeft: theme.spacing(1.5),
  },
}));

interface ProviderWeeklyHoursProps {
  provider: Provider;
  currentValue: number;
  indicatorColor: string;
  label?: string;
  variant?: 'labels_are_next_to' | 'labels_are_under';
}

const ProviderWeeklyHours = ({
  provider,
  currentValue,
  indicatorColor,
  label = 'actual',
  variant = 'labels_are_under',
}: ProviderWeeklyHoursProps) => {
  const progressBarRef: React.Ref<any> = useRef(null);
  const overflowProgressBarWidth =
    24 * (variant === 'labels_are_next_to' ? 1.8 : 1);
  const [progressBarWidth, setProgressBarWidth] = useState(100);
  const classes = useStyles({
    progressBarWidth:
      variant === 'labels_are_under' ? progressBarWidth : undefined,
    overflowProgressBarWidth,
  });

  useEffect(() => {
    if (!progressBarRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      // when the size of the element changes
      setProgressBarWidth(progressBarRef.current.offsetWidth);
    });
    resizeObserver.observe(progressBarRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const { minHrsTarget, maxHrsTarget } = provider;

  const progressIndicatorStyle = useMemo(() => {
    const style: React.CSSProperties = {
      backgroundColor: indicatorColor,
    };

    if (currentValue > maxHrsTarget) {
      style.left =
        progressBarWidth +
        overflowProgressBarMargin +
        (overflowProgressBarWidth - progressIndicatorSize) / 2;
    } else if (currentValue < minHrsTarget) {
      style.left =
        -overflowProgressBarMargin -
        (overflowProgressBarWidth + progressIndicatorSize) / 2;
    } else if (maxHrsTarget === minHrsTarget) {
      style.left = 0;
    } else {
      style.left =
        ((currentValue - minHrsTarget) / (maxHrsTarget - minHrsTarget)) *
          progressBarWidth -
        progressIndicatorSize / 2;
    }

    return style;
  }, [
    progressBarWidth,
    minHrsTarget,
    maxHrsTarget,
    currentValue,
    overflowProgressBarWidth,
    indicatorColor,
  ]);

  return (
    <div className={classes.container}>
      {variant === 'labels_are_next_to' && (
        <div className={clsx(classes.textLight, classes.mr)}>min</div>
      )}
      <div className={classes.leftComponent} />
      <div ref={progressBarRef} className={classes.progressBar}>
        <div
          className={classes.progressIndicator}
          style={progressIndicatorStyle}
        >
          <div
            style={{
              color: indicatorColor,
            }}
            className={classes.indicatorTextWrapper}
          >
            <div style={{ fontSize: 10 }} className={classes.textLight}>
              {label}
            </div>
            <div>{currentValue}</div>
          </div>
        </div>
        <div className={classes.progressBarTextWrapper}>
          <div style={{ textAlign: 'left' }}>
            <div>{minHrsTarget}</div>
            {variant !== 'labels_are_next_to' && (
              <div className={classes.textLight}>min</div>
            )}
          </div>
          <div style={{ textAlign: 'right' }}>
            <div>{maxHrsTarget}</div>
            {variant !== 'labels_are_next_to' && (
              <div className={classes.textLight}>max</div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.rightComponent} />
      {variant === 'labels_are_next_to' && (
        <div className={clsx(classes.textLight, classes.ml)}>max</div>
      )}
    </div>
  );
};

export default ProviderWeeklyHours;

import { Collapse, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  AVAILABILITY_ACTION_STATUS,
  deleteAvailability,
  setActionStatus,
} from '../../redux/modules/availability';
import { useAppDispatch } from '../../redux/store';
import { ItemContentFooter } from './ModifiedAvailabilityList';
import ConfirmationDialogTemplate from '../ConfirmationDialogTemplate';
import CreatedAvailabilityActions, {
  ModifiedAvailabilityFooterActionsStyles,
} from './CreatedAvailabilityActions';
import StyledButtonLink from '../buttons/StyledButtonLink';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    cursor: 'pointer',
    userSelect: 'none',
    margin: theme.spacing(0.5, 2.5, 1.35),
    alignItems: 'center',
    display: 'flex',
    color: theme.palette.kyoBlue2.main,
    fontSize: theme.spacing(1.625),
    width: 'max-content',
    position: 'relative',
    left: theme.spacing(-0.8),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

const ModifiedAvailabilityContentFooterWrapper = ({
  availability,
  actionsStyles,
  hasLinks = true,
  children,
}: ItemContentFooter & {
  children?: React.ReactNode;
  actionsStyles?: ModifiedAvailabilityFooterActionsStyles;
  hasLinks?: boolean;
}) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [extended, setExtended] = useState(false);
  const [showEditWarning, setShowEditWarning] = useState(false);

  const LabelButton = extended ? KeyboardArrowDown : KeyboardArrowRight;
  const label = extended ? 'View less' : 'View more';

  const withLink = ({ to, data }: { to: string; data: React.ReactNode }) => {
    return hasLinks ? (
      <StyledButtonLink
        className={classes.link}
        onClick={() => navigate(`/${to}`)}
      >
        {data}
      </StyledButtonLink>
    ) : (
      data
    );
  };

  const contactScheduling = withLink({
    to: 'contact-scheduling',
    data: 'contact scheduling',
  });

  const onDelete = () => {
    confirm({
      description: (
        <>
          If you delete this change, your standard availability will be used
          during this period. Previously removed sessions are not automatically
          rebooked; please contact scheduling or try using Add Sessions if you
          want to rebook removed sessions.
        </>
      ),
    }).then(() => dispatch(deleteAvailability({ id: availability.id })));
  };

  const onEdit = () => {
    const momentStartDate = moment(availability.startDate).startOf('day');
    const canEditAvailability = momentStartDate >= moment().startOf('week');

    if (!canEditAvailability) {
      setShowEditWarning(true);
      return;
    }

    dispatch(
      setActionStatus({
        status: AVAILABILITY_ACTION_STATUS.EDIT_TEMPORARY,
        availability,
      })
    );
  };

  return (
    <>
      <Typography
        className={classes.label}
        onClick={() => setExtended(!extended)}
      >
        <LabelButton fontSize="small" />
        {label}
      </Typography>
      <Collapse in={extended}>
        {children}
        <CreatedAvailabilityActions
          onEdit={onEdit}
          onDelete={onDelete}
          styles={actionsStyles}
        />
      </Collapse>
      <ConfirmationDialogTemplate
        title="Change Availability"
        open={showEditWarning}
        handleClose={() => setShowEditWarning(false)}
      >
        <Typography variant="body1">
          Please {contactScheduling} for availability changes in next 14 days.
        </Typography>
      </ConfirmationDialogTemplate>
    </>
  );
};

export default ModifiedAvailabilityContentFooterWrapper;

import React from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import CelebrationIcon from '../../../assets/star.svg';
import { DATE_FORMAT_MONTH_NAME } from '../../../utils/helpers';
import { Goal } from '../../../types/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

interface GoalProps {
  goal: Goal;
  className?: string;
}

const GoalComponent = (props: GoalProps) => {
  const { goal, className } = props;
  const classes = useStyles();

  const formattedMetDate =
    goal.metDate && moment(goal.metDate).format(DATE_FORMAT_MONTH_NAME);

  return (
    <Box className={clsx(classes.root, className)}>
      <img src={CelebrationIcon} className={classes.icon} alt="" />
      <Box className={classes.detailsContainer}>
        <Typography variant="subtitle2">{goal.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {formattedMetDate}
        </Typography>
      </Box>
    </Box>
  );
};

export default GoalComponent;

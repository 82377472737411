import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';

import { Chip, makeStyles, Theme, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    backgroundColor: theme.palette.kyoPastelOrange.main,
    color: theme.palette.kyoPastelOrange.contrastText,
    width: '100%',
    height: 22,
    ...theme.typography.caption,
  },
  tooltip: {
    width: 220,
    padding: theme.spacing(1.5, 2.5),
    ...theme.typography.caption,
  },
  warningIcon: {
    color: 'inherit',
    fontSize: theme.spacing(2),
  },
}));

interface IncreaseAvailabilityBadgeProps {
  disableHover?: boolean;
}

const IncreaseAvailabilityBadge = ({
  disableHover,
}: IncreaseAvailabilityBadgeProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={
        !disableHover
          ? 'Provide more availability to meet the weekly minimum hours.'
          : ''
      }
      placement="top"
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <Chip
        className={classes.chip}
        icon={<WarningIcon className={classes.warningIcon} />}
        label="Increase availability"
      />
    </Tooltip>
  );
};

export default IncreaseAvailabilityBadge;

export const SCH_PHONE_NUMBER = '18772646747';

export const SCH_PHONE_NUMBER_FORMATTED = '1 (877) 264 6747';

export const SCH_EMAIL = 'scheduling@kyocare.com';

export const SCH_KYOCARE = 'sch@kyocare.com';

export const SUPPORT_EMAIL = 'portal-support@kyocare.com';

export const KYO_EMAIL = 'cs@kyocare.com';

export const PROVIDER_MIN_NEW_SESSIONS = 1;

export const SCHEDULING_POLICIES_AND_PROCEDURES_URL =
  'https://sites.google.com/kyocare.com/portal/scheduling-policies-and-procedures';

export const REQUEST_TIME_OFF_URL =
  'https://jira.kyocare.com/servicedesk/customer/portal/5/create/62';

export const CHANGE_INSURANCE_PLAN_LINK =
  'https://docs.google.com/forms/d/15i8KcxHQq9n-g02oN-Ut7LJ5EL9KQzsXgGI_sED7H0E/viewform?edit_requested=true';

export const CANCELLATIONS_LAST_DAYS = 90;

export const MY_AVAILABILITY_PAGE = 'my-availability';

export const PREFERRED_BLOCK_MIN_INTERVAL = 3;

export const TWO_WEEKS = 14;

export const KYO_CARE_APP = 'https://kyocare.com/about-us/kyo-care-app/';

export const KYO_CLIENT_INSURANCE_SWITCH =
  'https://docs.google.com/forms/d/e/1FAIpQLSe6imLyceOW5aWCY5Ltd5WNNecMSNqma3hI96qzAH91PGeG_w/viewform';

export const ABA_INFO_FOR_FAMILIES =
  'https://kyocare.com/autism-aba-therapy-faqs';

export const CAREGIVER_RESOURCES =
  'https://kyocare.com/autism-resources-for-caregivers/';

export const KYO_WEB_AND_APP_PRIVACY_POLICY =
  'https://kyocare.com/privacy-policy/';

export const CONTACT_BILLING = 'billing@kyocare.com';

export const KYO_CARE_WEB = 'https://portal.kyocare.com';

export const CENTRAL_REACH_URL =
  'https://members.centralreach.com/#scheduling/manage/';

export const OPEN_SESSION_REQUEST = 'https://forms.gle/4npfw4idYSYVeyPg6';

export const KYO_CLIMB_INTRANET =
  'https://sites.google.com/kyocare.com/portal/kyo-climb';

export const SHORT_WEEK_DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

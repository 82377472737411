import { useAuth } from './useAuth';

const useGroups = () => {
  const { user } = useAuth();

  const groups = user?.getSignInUserSession()?.getIdToken().payload[
    'cognito:groups'
  ];

  if(!groups) {
    return [];
  }

  return groups;
};

export default useGroups;
import React from 'react';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    fontSize: 16,
    padding: 16,
    margin: theme.spacing(0, 0.5),
    border: '1px solid transparent',
    width: 20,
    height: 20,
    color: 'inherit',
  },
  current: {
    borderColor: theme.palette.grey[300],
  },
}));

interface PaginationProps {
  numOfPages: number;
  currentPage: number;
  changePage: Function;
  maxShownPages?: number;
  className?: string;
}

const Pagination = (props: PaginationProps) => {
  const {
    changePage,
    currentPage,
    maxShownPages,
    className,
    numOfPages,
  } = props;
  const classes = useStyles();

  let firstShownPage = 1;
  if (maxShownPages && maxShownPages < numOfPages) {
    firstShownPage = Math.max(currentPage - maxShownPages / 2, 1);
  }

  if (numOfPages <= 1) {
    return null;
  }

  return (
    <span className={className || ''}>
      {Array(numOfPages)
        .fill({})
        .map((_, i) => firstShownPage + i)
        .filter((p) =>
          maxShownPages
            ? p <= firstShownPage + (maxShownPages || 0) && p <= numOfPages
            : true
        )
        .map((p) => {
          const isCurrentPage = p === currentPage + 1;
          return (
            <IconButton
              key={p}
              className={clsx(
                classes.button,
                isCurrentPage ? classes.current : ''
              )}
              onClick={() => {
                if (!isCurrentPage) {
                  changePage(p - 1);
                }
              }}
            >
              {p}
            </IconButton>
          );
        })}
    </span>
  );
};

export default Pagination;

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  Dispatch,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import providerApi from '../../api/providerApi';
import { clearSearchResult } from './action';
import {
  NotificationPreference,
  Provider,
  ProviderAddress,
  ProviderAttendanceAwardVisibilityStatus,
  ProviderAttributes,
  ProviderLevel,
  ScheduleSegment,
} from '../../types/types';
import {
  getDiffInDays,
  getFirstSessionAfter,
  getNumberOfHoursBelowRequiredAvail,
} from '../../utils/helpers';
import { TWO_WEEKS } from '../../utils/constants';
import { RootState } from './rootReducer';

export interface ProviderState {
  isLoading: boolean;
  provider: Provider | null;
  providerAttributes: ProviderAttributes | null;
  providerAttributesLoading: boolean;
  providerFullSchedule: ScheduleSegment[] | null;
  providerFullScheduleLoading: boolean;
  notificationPreferences: NotificationPreference[];
  notificationPreferencesLoading: boolean;
  notificationPreferencesError: SerializedError | null;
  saveNotificationPreferencesLoading: boolean;
  providerAttendanceAwardsLoading: boolean;
  relatedClientIds: number[];
  providerProjectedLevelLoading: boolean;
  providerProjectedLevel: ProviderLevel | null;
}

const initialState: ProviderState = {
  provider: null,
  isLoading: false,
  providerAttributes: null,
  providerAttributesLoading: false,
  providerFullSchedule: null,
  providerFullScheduleLoading: false,
  notificationPreferences: [],
  notificationPreferencesLoading: false,
  notificationPreferencesError: null,
  saveNotificationPreferencesLoading: false,
  providerAttendanceAwardsLoading: false,
  relatedClientIds: [],
  providerProjectedLevelLoading: false,
  providerProjectedLevel: null,
};

export const fetchProviderNotificationPreferences = createAsyncThunk(
  'provider/fetchProviderNotificationPreferences',
  (providerId: number) =>
    providerApi
      .fetchProviderNotificationPreferences(providerId)
      .then((res) => res)
);

export const replaceProviderNotificationPreferences = createAsyncThunk(
  'provider/saveProviderNotificationPreferences',
  (
    arg: {
      notificationPreferences: NotificationPreference[];
      providerId: number;
    },
    { rejectWithValue }
  ) =>
    providerApi
      .replaceProviderNotificationPreferences(
        arg.notificationPreferences,
        arg.providerId
      )
      .catch((e) => rejectWithValue(e))
);

export const updateProviderAttendanceAward = createAsyncThunk(
  'provider/updateProviderAttendanceAward',
  (id: number, { rejectWithValue }) =>
    providerApi
      .updateProviderAttendanceAward(id)
      .catch((e) => rejectWithValue(e))
);

export const fetchProvider = createAsyncThunk(
  'providers/fetchProvider',
  async () => {
    return providerApi.fetchCurrentProvider();
  }
);

export const fetchProviderAttributes = createAsyncThunk(
  'providers/fetchProviderAttributes',
  (providerId: number) => {
    return providerApi.fetchProviderAttributes(providerId);
  }
);

export const updateProviderAttributes = createAsyncThunk(
  'providers/updateProviderAttributes',
  (
    arg: { providerId: number; newAttributes: Partial<ProviderAttributes> },
    { rejectWithValue }
  ) => {
    const { providerId, newAttributes } = arg;

    return providerApi
      .updateProviderAttributes(providerId, newAttributes)
      .catch((e) => rejectWithValue(e));
  }
);

export const fetchRelatedClients = createAsyncThunk(
  'providers/fetchRelatedClients',
  async (arg: { providerId: number }) => {
    const { providerId } = arg;

    return (await providerApi.fetchRelatedClientIds(providerId)).data;
  }
);

export const updateProviderAddress = createAsyncThunk(
  'providers/updateProviderAddress',
  async (arg: { providerId: number; newProviderAddress: ProviderAddress }) => {
    return providerApi.updateProviderAddress(
      arg.providerId,
      arg.newProviderAddress
    );
  }
);

export const fetchProviderProjectedLevel = createAsyncThunk(
  'providers/fetchProviderProjectedLevel',
  (providerId: number) => {
    return providerApi.fetchProviderProjectedLevel(providerId);
  }
);

const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    providerLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    providerReceived: (state, action: PayloadAction<Provider>) => ({
      ...state,
      isLoading: false,
      provider: action.payload,
    }),
    setProviderAttributesLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      providerAttributesLoading: action.payload,
    }),
    setProviderAttributes: (
      state,
      action: PayloadAction<ProviderAttributes | null>
    ) => ({
      ...state,
      providerAttributes: action.payload,
    }),
    setProviderFullSchedule: (
      state,
      action: PayloadAction<ScheduleSegment[] | null>
    ) => {
      if (action.payload === null) {
        state.providerFullSchedule = null;
      } else {
        const firstTimeOffSession = getFirstSessionAfter({
          sessions: action.payload,
          after: TWO_WEEKS,
        });

        state.providerFullSchedule = action.payload.map((session) => {
          const diff = getDiffInDays({
            from: new Date().toUTCString(),
            to: session.segmentStartUtc,
          });
          const isTimeOff = diff > TWO_WEEKS;

          return {
            ...session,
            isTimeOff,
            isFirstTimeOff:
              firstTimeOffSession?.segmentId === session.segmentId,
          };
        });
      }
    },
    setProviderFullScheduleLoading: (
      state,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      providerFullScheduleLoading: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearSearchResult, (state) => {
        return {
          ...initialState,
          provider: state.provider,
        };
      })
      .addCase(fetchProviderNotificationPreferences.pending, (state) => {
        return {
          ...state,
          notificationPreferencesLoading: true,
        };
      })
      .addCase(
        fetchProviderNotificationPreferences.fulfilled,
        (state, action) => {
          return {
            ...state,
            notificationPreferencesLoading: false,
            notificationPreferences: action.payload,
          };
        }
      )
      .addCase(
        fetchProviderNotificationPreferences.rejected,
        (state, action) => {
          return {
            ...state,
            notificationPreferencesLoading: false,
            notificationPreferencesError: action.error,
          };
        }
      )
      .addCase(
        replaceProviderNotificationPreferences.fulfilled,
        (state, action) => {
          return {
            ...state,
            saveNotificationPreferencesLoading: false,
          };
        }
      )
      .addCase(replaceProviderNotificationPreferences.pending, (state) => {
        return {
          ...state,
          saveNotificationPreferencesLoading: true,
        };
      })
      .addCase(
        replaceProviderNotificationPreferences.rejected,
        (state, action) => {
          return {
            ...state,
            saveNotificationPreferencesLoading: false,
            notificationPreferencesError: action.error,
          };
        }
      )
      .addCase(updateProviderAttendanceAward.pending, (state) => {
        state.providerAttendanceAwardsLoading = true;
      })
      .addCase(updateProviderAttendanceAward.rejected, (state) => {
        state.providerAttendanceAwardsLoading = false;
      })
      .addCase(
        updateProviderAttendanceAward.fulfilled,
        (
          state,
          action: PayloadAction<ProviderAttendanceAwardVisibilityStatus>
        ) => {
          const { id, isSeen } = action.payload;
          state.providerAttendanceAwardsLoading = false;
          const currentProviderAttendanceAward = state.provider?.attendanceAwards.find(
            (a) => a.id === id
          );

          if (currentProviderAttendanceAward) {
            currentProviderAttendanceAward.isSeen = isSeen;
          }
        }
      )
      .addCase(fetchProvider.pending, (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(fetchProvider.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(fetchProvider.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          provider: action.payload,
        };
      })
      .addCase(fetchProviderAttributes.pending, (state, action) => {
        return {
          ...state,
          providerAttributesLoading: true,
        };
      })
      .addCase(fetchProviderAttributes.rejected, (state) => {
        return {
          ...state,
          providerAttributesLoading: false,
        };
      })
      .addCase(fetchProviderAttributes.fulfilled, (state, action) => {
        return {
          ...state,
          providerAttributesLoading: false,
          providerAttributes: action.payload,
        };
      })
      .addCase(updateProviderAttributes.pending, (state, action) => {
        return {
          ...state,
          providerAttributesLoading: true,
        };
      })
      .addCase(updateProviderAttributes.rejected, (state) => {
        return {
          ...state,
          providerAttributesLoading: false,
        };
      })
      .addCase(updateProviderAttributes.fulfilled, (state, action) => {
        return {
          ...state,
          providerAttributesLoading: false,
          providerAttributes: action.payload,
        };
      })
      .addCase(fetchRelatedClients.fulfilled, (state, action) => {
        state.relatedClientIds = action.payload;
      })
      .addCase(fetchRelatedClients.rejected, (state) => {
        state.relatedClientIds = [];
      })
      .addCase(updateProviderAddress.fulfilled, (state, action) => {
        if (
          state.provider &&
          state.provider?.id === action.payload.providerId
        ) {
          state.provider.address = action.payload;
        }
      })
      .addCase(fetchProviderProjectedLevel.pending, (state, action) => {
        return {
          ...state,
          providerProjectedLevelLoading: true,
        };
      })
      .addCase(fetchProviderProjectedLevel.rejected, (state) => {
        return {
          ...state,
          providerProjectedLevelLoading: false,
        };
      })
      .addCase(fetchProviderProjectedLevel.fulfilled, (state, action) => {
        return {
          ...state,
          providerProjectedLevelLoading: false,
          providerProjectedLevel: action.payload,
        };
      });
  },
});

export const {
  providerReceived,
  setProviderAttributesLoading,
  setProviderAttributes,
  setProviderFullSchedule,
  setProviderFullScheduleLoading,
} = providerSlice.actions;

export default providerSlice.reducer;

export const fetchProviderFullSchedule = (
  providerId: number,
  numOfWeeks?: number,
  limit?: number,
  includePending?: boolean
) => async (dispatch: Dispatch) => {
  dispatch(setProviderFullScheduleLoading(true));

  let response;
  try {
    response = await providerApi.fetchProviderFullSchedule(
      providerId,
      numOfWeeks,
      limit,
      includePending
    );
  } catch (e: any) {
    console.log(e);
  }

  if (response && response.data) {
    dispatch(setProviderFullSchedule(response.data));
  }

  dispatch(setProviderFullScheduleLoading(false));
};

export const selectNumberOfHoursBelowRequiredAvail = createSelector(
  (state: RootState) => state.provider.provider,
  (provider: Provider | null) => {
    return provider?.schedule
      ? getNumberOfHoursBelowRequiredAvail({
          schedule: provider.schedule,
          minHrsTarget: provider.minHrsTarget,
        })
      : 0;
  }
);

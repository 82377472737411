import {
  Divider,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

import CustomLoading from '../../components/CustomLoading/CustomLoading';
import {
  Availability,
  AVAILABILITY_ACTION_STATUS,
  AvailabilityBlock,
  AvailabilityLongTermActions,
  AvailabilityShortTermActions,
  fetchAvailability,
  setActionStatus,
} from '../../redux/modules/availability';
import { RootState } from '../../redux/modules/rootReducer';
import { useAppDispatch } from '../../redux/store';
import { AvailabilityStatus } from '../../shared/types';
import EmptyAvailabilityInfo from '../../components/Availability/EmptyAvailabilityInfo';
import UpcomingStandardAvailability from '../../components/Availability/UpcomingAvailability';
import ModifiedAvailability from './ModifiedAvailability';
import { ClientSchedule, ProviderSchedule } from '../../types/types';
import StandardAvailability from './StandardAvailability';
import AvailabilityActionsProvider from './AvailabilityActionsProvider/AvailabilityActionsProvider';
import TemporaryAvailabilityActions from '../../components/TemporaryAvailabilityActions/TemporaryAvailabilityActions';
import { getNumberOfHoursBelowRequiredAvail } from '../../utils/helpers';
import AnalyticsTracker, {
  TrackerNames,
} from '../../analytics/AnalyticsTracker';

export const maxWidth = (theme: Theme) => ({
  maxWidth: theme.spacing(60),
});

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(3),
  },
  matrixInfoRoot: {
    marginTop: theme.spacing(3),
  },
  mainContent: {
    marginTop: theme.spacing(6),
    gap: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
  },
  myAvailability: {
    padding: theme.spacing(0, 4, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2, 2),
    },
  },
  pageTitle: {
    [theme.breakpoints.down('xs')]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
  },
  changeAvailabilityText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),

    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px 0`,
    },
  },
  link: {
    fontWeight: 400,
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(0.25),
    lineHeight: theme.spacing(0.125),
    minWidth: 'auto',
  },
  emptyUpcomingAvailability: {
    marginBottom: theme.spacing(4),
  },
  emptyAvailability: {
    ...maxWidth(theme),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const availabilityBlocksMapping = (availabilityBlocks?: AvailabilityBlock[]) =>
  availabilityBlocks?.map((block) => {
    return {
      dayOfWeekId: block.timeBlockDayOfWeek.dayOfWeekId,
      isAvailable: block.isAvailable,
    };
  }) || [];

const MyAvailability = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const currentProvider = useSelector((state: RootState) => {
    return state.provider.provider;
  });
  const currentProviderLoading = useSelector((state: RootState) => {
    return state.provider.isLoading;
  });
  const providerSchedule = useSelector((state: RootState) => {
    return state.provider.provider?.schedule;
  });
  const ltAvailability = useSelector((state: RootState) => {
    return state.availability.ltAvailability;
  });
  const loadingProviderAvailability = useSelector((state: RootState) => {
    return state.availability.loading;
  });
  const AvailabilityActionStatus = useSelector((state: RootState) => {
    return state.availability.action.status;
  });
  const availabilityIdentifier = useSelector((state: RootState) => {
    return state.availability.availabilityIdentifier;
  });
  const canShowAvailability = useSelector((state: RootState) => {
    const isActive = state.provider.provider?.isActive;
    const termAnnounceDate = state.provider.provider?.termAnnounceDate;

    return isActive && !termAnnounceDate;
  });

  const fetchProviderAvailability = useCallback(() => {
    if (!currentProvider?.id) {
      return;
    }

    dispatch(
      fetchAvailability({
        providerId: currentProvider.id,
        excludeStatuses: [
          AvailabilityStatus.Expired,
          AvailabilityStatus.Discarded,
        ],
      })
    );
  }, [currentProvider, dispatch]);

  useEffect(() => {
    fetchProviderAvailability();
  }, [currentProvider, availabilityIdentifier, fetchProviderAvailability]);

  const currentAvailability = ltAvailability.find(
    (avail) => avail.status === AvailabilityStatus.Current
  );

  const upcomingAvailability = ltAvailability.find(
    (avail) => avail.status === AvailabilityStatus.Upcoming
  );

  const isLowCurrentAvailability =
    getNumberOfHoursBelowRequiredAvail({
      schedule: availabilityBlocksMapping(
        currentAvailability?.availabilityBlocks
      ),
      minHrsTarget: currentProvider?.minHrsTarget || 0,
    }) > 0;

  const isLowUpcomingAvailability =
    getNumberOfHoursBelowRequiredAvail({
      schedule: availabilityBlocksMapping(
        upcomingAvailability?.availabilityBlocks
      ),
      minHrsTarget: currentProvider?.minHrsTarget || 0,
    }) > 0;

  if (currentProviderLoading || loadingProviderAvailability) {
    return <CustomLoading />;
  }

  if (AvailabilityLongTermActions.includes(AvailabilityActionStatus)) {
    return (
      <div className={classes.myAvailability}>
        <AvailabilityActionsProvider
          schedule={providerSchedule}
          handleSuccess={fetchProviderAvailability}
        />
      </div>
    );
  }

  if (AvailabilityShortTermActions.includes(AvailabilityActionStatus)) {
    return (
      <div className={classes.myAvailability}>
        <TemporaryAvailabilityActions
          schedule={providerSchedule}
          handleSuccess={fetchProviderAvailability}
          type="provider"
        />
      </div>
    );
  }

  return (
    <div className={classes.myAvailability}>
      <AnalyticsTracker name={TrackerNames.Availability} />
      <Typography
        variant="h6"
        className={classes.pageTitle}
        color="textSecondary"
      >
        My Availability
      </Typography>
      {!isMobile && <Divider className={classes.divider} />}

      <div className={classes.mainContent}>
        <div>
          {currentAvailability && (
            <StandardAvailability
              unavailable={!canShowAvailability}
              showBooked={canShowAvailability}
              availability={currentAvailability}
              schedule={providerSchedule}
              showDemand
              showLowAvailWarning={isLowCurrentAvailability}
            />
          )}

          {canShowAvailability && upcomingAvailability ? (
            <UpcomingStandardAvailability
              availability={upcomingAvailability}
              schedule={providerSchedule}
              showDemand
              showLowAvailWarning={isLowUpcomingAvailability}
            />
          ) : (
            <EmptyUpcomingAvailability
              disabled={!canShowAvailability}
              styles={{
                emptyAvailabilityWrapper: clsx(
                  classes.emptyAvailability,
                  classes.emptyUpcomingAvailability
                ),
              }}
              currentAvailability={currentAvailability}
            />
          )}
        </div>

        <div>
          <ModifiedAvailability
            styles={{
              emptyAvailabilityWrapper: classes.emptyAvailability,
            }}
            disabled={!canShowAvailability}
          />
        </div>
      </div>
    </div>
  );
};

export default MyAvailability;

export interface AvailabilityWrapperProps {
  availability: Availability;
  isEditable?: boolean;
  showBooked?: boolean;
  preferredTimes?: React.ReactNode;
  unavailable?: boolean;
  schedule?: ClientSchedule[] | ProviderSchedule[];
  showDemand?: boolean;
  showLowAvailWarning?: boolean;
}

const EmptyUpcomingAvailability = ({
  styles,
  disabled = false,
  currentAvailability,
}: {
  styles?: Partial<Record<'emptyAvailabilityWrapper', string>>;
  disabled?: boolean;
  currentAvailability?: Availability;
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles?.emptyAvailabilityWrapper}>
      <EmptyAvailabilityInfo
        handleButtonClick={() => {
          dispatch(
            setActionStatus({
              status: AVAILABILITY_ACTION_STATUS.ADD,
              availability: currentAvailability,
            })
          );
        }}
        imageSrc={require('../../assets/switchWithCalendar.png')}
        content="If your standard availability is changing, please let us know"
        buttonLabel="Add Upcoming Availability"
        buttonProps={{ disabled, startIcon: <AddIcon /> }}
      />
    </div>
  );
};

import { makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  dataRow: {
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
  },
  labelSection: {
    marginLeft: theme.spacing(1),
  },
}));

interface DataRowProps {
  imageSrc: string;
  label: string;
  value: number | string | React.ReactNode;
}

const DataRow = ({ imageSrc, label, value }: DataRowProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.flexCenter, classes.dataRow)}>
      <div className={clsx(classes.flexCenter)}>
        <img
          className={classes.labelSection}
          src={imageSrc}
          alt="review data icon"
        />
        <Typography variant="body1" className={classes.labelSection}>
          {label}
        </Typography>
      </div>
      <Typography variant="subtitle1">{value}</Typography>
    </div>
  );
};

export default DataRow;

import React from 'react';
import { ChannelList, ChannelListProps } from 'stream-chat-react';
import type { Channel, ChannelFilters } from 'stream-chat';
import clsx from 'clsx';
import './ChannelListContainer.css';
import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import { TeamChannelPreview } from '../TeamChannelPreview/TeamChannelPreview';

type Props = Omit<ChannelListProps, 'filters'> & {
  filters: ChannelFilters;
  className: string;
  onChannelChange?: (channel: Channel, closeChannelList?: boolean) => void;
};

const customChannelTeamFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'team');
};

export const ChannelListContainer: React.FC<Props> = (props: Props) => {
  const { filters, options, sort, className, onChannelChange } = props;
  return (
    <div className={clsx('channel-list__container', className)}>
      <div className="channel-list__list__wrapper">
        <ChannelList
          channelRenderFilterFn={customChannelTeamFilter}
          filters={filters}
          options={options}
          sort={sort}
          List={(listProps) => <TeamChannelList {...listProps} type="team" />}
          Preview={(previewProps) => (
            <TeamChannelPreview
              {...previewProps}
              {...{ onChannelChange }}
              type="team"
            />
          )}
        />
      </div>
    </div>
  );
};

import { MUIDataTableOptions } from 'mui-datatables';
import { AvailabilityBlock } from '../../redux/modules/availability';
import { AvailabilityType } from '../../shared/constants';
import { ScheduleSegment } from '../../types/types';
import { AvailabilityStatus } from '../../shared/types';

export enum PageMode {
  Schedule = 'View My Schedule',
  Remove = 'Remove Sessions',
  TempAvail = 'Manage Temporary Availability',
  StandardAvail = 'Manage Standard Availability',
}

export enum PageModeParam {
  schedule = 'schedule',
  remove = 'remove',
  temporary = 'temporary',
  standard = 'standard',
}

export type PreferencesKeys =
  | 'preferredBlock1Start'
  | 'preferredBlock3Start'
  | 'preferredBlock3End';

export type UserType = 'Client' | 'Provider';

export enum MyScheduleViewMode {
  grid = 'grid',
  list = 'list',
  /** `mobileGrid` must be set only depending on the screen size */
  mobileGrid = 'mobileGrid',
}

export type AvailabilityByWeek = {
  blocks: AvailabilityBlock[];
  originalId: number;
  preferences: {
    preferredBlock1Start?: string | null;
    preferredBlock3Start?: string | null;
    preferredBlock3End?: string | null;
  };
  type: AvailabilityType;
  status?: AvailabilityStatus;
};

export interface UserSpecificMatrixCardProps {
  scheduleSegment: ScheduleSegment;
  children: React.ReactNode;
  cancelMode: boolean;
}

export interface UserSpecificDialogContentProps {
  scheduleSegment: ScheduleSegment;
  startDate: string;
  endDate: string;
  duration: string;
}

export interface UserSpecificSessionsTableProps {
  data: Array<ScheduleSegment>;
  selectMode: boolean;
  sessionsToRemove: ScheduleSegment[];
  onRowSelection: (segmentId: number, checked: boolean) => void;
  options?: MUIDataTableOptions;
  isSmallScreen: boolean;
}

export interface UserSpecificSessionToRemoveCardProps {
  session: ScheduleSegment;
  onKeepSession: () => void;
}

import { State } from '../types/types';
import requester from './requester';

const fetchStates = async (): Promise<State[]> => {
  return requester
    .get('states')
    .then((response) => response.json())
    .then((response) => response?.data);
};

export default { fetchStates };

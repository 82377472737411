import { makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { Availability } from '../../redux/modules/availability';
import {
  createWeekString,
  DATE_FORMAT_MONTH_NAME_DAY_YEAR,
  DATE_FORMAT_MONTH_NAME_WITH_DAY,
  DATE_FORMAT_MONTH_NAME_WITH_YEAR,
} from '../../utils/helpers';

import AvailabilityMatrix from '../Matrix/AvailabilityMatrix';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '25%',
  },
  listItem: {
    border: '1px solid #EEEEEE',
    boxShadow:
      '0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)',
    borderRadius: theme.spacing(1),
  },
  itemVisibleContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export interface ItemContentFooter {
  availability: Availability;
}

interface ModifiedAvailabilityListProps {
  availabilities: Availability[];
  ItemContentFooter?: (args: { availability: Availability }) => JSX.Element;
  styles?: Partial<Record<'listItem' | 'visibleContent', string>>;
}

const ModifiedAvailabilityList = ({
  availabilities,
  ItemContentFooter = () => <></>,
  styles,
}: ModifiedAvailabilityListProps) => {
  const classes = useStyles();

  return (
    <>
      {availabilities.map((avail) => {
        const unavailableWholeWeek = avail.availabilityBlocks.every(
          (block) => !block.isAvailable
        );
        return (
          <div
            key={avail.id}
            className={clsx(styles?.listItem, classes.listItem)}
          >
            <div
              className={clsx(
                styles?.visibleContent,
                classes.itemVisibleContent
              )}
            >
              <div>
                <Typography variant="subtitle1" color="textSecondary">
                  {moment(avail.startDate).format(
                    DATE_FORMAT_MONTH_NAME_WITH_YEAR
                  )}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {createWeekString(
                    avail.startDate,
                    avail.endDate,
                    DATE_FORMAT_MONTH_NAME_WITH_DAY,
                    DATE_FORMAT_MONTH_NAME_DAY_YEAR
                  )}
                </Typography>
                {unavailableWholeWeek && (
                  <Typography variant="subtitle2" color="error">
                    Unavailable whole period
                  </Typography>
                )}
              </div>
              <div>
                <AvailabilityMatrix
                  availabilityBlocks={avail.availabilityBlocks}
                  cellSizes={1.25}
                  styles={{
                    root: classes.root,
                  }}
                  HorizontalLabel={() => <></>}
                  getBlockMainStyles={(theme: Theme) => ({
                    [theme.breakpoints.up('xs')]: {
                      margin: `${theme.spacing(0.5)}px ${theme.spacing(
                        0.25
                      )}px`,
                    },
                  })}
                />
              </div>
            </div>
            <ItemContentFooter availability={avail} />
          </div>
        );
      })}
    </>
  );
};

export default ModifiedAvailabilityList;

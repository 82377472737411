import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';

const getButtonHoverCss = (backgroundColor: string) => {
  return {
    '&:hover': {
      backgroundColor,
      opacity: 0.8,
    },
  };
};

const useStyles = makeStyles((theme) => ({
  openButton: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.75),
  },
  closed: {
    backgroundColor: `${theme.palette.kyoGray.main}`,
    color: theme.palette.kyoGray.dark,
    ...getButtonHoverCss(`${theme.palette.kyoGray.main}`),
  },
  open: {
    backgroundColor: '#C5DCFA',
    color: theme.palette.kyoBlue.main,
    ...getButtonHoverCss('#C5DCFA'),
  },
}));

interface FiltersListProps {
  children: (open: boolean) => React.ReactNode;
  useButton?: boolean;
  buttonClassName?: string;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const FiltersList = ({
  children,
  useButton,
  buttonClassName,
  open,
  setOpen,
}: FiltersListProps) => {
  const classes = useStyles();

  return (
    <>
      {useButton && (
        <IconButton
          className={clsx(
            buttonClassName,
            classes.openButton,
            open ? classes.open : classes.closed
          )}
          onClick={() => setOpen(!open)}
          aria-label="Filters List"
        >
          <FilterListIcon fontSize="small" />
        </IconButton>
      )}
      {children(useButton ? open : true)}
    </>
  );
};

export default FiltersList;

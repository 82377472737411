import React, { useState } from 'react';
import moment from 'moment';
import { MessageResponse, logChatPromiseExecution } from 'stream-chat';

import {
  defaultPinPermissions,
  MessageList,
  MessageInput,
  PinEnabledUserRoles,
  Thread,
  useChannelActionContext,
  Window,
  MessageToSend,
  useChannelStateContext,
} from 'stream-chat-react';
import { Alert } from '@material-ui/lab';
import configs from '../../configs';

import { TeamChannelHeader } from '../TeamChannelHeader/TeamChannelHeader';
import { ThreadMessageInput } from '../TeamMessageInput/ThreadMessageInput';

import type {
  TeamAttachmentType,
  TeamChannelType,
  TeamCommandType,
  TeamEventType,
  TeamMessageType,
  TeamReactionType,
  TeamUserType,
} from '../types';
import { DATE_FORMAT } from '../../utils/helpers';

type GiphyStateObj = {
  giphyState: boolean;
  setGiphyState: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GiphyContext = React.createContext<GiphyStateObj>(
  {} as GiphyStateObj
);

const thirtyDaysAgo = moment()
  .subtract(+configs.chatHistoryDays, 'days')
  .format(DATE_FORMAT);

export const ChannelInner = () => {
  const [giphyState, setGiphyState] = useState(false);
  const { messages, channel, hasMore } = useChannelStateContext();

  const filteredMessages = messages?.filter((message) => {
    const messageDate = moment(message.created_at as Date).format(DATE_FORMAT);
    return messageDate >= thirtyDaysAgo;
  });

  let hasMoreToLoad = true;
  const [earliestMessage] = messages || [];
  const earliestMessageDate = moment(earliestMessage?.created_at).format(
    DATE_FORMAT
  );

  if (earliestMessageDate < thirtyDaysAgo || !hasMore) {
    hasMoreToLoad = false;
  }

  const giphyStateObj = {
    giphyState,
    setGiphyState,
  };

  const { sendMessage } = useChannelActionContext<
    TeamAttachmentType,
    TeamChannelType,
    TeamCommandType,
    TeamEventType,
    TeamMessageType,
    TeamReactionType,
    TeamUserType
  >();

  const teamPermissions: PinEnabledUserRoles = {
    ...defaultPinPermissions.team,
    user: true,
  };
  const messagingPermissions: PinEnabledUserRoles = {
    ...defaultPinPermissions.messaging,
    user: true,
  };
  const pinnedPermissions = {
    ...defaultPinPermissions,
    team: teamPermissions,
    messaging: messagingPermissions,
  };

  const overrideSubmitHandler = (message: MessageToSend) => {
    let updatedMessage = {
      attachments: message.attachments,
      mentioned_users: message.mentioned_users,
      parent_id: message.parent?.id,
      parent: message.parent as MessageResponse,
      text: message.text,
    };

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = { ...updatedMessage, text: updatedText };
    }

    if (sendMessage) {
      const sendMessagePromise = sendMessage(updatedMessage);
      logChatPromiseExecution(sendMessagePromise, 'send message');
      setGiphyState(false);
    }
  };

  if (channel?.data?.frozen) {
    return (
      <div className="attain-chat_disabled">
        <Alert severity="info" style={{ width: '95%' }}>
          This channel has been temporarily suspended. Please use the features
          available in Care for schedule updates, refer to the Help page or send
          a request through Contact Us for further assistance.
        </Alert>
      </div>
    );
  }

  return (
    <GiphyContext.Provider value={giphyStateObj}>
      <div style={{ display: 'flex', width: '100%' }}>
        <Window>
          <TeamChannelHeader />
          <MessageList
            hasMore={hasMoreToLoad}
            messages={filteredMessages}
            disableQuotedMessages
            pinPermissions={pinnedPermissions}
            messageActions={[
              'delete',
              'edit',
              'mute',
              'pin',
              'quote',
              'react',
              'reply',
            ]}
          />
          <MessageInput grow overrideSubmitHandler={overrideSubmitHandler} />
        </Window>
        <Thread
          additionalMessageInputProps={{
            grow: true,
            Input: ThreadMessageInput,
          }}
        />
      </div>
    </GiphyContext.Provider>
  );
};

import React from 'react';
import { Theme, makeStyles, useMediaQuery } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import clsx from 'clsx';
import moment from 'moment';

import MatrixCell, { MatrixCellProps } from '../../../shared/matrix/ui/cell';
import { SelectedBookingOption } from '../../../redux/modules/newSessions';
import CellInfo from './CellInfo';
import { TIME_FORMAT_2, TIME_FORMAT_24HR } from '../../../utils/helpers';

const useSelectedCellStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.kyoBlue2.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingRight: theme.spacing(2),
    zIndex: 1,
    overflow: 'hidden',
    color: 'white',
  },
  rootMobile: {
    alignItems: 'center',
    paddingRight: 0,
  },
  smallImage: {
    width: '100%',
  },

  stripedBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  stripe: {
    transform: 'rotate(-45deg) translateY(-110px)',
    width: '250%',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    height: '1px',
    margin: theme.spacing(0.75),
  },
  stripeMobile: {
    transform: 'rotate(-45deg) translateY(-80px)',
  },
  icon: {
    backgroundColor: theme.palette.kyoBlue2.main,
    color: 'white',
    position: 'relative',
    borderRadius: '50%',
  },
}));

const SelectedCell = ({
  className,
  selectedBookingOption,
}: Omit<MatrixCellProps, 'children'> & {
  selectedBookingOption: SelectedBookingOption;
}) => {
  const classes = useSelectedCellStyles();

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const { startTime, duration } = selectedBookingOption.sessionPlacement;

  return (
    <MatrixCell
      className={clsx(
        classes.root,
        isSmallScreen && classes.rootMobile,
        className
      )}
    >
      <AccessTimeIcon className={classes.icon} />
      {!isSmallScreen && (
        <CellInfo
          startTime={moment(startTime, TIME_FORMAT_24HR).format(TIME_FORMAT_2)}
          duration={duration || 0}
        />
      )}
      <div className={classes.stripedBackground}>
        {Array.from({ length: 30 }, (x, i) => i).map((id) => (
          <div
            key={id}
            className={clsx(
              classes.stripe,
              isSmallScreen && classes.stripeMobile
            )}
          />
        ))}
      </div>
    </MatrixCell>
  );
};

export default SelectedCell;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme, Divider, Typography } from '@material-ui/core';
import FormContainer from './FormContainer/FormContainer';
import icon from '../assets/maintenance-icon.svg';
import { fetchMaintenance } from '../redux/modules/maintenance';

const useStyles = makeStyles((theme: Theme) => ({
  maintenanceForm: {
    textAlign: 'center',
    margin: theme.spacing(2, 'auto'),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(0),
  },
  icon: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(1),
  },
  dividerEnd: {
    margin: theme.spacing(3, 0),
  },
}));

const Maintenance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(fetchMaintenance());
    }, 30000);

    return () => clearInterval(timer);
  }, [dispatch]);

  return (
    <FormContainer className={classes.maintenanceForm}>
      <>
        <Typography variant="h5" className={classes.title}>
          Website under Maintenance!
        </Typography>

        <Divider className={classes.divider} />

        <>
          <img src={icon} className={classes.icon} alt="maintenanceIcon" />
          <Typography>We are currently down for maintenance.</Typography>
          <Typography>Please check back soon.</Typography>
        </>

        <Divider className={classes.dividerEnd} />
      </>
    </FormContainer>
  );
};

export default Maintenance;

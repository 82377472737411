import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import AvailabilityMatrix from '../../components/Matrix/AvailabilityMatrix';
import MatrixInfo from '../../components/Matrix/MatrixInfo';
import { AvailabilityWrapperProps, maxWidth } from './MyAvailability';
import InfoCircleBlue from '../../assets/info-circle-blue.svg';
import AvailabilityHeader from '../../components/Availability/AvailabilityHeader';
import { matrixInfoStyles } from '../../components/Availability/AvailabilityStyles';
import DemandInfo from '../../components/Matrix/DemandInfo';
import IncreaseAvailabilityBadge from '../providerProfile/IncreaseAvailabilityBadge';

export const useStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    ...maxWidth(theme),
  },
  headerTitle: {
    ...theme.typography.h6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  headerSubTitle: {
    ...theme.typography.body1,
  },
  changeButton: {
    height: 'fit-content',
    color: theme.palette.kyoBlue.main,
    ...theme.typography.body2,
  },
  availabilityMatrix: {
    ...maxWidth(theme),
    marginTop: theme.spacing(2),
  },
  matrixInfo: {
    ...maxWidth(theme),
    ...matrixInfoStyles(theme),
  },
  matrixVerticalLabelContent: {
    fontSize: 12,
    fontWeight: 600,
  },
  learnMoreLink: {
    padding: 0,
    marginBottom: theme.spacing(0.5),
    fontWeight: 400,
    textTransform: 'none',
    color: theme.palette.kyoBlue.main,
  },
  cellWrapper: {
    position: 'relative',
  },
  demandImg: {
    position: 'absolute',
    top: -2,
    right: 3,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useStandardAvailabilityInfo = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: theme.spacing(57),
    margin: 'auto',
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
  },
  gotItButton: {
    width: '100%',
    textTransform: 'capitalize',
    color: theme.palette.kyoLightGreen.main,
    borderColor: theme.palette.kyoLightGreen.main,
    marginTop: theme.spacing(2),
  },
}));

const StandardAvailability = ({
  availability,
  unavailable = false,
  showBooked = true,
  schedule,
  showDemand = false,
  showLowAvailWarning = false,
}: AvailabilityWrapperProps) => {
  const classes = useStyles();

  const { availabilityBlocks } = availability;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const [isInfoOpen, setIsOpenInfo] = useState(false);

  return (
    <>
      <AvailabilityHeader
        title={
          <div className={classes.title}>
            Standard Availability
            <IconButton onClick={() => setIsOpenInfo(true)}>
              <img src={InfoCircleBlue} alt="info icon" />
            </IconButton>
          </div>
        }
        classes={{
          root: classes.headerRoot,
          title: classes.headerTitle,
          subTitle: classes.headerSubTitle,
        }}
      />
      {showLowAvailWarning && (
        <div style={{ maxWidth: 480 }}>
          <IncreaseAvailabilityBadge />
        </div>
      )}
      <AvailabilityMatrix
        cellSizes={isMobile ? 4 : 5}
        schedule={schedule}
        availabilityBlocks={availabilityBlocks}
        disableWeekends
        unavailable={unavailable}
        styles={{
          root: classes.availabilityMatrix,
        }}
        showBooked={showBooked}
        showDemand={showDemand}
        CellWrapper={DemandInfo}
      />
      <MatrixInfo rootClassName={classes.matrixInfo} showDemand={showDemand} />
      <StandardAvailabilityInfo
        open={isInfoOpen}
        handleClose={() => setIsOpenInfo(false)}
      />
    </>
  );
};

const StandardAvailabilityInfo = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const classes = useStandardAvailabilityInfo();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ root: classes.dialog, paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.content}>
        <Typography variant="subtitle1" color="textSecondary">
          Kyo uses a system of block-based availability. BTs will be asked to
          select up to three blocks of time when they are available each day.
          For any blocks that are selected, BTs should be prepared to fill
          appointment needs during any portion of the block. Client sessions are
          typically 3 hours long, but you may occasionally be scheduled for 2
          hour or 4 hour sessions. BTs should let us know anytime there is an
          upcoming change in their availability. The form must be submitted at
          least two weeks in advance of the new availability start date. The
          Scheduling team has a two week processing timeframe to update your
          availability and replan your schedule if needed.
        </Typography>

        <Button
          className={classes.gotItButton}
          variant="outlined"
          onClick={handleClose}
        >
          Got It
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default StandardAvailability;

import React from 'react';
import {
  MessageUIComponentProps,
  useMessageContext,
  MessageSimple,
} from 'stream-chat-react';

import './TeamMessage.css';

export const TeamMessage: React.FC<MessageUIComponentProps> = (props) => {
  const { message } = useMessageContext();

  return (
    <div className={message.pinned ? 'pinned-message' : 'unpinned-message'}>
      <MessageSimple message={message} {...props} />
      {/** potentially add replies component here */}
    </div>
  );
};

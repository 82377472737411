import moment from 'moment';
import {
  Box,
  Collapse,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useConfirm } from 'material-ui-confirm';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import FiltersList from '../../components/FiltersList';
import StyledMultipleDatePicker from '../../components/styledMultipleDatePicker/StyledMultipleDatePicker';
import { DATE_FORMAT_US } from '../../utils/helpers';
import StyledSwitch from '../../components/StyledSwitch/StyledSwitch';
import { RootState } from '../../redux/modules/rootReducer';
import { ClientAttributes } from '../../types/types';
import { SHORT_WEEK_DAYS } from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.75),
    backgroundColor: theme.palette.kyoGray.light,
    borderRadius: theme.spacing(1.5),
  },
  rootMobile: {
    backgroundColor: 'transparent',
    padding: 0,
    alignItems: 'end',
  },
  preferredDateCaptionMobile: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'flex-end',
  },
  collapseMobile: {
    gridColumn: '1 / 3',
  },
  filtersWrapper: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  filtersWrapperMobile: {
    flexDirection: 'column',
    marginTop: theme.spacing(0.75),
    gap: theme.spacing(0.75),
  },
  row: {
    display: 'flex',
    gap: theme.spacing(0.75),
    alignItems: 'center',
  },
  preferredRowMobile: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  filterRow: {
    justifyContent: 'space-between',
  },
  mobileSwitch: {
    transform: 'translateX(9px)',
  },
}));

type PreferredDatesValue = DateObject[];

interface AddSessionFiltersProps {
  preferredDates: PreferredDatesValue;
  setPreferredDates: (value: PreferredDatesValue) => void;
  showOptionsOutsideOfMyAvailability: boolean;
  setShowOptionsOutsideOfMyAvailability: (value: boolean) => void;
  isShowMyTeam: boolean;
  setShowMyTeam: (value: boolean) => void;
  filtersListOpen: boolean;
  setFiltersListOpen: (value: boolean) => void;
  clientAttributes?: Pick<ClientAttributes, 'assignmentApprovalRequired'>;
}

const AddSessionFilters = ({
  clientAttributes,
  preferredDates,
  setPreferredDates,
  showOptionsOutsideOfMyAvailability,
  setShowOptionsOutsideOfMyAvailability,
  isShowMyTeam,
  setShowMyTeam,
  filtersListOpen,
  setFiltersListOpen,
}: AddSessionFiltersProps) => {
  const classes = useStyles();

  const isTabletScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const isMobileScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const confirm = useConfirm();

  const calendarDefaultCurrentDate = new DateObject().add(2, 'days');

  const calendarMinDate = moment().startOf('day').add(2, 'day').toDate();
  const maxDate = useSelector((state: RootState) => state.newSessions.maxDate);

  const handleShowMyTeamCheck = (isChecked: boolean) => {
    if (clientAttributes?.assignmentApprovalRequired) {
      confirm({
        title: 'Sorry!',
        description:
          'You may book only with providers in your current Care Team. For more' +
          ' information please contact your BCBA.',
      });
    } else {
      setShowMyTeam(isChecked);
    }
  };

  return (
    <div className={clsx(classes.root, isMobileScreen && classes.rootMobile)}>
      <div
        className={clsx(
          classes.row,
          isMobileScreen && classes.preferredRowMobile
        )}
      >
        <Box
          className={clsx(isMobileScreen && classes.preferredDateCaptionMobile)}
        >
          <Typography variant="body1">Preferred dates</Typography>
          <Typography variant="caption">(optional, up to 2)</Typography>
        </Box>
        <StyledMultipleDatePicker
          multiple
          value={preferredDates}
          placeholder="MM/DD/YYYY"
          format={DATE_FORMAT_US}
          weekDays={SHORT_WEEK_DAYS}
          onChange={(array) => {
            if (Array.isArray(array) && array.length <= 2) {
              setPreferredDates([...array]);
            } else {
              setPreferredDates([...preferredDates]);
            }
          }}
          portal
          currentDate={calendarDefaultCurrentDate}
          minDate={calendarMinDate}
          maxDate={maxDate}
          style={{
            backgroundColor: 'white',
            border: '1px solid #C0C0C0',
          }}
        />
      </div>
      <FiltersList
        open={filtersListOpen}
        setOpen={setFiltersListOpen}
        useButton={isTabletScreen}
      >
        {(open) => (
          <Collapse
            in={open}
            className={clsx(isTabletScreen && classes.collapseMobile)}
          >
            <div
              className={clsx(
                classes.filtersWrapper,
                isTabletScreen && classes.filtersWrapperMobile
              )}
            >
              <div className={clsx(classes.row, classes.filterRow)}>
                <Typography variant="body1">Show My Team only</Typography>
                <StyledSwitch
                  checked={isShowMyTeam}
                  className={classes.mobileSwitch}
                  onChange={(_, value) => {
                    handleShowMyTeamCheck(value);
                  }}
                />
              </div>
              <div className={clsx(classes.row, classes.filterRow)}>
                <Typography variant="body1">
                  Show Times Outside My Availability
                </Typography>
                <StyledSwitch
                  checked={showOptionsOutsideOfMyAvailability}
                  className={classes.mobileSwitch}
                  onChange={(_, value) => {
                    setShowOptionsOutsideOfMyAvailability(value);
                  }}
                />
              </div>
            </div>
          </Collapse>
        )}
      </FiltersList>
    </div>
  );
};

export default AddSessionFilters;

import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import availabilityCalendarInfo from '../../../assets/availabilityCalendarInfo.png';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: theme.spacing(80),
    margin: 'auto',
  },
  dialogPaper: {
    borderRadius: 12,
  },
  title: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.kyoGray.light,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  closeButton: {
    width: '100%',
    textTransform: 'capitalize',
    color: theme.palette.kyoLightGreen.main,
    borderColor: theme.palette.kyoLightGreen.main,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderRadius: 8,
  },
  infoText: {
    fontSize: 14,
    marginBottom: theme.spacing(1),
  },
  bold: {
    fontSize: 14,
    fontWeight: 700,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
}));

interface WeeklyHoursInfoModalProps {
  open: boolean;
  onClose: () => void;
}

const WeeklyHoursInfoModal = ({ open, onClose }: WeeklyHoursInfoModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ root: classes.dialog, container: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6" color="textSecondary">
          Weekly Hours Info
        </Typography>
        <IconButton onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.iconWrapper}>
          <img
            src={availabilityCalendarInfo}
            alt="availability info icon"
            width={121}
            height={82}
          />
        </div>
        <Typography className={classes.infoText}>
          <Typography className={classes.bold} component="span">
            Minimum Weekly
          </Typography>{' '}
          - The minimum number of hours that you will be scheduled.
        </Typography>
        <Typography className={classes.infoText}>
          <Typography className={classes.bold} component="span">
            Maximum Weekly
          </Typography>{' '}
          - The maximum number of hours that you requested and/or have
          availability to be scheduled.
        </Typography>
        <Typography className={classes.infoText}>
          To adjust your weekly min or max preferences, please contact your
          Talent Consultant (TC)
        </Typography>
        <Button
          className={classes.closeButton}
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default WeeklyHoursInfoModal;

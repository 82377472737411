import { useMediaQuery, useTheme } from '@material-ui/core';

const useResponsiveMatrix = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.down('sm'));

  const getCellSize = () => {
    if (isMedium && !isMobile) {
      return 3.4;
    }
    if (isMobile) {
      return 4;
    }
    return 4.5;
  };

  return { cellSize: getCellSize(), isMobile };
};

export default useResponsiveMatrix;

import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { Theme, useMediaQuery } from '@material-ui/core';
import {
  MyScheduleViewMode,
  PageMode,
  PageModeParam,
  UserSpecificDialogContentProps,
  UserType,
} from '../types';
import { ScheduleSegment, SessionStatus } from '../../../types/types';
import useQuery from '../../../hooks/useQuery';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { resetSessionRemoval } from '../../../redux/modules/sessionRemoval';
import { DATE_FORMAT } from '../../../utils/helpers';
import { getWeekRange } from '../helpers';

type Props = {
  schedule: Array<ScheduleSegment>;
  userType: UserType;
  pageMode: PageMode;
  viewMode: MyScheduleViewMode;
  setPageMode: (pageMode: PageMode) => void;
  setViewMode: (viewMode: MyScheduleViewMode) => void;
  startOfWeek: moment.Moment;
  onNextWeek: () => void;
  onPrevWeek: () => void;
  switchPageMode: (v: PageMode) => void;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  sessionsToPreview: Array<ScheduleSegment>;
  showDetails: boolean;
  showSessionsDetails: (sessions: Array<ScheduleSegment>) => void;
  hideSessionsDetails: () => void;
  SegmentDialogContentComponent: React.FC<UserSpecificDialogContentProps>;
  pickerValue: { startDate: Date; endDate: Date };
  setWeekRange: (v: { startDate: Date; endDate: Date }) => void;
};

const noop = () => {
  /*  */
};

const PREFERRED_VIEW_MODE_STORAGE_KEY = 'my-schedule-preferred-view-mode';

const defaultValue = {
  schedule: [],
  userType: 'Client' as UserType,
  pageMode: PageMode.Schedule,
  viewMode: MyScheduleViewMode.grid,
  setViewMode: noop,
  setPageMode: noop,
  startOfWeek: moment(),
  onNextWeek: noop,
  onPrevWeek: noop,
  switchPageMode: noop,
  minDate: moment(),
  maxDate: moment(),
  sessionsToPreview: [],
  showDetails: false,
  showSessionsDetails: noop,
  hideSessionsDetails: noop,
  SegmentDialogContentComponent: () => null,
  pickerValue: {
    startDate: new Date(),
    endDate: new Date(),
  },
  setWeekRange: noop,
};

export const MyScheduleContext = createContext<Props>(defaultValue);

export const MyScheduleContextProvider = (props: {
  fullSchedule: Array<ScheduleSegment>;
  userType: UserType;
  SegmentDialogContentComponent: React.FC<UserSpecificDialogContentProps>;
  children: ReactNode;
}) => {
  const {
    fullSchedule,
    userType,
    SegmentDialogContentComponent,
    children,
  } = props;

  const clientFullScheduleExtended =
    useAppSelector((state) => state.client.clientFullScheduleExtended) || [];

  const scheduleExcludedRemoved =
    clientFullScheduleExtended?.filter((s) => {
      if ([SessionStatus.Cancelled, SessionStatus.TimeOff].includes(s.status)) {
        return false;
      }
      return true;
    }) || [];

  const query = useQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const [pageMode, setPageMode] = useState<PageMode>(() => {
    if (query.get('page-mode') === PageModeParam.remove) {
      return PageMode.Remove;
    }
    return PageMode.Schedule;
  });

  const [viewMode, setViewMode] = useState<MyScheduleViewMode>(() => {
    const preferredViewMode = localStorage.getItem(
      PREFERRED_VIEW_MODE_STORAGE_KEY
    ) as MyScheduleViewMode.grid | MyScheduleViewMode.list | null;

    return preferredViewMode || MyScheduleViewMode.list;
  });

  const [startOfWeek, setStartOfWeek] = useState(() =>
    moment().startOf('week')
  );

  const [pickerValue, setPickerValue] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: getWeekRange(startOfWeek)[0],
    endDate: getWeekRange(startOfWeek)[1],
  });

  const [sessionsToPreview, setSessionsToPreview] = useState<
    Array<ScheduleSegment>
  >([]);

  const [showDetails, setShowDetails] = useState(false);

  const schedule =
    pageMode === PageMode.TempAvail ? scheduleExcludedRemoved : fullSchedule;

  useEffect(() => {
    if (pageMode === PageMode.TempAvail) {
      setViewMode(MyScheduleViewMode.grid);
    }
  }, [pageMode]);

  useEffect(() => {
    const [nearestSession] = [...fullSchedule].sort((a, b) =>
      a.segmentStart > b.segmentStart ? 1 : -1
    );

    if (!nearestSession) {
      return;
    }

    const nearestSessionStartDate = nearestSession.scheduledDate;

    setStartOfWeek(moment(nearestSessionStartDate).startOf('week'));
  }, [fullSchedule]);

  const updateCancelMode = useCallback(() => {
    setPageMode(PageMode.Remove);
    navigate(`/schedule?page-mode=${PageModeParam.remove}`);
  }, [navigate]);

  const updateTempAvailMode = useCallback(() => {
    setPageMode(PageMode.TempAvail);
    navigate(`/schedule?page-mode=${PageModeParam.temporary}`);
  }, [navigate]);

  const updateStandardAvailMode = useCallback(() => {
    setPageMode(PageMode.StandardAvail);
    navigate(`/schedule?page-mode=${PageModeParam.standard}`);
  }, [navigate]);

  const resetPageMode = useCallback(() => {
    setPageMode(PageMode.Schedule);
    navigate(`/schedule?page-mode=${PageModeParam.schedule}`);
  }, [navigate]);

  useEffect(() => {
    if (query.get('page-mode') === PageModeParam.temporary) {
      updateTempAvailMode();
    }
    if (query.get('page-mode') === PageModeParam.standard) {
      updateStandardAvailMode();
    }
    if (query.get('page-mode') === PageModeParam.remove) {
      updateCancelMode();
    }
    if (query.get('page-mode') === PageModeParam.schedule) {
      resetPageMode();
    }
  }, [
    query,
    resetPageMode,
    updateCancelMode,
    updateStandardAvailMode,
    updateTempAvailMode,
  ]);

  useEffect(() => {
    if (pageMode === PageMode.Remove) {
      return;
    }

    dispatch(resetSessionRemoval());
  }, [dispatch, pageMode]);

  return (
    <MyScheduleContext.Provider
      value={{
        schedule,
        userType,
        SegmentDialogContentComponent,
        viewMode: isMobile ? MyScheduleViewMode.mobileGrid : viewMode,
        pageMode,
        setPageMode,
        startOfWeek,
        onNextWeek: () => {
          setStartOfWeek((oldValue) => {
            const [weekStart, weekEnd] = getWeekRange(
              oldValue.clone().add({ week: 1 })
            );
            setPickerValue({
              startDate: weekStart,
              endDate: weekEnd,
            });
            return oldValue.clone().add({ week: 1 });
          });
        },
        onPrevWeek: () => {
          setStartOfWeek((oldValue) => {
            const [weekStart, weekEnd] = getWeekRange(
              oldValue.clone().add({ week: -1 })
            );
            setPickerValue({
              startDate: weekStart,
              endDate: weekEnd,
            });
            return oldValue.clone().add({ week: -1 });
          });
        },
        switchPageMode(v: PageMode) {
          switch (v) {
            case PageMode.Remove:
              updateCancelMode();
              break;
            case PageMode.TempAvail:
              updateTempAvailMode();
              break;
            case PageMode.StandardAvail:
              updateStandardAvailMode();
              break;
            default:
              resetPageMode();
              break;
          }
        },
        setViewMode: (newViewMode) => {
          localStorage.setItem(PREFERRED_VIEW_MODE_STORAGE_KEY, newViewMode);
          setViewMode(newViewMode);
        },
        minDate: moment(),
        maxDate: (() => {
          if (pageMode === PageMode.TempAvail) {
            return moment().clone().add(6, 'months');
          }

          const [latestSession] = [...fullSchedule].sort((a, b) =>
            a.segmentStart > b.segmentStart ? -1 : 1
          );

          if (!latestSession) {
            return moment();
          }

          if (
            moment(startOfWeek).format(DATE_FORMAT) >
            latestSession.scheduledDate
          ) {
            const latestSessionDate = moment(
              latestSession.scheduledDate
            ).startOf('week');

            setStartOfWeek(latestSessionDate);
            const [weekStart, weekEnd] = getWeekRange(latestSessionDate);
            setPickerValue({
              startDate: weekStart,
              endDate: weekEnd,
            });
          }

          return moment(latestSession.scheduledDate);
        })(),
        showDetails,
        sessionsToPreview,
        showSessionsDetails: (sessions) => {
          setSessionsToPreview(sessions);
          setShowDetails(true);
        },
        hideSessionsDetails: () => {
          setShowDetails(false);
        },
        pickerValue,
        setWeekRange: (v) => {
          const formattedStartDate = v.startDate.toISOString().split('T')[0];

          setPickerValue(v);
          setStartOfWeek(moment(formattedStartDate));
        },
      }}
    >
      {children}
    </MyScheduleContext.Provider>
  );
};

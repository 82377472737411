import {
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ReplayIcon from '@material-ui/icons/Replay';
import clsx from 'clsx';
import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';

import InfoCircle from '../../../assets/info-circle.svg';
import InfoCircleBlue from '../../../assets/info-circle-blue.svg';
import { RootState } from '../../../redux/modules/rootReducer';
import EditableDate from '../../../shared/editableDate';
import AllowedAvailability from './AllowedAvailability';
import AvailabilityMatrix from '../../../components/Matrix/AvailabilityMatrix';
import { DATE_FORMAT } from '../../../utils/helpers';
import MatrixInfo from '../../../components/Matrix/MatrixInfo';
import useStyles, { maxWidthSize } from './AvailabilityActionsProviderStyles';
import { editMatrixBlock } from '../../../shared/lib/availability';
import useSettings from '../../../hooks/useSettings';
import { availabilityValidationSchema } from '../../../validation/validation';
import SucceedDialog from '../../../components/Availability/AvailabilityActions/SucceedDialog';
import { ProviderSchedule } from '../../../types/types';
import useAvailabilityActions, {
  InitialValues as FormikInitialValues,
} from '../../../components/Availability/hooks/useAvailabilityActions';
import AvailabilityActionsHeader from '../../../components/Availability/AvailabilityActions/AvailabilityActionsHeader';
import {
  editableDateStyles,
  getBlockMainStyles,
} from '../../../components/Availability/AvailabilityActions/AvailabilityActionsStyles';
import ActionsControlButton from '../../../components/buttons/ActionsControlButton';
import CustomStyledInfoCard from '../../../components/Availability/AvailabilityActions/CustomStyledInfoCard';
import DemandInfo from '../../../components/Matrix/DemandInfo';
import { AVAILABILITY_DATE_PRESENT_FORMAT } from '../../../components/Availability/constants';
import WeeklyHoursMeterInfo from './WeeklyHoursMeterInfo';
import VerticalLabelTimeBlock from '../../../shared/matrix/ui/verticalLabelTimeBlock';

interface AvailabilityActionsProviderProps {
  schedule?: ProviderSchedule[];
  handleSuccess?: () => void;
}

const AvailabilityActionsProvider = ({
  schedule,
  handleSuccess,
}: AvailabilityActionsProviderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { upcomingAvailabilityMinPeriod } = useSettings();
  const formikRef = useRef<
    FormikProps<FormikInitialValues & { editingStartDate: boolean }>
  >(null);

  const {
    status: actionStatus,
    availability,
    loading,
    succeeded,
  } = useSelector((state: RootState) => state.availability.action);

  const timeBlocks = useSelector(
    (state: RootState) => state.adminSettings.timeBlocks
  );
  const [showAllowedAvailability, setShowAllowedAvailability] = useState(false);

  const statusData = useAvailabilityActions({
    upcomingAvailabilityMinPeriod,
    availability,
  })[actionStatus];

  useEffect(() => {
    if (!formikRef.current) {
      return;
    }

    formikRef.current.setValues({
      ...formikRef.current.values,
      startDate: statusData.initialValues.startDate,
    });
  }, [upcomingAvailabilityMinPeriod, statusData.initialValues.startDate]);

  return (
    <>
      <AvailabilityActionsHeader
        handleGoBack={statusData.handleClose}
        label="Change Your Availability"
      />

      <Formik
        initialValues={{ ...statusData.initialValues, editingStartDate: false }}
        validationSchema={availabilityValidationSchema}
        onSubmit={(values) => statusData.onSubmit(values)}
        innerRef={formikRef}
        enableReinitialize
      >
        {({
          values: { availabilityBlocks, startDate, editingStartDate },
          handleSubmit,
          handleReset,
          setFieldValue,
          submitForm,
        }) => {
          const upcomingStandardAvailabilityBlocks = availabilityBlocks.map(
            (sch) => ({
              dayOfWeekId: sch.timeBlockDayOfWeek!.dayOfWeekId,
              isAvailable: sch.isAvailable as boolean,
            })
          );

          return (
            <Form onSubmit={handleSubmit}>
              {startDate && (
                <EditableDate
                  label="Start Date:"
                  value={startDate}
                  onEdit={editingStartDate}
                  onEditStart={() => {
                    setFieldValue('editingStartDate', true);
                  }}
                  valueFormat={DATE_FORMAT}
                  presentFormat={AVAILABILITY_DATE_PRESENT_FORMAT}
                  name="startDate"
                  onChange={(newValue) => {
                    setFieldValue('startDate', newValue);
                    setFieldValue('editingStartDate', false);
                  }}
                  styles={editableDateStyles({
                    maxWidth: theme.spacing(maxWidthSize),
                  })}
                  minDate={statusData.minStartDate}
                  shouldDisableDate={(day) => day?.weekday() !== 0}
                />
              )}

              <CustomStyledInfoCard
                overloadRootStyles={classes.pageMaxWidth}
                image={InfoCircle}
              >
                <Typography variant="body2">
                  You must provide at least{' '}
                  {moment
                    .duration(upcomingAvailabilityMinPeriod, 'week')
                    .humanize({ d: 7, w: 4 })}{' '}
                  prior notice for any availability change.
                </Typography>
              </CustomStyledInfoCard>

              <div className={classes.editableMatrixInfo}>
                <Divider
                  className={classes.matrixInfoSideBorder}
                  orientation="vertical"
                />
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Tap on blocks to add or remove them
                  </Typography>

                  <Button
                    variant="text"
                    startIcon={<img src={InfoCircleBlue} alt="info icon" />}
                    className={clsx(classes.mainColor, classes.textCapitalize)}
                    onClick={() => setShowAllowedAvailability(true)}
                  >
                    <Typography variant="body2">
                      See allowed availabilities
                    </Typography>
                  </Button>
                </div>
              </div>

              <AvailabilityMatrix
                schedule={schedule}
                availabilityBlocks={availabilityBlocks}
                cellSizes={isMobile ? 3.4 : 4}
                VerticalLabel={({ value }) => (
                  <VerticalLabelTimeBlock
                    value={value}
                    timeBlock={keyBy(timeBlocks, 'id')[value]}
                    styles={{
                      root: classes.matrixVerticalLabel,
                      name: classes.matrixVerticalLabelName,
                      content: classes.matrixVerticalLabelContent,
                    }}
                    periodNameLength={2}
                  />
                )}
                getBlockMainStyles={getBlockMainStyles}
                disableWeekends
                styles={{
                  root: classes.availabilityMatrix,
                }}
                onCellClick={({ key, isAvailable }) => {
                  setFieldValue(
                    'availabilityBlocks',
                    editMatrixBlock(key, isAvailable, availabilityBlocks)
                  );
                  setFieldValue('editingStartDate', false);
                }}
                showDemand
                CellWrapper={DemandInfo}
              />
              <MatrixInfo rootClassName={classes.matrixInfo} showDemand />

              <div className={classes.resetButtonWrapper}>
                <Button
                  startIcon={<ReplayIcon />}
                  className={clsx(classes.textCapitalize, classes.resetButton)}
                  variant="text"
                  onClick={handleReset}
                >
                  <Typography variant="body2">Reset to current</Typography>
                </Button>
              </div>
              <WeeklyHoursMeterInfo
                blocks={upcomingStandardAvailabilityBlocks}
              />
              <ActionsControlButton
                disabled={{ successButton: loading, cancelButton: loading }}
                handleClick={{
                  successButton: submitForm,
                  cancelButton: statusData.handleClose,
                }}
                label={{
                  successButton: 'Save',
                  cancelButton: 'Cancel',
                }}
                styles={{ buttonRoot: classes.pageMaxWidth }}
              />
            </Form>
          );
        }}
      </Formik>
      <AllowedAvailability
        open={showAllowedAvailability}
        handleClose={() => setShowAllowedAvailability(false)}
      />
      {succeeded && (
        <SucceedDialog handleClose={handleSuccess}>
          Thank you for submitting your Availability. Please allow the
          Scheduling Team to process the changes and notify impacted clients of
          any new schedule adjustments.
        </SucceedDialog>
      )}
    </>
  );
};

export default AvailabilityActionsProvider;

import React from 'react';
import type { ChannelListMessengerProps } from 'stream-chat-react';
import './TeamChannelList.css';
import { Hidden, Typography } from '@material-ui/core';
import CustomLoading from '../../components/CustomLoading/CustomLoading';

export type TeamChannelListProps = ChannelListMessengerProps & {
  type: string;
  children?: React.ReactNode;
};

const ChannelList: React.FC<TeamChannelListProps> = (
  props: TeamChannelListProps
) => {
  const { children, error = false, loading, type } = props;

  if (error) {
    return type === 'team' ? (
      <div className="team-channel-list">
        <p className="team-channel-list__message">
          Connection error, please wait a moment and try again.
        </p>
      </div>
    ) : null;
  }

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <div className="team-channel-list">
      <div className="team-channel-list__header">
        <Hidden smDown implementation="css">
          <Typography variant="h6">Channels</Typography>
        </Hidden>
        <Hidden mdUp implementation="css">
          <Typography variant="subtitle2" color="textSecondary">
            CHANNELS
          </Typography>
        </Hidden>
      </div>
      {children}
    </div>
  );
};

export const TeamChannelList = React.memo(ChannelList);

import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  datesWrapper: {
    display: 'flex',
  },
  datePicker: {
    minWidth: theme.spacing(18),
    width: theme.spacing(18),
    '& input': {
      padding: 0,
    },

    '& p': {
      display: 'none',
    },
  },
  editButton: {
    padding: 0,
    minWidth: 0,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.2),
    color: theme.palette.primary.main,
  },
  label: {
    marginRight: theme.spacing(0.5),
  },
}));

interface EditableDateProps extends Omit<KeyboardDatePickerProps, 'onChange'> {
  label?: string;
  onEdit?: boolean;
  value: string;
  valueFormat: string;
  presentFormat?: string;
  onEditStart: () => void;
  onChange: (value: string) => void;
  disableEdit?: boolean;
  styles?: Partial<
    Record<
      'root' | 'label' | 'dataPicker' | 'valueWrapper' | 'value' | 'editButton',
      string
    >
  >;
}

const EditableDate = ({
  label,
  onEdit = false,
  value,
  valueFormat,
  presentFormat,
  onEditStart,
  onChange,
  disableEdit = false,
  styles,
  ...restProps
}: EditableDateProps) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <div className={clsx(styles?.root, classes.datesWrapper)}>
        <Typography className={clsx(styles?.label, classes.label)}>
          {label}
        </Typography>
        {onEdit ? (
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format={valueFormat}
            value={value || null}
            className={clsx(styles?.dataPicker, classes.datePicker)}
            required
            onChange={(newValue) => {
              const formattedDate = newValue?.format(valueFormat);
              return formattedDate && onChange(formattedDate);
            }}
            {...restProps}
          />
        ) : (
          <Typography className={styles?.valueWrapper}>
            <span className={styles?.value}>
              {moment(value).format(presentFormat || valueFormat)}
            </span>
            {!disableEdit && (
              <Button
                className={clsx(styles?.editButton, classes.editButton)}
                onClick={onEditStart}
              >
                Edit
              </Button>
            )}
          </Typography>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default EditableDate;

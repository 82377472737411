import React, { useState } from 'react';
import { Channel, ChannelFilters, ChannelSort, StreamChat } from 'stream-chat';
import { Hidden, Typography } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import { Chat, CustomStyles } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import { ChannelListContainer } from './ChannelListContainer/ChannelListContainer';
import { ChannelContainer } from './ChannelContainer/ChannelContainer';
import './chat.css';
import StyledButtonLink from '../components/buttons/StyledButtonLink';
import AnalyticsTracker, { TrackerNames } from '../analytics/AnalyticsTracker';

const customStyles: CustomStyles = {
  '--main-font': 'Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
  '--second-font': 'Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
};

const options = { state: true, watch: true, presence: true };
// eslint-disable-next-line @typescript-eslint/camelcase
const sort = { last_message_at: -1, updated_at: -1 } as ChannelSort;
type AttainChatProps = {
  showChannels: boolean;
  chatClient: StreamChat;
  channelFilters: ChannelFilters;
  channelId?: string | null;
  isChannelListEmpty: boolean;
  onChannelChange?: (channel: Channel) => void;
};

const AttainChat: React.FC<AttainChatProps> = (props: AttainChatProps) => {
  const {
    chatClient,
    channelId,
    channelFilters,
    showChannels,
    isChannelListEmpty,
    onChannelChange,
  } = props;
  const [showChannelList, setShowChannelList] = useState(false);

  const handleChannelChange = (ch: Channel, closeChannelList = true) => {
    if (closeChannelList) {
      setShowChannelList(false);
    }

    if (onChannelChange) {
      onChannelChange(ch);
    }
  };

  if (isChannelListEmpty) {
    return (
      <>
        <AnalyticsTracker
          name={TrackerNames.AddSessionsSelectTime}
          delay={1000}
        />
        <Typography>
          You don&apos;t have a Team Chat channel yet. Please check back later.
        </Typography>
      </>
    );
  }

  return (
    <>
      <Hidden mdUp implementation="css">
        {!showChannelList && showChannels && (
          <div className="attain-chat__mobile-nav-bar">
            <StyledButtonLink
              onClick={() => {
                setShowChannelList(true);
              }}
              startIcon={<KeyboardBackspace />}
            >
              Team Chat
            </StyledButtonLink>
          </div>
        )}
      </Hidden>
      <Hidden smDown implementation="css">
        <div className="attain-chat__header">
          <Typography variant="body2" color="textSecondary">
            Team Chat connects clients to their care team. Type @name to send an
            alert to a specific person. Do not discuss health information in
            chat.
          </Typography>
          {showChannels && (
            <Typography variant="body2" color="textSecondary">
              Contact Scheduling if your client&apos;s channel is missing.
            </Typography>
          )}
        </div>
      </Hidden>

      <div className="attain-chat">
        <AnalyticsTracker name={TrackerNames.TeamChat} delay={1000} />
        <Chat {...{ client: chatClient }} customStyles={customStyles}>
          {channelId && !showChannels ? (
            <ChannelContainer
              {...{
                className: 'active',
                channelId,
              }}
            />
          ) : (
            <>
              <ChannelListContainer
                {...{
                  filters: channelFilters,
                  options,
                  sort,
                  className: showChannelList ? 'active' : '',
                  onChannelChange: handleChannelChange,
                }}
              />
              <ChannelContainer
                {...{
                  className: showChannelList ? '' : 'active',
                }}
              />
            </>
          )}
        </Chat>
      </div>
    </>
  );
};

export default AttainChat;

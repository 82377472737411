import React, { useState } from 'react';
import {
  TableCell,
  TableRow,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables';
import moment from 'moment';
import CustomTableFooter from '../../components/table/CustomTableFooter';
import LocationCell from '../../components/table/LocationCell';
import SessionTimeCell from '../../components/table/SessionTimeCell';
import StyledCheckbox from '../../components/StyledCheckbox/StyledCheckbox';
import SessionCard from '../../components/SessionCard/SessionCard';
import TimeOffInfo from '../../components/SessionCard/TimeOffInfo';
import ProviderSessionDetails from '../../components/SessionCard/ProviderSessionDetails';
import { getCentralReachLink } from './SessionsForProvider';
import { DATE_FORMAT } from '../../utils/helpers';
import { RemoveSessionVariantText } from '../../shared/constants';

import {
  Provider,
  RemoveSessionVariant,
  ScheduleSegment,
  SessionStatus,
} from '../../types/types';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [RemoveSessionVariant.cancellation]: {
    color: theme.palette.kyoRed.main,
    whiteSpace: 'nowrap',
  },
  [RemoveSessionVariant.timeOff]: {
    color: theme.palette.kyoGreen.main,
    whiteSpace: 'nowrap',
  },
  [RemoveSessionVariant.retraction]: {
    color: theme.palette.kyoBurntOrange.main,
    whiteSpace: 'nowrap',
  },
  timeOffInfoCell: {
    padding: theme.spacing(4, 0, 2, 0),
    borderBottom: 'none',
  },
  expandedTableCell: {
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  darkBg: {
    backgroundColor: 'rgba(229, 229, 229, 0.5)',
  },
  p0: {
    padding: 0,
  },
  CRLink: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.kyoBlue.main,
    '& > p:first-child': {
      whiteSpace: 'nowrap',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const getColumns = (
  classes: ReturnType<typeof useStyles>,
  sessions: ScheduleSegment[],
  provider: Provider | null,
  providerZipCode: string | null,
  handleRowExpand: (id: number, expanded: boolean) => void,
  expandedRows: Array<number>,
  multiSelectMode: boolean,
  selectedSessions: ScheduleSegment[],
  handleSelect: (id: number, value: boolean) => void,
  isSmallScreen?: boolean
): MUIDataTableColumn[] => [
  {
    name: 'Select',
    label: '',
    options: {
      sort: false,
      display: multiSelectMode || 'excluded',
      customBodyRenderLite: (dataIndex) => {
        const session = sessions[dataIndex];
        if (
          !session.segmentId ||
          session.status !== SessionStatus.Confirmed ||
          !session.isCancellableByProvider
        ) {
          return null;
        }

        return (
          <div className={classes.select}>
            <StyledCheckbox
              checked={
                !!selectedSessions.find(
                  (s) => s.segmentId === session.segmentId
                )
              }
              onChange={(_, newValue) => {
                if (session.segmentId) {
                  handleSelect(session.segmentId, newValue);
                }
              }}
            />
            <Typography
              display="inline"
              variant="subtitle2"
              className={
                classes[
                  session.removeSessionVariant ||
                    RemoveSessionVariant.cancellation
                ]
              }
            >
              {session.removeSessionVariant &&
                RemoveSessionVariantText[session.removeSessionVariant]}
            </Typography>
          </div>
        );
      },
      ...(isSmallScreen ? { customHeadRender: () => null } : {}),
    },
  },
  {
    name: '',
    label: 'Session',
    options: {
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        const session = sessions[dataIndex];
        const onViewDetails = (newState: boolean) => {
          handleRowExpand(session.segmentId, newState);
        };

        return (
          provider && (
            <SessionCard
              key={session.segmentId}
              session={session}
              sessionWith={`${session.clientId}`}
              onViewDetails={onViewDetails}
              showDetailsDefault={expandedRows.includes(session.segmentId)}
              showCancelLink={false}
              userType="Provider"
            />
          )
        );
      },
      ...(isSmallScreen ? { customHeadRender: () => null } : {}),
    },
  },
  {
    name: 'centralReach',
    label: 'CR',
    options: {
      display: isSmallScreen ? 'excluded' : true,
      customBodyRenderLite: (dataIndex) => {
        const { providerId, segmentStartUtc, isBookRequest } = sessions[
          dataIndex
        ];
        const isFromCR = !isBookRequest;
        const sessionStartDate = moment(segmentStartUtc).format(DATE_FORMAT);

        return (
          isFromCR &&
          providerId &&
          getCentralReachLink({
            id: providerId,
            date: sessionStartDate,
            styles: classes.CRLink,
          })
        );
      },
    },
  },
  {
    name: '',
    label: 'Location',
    options: {
      display: isSmallScreen ? 'excluded' : true,
      customBodyRenderLite: (dataIndex) => {
        const session = sessions[dataIndex];
        const roundedDistance =
          session.distanceInMiles && Math.round(session.distanceInMiles);
        const zipCodes = [];
        if (providerZipCode) {
          zipCodes.push(providerZipCode);
        }

        zipCodes.push(String(session.client.zipCode));

        return (
          <LocationCell
            zip={session.client.zipCode}
            distance={roundedDistance}
            dir="row"
          />
        );
      },
    },
  },

  {
    name: '',
    label: 'Session Time',
    options: {
      sort: false,
      display: isSmallScreen ? 'excluded' : true,
      customBodyRenderLite: (dataIndex) => {
        const session = sessions[dataIndex];
        return <SessionTimeCell session={session} />;
      },
    },
  },
];

export interface ProviderSessionsTableProps {
  data: Array<ScheduleSegment>;
  provider: Provider | null;
  selectMode: boolean;
  sessionsToRemove: ScheduleSegment[];
  onRowSelection: (segmentId: number, checked: boolean) => void;
  options?: MUIDataTableOptions;
  isSmallScreen: boolean;
  handleRefresh?: () => void;
}

const ProviderSessionsTable = ({
  handleRefresh: handleRefreshProp,
  data,
  provider,
  selectMode,
  sessionsToRemove,
  onRowSelection,
  options: optionsParam,
  isSmallScreen,
}: ProviderSessionsTableProps) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const handleRowExpand = (id: number, expanded: boolean) => {
    if (expanded) {
      setExpandedRows((curr) => [...curr, id]);
    } else {
      setExpandedRows((curr) => curr.filter((i) => i !== id));
    }
  };

  const handleRefresh =
    handleRefreshProp &&
    (() => {
      handleRefreshProp();
      setExpandedRows([]);
    });

  const options: MUIDataTableOptions = {
    viewColumns: false,
    selectableRows: 'none',
    search: false,
    print: false,
    sort: false,
    filter: false,
    download: false,
    elevation: 0,
    responsive: 'standard',
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15],
    rowHover: false,
    expandableRows: true,
    expandableRowsHeader: false,
    onChangeRowsPerPage: (newRowsPerPage) => setRowsPerPage(newRowsPerPage),
    customRowRender(columns, dataIndex) {
      const session = data[dataIndex];
      let timeOffInfoComp = null;

      if (session.isFirstTimeOff && selectMode) {
        timeOffInfoComp = (
          <TableRow>
            <TableCell colSpan={7} className={classes.timeOffInfoCell}>
              <TimeOffInfo type="provider" />
            </TableCell>
          </TableRow>
        );
      }

      return (
        <React.Fragment key={session.segmentId}>
          {timeOffInfoComp}
          <TableRow
            className={
              expandedRows.includes(session.segmentId) ? classes.darkBg : ''
            }
          >
            {selectMode && <TableCell>{columns[0](dataIndex)}</TableCell>}
            <TableCell style={{ paddingLeft: 0 }}>
              {columns[1](dataIndex)}
            </TableCell>
            {!isSmallScreen && <TableCell>{columns[2](dataIndex)}</TableCell>}
            {!isSmallScreen && <TableCell>{columns[3](dataIndex)}</TableCell>}
            {!isSmallScreen && <TableCell>{columns[4](dataIndex)}</TableCell>}
          </TableRow>
          {!isSmallScreen && expandedRows.includes(session.segmentId) && (
            <TableRow>
              <TableCell colSpan={selectMode ? 5 : 4} className={classes.p0}>
                <ProviderSessionDetails
                  session={session}
                  hideButtons
                  extendedDetails={!isSmallScreen}
                  horizontalMode
                  refresh={handleRefresh}
                />
              </TableCell>
            </TableRow>
          )}
          {session &&
            isSmallScreen &&
            expandedRows.includes(session.segmentId) && (
              <TableRow>
                <TableCell colSpan={1000} className={classes.expandedTableCell}>
                  <ProviderSessionDetails
                    session={session}
                    hideButtons
                    extendedDetails={isSmallScreen}
                    refresh={handleRefresh}
                  />
                </TableCell>
              </TableRow>
            )}
        </React.Fragment>
      );
    },
    rowsExpanded: data.reduce((acc, item, index) => {
      if (expandedRows.includes(item.segmentId)) {
        acc.push(index);
      }
      return acc;
    }, [] as Array<number>),
  };

  return (
    <MUIDataTable
      options={{ ...options, ...optionsParam }}
      title=""
      data={data}
      columns={getColumns(
        classes,
        data,
        provider,
        provider?.address?.zip || provider?.homeZip || null,
        handleRowExpand,
        expandedRows,
        selectMode,
        sessionsToRemove,
        onRowSelection,
        isSmallScreen
      )}
      components={{
        TableFooter: CustomTableFooter,
      }}
    />
  );
};

export default ProviderSessionsTable;

import requester from '../requester';
import { NotificationPreference, User } from '../../types/types';

const fetchCurrentUser = async () => {
  return requester
    .get(`users/me`)
    .then((response) => response.json() as Promise<{ data: User }>);
};

const fetchRelationships = async () => {
  return requester
    .get(`users/relationships?type=client`)
    .then((response) => response.json());
};

const updateUser = async (
  userId: number,
  firstName: string,
  lastName: string,
  mobileNumber: string
) => {
  return requester
    .put(`users/${userId}`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ firstName, lastName, mobileNumber }),
    })
    .then((response) => response.json());
};

const verifyUserClient = async (
  userId: number,
  clientId: number,
  dob: string
) => {
  return requester
    .put(`users/${userId}/clients/${clientId}/verified`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ dob }),
    })
    .then((response) => response.json());
};

const updateUserRelationship = async (
  userId: number,
  clientId: number,
  relationshipId: number
) => {
  return requester
    .put(`users/${userId}/clients/${clientId}/relationship`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ relationshipId }),
    })
    .then((response) => response.json());
};

const getUserNotificationPreferences = async (
  userId: number
): Promise<NotificationPreference[]> => {
  return requester
    .get(`notificationPreferences/user/${userId}`, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

const replaceUserNotificationPreferences = async (
  userId: number,
  notificationPreferences: NotificationPreference[]
): Promise<void> => {
  return requester
    .put(`notificationPreferences/user/${userId}`, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ notificationPreferences }),
    })
    .then((response) => response.json());
};

export default {
  fetchCurrentUser,
  fetchRelationships,
  updateUser,
  verifyUserClient,
  updateUserRelationship,
  getUserNotificationPreferences,
  replaceUserNotificationPreferences,
};

import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import MatrixCell, { MatrixCellProps } from '../../../shared/matrix/ui/cell';

const useEmptyCellStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.kyoSilver.light,
  },
}));

const EmptyCell = ({ className }: Omit<MatrixCellProps, 'children'>) => {
  const classes = useEmptyCellStyles();

  return <MatrixCell className={clsx(classes.root, className)} />;
};

export default EmptyCell;

import React, { useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import keyBy from 'lodash/keyBy';

import AvailabilityMatrix, {
  AvailabilityMatrixState,
} from '../../components/Matrix/AvailabilityMatrix';
import MatrixInfo from '../../components/Matrix/MatrixInfo';
import { ClientSchedule } from '../../types/types';
import AvailabilityInfo from './availability/AvailabilityInfo';

import type { RootState } from '../../redux/modules/rootReducer';
import VerticalLabelTimeBlock from '../../shared/matrix/ui/verticalLabelTimeBlock';

const useStyles = makeStyles((theme) => ({
  title: {
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: theme.spacing(2),
    },
  },
  closeIcon: {
    fontSize: theme.spacing(3),
    cursor: 'pointer',
    color: theme.palette.kyoBlue.light,
  },
  infoText: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  infoTextHeader: {
    padding: theme.spacing(0, 2),
  },
  matrixRoot: {
    padding: theme.spacing(1, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },
  matrixWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  matrixInfoRoot: {
    padding: theme.spacing(1, 4),
    paddingLeft: 80,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
      justifyContent: 'flex-end',
    },
  },
  verticalLabelRoot: {
    display: 'flex',
  },
  verticalLabelContent: {
    marginLeft: theme.spacing(1),
  },
  [AvailabilityMatrixState.Available]: {
    backgroundColor: '#CCE2FF',
  },
  [AvailabilityMatrixState.NotAvailable]: {
    backgroundColor: theme.palette.kyoGray.main,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.5),
    },
  },
}));

const getMatrixMainBlockStyles = (theme: Theme) => ({
  [theme.breakpoints.down('xs')]: {
    margin: theme.spacing(0.5),
  },
});

interface PreferencesPopupProps {
  isOpen: boolean;
  handleClose: () => void;
  schedule: ClientSchedule[];
}

const PreferencesPopup = ({
  isOpen,
  handleClose,
  schedule,
}: PreferencesPopupProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const timeBlocks = useSelector(
    (state: RootState) => state.adminSettings.timeBlocks
  );
  const timeBlocksMap = useMemo(() => keyBy(timeBlocks, 'id'), [timeBlocks]);

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      fullScreen={isMobile}
      maxWidth="lg"
    >
      <DialogTitle className={classes.title}>
        My Availability
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </DialogTitle>
      <div className={clsx(classes.infoText, classes.infoTextHeader)}>
        To optimize scheduling for clients and providers, we organize each day
        into three time blocks.
      </div>
      <div className={classes.matrixWrapper}>
        <AvailabilityMatrix
          schedule={schedule}
          cellSizes={4.3}
          showBooked={false}
          cellStyleOverride={{
            [AvailabilityMatrixState.Available]:
              classes[AvailabilityMatrixState.Available],
            [AvailabilityMatrixState.NotAvailable]:
              classes[AvailabilityMatrixState.NotAvailable],
          }}
          getBlockMainStyles={getMatrixMainBlockStyles}
          styles={{
            root: classes.matrixRoot,
          }}
          useVerticalLabelsInsideRows
          VerticalLabel={({ value }) => (
            <VerticalLabelTimeBlock
              value={value}
              timeBlock={timeBlocksMap[value]}
              styles={{
                root: classes.verticalLabelRoot,
                content: classes.verticalLabelContent,
              }}
            />
          )}
        />
      </div>
      <MatrixInfo
        showBooked={false}
        dotStyleOverride={{
          [AvailabilityMatrixState.Available]:
            classes[AvailabilityMatrixState.Available],
        }}
        rootClassName={classes.matrixInfoRoot}
      />
      <AvailabilityInfo schedule={schedule} popup />
    </Dialog>
  );
};

export default PreferencesPopup;

import React from 'react';
import { makeStyles } from '@material-ui/core';

import { humanizeDuration } from '../../../utils/helpers';

interface CellInfoProps {
  startTime: string;
  duration: number;
}

const useStyles = makeStyles((theme) => ({
  time: {
    fontWeight: 700,
  },
  duration: {
    fontWeight: 600,
    fontSize: '12px',
  },
}));

const CellInfo = ({ startTime, duration }: CellInfoProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.time}>{startTime}</div>
      <div className={classes.duration}>{humanizeDuration(duration)}</div>
    </>
  );
};

export default CellInfo;

import requester from '../requester';
import { AttendanceAward } from '../../redux/modules/adminSettings';
import { AttendanceStatusThreshold } from '../../types/types';

const fetchAttendanceAwards = async () => {
  return requester
    .get(`settings/attendance-awards`)
    .then((response) => response.json() as Promise<{ data: AttendanceAward[] }>)
    .then((data) => data.data);
};

const fetchAttendanceStatusThresholds = async () => {
  return requester
    .get(`settings/attendance-status-thresholds`)
    .then(
      (response) =>
        response.json() as Promise<{ data: AttendanceStatusThreshold[] }>
    )
    .then((data) => data.data);
};

const fetchSettings = async () => {
  return requester.get(`settings`).then((response) => response.json());
};

const fetchKyoClimbSettings = async () => {
  return requester
    .get(`settings/kyo-climb-settings`)
    .then((response) => response.json());
};

export default {
  fetchAttendanceAwards,
  fetchAttendanceStatusThresholds,
  fetchSettings,
  fetchKyoClimbSettings,
};

import { useRef, useEffect } from 'react';
import useAnalyticsData from './useAnalyticsData';
import { storeAnalytics } from '../api/analyticsApi';

type Props = {
  name: TrackerNames;
  delay?: number; // milliseconds
};

const AnalyticsTracker = (props: Props) => {
  const { name, delay = 0 } = props;
  const ref = useRef({ tracked: false });

  const data = useAnalyticsData();

  useEffect(() => {
    const trackedState = ref.current;
    const { isReady, selectedClientId, providerId } = data;
    let timer: NodeJS.Timeout;

    if (!trackedState.tracked && isReady) {
      trackedState.tracked = true;

      timer = setTimeout(() => {
        storeAnalytics({
          visitedPage: name,
          clientId: selectedClientId,
          providerId,
        }).catch((e) => {
          console.error(e);
        });
      }, delay);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      trackedState.tracked = false;
    };
  }, [name, data, delay]);

  return null;
};

export enum TrackerNames {
  Home = 'Home',
  MyScheduleViewSessions = 'MySchedule/ViewSessions',
  MyScheduleRemoveSessions = 'MySchedule/RemoveSessions',
  MyScheduleShortTermAvailability = 'MySchedule/ShortTermAvailability',
  MyScheduleStandardAvailability = 'MySchedule/StandardAvailability',
  AddSessionsSelectProvider = 'AddSessions/SelectProvider',
  AddSessionsSelectTime = 'AddSessions/SelectTime',
  AddSessionsResultScreen = 'AddSessions/ResultScreen',
  AddSessionsTryLater = 'AddSessions/TryLater',
  Availability = 'Availability',
  TeamChat = 'TeamChat',
  Alerts = 'Alerts',
  MyRequests = 'MyRequests',
  Settings = 'Settings',
  ContactUs = 'ContactUs',
  Help = 'Help',
  MyProfile = 'MyProfile',
  MyUserProfile = 'MyUserProfile',
  KyoClimb = 'KyoClimb',
  MenteeList = 'MenteeList',
  MenteeProfile = 'MenteeProfile',
  OpenSessions = 'OpenSessions',
  Billing = 'Billing',
}

export default AnalyticsTracker;

import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import moment from 'moment';

import {
  DATE_FORMAT_US,
  getFormattedWeekPickerDates,
} from '../../utils/helpers';
import StyledMultipleDatePicker from '../../components/styledMultipleDatePicker/StyledMultipleDatePicker';
import { WeekRange } from './NewSessions';

const useStyles = makeStyles((theme) => ({
  weekSelectorWithButtonsWrapper: {
    display: 'flex',
    border: `1px solid ${theme.palette.kyoGray.main}`,
    borderRadius: theme.spacing(0.5),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  weekPicker: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  weekIcon: {
    color: theme.palette.kyoBlue2.main,
  },
  firstIcon: {
    borderRadius: 0,
    borderLeft: `1px solid #F0F0F0`,
    borderRight: `1px solid #F0F0F0`,
  },
}));

const AddSessionsWeekSelector = ({
  weekRange: { weekStart, weekEnd },
  handleChange,
  minDate,
  maxDate,
}: {
  weekRange: WeekRange;
  handleChange: (values: string[]) => void;
  minDate: moment.Moment;
  maxDate: moment.Moment;
}) => {
  const classes = useStyles();

  const handleDirectedWeekChange = (weeksCount: number) => {
    const newWeek = moment(weekStart, DATE_FORMAT_US).add(weeksCount, 'weeks');

    handleChange([
      newWeek.clone().startOf('week').format(DATE_FORMAT_US),
      newWeek.clone().endOf('week').format(DATE_FORMAT_US),
    ]);
  };

  return (
    <div className={classes.weekSelectorWithButtonsWrapper}>
      <div className={classes.weekPicker}>
        <StyledMultipleDatePicker
          editable={false}
          value={[weekStart, weekEnd]}
          weekPicker
          format={DATE_FORMAT_US}
          onChange={(value) => {
            handleChange(
              getFormattedWeekPickerDates(value, DATE_FORMAT_US) || []
            );
          }}
          portal
          style={{
            backgroundColor: 'transparent',
            height: '52px',
            borderRadius: 0,
          }}
          minDate={minDate.clone().toDate()}
          maxDate={maxDate.clone().subtract(1, 'day').toDate()}
        />
        <DateRangeIcon className={classes.weekIcon} />
      </div>
      <IconButton
        className={classes.firstIcon}
        disabled={moment(weekStart, DATE_FORMAT_US).weeks() === minDate.weeks()}
        onClick={() => {
          handleDirectedWeekChange(-1);
        }}
        aria-label="arrowLeft"
      >
        <KeyboardArrowLeftRoundedIcon />
      </IconButton>
      <IconButton
        disabled={moment(weekEnd, DATE_FORMAT_US).weeks() === maxDate.weeks()}
        onClick={() => {
          handleDirectedWeekChange(1);
        }}
        aria-label="arrowRight"
      >
        <KeyboardArrowRightRoundedIcon />
      </IconButton>
    </div>
  );
};

export default AddSessionsWeekSelector;

import React, {
  useEffect,
  useRef,
  Suspense,
  lazy,
  useLayoutEffect,
} from 'react';
import {
  Container,
  Box,
  Typography,
  Link as MuiLink,
  CircularProgress,
  Hidden,
  Drawer,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Route, Routes, Navigate } from 'react-router-dom';
import Amplify from '@aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Location, useNavigate } from 'react-router';
import './App.css';
import moment from 'moment';
import 'moment-timezone';
import queryString from 'query-string';

import { Channel } from 'stream-chat';
import configs from './configs';
import useGtag from './hooks/useGtag';
import {
  fetchAttendanceAwards,
  fetchAttendanceStatusThresholds,
  fetchKyoClimbSettings,
  fetchSettings,
  fetchTimeBlocks,
} from './redux/modules/adminSettings';
import {
  fetchClientCaseTeam,
  fetchCurrentClientById,
  setCurrentClient,
} from './redux/modules/client';
import { useAuth, UserType } from './hooks/useAuth';

import { fetchCurrentUser, setCurrentUser } from './redux/modules/users';
import { RootState } from './redux/modules/rootReducer';
import { useAppDispatch } from './redux/store';
import usePermission from './hooks/usePermission';
import { Resources } from './accessControl/acl';
import AddSessions from './clientPortal/sessions/AddSessions';
import useMaintenanceCheck from './hooks/useMaintenanceCheck';
import Maintenance from './components/Maintenance';
import { closeSocketConnection, openSocketConnection } from './api/socketApi';
import { resetSessionRemoval } from './redux/modules/sessionRemoval';
import AttainChat from './chat/AttainChat';
import ErrorBoundary from './components/ErrorBoundry/ErrorBoundry';
import useAttainChat from './hooks/useAttainChat';
import DrawerItemsList from './components/DrawerItemsList';
import AppHeader from './components/AppHeader';
import useApiVersionCheck from './hooks/useApiVersionCheck';
import AppErrors from './components/AppErrors/AppErrors';
import { fetchProvider } from './redux/modules/provider';
import { UserAssociation } from './types/types';
import UserAvailability from './clientPortal/myAvailability/UserAvailability';
import { MY_AVAILABILITY_PAGE } from './utils/constants';
import { fetchMenteeProviders } from './redux/modules/menteeProviders';
import { HelpPageProvider } from './commonPages/help/HelpPageContext';
import useActivityTracker from './hooks/useActivityTracker';
import useSettings from './hooks/useSettings';
import { minutesToMilliseconds } from './utils/helpers';
import usePreviousPathByRoutesAccess from './hooks/usePreviousPath';

moment.updateLocale('precise-en', { relativeTime: { h: '1 hour' } });

const Home = lazy(() => import('./clientPortal/home/Home'));

const ProviderProfile = lazy(
  () => import('./providerPortal/providerProfile/ProviderProfile')
);

const Requests = lazy(() => import('./providerPortal/requests/Requests'));

const OpenSessions = lazy(
  () => import('./providerPortal/requests/openSessions/OpenSessions')
);
const MyRequests = lazy(() => import('./clientPortal/requests/MyRequests'));
const VerificationForm = lazy(
  () => import('./clientPortal/verificationForm/VerificationForm')
);
const Login = lazy(() => import('./commonPages/login/Login'));
const ForgotPasswordForm = lazy(
  () => import('./commonPages/login/ForgotPasswordForm')
);
const SessionsRemovalResults = lazy(
  () => import('./commonPages/SessionsRemovalResults/SessionsRemovalResults')
);
const MyUserProfile = lazy(
  () => import('./clientPortal/myUserProfile/MyUserProfile')
);

const HomeForProvider = lazy(
  () => import('./providerPortal/home/HomeForProvider')
);
const Alerts = lazy(() => import('./commonPages/messages/Messages'));
const MyClientProfile = lazy(() => import('./clientPortal/myProfile'));
const HelpPage = lazy(() => import('./commonPages/help/HelpPage'));
const Contact = lazy(
  () => import('./clientPortal/contactScheduling/ContactScheduling')
);

const ClientHelpPage = lazy(
  () => import('./commonPages/help/client/ClientHelpPage')
);
const ProviderHelpPage = lazy(
  () => import('./commonPages/help/provider/ProviderHelpPage')
);

const SwitchToSpanishPage = lazy(
  () => import('./commonPages/languages/SwitchToSpanishPage')
);

const ClientSettingsPage = lazy(
  () => import('./clientPortal/ClientSettingsPage')
);

const ProviderSettingsPage = lazy(
  () => import('./providerPortal/ProviderSettingsPage')
);
const MyAvailability = lazy(
  () => import('./providerPortal/myAvailability/MyAvailability')
);
const Climb = lazy(() => import('./providerPortal/climb/Climb'));
const MenteeProviders = lazy(
  () => import('./providerPortal/menteeProviders/MenteeProviders')
);
const MyScheduleProvider = lazy(
  () => import('./providerPortal/MySchedule/MyScheduleProvider')
);
const MyScheduleClient = lazy(
  () => import('./clientPortal/MySchedule/MyScheduleClient')
);
// const Billing = lazy(() => import('./clientPortal/billing/Billing'));

Amplify.configure(configs.cognito);
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      border: 'none',
      background: theme.palette.grey[100],
      [theme.breakpoints.down('md')]: {
        height: '100%',
        width: '100%',
      },
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      minHeight: `calc(100vh - ${theme.spacing(12)}px)`,
      [theme.breakpoints.up('lg')]: {
        minHeight: `calc(100vh - ${theme.spacing(20)}px)`,
      },
      background: theme.palette.common.white,
    },
    contentBox: {
      paddingTop: theme.spacing(2),
    },
    toolbar: {
      minHeight: theme.spacing(20),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3.5),
        minHeight: theme.spacing(11.5),
        justifyContent: 'space-between',
      },
      justifyContent: 'space-around',
    },
    logo: {
      width: theme.spacing(23),
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(12.5),
      },
    },
    rectangles: {
      paddingLeft: theme.spacing(2),
      width: theme.spacing(86),
      height: theme.spacing(16),
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    navBarControls: {
      display: 'flex',
      alignItems: 'center',
    },
    sideBar: {
      height: '100%',
    },
    suspenseLoading: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
});

const NotFound = ({ previousPath }: { previousPath?: string }) => {
  const { isAuthenticated } = useAuth();

  let navigateTo = '/login';
  if (isAuthenticated && previousPath) {
    navigateTo = previousPath;
  } else if (isAuthenticated) {
    navigateTo = '/home';
  }

  return <Navigate to={navigateTo} />;
};

const SessionsHOC = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(resetSessionRemoval());
    },
    [dispatch]
  );

  return children;
};

const App: React.FC = () => {
  useApiVersionCheck();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const {
    isAuthenticated,
    initialLoading,
    loading,
    setLastLoggedInUserType,
    signOut,
  } = useAuth();
  const { maintenanceStatusOn } = useMaintenanceCheck();
  const location = useLocation();
  const navigate = useNavigate();
  const checkPermission = usePermission();
  const initialLocationRef = useRef<Location | null>(location);
  const {
    chatClient,
    channelFilters,
    channelId,
    isChannelListEmpty,
  } = useAttainChat();

  const clientRoutesAccess = checkPermission(Resources.clientRoutes);
  const providerRoutesAccess = checkPermission(Resources.providerRoutes);
  const educatorPortalAccess = checkPermission(Resources.educatorPortal);
  const { previousPath, removePreviousPath } = usePreviousPathByRoutesAccess(
    providerRoutesAccess
  );

  const { reAuthenticationThreshold } = useSettings();
  const reAuthenticationTimeout = minutesToMilliseconds(
    reAuthenticationThreshold
  );

  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const currentClient = useSelector(
    (state: RootState) => state.client.currentClient
  );

  const currentClientLoading = useSelector(
    (state: RootState) => state.client.currentClientLoading
  );

  const { id: currentProviderId, timezoneIANA: currentProviderTimezoneIANA } =
    useSelector((state: RootState) => state.provider.provider) || {};

  useEffect(() => {
    if (educatorPortalAccess && configs.educatorPortalUrl) {
      window.location.replace(configs.educatorPortalUrl);
    }
  }, [educatorPortalAccess]);

  useEffect(() => {
    if (currentProviderId) {
      moment.tz.setDefault(currentProviderTimezoneIANA);
      dispatch(fetchMenteeProviders(currentProviderId));
    }
    if (currentClient) {
      moment.tz.setDefault(currentClient.timezoneIANA);
      dispatch(fetchClientCaseTeam(currentClient.id));
    }
  }, [currentProviderId, currentProviderTimezoneIANA, currentClient, dispatch]);

  useEffect(() => {
    if (isAuthenticated && !maintenanceStatusOn) {
      dispatch(fetchAttendanceStatusThresholds());
      dispatch(fetchSettings());
      dispatch(fetchTimeBlocks());
      dispatch(fetchKyoClimbSettings());
      removePreviousPath();

      if (clientRoutesAccess) {
        dispatch(fetchCurrentUser());
        setLastLoggedInUserType(UserType.Client);
      }

      if (providerRoutesAccess) {
        dispatch(fetchProvider());
        dispatch(fetchAttendanceAwards());
        setLastLoggedInUserType(UserType.Provider);
      }
      if (providerRoutesAccess) {
        openSocketConnection();
      }
    }

    return () => {
      closeSocketConnection();
    };
  }, [
    dispatch,
    isAuthenticated,
    providerRoutesAccess,
    clientRoutesAccess,
    maintenanceStatusOn,
    removePreviousPath,
    setLastLoggedInUserType,
  ]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setCurrentUser(null));
      dispatch(setCurrentClient(null));
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (
      !loading &&
      !initialLoading &&
      isAuthenticated &&
      initialLocationRef.current?.pathname &&
      initialLocationRef.current?.pathname !== '/'
    ) {
      // once auth loading is finished, attempt to go to initial route
      navigate({
        pathname: initialLocationRef.current.pathname,
        search: initialLocationRef.current.search,
      });
      initialLocationRef.current = null;
    }
  }, [loading, initialLoading, isAuthenticated, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (currentUser && !currentClient && !currentClientLoading) {
      const nonRevokedAssociations = currentUser.associations.filter(
        (assoc) => !assoc.dateRevoked
      );
      if (nonRevokedAssociations.length === 0) {
        return;
      }

      let selectedClient: UserAssociation | undefined;

      if (location.search) {
        const { currentClientId } = queryString.parse(location.search);

        if (currentClientId) {
          selectedClient = nonRevokedAssociations.find(
            (c) => c.clientId === +currentClientId
          );
        }
      }

      if (!selectedClient) {
        const verifiedClient = nonRevokedAssociations.find(
          (assoc) => assoc.dateVerified
        );
        if (verifiedClient) {
          selectedClient = verifiedClient;
        } else {
          [selectedClient] = nonRevokedAssociations;
        }
      }

      if (selectedClient && selectedClient.dateVerified) {
        dispatch(fetchCurrentClientById(+selectedClient.clientId));
        dispatch(fetchClientCaseTeam(+selectedClient.clientId));
      } else if (selectedClient) {
        navigate(`/verify-client/${selectedClient.clientId}`);
      }
    }
  }, [
    dispatch,
    currentUser,
    currentClient,
    currentClientLoading,
    navigate,
    location.pathname,
    location.search,
  ]);

  useGtag();

  useActivityTracker(reAuthenticationTimeout, providerRoutesAccess);

  const handleChannelChange = (channel: Channel) => {
    if (!channel.id) {
      return;
    }

    const clientId = Number(channel.id.split('_')[2]);
    dispatch(fetchClientCaseTeam(clientId));
  };

  const dropStylePages = [`/${MY_AVAILABILITY_PAGE}`].includes(
    location.pathname
  );

  const isOnSpanishPage = location.pathname === '/public/spanish';

  return (
    <>
      <AppHeader {...{ isDrawerOpen, setIsDrawerOpen }} />
      {isAuthenticated && !maintenanceStatusOn && (
        <nav className={classes.drawer} aria-label="nav section">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden lgUp implementation="css">
            <Drawer
              elevation={0}
              BackdropProps={{ invisible: true }}
              variant="persistent"
              anchor="bottom"
              open={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen((prevState) => !prevState);
              }}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <DrawerItemsList
                {...{ setIsDrawerOpen, signOut, isDrawerOpen }}
              />
            </Drawer>
          </Hidden>
        </nav>
      )}
      <Container maxWidth="xl" disableGutters className={classes.content}>
        {isAuthenticated && !maintenanceStatusOn && !isOnSpanishPage && (
          <Hidden mdDown implementation="css">
            <Box className={classes.sideBar}>
              <DrawerItemsList
                {...{ setIsDrawerOpen, signOut, isDrawerOpen }}
              />
            </Box>
          </Hidden>
        )}
        <ErrorBoundary>
          <Box
            width={1}
            {...(!dropStylePages && { p: 4 })}
            className={classes.contentBox}
          >
            <Suspense
              fallback={
                <div className={classes.suspenseLoading}>
                  <CircularProgress />
                </div>
              }
            >
              {maintenanceStatusOn ? (
                <Maintenance />
              ) : (
                <Routes>
                  {/* /public/help routes are for the mobile app */}
                  <Route
                    path="/public/help"
                    element={
                      <HelpPageProvider value={{ reloadOnNavigate: true }}>
                        <ClientHelpPage />
                      </HelpPageProvider>
                    }
                    key="/public/help"
                  />
                  <Route
                    path="/public/help/client"
                    element={
                      <HelpPageProvider value={{ reloadOnNavigate: true }}>
                        <ClientHelpPage />
                      </HelpPageProvider>
                    }
                    key="/public/help/client"
                  />
                  <Route
                    path="/public/help/provider"
                    element={
                      <HelpPageProvider value={{ reloadOnNavigate: true }}>
                        <ProviderHelpPage />
                      </HelpPageProvider>
                    }
                    key="/public/help/provider"
                  />
                  <Route
                    path="/public/spanish"
                    Component={SwitchToSpanishPage}
                    key="/public/spanish"
                  />

                  {!isAuthenticated && [
                    <Route path="/login" Component={Login} key="/login" />,
                    <Route
                      path="/forgot-password"
                      Component={ForgotPasswordForm}
                      key="/forgot-password"
                    />,
                  ]}
                  {isAuthenticated &&
                    providerRoutesAccess && [
                      <Route
                        path="/home"
                        element={<HomeForProvider />}
                        key="/home"
                      />,
                      <Route
                        path="/my-profile"
                        element={<ProviderProfile />}
                        key="/my-profile"
                      />,
                      <Route
                        path="/alerts"
                        element={<Alerts />}
                        key="/alerts"
                      />,
                      <Route
                        path="/requests"
                        element={<Requests />}
                        key="/requests"
                      />,
                      <Route
                        path="/schedule/*"
                        key="/schedule"
                        element={
                          <SessionsHOC>
                            <Routes>
                              <Route path="/" Component={MyScheduleProvider} />
                              <Route
                                path="/remove/result"
                                element={
                                  <SessionsRemovalResults backTitle="My Schedule" />
                                }
                              />
                            </Routes>
                          </SessionsHOC>
                        }
                      />,
                      <Route
                        path="/openSessions"
                        element={<OpenSessions />}
                        key="/openSessions"
                      />,
                      <Route
                        path="/settings"
                        element={<ProviderSettingsPage />}
                        key="settings"
                      />,
                      <Route
                        path={`/${MY_AVAILABILITY_PAGE}`}
                        element={<MyAvailability />}
                        key="MyAvailability"
                      />,
                      <Route path="/climb" element={<Climb />} key="Climb" />,
                      <Route
                        path="/chat"
                        key="chat"
                        element={
                          <AttainChat
                            {...{
                              chatClient,
                              channelFilters,
                              showChannels: true,
                              isChannelListEmpty,
                              onChannelChange: handleChannelChange,
                            }}
                          />
                        }
                      />,
                    ]}
                  {providerRoutesAccess && [
                    <Route
                      key="/mentees"
                      path="/mentees"
                      element={<MenteeProviders />}
                    />,
                    <Route
                      key="/providers/:providerId"
                      path="/providers/:providerId"
                      element={<ProviderProfile />}
                    />,
                  ]}
                  {isAuthenticated &&
                    clientRoutesAccess && [
                      <Route path="/home" element={<Home />} key="/home" />,
                      <Route
                        path="/verify-client/:clientId"
                        element={<VerificationForm />}
                        key="/verify-client"
                      />,
                      <Route
                        path="/add-sessions"
                        element={<AddSessions />}
                        key="/add-sessions"
                      />,
                      <Route
                        path="/alerts"
                        element={<Alerts />}
                        key="/alerts"
                      />,
                      <Route
                        path="/schedule/*"
                        key="/schedule"
                        element={
                          <SessionsHOC>
                            <Routes>
                              <Route path="/" Component={MyScheduleClient} />
                              <Route
                                path="/remove/result"
                                element={
                                  <SessionsRemovalResults backTitle="My Schedule" />
                                }
                              />
                            </Routes>
                          </SessionsHOC>
                        }
                      />,
                      <Route
                        path="/requests"
                        element={<MyRequests />}
                        key="/requests"
                      />,
                      <Route
                        path="/user-profile"
                        element={<MyUserProfile />}
                        key="/user-profile"
                      />,
                      <Route
                        path={`/${MY_AVAILABILITY_PAGE}`}
                        element={<UserAvailability />}
                        key="MyAvailability"
                      />,
                      <Route
                        path="/my-profile"
                        element={<MyClientProfile />}
                        key="/my-profile"
                      />,
                      <Route
                        path="/settings"
                        element={<ClientSettingsPage />}
                        key="settings"
                      />,
                      <Route
                        path="/chat"
                        key="chat"
                        element={
                          <AttainChat
                            {...{
                              chatClient,
                              channelFilters,
                              channelId,
                              showChannels: false,
                              isChannelListEmpty,
                            }}
                          />
                        }
                      />,
                      <Route
                        path="/contact-scheduling"
                        key="/contact-scheduling"
                        element={<Contact />}
                      />,
                      // <Route
                      //   path="/billing"
                      //   key="/billing"
                      //   element={<Billing />}
                      // />,
                    ]}
                  <Route path="/help" Component={HelpPage} />
                  <Route
                    path="*"
                    element={<NotFound previousPath={previousPath} />}
                  />
                </Routes>
              )}
              {(maintenanceStatusOn ||
                (!isAuthenticated && !loading && !initialLoading)) && (
                <Typography align="center">
                  © 2021 Kyo Autism Therapy, LLC |{' '}
                  <MuiLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://kyocare.com/privacy-policy/"
                  >
                    Privacy Notice
                  </MuiLink>
                </Typography>
              )}
            </Suspense>
          </Box>
        </ErrorBoundary>
      </Container>
      <AppErrors />
    </>
  );
};

export default App;

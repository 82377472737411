import React from 'react';
import { Box, Divider, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    margin: theme.spacing(0, 1),
    flexShrink: 0,
  },
  divider: {
    width: '100%',
    flexShrink: 1,
  },
}));

interface DividerTextProps {
  children: JSX.Element | string;
  className?: string;
}

const DividerText = (props: DividerTextProps) => {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Divider className={classes.divider} />
      <Box className={classes.content}>{children}</Box>
      <Divider className={classes.divider} />
    </Box>
  );
};

export default DividerText;

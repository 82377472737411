import { useEffect } from 'react';
import { useLocation } from 'react-router';
import configs from '../configs';

const { gtagId } = configs;

declare function gtag(
  key: string,
  id: string,
  params?: Record<string, any>
): void;

const useGtag = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!gtagId) {
      return;
    }
    const gtagScript = document.createElement('script');
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
    gtagScript.setAttribute('async', '');

    const dataLayerScript = document.createElement('script');
    dataLayerScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '${gtagId}');
    `;

    const { firstChild } = document.head;

    document.head.insertBefore(gtagScript, firstChild);
    document.head.insertBefore(dataLayerScript, firstChild);
  }, []);

  useEffect(() => {
    if (gtagId && gtag) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      gtag('config', gtagId, { page_path: pathname });
    }
  }, [pathname]);
};

export default useGtag;

import queryString from 'query-string';
import GoogleApiWrapper from './googleApiWrapper';
import requester from './requester';
import {
  Client,
  MidTermBookRequest,
  PaginatedData,
  Provider,
  RequestStatus,
  ZipCodeData,
} from '../types/types';

const googleWrapperInstance = GoogleApiWrapper.getInstance();

const extendWithDriveTimes = async <T extends PaginatedData<any>>({
  data,
  originalZipCode,
  destinations,
}: {
  data: T;
  originalZipCode?: ZipCodeData;
  destinations: google.maps.LatLngLiteral[];
}) => {
  if (!originalZipCode) {
    return data;
  }

  const distances = await googleWrapperInstance.getDistanceMatrix({
    origins: [
      {
        lat: Number(originalZipCode.latitude),
        lng: Number(originalZipCode.longitude),
      },
    ],
    destinations,
  });

  const { items, ...rest } = data;

  return {
    ...rest,
    items: items.map((item, index) => {
      return { ...item, driveTime: distances.rows[0]?.elements[index] };
    }),
  };
};

const getClientsLatLng = (clients: Client[]) => {
  return clients.reduce((acc: google.maps.LatLngLiteral[], client) => {
    const { latitude, longitude } = client.zipCodeData || {};

    if (!latitude || !longitude) {
      return acc;
    }

    return acc.concat({
      lat: +latitude,
      lng: +longitude,
    });
  }, []);
};

const fetchOpenSessions = async (
  provider: Provider,
  isFromCaseTeam: boolean,
  ignoreAvailability: boolean,
  page: number,
  limit: number,
  clientId?: number
) => {
  const query = queryString.stringify({
    limit,
    page,
    isFromCaseTeam,
    considerAvailability: !ignoreAvailability,
    clientId,
    providerId: provider.id,
  });

  const url = `v2/bookings/matching-mid-term-book-requests?${query}`;

  const data = await requester
    .get(url)
    .then((resp) => resp.json())
    .then((resp) => resp.data);

  return extendWithDriveTimes<PaginatedData<MidTermBookRequest>>({
    data,
    originalZipCode: provider?.address?.zipCodeData,
    destinations: getClientsLatLng(
      data.items.map((i: MidTermBookRequest) => i.client)
    ),
  });
};

const updateOpenSessionRequest = async (
  requestId: number,
  sessionIds: number[],
  statusId: RequestStatus,
  providerId?: number
) => {
  const url = `v2/bookings/mid-term-book-requests/${requestId}`;

  const response = await requester.put(url, {
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ statusId, providerId, sessionIds }),
  });
  const res = await response.json();
  return res.data;
};

export default {
  fetchOpenSessions,
  updateOpenSessionRequest,
};

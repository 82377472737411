import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';

import { RootState } from '../redux/modules/rootReducer';
import StyledButton from './buttons/StyledButton';
import ConfirmationDialogTemplate from './ConfirmationDialogTemplate';
import ProviderCell from './table/ProviderCell';
import configs from '../configs';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
  },
  dialogContent: {
    padding: theme.spacing(1),
    overflow: 'hidden',
  },
  providerCellContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderRadius: 16,
    width: '80%',
    minWidth: 280,
  },
}));

interface ProviderRoleSelectionComponentProps {
  onAction?: () => any;
}

const ProviderRoleSelectionComponent = (
  props: ProviderRoleSelectionComponentProps
) => {
  const classes = useStyles();
  const { onAction } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const currentProvider = useSelector(
    (state: RootState) => state.provider.provider
  );

  const redirectToOldUI = () => {
    window.open(configs.oldUIUrl, '_blank');
  };

  return (
    <Box>
      <StyledButton
        className={classes.button}
        onClick={() => {
          setDialogOpen(true);
        }}
        customColor="kyoTeal"
      >
        Switch Role
      </StyledButton>
      <ConfirmationDialogTemplate
        open={dialogOpen}
        title="Switch Role"
        handleClose={() => {
          setDialogOpen(false);
          if (onAction) {
            onAction();
          }
        }}
        actions={
          <>
            <StyledButton
              endIcon={<ArrowRightAlt />}
              customColor="kyoLightGreen"
              onClick={() => {
                redirectToOldUI();
              }}
            >
              Switch to Scheduler
            </StyledButton>
          </>
        }
      >
        <Box className={classes.dialogContent}>
          <Typography variant="body1" color="textSecondary">
            You are logged in as a Provider. Switch to the Scheduler portal for
            Scheduling, RD/Con, or other role functions.
          </Typography>
          {currentProvider && (
            <Box className={classes.providerCellContainer}>
              <ProviderCell provider={currentProvider} />
            </Box>
          )}
        </Box>
      </ConfirmationDialogTemplate>
    </Box>
  );
};

export default ProviderRoleSelectionComponent;

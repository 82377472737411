import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    backgroundColor: theme.palette.kyoGray.light,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.kyoGray.main}`,
    display: 'inline-flex',
    alignItems: 'center',
    gap: 5,
    fontSize: theme.spacing(1.375),
    fontWeight: 600,
    padding: theme.spacing(0, 1),
  },
}));

interface BadgeProps {
  text: string;
  icon: React.ReactNode;
  textColor: string;
}

const Badge = ({ text, icon, textColor }: BadgeProps) => {
  const classes = useStyles();

  return (
    <div style={{ color: textColor }} className={classes.badge}>
      {text} {icon}
    </div>
  );
};

export default Badge;

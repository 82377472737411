import {
  Avatar,
  Divider,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DateObject } from 'react-multi-date-picker';
import { groupBy } from 'lodash';

import StyledButtonLink from '../../components/buttons/StyledButtonLink';
import { RootState } from '../../redux/modules/rootReducer';
import {
  clearSelectedBookingOptions,
  createSingleTermBookRequest,
  SelectedBookingOption,
  fetchScheduledSessions,
  clearScheduledSessions,
} from '../../redux/modules/newSessions';
import {
  DayOfWeekId,
  MainRequestTypes,
  RequestStatus,
  RequestType,
  STBRBlockOption,
} from '../../types/types';
import SessionsSubmitted from './SessionsSubmitted';
import { DATE_FORMAT, DATE_FORMAT_US } from '../../utils/helpers';
import AddSessionsMatrix from './matrix/AddSessionsMatrix';
import { getTimeBlockDayOfWeekKey } from '../../shared/lib/availability';
import AddSessionsWeekSelector from './AddSessionsWeekSelector';
import ActionsControlButton from '../../components/buttons/ActionsControlButton';
import {
  clearRequestsList,
  fetchClientRequests,
} from '../../redux/modules/requests';
import SelectedBooingOptions from './SelectedBookingOptions';
import AddSessionsMatrixInfo from './matrix/AddSessionsMatrixInfo';
import BTAvailabilityInfoDialog from '../../components/HintText/BTAvailabilityInfoDialog';
import AnalyticsTracker, {
  TrackerNames,
} from '../../analytics/AnalyticsTracker';

export interface BookedOption {
  timeBlockDayOfWeekId: number;
  type?: MainRequestTypes;
  scheduledDate: string;
  bookingWith: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
  duration: number;
  status: RequestStatus;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: 8,
  },
  description: {
    color: theme.palette.kyoGray63.dark,
    marginTop: theme.spacing(2),
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      gap: theme.spacing(2),
      flexDirection: 'row-reverse',
      width: '50%',
      marginLeft: 'auto',
    },
  },

  providerCard: {
    display: 'flex',
    alignItems: 'center',
  },
  providerCardText: {
    paddingLeft: theme.spacing(1),
  },
  provideName: {
    lineHeight: '138%',
    letterSpacing: '0.0075em',
  },
  addSessionHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  matrixCustomContent: {
    gridColumnStart: 2,

    [theme.breakpoints.down('xs')]: {
      gridColumnStart: 1,
    },
  },
  selectedBookingOptionsWrapper: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: theme.spacing(3.74),
    marginTop: theme.spacing(2),
  },
  countAndMatrixInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.75),
  },
  countInfo: {
    opacity: 0.5,
  },
  loading: {
    marginTop: theme.spacing(4),
  },
}));

interface NewSessionsProps {
  goBack: () => void;
  handleSuccess?: () => void;
  preferredDate?: DateObject;
}

export interface WeekRange {
  weekStart: string;
  weekEnd: string;
}

const getMaxSessionsCount = (
  potentialSessions: number,
  numOfAllowedSessions: number,
  extraSessions: number
) => {
  return Math.min(potentialSessions, numOfAllowedSessions + extraSessions);
};

const getAddSessionWithProviderText = (
  potentialSessions: number,
  numOfAllowedSessions: number,
  extraSessions: number
) => {
  return (
    <>
      Select a block and a time. You can request up to{' '}
      <b>
        {getMaxSessionsCount(
          potentialSessions,
          numOfAllowedSessions,
          extraSessions
        )}
      </b>{' '}
      additional sessions.
    </>
  );
};

const NewSessions = ({
  goBack,
  preferredDate,
  handleSuccess,
}: NewSessionsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const selectedProvider = useSelector(
    (state: RootState) => state.newSessions.selectedProvider
  );

  const currentClient = useSelector(
    (state: RootState) => state.client.currentClient
  );

  const selectedBookingOptions = useSelector(
    (state: RootState) => state.newSessions.selectedBookingOptions
  );

  const createSessionInProgress = useSelector(
    (state: RootState) => state.newSessions.createSessionInProgress
  );

  const createSessionSuccess = useSelector(
    (state: RootState) => state.newSessions.createSessionSuccess
  );
  const clientSTBRAvailability = useSelector(
    (state: RootState) => state.newSessions.clientSTBRAvailability
  );

  const scheduledSessions = useSelector(
    (state: RootState) => state.newSessions.scheduledSessions
  );

  const requestsList = useSelector(
    (state: RootState) => state.requests.requestsList
  );

  useEffect(() => {
    return () => {
      dispatch(clearSelectedBookingOptions());
    };
  }, [dispatch]);

  const bookOptionsCount =
    selectedProvider?.bookDetails.bookOptions.length || 0;

  const lastSessionDate =
    selectedProvider?.bookDetails.bookOptions[bookOptionsCount - 1]
      .scheduledDate;

  const firstSessionDate =
    selectedProvider?.bookDetails.bookOptions[0].scheduledDate;

  const minWeekDate = useMemo(
    () => moment(firstSessionDate, DATE_FORMAT).startOf('week'),
    [firstSessionDate]
  );
  const maxWeekDate = useMemo(
    () => moment(lastSessionDate, DATE_FORMAT).endOf('week'),
    [lastSessionDate]
  );

  useEffect(() => {
    const { id: clientId } = currentClient || {};
    if (!clientId) {
      return () => null;
    }

    dispatch(
      fetchScheduledSessions({
        clientId,
        startDate: minWeekDate.format(DATE_FORMAT),
        endDate: maxWeekDate.format(DATE_FORMAT),
        includeSessionBlocks: true,
        timeBlockDayOfWeekIds: [],
      })
    );

    dispatch(
      fetchClientRequests({
        clientId,
        type: [RequestType.MidTerm, RequestType.SingleTerm],
        status: [RequestStatus.ACCEPTED, RequestStatus.PENDING],
        excludeSessionStatus: [
          RequestStatus.DECLINED,
          RequestStatus.RETRACTED,
          RequestStatus.EXPIRED,
          RequestStatus.REJECTED,
          RequestStatus.REMOVED,
        ],
        sessionStartFrom: minWeekDate.toISOString(),
        sessionStartTo: maxWeekDate.toISOString(),
      })
    );

    return () => {
      dispatch(clearScheduledSessions());
      dispatch(clearRequestsList());
    };
  }, [dispatch, currentClient, minWeekDate, maxWeekDate]);

  const [bookedOptions, setBookedOptions] = useState<BookedOption[]>([]);

  useEffect(() => {
    const tempBookedOptions: BookedOption[] = [];

    (scheduledSessions || []).forEach((scheduledSession) => {
      scheduledSession.blocks.forEach((block) => {
        tempBookedOptions.push({
          timeBlockDayOfWeekId: block.id,
          scheduledDate: scheduledSession.scheduledDate,
          bookingWith: scheduledSession.provider?.name || '',
          startTime: moment(scheduledSession.segmentStartUtc),
          endTime: moment(scheduledSession.segmentEndUtc),
          duration: scheduledSession.segmentDuration,
          status: RequestStatus.CONFIRMED,
        });
      });
    });

    (requestsList || []).forEach((request) => {
      request.sessions.forEach((session) => {
        session.blocks.forEach((block) => {
          tempBookedOptions.push({
            timeBlockDayOfWeekId: block.id,
            type: request.type as BookedOption['type'],
            scheduledDate: moment(session.sessionStart).format(DATE_FORMAT),
            bookingWith: request.provider?.name || '',
            startTime: moment(session.sessionStart),
            endTime: moment(session.sessionEnd),
            duration: session.duration,
            status: RequestStatus.PENDING,
          });
        });
      });
    });

    setBookedOptions(tempBookedOptions);
  }, [scheduledSessions, requestsList]);

  const [weekRange, setWeekRange] = useState<WeekRange>(
    (() => {
      const defaultDate = (() => {
        if (preferredDate) {
          return moment(preferredDate.toDate());
        }
        return minWeekDate;
      })();

      return {
        weekStart: defaultDate.clone().startOf('week').format(DATE_FORMAT_US),
        weekEnd: defaultDate.clone().endOf('week').format(DATE_FORMAT_US),
      };
    })()
  );

  const [inWeekBookOptions, setInWeekBookOptions] = useState<
    Record<number, STBRBlockOption>
  >({});

  const [inWeekSelectedBookOptions, setInWeekSelectedBookingOptions] = useState<
    Record<number, SelectedBookingOption>
  >({});

  const [inWeekBookedOptions, setInWeekBookedOptions] = useState<
    Record<number, BookedOption[]>
  >({});

  const getFindInRangeOptionsFilter = (
    weekStart: moment.Moment,
    weekEnd: moment.Moment
  ) => <T extends Pick<STBRBlockOption, 'scheduledDate'>>(blockOption: T) => {
    const { scheduledDate } = blockOption;
    const momentScheduledDate = moment(scheduledDate, DATE_FORMAT);
    const isBookOptionInRange = momentScheduledDate.isBetween(
      weekStart,
      weekEnd,
      undefined,
      '[]'
    );

    return isBookOptionInRange;
  };

  const mapOptionsByTimeBlockDayOfWeekId = <
    T extends Pick<STBRBlockOption, 'scheduledDate' | 'timeBlockId'>
  >(
    mappedOptions: Record<number, T>,
    option: T
  ) => {
    const { scheduledDate, timeBlockId } = option;

    const timeBlockDayOfWeekId = getTimeBlockDayOfWeekKey({
      dayOfWeekId: moment(scheduledDate, DATE_FORMAT).weekday() as DayOfWeekId,
      timeBlockId,
    });

    return { ...mappedOptions, [timeBlockDayOfWeekId]: option };
  };

  useEffect(() => {
    const momentWeekStart = moment(weekRange.weekStart, DATE_FORMAT_US);
    const momentWeekEnd = moment(weekRange.weekEnd, DATE_FORMAT_US);

    const inRangeOptionsFilter = getFindInRangeOptionsFilter(
      momentWeekStart,
      momentWeekEnd
    );

    setInWeekBookOptions(
      selectedProvider?.bookDetails.bookOptions
        .filter(inRangeOptionsFilter)
        .reduce(
          mapOptionsByTimeBlockDayOfWeekId,
          {} as Record<number, STBRBlockOption>
        ) || {}
    );

    setInWeekSelectedBookingOptions(
      selectedBookingOptions
        .filter(inRangeOptionsFilter)
        .reduce(
          mapOptionsByTimeBlockDayOfWeekId,
          {} as Record<number, SelectedBookingOption>
        ) || {}
    );

    setInWeekBookedOptions(
      groupBy(
        bookedOptions.filter(inRangeOptionsFilter),
        'timeBlockDayOfWeekId'
      ) as Record<number, BookedOption[]>
    );
  }, [
    weekRange.weekStart,
    weekRange.weekEnd,
    selectedProvider,
    selectedBookingOptions,
    bookedOptions,
  ]);

  const handleWeekPickerChange = (range: string[]) => {
    setWeekRange({ weekStart: range[0], weekEnd: range[1] });
  };

  const {
    minNumberOfSessionsCanBooked,
    maxNumberOfSessionsCanBooked,
    extraSessionsNumberToSelect,
  } = clientSTBRAvailability;

  const isSubmitDisabled = useMemo(() => {
    if (selectedProvider) {
      const selectedBookingOptionsCount = selectedBookingOptions.length;

      return selectedBookingOptionsCount < minNumberOfSessionsCanBooked;
    }
    return false;
  }, [selectedProvider, selectedBookingOptions, minNumberOfSessionsCanBooked]);

  const handleSubmit = async () => {
    if (!currentClient || !selectedProvider) {
      return;
    }
    await dispatch(
      createSingleTermBookRequest({
        clientId: currentClient.id,
        providerId: selectedProvider?.id,
      })
    );
    if (handleSuccess) {
      handleSuccess();
    }
  };

  const maxSessionsCount = selectedProvider
    ? getMaxSessionsCount(
        selectedProvider.bookDetails.numOfPotentialSessions,
        maxNumberOfSessionsCanBooked,
        extraSessionsNumberToSelect
      )
    : 0;

  const matrixCustomElements = (
    <div className={classes.matrixCustomContent}>
      <div className={classes.countAndMatrixInfo}>
        <Typography variant="subtitle1" className={classes.countInfo}>
          {selectedBookingOptions.length} of {maxSessionsCount} are selected
        </Typography>
        <AddSessionsMatrixInfo />
      </div>
      <div className={classes.selectedBookingOptionsWrapper}>
        <SelectedBooingOptions />
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <AnalyticsTracker
        name={TrackerNames.AddSessionsSelectTime}
        delay={2000}
      />
      <StyledButtonLink onClick={goBack} startIcon={<KeyboardBackspace />}>
        Add Sessions
      </StyledButtonLink>
      <Typography variant="h5">
        {createSessionSuccess ? 'Sessions submitted' : 'Select Time'}
      </Typography>
      <Divider />
      {createSessionSuccess ? (
        <SessionsSubmitted />
      ) : (
        <>
          <div className={classes.addSessionHeader}>
            <div>
              <div className={classes.providerCard}>
                <Avatar
                  alt="profile"
                  src={selectedProvider?.profilePhoto}
                  className={classes.avatar}
                  imgProps={{
                    referrerPolicy: 'no-referrer',
                  }}
                />
                <div className={classes.providerCardText}>
                  <Typography variant="body2">Book with</Typography>
                  <Typography variant="h6" className={classes.provideName}>
                    {`${selectedProvider?.firstName} ${selectedProvider?.lastName}`}
                  </Typography>
                </div>
              </div>

              <Typography variant="body1" className={classes.description}>
                {selectedProvider &&
                  getAddSessionWithProviderText(
                    selectedProvider.bookDetails.numOfPotentialSessions,
                    maxNumberOfSessionsCanBooked,
                    extraSessionsNumberToSelect
                  )}
              </Typography>
              <BTAvailabilityInfoDialog />
            </div>

            <AddSessionsWeekSelector
              weekRange={weekRange}
              handleChange={handleWeekPickerChange}
              minDate={minWeekDate}
              maxDate={maxWeekDate}
            />
          </div>
          <AddSessionsMatrix
            cellSizes={isSmallScreen ? 5.5 : 10}
            availableCells={inWeekBookOptions}
            bookedCells={inWeekBookedOptions}
            selectedCells={inWeekSelectedBookOptions}
            weekRange={weekRange}
            useVerticalLabelsInsideRows={isSmallScreen}
            customElements={matrixCustomElements}
            disableAvailableCells={
              selectedBookingOptions.length >= maxSessionsCount
            }
          />
          <ActionsControlButton
            styles={{ buttonWrapper: classes.buttonWrapper }}
            disabled={{
              cancelButton: createSessionInProgress,
              successButton: isSubmitDisabled || createSessionInProgress,
            }}
            handleClick={{
              successButton: handleSubmit,
              cancelButton: goBack,
            }}
            label={{
              successButton: 'Submit',
              cancelButton: 'Cancel',
            }}
          />
          {createSessionInProgress && (
            <div className={classes.loading}>
              <LinearProgress />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NewSessions;

import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { Availability } from '../../redux/modules/availability';
import { DATE_FORMAT, DATE_FORMAT_US } from '../../utils/helpers';

interface GetOverlapSessionsArgs {
  stAvails: Availability[];
  ignoreId?: number;
  startDate?: string;
  endDate?: string;
}

interface OverlappingSessionsWrapperProps extends GetOverlapSessionsArgs {
  children: (overlappingSession?: Availability) => JSX.Element;
}

const OverlappingSessionsWrapper = ({
  children,
  ...props
}: OverlappingSessionsWrapperProps) => {
  const getOverlappingSession = ({
    stAvails,
    ignoreId,
    startDate,
    endDate,
  }: GetOverlapSessionsArgs) => {
    if (!startDate || !endDate) {
      return undefined;
    }
    const startDateFormatted = moment(startDate, DATE_FORMAT_US).format(
      DATE_FORMAT
    );
    const endDateFormatted = moment(endDate, DATE_FORMAT_US).format(
      DATE_FORMAT
    );

    return stAvails.find((avail) => {
      if (ignoreId === avail.id) {
        return false;
      }
      const isOverlapDate = (data: string) => {
        return data >= avail.startDate && data <= avail.endDate;
      };

      const overlapsStartDate = isOverlapDate(startDateFormatted);
      const overlapsEndDate = isOverlapDate(endDateFormatted);

      const includeAvail =
        startDateFormatted < avail.startDate &&
        endDateFormatted > avail.endDate;

      return overlapsEndDate || overlapsStartDate || includeAvail;
    });
  };

  const overlappingSession = useMemo(() => {
    return getOverlappingSession({ ...props });
  }, [props]);

  return children(overlappingSession);
};

export default OverlappingSessionsWrapper;

export const OverlappingSessionWarning = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return (
    <Typography>
      Another temporary change was found during this period{' '}
      <Typography variant="subtitle1" component="span">
        {moment(startDate).format(DATE_FORMAT_US)} -{' '}
        {moment(endDate).format(DATE_FORMAT_US)}
      </Typography>
      . Please go back and adjust the existing change.
    </Typography>
  );
};

import { Link, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Address, ZipCodeData } from '../../types/types';
import { zipCodeToLocationParser } from '../../utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  inline: {
    display: 'inline',
    marginRight: theme.spacing(1),
  },
  link: {
    color: theme.palette.kyoBlue.main,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.8),
  },
  body2: {
    color: theme.palette.kyoGray63.main,
    ...theme.typography.body2,
  },
}));

interface LocationPoints {
  latitude?: number | null;
  longitude?: number | null;
}
interface LocationCellProps {
  zip?: number | null;
  locationNotes?: string;
  distance?: number | null;
  points?: LocationPoints[];
  zipCodes?: string[];
  compact?: boolean;
  dir?: 'row' | 'col';
  providerAddress?: Address;
  sessionAddress?: Address;
}

const getFormattedAddress = (address: Address) => {
  const { street = '', city = '', state = '', zip = '' } = address || {};

  // `${street}, ${city}, ${state} ${zip}`
  let fullAddress = '';

  if (street) {
    fullAddress += street;
  }

  if (city) {
    fullAddress = street ? `${fullAddress}, ` : `${fullAddress} `;
    fullAddress += city;
  }

  if (state) {
    fullAddress = street || city ? `${fullAddress}, ` : `${fullAddress} `;
    fullAddress += state;
  }

  if (zip && state) {
    fullAddress = `${fullAddress} `;
    fullAddress += zip;
  } else if (zip && (street || city)) {
    fullAddress = `${fullAddress}, `;
    fullAddress += zip;
  } else if (zip) {
    fullAddress += zip;
  }

  return fullAddress;
};

const LocationCell: React.FC<LocationCellProps> = ({
  zip,
  locationNotes,
  distance,
  points,
  zipCodes,
  compact,
  dir = 'col',
  providerAddress,
  sessionAddress,
}) => {
  const classes = useStyles();

  const locationToString = (index: number) => {
    return points && points[index]
      ? `${points[index].latitude},${points[index].longitude}`
      : '';
  };

  const pointsQuery =
    points && points.length === 1
      ? `?q=${locationToString(0)}`
      : `/dir/?api=1&origin=${locationToString(
          0
        )}&destination=${locationToString(1)}&travelmode=driving`;

  const formattedProviderAddress =
    providerAddress && getFormattedAddress(providerAddress);

  const formattedSessionAddress =
    sessionAddress && getFormattedAddress(sessionAddress);

  const start = formattedProviderAddress || (zipCodes && zipCodes[0]);
  const destination = formattedSessionAddress || (zipCodes && zipCodes[1]);

  const query =
    start && destination ? `/dir/${start}/${destination}` : pointsQuery;

  return (
    <div className={dir === 'row' ? classes.flex : ''}>
      <div className={classes.flex}>
        <Typography variant={compact ? 'body2' : 'body1'}>
          {formattedSessionAddress || zip}
        </Typography>

        {distance !== undefined && distance !== null && dir === 'row' && (
          <Typography variant="body2" className={compact ? classes.inline : ''}>
            {`${distance} mile${distance > 1 ? 's' : ''}`}
          </Typography>
        )}
        {Boolean((start && destination) || points) && (
          <div>
            <Link
              href={`https://maps.google.com/maps${query}`}
              target="_blank"
              className={classes.link}
            >
              Map
            </Link>
          </div>
        )}
      </div>
      {dir === 'col' && locationNotes && (
        <div>
          <span className={classes.body2}>Note:</span> {locationNotes}
        </div>
      )}
    </div>
  );
};

export default LocationCell;

export const mapLocationCellParams = (
  zipCodeData: (ZipCodeData | undefined)[] = [],
  distance?: number
) => {
  const points: LocationPoints[] = [];
  const zipCodes: string[] = [];
  const roundedDistance = Math.round(Number(distance));

  zipCodeData.forEach((data) => {
    if (!data) {
      return;
    }

    points.push(zipCodeToLocationParser(data));
    zipCodes.push(data.zipCode);
  });

  return { roundedDistance, zipCodes, points };
};

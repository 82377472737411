import { AvailabilityType } from './constants';

export interface AvailabilityBlock {
  id: number;
  timeBlockDayOfWeekId: number;
  isAvailable: boolean;
  timeOff: boolean;
  timeBlockDayOfWeek: {
    timeBlockId: number;
    dayOfWeekId: number;
  };
}

export interface Availability {
  id: number;
  type: AvailabilityType;
  startDate: string;
  endDate: string;
  effectiveDate: string;
  createdAt: string;
  availabilityBlocks: AvailabilityBlock[];
  preferredBlock1Start?: string;
  preferredBlock3Start?: string;
  preferredBlock3End?: string;
  status?: AvailabilityStatus;
}

export enum AvailabilityStatus {
  Expired = 'Expired',
  Overridden = 'Overridden',
  Removed = 'Removed',
  Current = 'Current',
  Upcoming = 'Upcoming',
  Discarded = 'Discarded',
}

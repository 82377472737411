import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DayOfWeekId } from '../../types/types';
import DemandIcon from '../../assets/demand-icon.svg';
import useSettings from '../../hooks/useSettings';

const useStyles = makeStyles((theme: Theme) => ({
  cellWrapper: {
    position: 'relative',
  },
  demandImg: {
    position: 'absolute',
    top: -2,
    right: 3,
  },
}));

interface DemandInfoProps {
  horizontalValue: DayOfWeekId;
  verticalValue: number;
  showDemand: boolean;
  demandScore: number;
  demandRank: number;
  children: React.ReactNode;
}

const DemandInfo = ({
  children,
  showDemand,
  demandScore,
  demandRank,
}: DemandInfoProps) => {
  const classes = useStyles();
  const { inDemandMaxRank, inDemandMinScore } = useSettings();

  const showFlameIcon =
    showDemand &&
    demandRank <= inDemandMaxRank &&
    demandScore >= inDemandMinScore;

  return (
    <div className={classes.cellWrapper}>
      {children}
      {showFlameIcon && (
        <img className={classes.demandImg} src={DemandIcon} alt="demand" />
      )}
    </div>
  );
};

export default DemandInfo;

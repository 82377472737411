import { TrackerNames } from '../analytics/AnalyticsTracker';
import requester from './requester';

interface AnalyticsApiParams {
  clientId: number | null;
  providerId: number | null;
  visitedPage: TrackerNames;
}

export const storeAnalytics = (params: AnalyticsApiParams) => {
  return requester
    .post(`analytics`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
    .then((response) => response.json())
    .then((response) => response.data);
};

export default storeAnalytics;

import {
  createAsyncThunk,
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit';
import notificationApi from '../../api/notificationsApi';
import { RootState } from './rootReducer';
import { Notification } from '../../types/types';

export interface NotificationsState {
  notifications: Notification[];
  notificationsLoading: boolean;
  notificationsRequestId: string | undefined;
  total: number;
  selectedRows: number[];
  unreadCount: number;
  isSelectMode: boolean;
  isSelectAll: boolean;
}

const initialState: NotificationsState = {
  notifications: [],
  notificationsLoading: false,
  notificationsRequestId: undefined,
  total: 0,
  selectedRows: [],
  unreadCount: 0,
  isSelectMode: false,
  isSelectAll: false,
};

export const fetchUnreadCount = createAsyncThunk(
  'notifications/fetchUnreadCount',
  () => {
    return notificationApi.fetchUnreadCount();
  }
);

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  (arg: {
    filters: {
      isSeen?: boolean;
    };
    paging?: { page: number; rowsPerPage: number };
  }) => {
    const { filters, paging } = arg;
    return notificationApi.fetchNotifications(filters, paging);
  }
);

export const bulkUpdateIsSeen = createAsyncThunk(
  'notifications/bulkUpdateIsSeen',
  (
    arg: {
      isSeen: boolean;
      ids: number[];
    },
    thunkAPI
  ) => {
    const { isSeen, ids } = arg;
    return notificationApi
      .bulkUpdateIsSeen(isSeen, ids)
      .catch((err) => thunkAPI.rejectWithValue(err));
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setTotal: (state, action: PayloadAction<number>) => ({
      ...state,
      total: action.payload,
    }),
    setSelectedRows: (state, action: PayloadAction<number[]>) => ({
      ...state,
      selectedRows: action.payload,
    }),
    setUnreadCount: (state, action: PayloadAction<number>) => ({
      ...state,
      unreadCount: action.payload,
    }),
    setIsSelectMode: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSelectMode: action.payload,
    }),
    setIsSelectAll: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSelectAll: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state, action) => {
        return {
          ...state,
          notificationsLoading: true,
          notificationsRequestId: action.meta.requestId,
        };
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.notificationsRequestId &&
          state.notificationsRequestId === requestId
        ) {
          return {
            ...state,
            notifications: action.payload.items,
            notificationsLoading: false,
            total: action.payload.meta.totalItems,
          };
        }
        return state;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.notificationsRequestId &&
          state.notificationsRequestId === requestId
        ) {
          return {
            ...state,
            notificationsLoading: false,
          };
        }
        return state;
      })

      .addCase(fetchUnreadCount.fulfilled, (state, action) => {
        return {
          ...state,
          unreadCount: action.payload,
        };
      });
  },
});

export const {
  setTotal,
  setSelectedRows,
  setIsSelectMode,
  setIsSelectAll,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const selectRow = (rowId: number) => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const { selectedRows } = getState().notifications;
  return dispatch(setSelectedRows([...selectedRows, rowId]));
};

export const deselectRow = (rowId: number) => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const { selectedRows } = getState().notifications;
  return dispatch(
    setSelectedRows(selectedRows.filter((r: number) => r !== rowId))
  );
};

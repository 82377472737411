import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

interface AvailabilityActionsHeaderProps {
  handleGoBack: () => void;
  label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
  },
  mainColor: {
    color: theme.palette.kyoBlue.main,
  },
  subTitle: {
    width: 'fit-content',
    cursor: 'pointer',
  },
  goBackIcon: {
    paddingRight: theme.spacing(1),
    fontSize: theme.spacing(3.75),
    cursor: 'pointer',
  },
}));

const AvailabilityActionsHeader = ({
  handleGoBack,
  label,
}: AvailabilityActionsHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <ArrowBackIcon
        onClick={handleGoBack}
        className={clsx(classes.goBackIcon, classes.mainColor)}
      />

      <div>
        <Typography
          onClick={handleGoBack}
          className={clsx(classes.mainColor, classes.subTitle)}
          variant="subtitle1"
        >
          My Availability
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {label}
        </Typography>
      </div>
    </div>
  );
};

export default AvailabilityActionsHeader;

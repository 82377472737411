import React, { useEffect, useState } from 'react';
import { getWholeChar } from '../../utils/helpers';
import type { UserResponse } from 'stream-chat';
import type { TeamUserType } from '../types';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
export type AvatarProps = {
  /** Image URL or default is an image of the first initial of the name if there is one  */
  image?: string | null;
  /** Name of the image, used for title tag fallback */
  name?: string;
  /** click event handler */
  onClick?: (event: React.BaseSyntheticEvent) => void;
  /** mouseOver event handler */
  onMouseOver?: (event: React.BaseSyntheticEvent) => void;
  /** Shape of the avatar - circle, rounded or square
   * @default circle
   */
  shape?: 'circle' | 'rounded' | 'square';
  /** Size in pixels
   * @default 32px
   */
  size?: number;
  /** The entire user object for the chat user displayed in the component */
  user?: UserResponse<TeamUserType>;
};

/**
 * A round avatar image with fallback to username's first letter
 */
export const CustomAvatar = (props: AvatarProps) => {
  const {
    image,
    name,
    onClick = () => undefined,
    onMouseOver = () => undefined,
    shape = 'circle',
    size = 32,
    user,
  } = props;

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const imageSrc = image || user?.profilePhoto;

  useEffect(() => {
    setError(false);
    setLoaded(false);
  }, [image]);

  const nameStr = name?.toString() || '';
  const initials = getWholeChar(nameStr, 0);

  return (
    <div
      className={`str-chat__avatar str-chat__avatar--${shape}`}
      data-testid="avatar"
      onClick={onClick}
      onMouseOver={onMouseOver}
      style={{
        flexBasis: `${size}px`,
        fontSize: `${size / 2}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
        width: `${size}px`,
      }}
      title={name}
    >
      {imageSrc && !error ? (
        <img
          alt={initials}
          className={`str-chat__avatar-image${
            loaded ? ' str-chat__avatar-image--loaded' : ''
          }`}
          data-testid="avatar-img"
          onError={() => setError(true)}
          onLoad={() => setLoaded(true)}
          src={imageSrc}
          style={{
            flexBasis: `${size}px`,
            height: `${size}px`,
            objectFit: 'cover',
            width: `${size}px`,
          }}
        />
      ) : (
        <div
          className="str-chat__avatar-fallback"
          data-testid="avatar-fallback"
          style={{
            backgroundColor: stringToColor(name || ''),
          }}
        >
          {initials}
        </div>
      )}
    </div>
  );
};

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Channel } from 'stream-chat';
import { RootState } from './rootReducer';

export interface ChatState {
  unreadMessagesCountPerClient: { [key: number]: number };
  currentChannel: Channel | null;
}

const initialState: ChatState = {
  unreadMessagesCountPerClient: {},
  currentChannel: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setUnreadMessageCountPerClient: (
      state,
      action: PayloadAction<{ id: number; count: number }>
    ) => {
      const { id, count } = action.payload;
      state.unreadMessagesCountPerClient[id] = count;
    },
    resetMessageCounts: (state) => {
      state.unreadMessagesCountPerClient = {};
    },
    setCurrentChannel: (state, action: PayloadAction<Channel | null>) => ({
      ...state,
      currentChannel: action.payload,
    }),
  },
});

export const {
  setUnreadMessageCountPerClient,
  resetMessageCounts,
  setCurrentChannel,
} = chatSlice.actions;

export const selectTotalUnreadCount = createSelector(
  (state: RootState) => state.chat.unreadMessagesCountPerClient,
  (unreadMessagesCountPerClient) => {
    return Object.values(unreadMessagesCountPerClient).reduce(
      (total, clientUnreadCount) => {
        return total + clientUnreadCount;
      },
      0
    );
  }
);

export default chatSlice.reducer;

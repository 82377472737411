import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  makeStyles,
  Theme,
  Badge,
  Tooltip,
} from '@material-ui/core';
import { ChatBubbleOutline } from '@material-ui/icons';

import { selectTotalUnreadCount } from '../redux/modules/chat';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  chatIcon: {
    color: theme.palette.common.black,
  },
}));

function notificationsLabel(count: number) {
  if (count === 0) {
    return 'no notifications';
  }
  if (count > 99) {
    return 'more than 99 notifications';
  }
  return `${count} notifications`;
}

const ChatIconHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const unreadMessagesCount = useSelector(selectTotalUnreadCount);

  return (
    <div className={classes.root}>
      <div>
        <Tooltip title={!unreadMessagesCount ? 'No new chat messages' : ''}>
          <IconButton
            onClick={() => navigate('/chat')}
            aria-label={notificationsLabel(100)}
          >
            <Badge
              badgeContent={unreadMessagesCount}
              color="error"
              showZero={false}
            >
              <ChatBubbleOutline className={classes.chatIcon} />
            </Badge>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default ChatIconHeader;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { ReactComponent as StarSvg } from '../../../assets/bigStar.svg';
import { ReactComponent as SuccessSvg } from '../../../assets/successRating.svg';
import { ReactComponent as InfoSvg } from '../../../assets/info-circle-blue-filled.svg';
import RatingProvidersPopup from './RatingProvidersPopup';
import { useAppDispatch } from '../../../redux/store';
import { fetchFeedbackTags } from '../../../redux/modules/feedbackTags';
import {
  fetchUnratedSessions,
  removeSessionFromRatingQueue,
  setShowSuspectedSessions,
} from '../../../redux/modules/newSessions';
import { RootState } from '../../../redux/modules/rootReducer';
import unratedSuspendedSessions from './unrated-suspended-sessions';
import SimpleDialog from '../../../components/Dialogs/SimpleDialog';

const opendPopupStyles = (theme: Theme) => ({
  border: `1px solid ${theme.palette.kyoLightGreen.main}`,

  '& h5': {
    color: theme.palette.kyoLightGreen.main,
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  ratingRecentSessionsButton: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 0, 2, 1.5),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 8px 16px 2px rgba(97, 97, 97, 0.10)',
    backgroundColor: 'white',
    textTransform: 'none',
    border: '1px solid transparent',

    '&:hover': {
      ...opendPopupStyles(theme),
      backgroundColor: '#FFFFFF',
    },
  },
  buttonLabel: {
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    color: theme.palette.kyoGray.dark,

    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(2),
    },
  },
  rightIcon: {
    color: theme.palette.kyoLightGreen.main,
    fontSize: theme.spacing(5),
  },
  opendPopup: {
    ...opendPopupStyles(theme),
  },
  loadingSessionsProgress: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface RatingProvidersProps {
  classNames?: Partial<Record<'rateRecentButtonRoot' | 'loadingIcon', string>>;
}

const RatingProviders = ({ classNames }: RatingProvidersProps) => {
  const classes = useStyles();
  const [isAnyProviderRated, setIsAnyProviderRated] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const dispatch = useAppDispatch();

  const currentClientId = useSelector(
    (state: RootState) => state.client.currentClient?.id
  );

  const unratedSessionsLoading = useSelector(
    (state: RootState) => state.newSessions.unratedSessionsLoading
  );

  const [sessionToRate] = useSelector(
    (state: RootState) => state.newSessions.sessionsToRate
  );

  const showSuspectedSessions = useSelector(
    (state: RootState) => state.newSessions.showSuspectedSessions
  );

  const noLatestSessionsToRate = useSelector(
    (state: RootState) => state.newSessions.noLatestSessionsToRate
  );

  const { segmentId } = sessionToRate || {};

  const handlePopupClose = useCallback(
    ({ closingWithoutSubmit = true }) => {
      if (!currentClientId) {
        return;
      }
      const closedSegmentId = segmentId;

      if (closingWithoutSubmit) {
        unratedSuspendedSessions.add(closedSegmentId, currentClientId);
      } else {
        unratedSuspendedSessions.remove([closedSegmentId], currentClientId);
        setIsAnyProviderRated(true);
      }

      dispatch(removeSessionFromRatingQueue(closedSegmentId));
    },
    [dispatch, segmentId, currentClientId]
  );

  const handleShowSuccessPopupClose = () => {
    setShowSuccessPopup(false);
    setIsAnyProviderRated(false);
  };

  useEffect(() => {
    dispatch(fetchFeedbackTags());
    if (currentClientId) {
      dispatch(fetchUnratedSessions({ clientId: currentClientId }));
    }
  }, [dispatch, currentClientId]);

  useEffect(() => {
    if (!segmentId && isAnyProviderRated) {
      setShowSuccessPopup(true);
    }
  }, [segmentId, isAnyProviderRated]);

  const haveSessionsToRate =
    sessionToRate ||
    (currentClientId &&
      unratedSuspendedSessions.getValue(currentClientId).length > 0);

  return (
    <>
      {haveSessionsToRate &&
        (unratedSessionsLoading ? (
          <div
            className={clsx(
              classes.loadingSessionsProgress,
              classNames?.loadingIcon
            )}
          >
            <CircularProgress />
          </div>
        ) : (
          <Button
            className={clsx(
              classes.ratingRecentSessionsButton,
              classNames?.rateRecentButtonRoot,
              segmentId && classes.opendPopup
            )}
            onClick={() => {
              dispatch(setShowSuspectedSessions(true));
              if (currentClientId) {
                dispatch(fetchUnratedSessions({ clientId: currentClientId }));
              }
            }}
          >
            <StarSvg />
            <Typography className={classes.buttonLabel} variant="h5">
              Rate your recent sessions
            </Typography>
            <ChevronRightIcon className={classes.rightIcon} />
          </Button>
        ))}
      {sessionToRate && (
        <RatingProvidersPopup
          key={sessionToRate.segmentId}
          handleClose={handlePopupClose}
          session={sessionToRate}
        />
      )}

      {/* SuccessDialog */}
      <WrappedSimpleDialog
        open={showSuccessPopup}
        onClose={handleShowSuccessPopupClose}
        icon={<SuccessSvg />}
        text="Thank you for your feedback!"
      />

      {/* NoSessionsInfoDialog */}
      <WrappedSimpleDialog
        open={showSuspectedSessions && noLatestSessionsToRate}
        onClose={() => dispatch(setShowSuspectedSessions(false))}
        icon={<InfoSvg />}
        text="There's no recent session to rate right now. Please check back again after your next session."
      />
    </>
  );
};

export default RatingProviders;

const useSimpleDialogStyles = makeStyles((theme: Theme) => ({
  title: {
    backgroundColor: 'transparent',
  },
  successIcon: {
    margin: 'auto',
    display: 'block',
  },
  icon: {
    margin: 'auto',
    display: 'block',
    width: 'max-content',
  },
  content: {
    margin: theme.spacing(1, 2, 2),
    color: theme.palette.kyoGray63.dark,

    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(2),
    },
  },
}));

const WrappedSimpleDialog = ({
  open,
  onClose,
  icon,
  text,
}: {
  open: boolean;
  onClose: () => void;
  icon: React.ReactNode;
  text: string;
}) => {
  const classes = useSimpleDialogStyles();
  return (
    <SimpleDialog
      onClose={onClose}
      styles={{ title: classes.title }}
      open={open}
    >
      <div className={classes.icon}>{icon}</div>
      <Typography className={classes.content} variant="h6">
        {text}
      </Typography>
    </SimpleDialog>
  );
};

import moment from 'moment';
import * as Yup from 'yup';
import { ModifiedAvailabilityViewState } from '../components/Availability/constants';
import { TIME_FORMAT } from '../shared/constants';
import { DayOfWeekId } from '../types/types';
import { PREFERRED_BLOCK_MIN_INTERVAL } from '../utils/constants';
import {
  DATE_FORMAT_US,
  daysToMilliseconds,
  diffOfDatesInDayMilliseconds,
  ModifiedAvailabilityTimeOffMaxStartDate,
  ModifiedAvailabilityTimeOffMinStartDate,
} from '../utils/helpers';

const MIN_PASSWORD_LENGTH = 8;
const SPECIAL_CHAR_REGEX = new RegExp(
  '[=\\+\\^\\$\\*\\.\\[\\]{}\\(\\)\\?\\-“!@#%&/,><’:;|_~`]',
  'g'
);

const REQUIRED_TEXT = 'This field is required';

const mobileNumberSchema = Yup.string()
  .trim()
  .required(REQUIRED_TEXT)
  .matches(/^[0-9]*$/g, 'Mobile number should contain only digits')
  .min(10, 'Mobile number too short, should have 10 digits')
  .max(10, 'Mobile number too long, should have 10 digits');

export const newPasswordSchema = Yup.string()
  .required(REQUIRED_TEXT)
  .min(
    MIN_PASSWORD_LENGTH,
    `Password should at least ${MIN_PASSWORD_LENGTH} characters long`
  )
  .matches(/[0-9]/g, 'Password should contain digits')
  .matches(/[A-Z]/g, 'Password should contain uppercase characters')
  .matches(/[a-z]/g, 'Password should contain lowercase characters')
  .matches(SPECIAL_CHAR_REGEX, 'Password should contain a special character');

export const registrationSchema = Yup.object().shape({
  newPassword: newPasswordSchema,
  newPasswordConfirm: Yup.string()
    .required(REQUIRED_TEXT)
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match"),
  firstName: Yup.string().trim().required(REQUIRED_TEXT),
  lastName: Yup.string().trim().required(REQUIRED_TEXT),
  mobileNumber: mobileNumberSchema,
});

export const emailFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required(REQUIRED_TEXT),
});

export const newPasswordFormSchema = Yup.object().shape({
  verificationCode: Yup.string().required(REQUIRED_TEXT),
  newPassword: newPasswordSchema,
  newPasswordConfirm: Yup.string()
    .required(REQUIRED_TEXT)
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match"),
});

export const clientLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required(REQUIRED_TEXT),
  password: Yup.string().required(REQUIRED_TEXT),
});

export const mfaSchema = Yup.object().shape({
  mfaCode: Yup.string()
    .trim()
    .required(REQUIRED_TEXT)
    .matches(/^[0-9]*$/g, 'Code should contain only digits')
    .min(6, 'Code should have 6 digits')
    .max(6, 'Code should have 6 digits'),
});

export const verificationFormSchema = Yup.object().shape({
  clientDob: Yup.date()
    .nullable()
    .required(REQUIRED_TEXT)
    .typeError('Invalid date'),
  relationshipId: Yup.number().required(REQUIRED_TEXT),
});

export const updateProfileSchema = Yup.object().shape({
  firstName: Yup.string().trim().required(REQUIRED_TEXT),
  lastName: Yup.string().trim().required(REQUIRED_TEXT),
  mobileNumber: mobileNumberSchema,
});

export const helpContactFormSchema = Yup.object().shape({
  helpWith: Yup.number().required(),
  details: Yup.string().required(),
});

const preferredBlock3EndValidation = Yup.string()
  .nullable()
  .test(
    'Check PreferredBlock3 Min Interval',
    `Last 2 dropdown time difference should be at least ${PREFERRED_BLOCK_MIN_INTERVAL} hours`,
    (preferredBlock3End, { parent: { preferredBlock3Start } }) => {
      if (!preferredBlock3End || !preferredBlock3Start) {
        return true;
      }
      const time1 = moment(preferredBlock3End, TIME_FORMAT);
      const time2 = moment(preferredBlock3Start, TIME_FORMAT);
      const difference = time1.diff(time2, 'minutes');

      return difference >= PREFERRED_BLOCK_MIN_INTERVAL * 60;
    }
  );

export const availabilityValidationSchema = Yup.object({
  startDate: Yup.string()
    .required('Start date is required')
    .test('Is Valid', 'Invalid start date', (value) => {
      return moment(value).isValid();
    }),
  preferredBlock3End: preferredBlock3EndValidation,
});

export const temporaryAvailabilityValidationSchema = ({
  viewState,
  availabilityMaxDuration,
}: {
  viewState: ModifiedAvailabilityViewState;
  availabilityMaxDuration: number;
}) =>
  Yup.object({
    startDate: Yup.string()
      .required('Start date is required')
      .test(
        'Is Valid',
        'Invalid start date',
        (value, { parent: { endDate } }) => {
          const momentStartDate: moment.Moment = moment(
            value,
            DATE_FORMAT_US
          ).startOf('day');

          if (!momentStartDate.isValid()) {
            return false;
          }

          if (
            viewState === ModifiedAvailabilityViewState.UnavailableEntirePeriod
          ) {
            return (
              momentStartDate >= ModifiedAvailabilityTimeOffMinStartDate &&
              momentStartDate <= ModifiedAvailabilityTimeOffMaxStartDate
            );
          }

          const momentEndDate: moment.Moment = moment(endDate, DATE_FORMAT_US);
          const weekStart = momentStartDate.weekday() === DayOfWeekId.Sunday;
          const weekEnd = momentEndDate.weekday() === DayOfWeekId.Saturday;
          const someWeek =
            momentStartDate.weekYear() === momentEndDate.weekYear();

          return weekStart && weekEnd && someWeek;
        }
      ),
    endDate: Yup.string()
      .required('End date is required')
      .test(
        'Is Valid',
        'Invalid end date',
        (value, { parent: { startDate } }) => {
          const momentEndDate = moment(value, DATE_FORMAT_US)
            .utc()
            .startOf('day');

          if (!momentEndDate.isValid() || !value) {
            return false;
          }

          const datesDiff = diffOfDatesInDayMilliseconds(startDate, value);
          const maxDiffInMilliseconds = daysToMilliseconds(
            availabilityMaxDuration
          );

          if (datesDiff < 0 || datesDiff > maxDiffInMilliseconds) {
            return false;
          }

          return true;
        }
      ),
    preferredBlock3End: preferredBlock3EndValidation,
  });

export const addressFormSchema = Yup.object().shape({
  street: Yup.string().trim().required('This field is required'),
  city: Yup.string().trim().required('This field is required'),
  state: Yup.string().trim().required('This field is required'),
  zip: Yup.string()
    .trim()
    .matches(/^[0-9]*$/g, { message: 'Zip Code must have 5 digits' })
    .required('This field is required')
    .length(5, 'Zip Code must have 5 digits'),
});

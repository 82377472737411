import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import availabilityApi, {
  GetAvailabilityFilter,
} from '../../api/availabilityApi';
import scheduleApi, {
  FetchScheduledSessionsFilters,
} from '../../api/scheduleApi';
import { AvailabilityType } from '../../shared/constants';
import { AvailabilityStatus } from '../../shared/types';
import { ScheduleSegment } from '../../types/types';
import { fetchCurrentClientById } from './client';

export interface AvailabilityBlock {
  id: number;
  timeBlockDayOfWeekId: number;
  isAvailable: boolean;
  timeBlockDayOfWeek: {
    id: number;
    timeBlockId: number;
    dayOfWeekId: number;
    timeBlock: {
      id: number;
      start: string;
      end: string;
    };
    dayOfWeek: {
      id: number;
      name: string;
    };
  };
}

export interface Availability {
  id: number;
  type: AvailabilityType;
  submittedByProviderId: number;
  submittedByUserId: number;
  clientId: number;
  providerId: number;
  startDate: string;
  endDate: string;
  effectiveDate: string;
  createdAt: string;
  status: AvailabilityStatus;
  availabilityBlocks: AvailabilityBlock[];
  submittedByProvider: {
    id: number;
    email: string;
  };
  preferredBlock1Start?: string;
  preferredBlock3Start?: string;
  preferredBlock3End?: string;
}

export type PartialAvailability = Partial<
  Omit<Availability, 'availabilityBlocks'> & {
    availabilityBlocks: Partial<AvailabilityBlock>[];
  }
>;

export interface PreferredTimeBlockOptions {
  block1StartOptions: string[];
  block3StartOptions: string[];
  block3EndOptions: string[];
}

export enum AVAILABILITY_ACTION_STATUS {
  EDIT = 'EDIT',
  ADD = 'ADD',
  PASSIVE = 'PASSIVE',
  ADD_TEMPORARY = 'ADD_TEMPORARY',
  EDIT_TEMPORARY = 'EDIT_TEMPORARY',
}

export const AvailabilityLongTermActions = [
  AVAILABILITY_ACTION_STATUS.ADD,
  AVAILABILITY_ACTION_STATUS.EDIT,
];

export const AvailabilityShortTermActions = [
  AVAILABILITY_ACTION_STATUS.ADD_TEMPORARY,
  AVAILABILITY_ACTION_STATUS.EDIT_TEMPORARY,
];

interface AvailabilityState {
  stAvailability: Availability[];
  ltAvailability: Availability[];
  loading: boolean;
  availabilityIdentifier: symbol;
  action: {
    status: AVAILABILITY_ACTION_STATUS;
    availability?: Availability;
    loading: boolean;
    succeeded: boolean;
    failed: boolean;
  };
  preferredTimeBlockOptions: PreferredTimeBlockOptions | null;
  preferredTimeBlockOptionsLoading: boolean;
  preferredTimeBlockOptionsError: SerializedError | null;
  scheduledSessions: ScheduleSegment[] | null;
  scheduledSessionsLoading: boolean;
  scheduledSessionsError: SerializedError | null;
}

const initialActionState = {
  status: AVAILABILITY_ACTION_STATUS.PASSIVE,
  loading: false,
  succeeded: false,
  failed: false,
};

const initialState: AvailabilityState = {
  stAvailability: [],
  ltAvailability: [],
  availabilityIdentifier: Symbol(undefined),
  loading: false,
  action: initialActionState,
  preferredTimeBlockOptions: null,
  preferredTimeBlockOptionsLoading: false,
  preferredTimeBlockOptionsError: null,
  scheduledSessions: null,
  scheduledSessionsLoading: false,
  scheduledSessionsError: null,
};

export const fetchPreferredTimeBlockOptions = createAsyncThunk(
  'availability/fetchPreferredTimeBlockOptions',
  () => availabilityApi.fetchPreferredTimeBlockOptions()
);

export const fetchAvailability = createAsyncThunk(
  'availability/fetchAvailability',
  (filters: GetAvailabilityFilter) => availabilityApi.fetchAvailability(filters)
);

export const deleteAvailability = createAsyncThunk(
  'availability/deleteAvailability',
  ({ id }: { id: number }) => availabilityApi.deleteAvailability(id)
);

export const createAvailability = createAsyncThunk(
  'availability/createAvailability',
  (payload: PartialAvailability, { rejectWithValue }) =>
    availabilityApi
      .createAvailability(payload)
      .catch((e) => rejectWithValue('Creating availability fails'))
);

export const updateAvailability = createAsyncThunk(
  'availability/updateAvailability',
  (
    payload: { id: number; availability: PartialAvailability },
    { rejectWithValue }
  ) =>
    availabilityApi
      .updateAvailability(payload.id, payload.availability)
      .catch((e) => rejectWithValue('Updating availability fails'))
);

export const fetchScheduledSessions = createAsyncThunk(
  'availability/fetchScheduledSessions',
  (payload: FetchScheduledSessionsFilters, { rejectWithValue }) =>
    scheduleApi
      .fetchScheduledSessions(payload, true)
      .catch((e) => rejectWithValue('Failed to load scheduled sessions'))
);

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setActionStatus: (
      state,
      action: PayloadAction<{
        status: AVAILABILITY_ACTION_STATUS;
        availability?: Availability;
      }>
    ) => {
      if (action.payload.status === AVAILABILITY_ACTION_STATUS.PASSIVE) {
        state.action = initialActionState;
      } else {
        state.action.status = action.payload.status;
        state.action.availability = action.payload.availability;
      }
    },
    clearScheduledSessions: (state) => {
      state.scheduledSessions = null;
      state.scheduledSessionsError = null;
      state.scheduledSessionsLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailability.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAvailability.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchAvailability.fulfilled, (state, action) => {
        state.loading = false;
        state.stAvailability = action.payload.filter(
          (avail) => avail.type === AvailabilityType.ShortTerm
        );
        state.ltAvailability = action.payload.filter(
          (avail) => avail.type === AvailabilityType.LongTerm
        );
      })
      .addCase(fetchPreferredTimeBlockOptions.pending, (state) => {
        state.preferredTimeBlockOptionsLoading = true;
      })
      .addCase(fetchPreferredTimeBlockOptions.fulfilled, (state, action) => {
        state.preferredTimeBlockOptionsLoading = false;
        state.preferredTimeBlockOptions = action.payload;
      })
      .addCase(fetchPreferredTimeBlockOptions.rejected, (state, action) => {
        state.preferredTimeBlockOptionsLoading = false;
        state.preferredTimeBlockOptionsError = action.error;
      })
      .addCase(deleteAvailability.fulfilled, (state, { meta }) => {
        state.availabilityIdentifier = Symbol(meta.requestId);
      })
      .addCase(fetchScheduledSessions.pending, (state) => {
        state.scheduledSessions = null;
        state.scheduledSessionsLoading = true;
      })
      .addCase(fetchScheduledSessions.fulfilled, (state, action) => {
        state.scheduledSessionsLoading = false;
        state.scheduledSessions = action.payload;
      })
      .addCase(fetchScheduledSessions.rejected, (state, action) => {
        state.scheduledSessionsLoading = false;
        state.scheduledSessionsError = action.error;
      })
      .addCase(fetchCurrentClientById.pending, () => initialState)
      .addMatcher(
        isAnyOf(createAvailability.pending, updateAvailability.pending),
        (state) => {
          state.action.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(createAvailability.fulfilled, updateAvailability.fulfilled),
        (state) => {
          state.action.succeeded = true;
          state.action.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(createAvailability.rejected, updateAvailability.rejected),
        (state) => {
          state.action.failed = true;
          state.action.loading = false;
        }
      );
  },
});
export const {
  setActionStatus,
  clearScheduledSessions,
} = availabilitySlice.actions;

export default availabilitySlice.reducer;

import React, { useEffect } from 'react';
import { Channel } from 'stream-chat';
import moment from 'moment';
import {
  ChannelPreviewUIComponentProps,
  useChatContext,
} from 'stream-chat-react';
import './TeamChannelPreview.css';

import type {
  TeamAttachmentType,
  TeamChannelType,
  TeamCommandType,
  TeamEventType,
  TeamMessageType,
  TeamReactionType,
  TeamUserType,
} from '../types';

type Props = ChannelPreviewUIComponentProps & {
  type: string;
  onChannelChange?: (channel: Channel, closeChannelList?: boolean) => void;
};

export const TeamChannelPreview: React.FC<Props> = (props: Props) => {
  const { channel, setActiveChannel, onChannelChange } = props;

  const { data } = channel;
  const isFrozen = data?.frozen;
  const channelName = isFrozen ? `[disabled]${data?.name}` : data?.name;

  const { channel: activeChannel } = useChatContext<
    TeamAttachmentType,
    TeamChannelType,
    TeamCommandType,
    TeamEventType,
    TeamMessageType,
    TeamReactionType,
    TeamUserType
  >();

  useEffect(() => {
    if (activeChannel && activeChannel.id === channel.id && onChannelChange) {
      onChannelChange(channel, false);
    }
  }, [activeChannel, channel, onChannelChange]);

  const channelLastMessage =
    channel.state.messages[channel.state.messages.length - 1];
  const renderMessageText = () => {
    if (!channelLastMessage) {
      return null;
    }
    const lastMessageAuthor = channelLastMessage?.user?.name;
    const lastMessageText = channelLastMessage?.text;

    const text = lastMessageText || 'message text';

    return (
      <div className="last_message">
        <div className="last_message__author">{lastMessageAuthor}:</div>
        <div className="last_message__text">
          {text?.length < 60 ? lastMessageText : `${text.slice(0, 70)}...`}
        </div>
      </div>
    );
  };
  const renderMessageTime = () => {
    const lastMessageDate = moment(channelLastMessage?.created_at);
    const isMessageDateOnFeature = lastMessageDate.isAfter();
    const messageDate = isMessageDateOnFeature ? moment() : lastMessageDate;
    return channelLastMessage ? messageDate.fromNow() : '';
  };
  const selected = channel?.id === activeChannel?.id;
  return (
    <div
      className={
        channel?.id === activeChannel?.id
          ? 'channel-preview__wrapper__selected'
          : 'channel-preview__wrapper'
      }
      onClick={() => {
        if (setActiveChannel) {
          setActiveChannel(channel);
          if (onChannelChange) {
            onChannelChange(channel);
          }
        }
      }}
      role="button"
      onKeyDown={() => null}
      tabIndex={0}
    >
      <div
        className="channel-preview__item"
        style={isFrozen ? { opacity: '0.4' } : undefined}
      >
        <div className="channel-preview__item__hash-tag">#</div>
        <div className="channel-preview__item__channel-info">
          <div className="channel-preview__item__channel-info__name">
            {channelName || 'Care Team'}
            {!!channel?.state.unreadCount && !selected && (
              <div className="channel-preview__item__channel-info__unread-count">
                {channel?.state.unreadCount}
              </div>
            )}
          </div>
          <div className="channel-preview__item__channel-info__last-message">
            {renderMessageText()}
          </div>
          <div className="channel-preview__item__channel-info__last-message-date">
            {renderMessageTime()}
          </div>
        </div>
      </div>
    </div>
  );
};

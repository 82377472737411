import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface ContainerWithImageProps {
  children: React.ReactNode;
  imgSrc: string;
  alt: string;
  styles?: Partial<Record<'root' | 'img', string | string[]>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },
}));

const ContainerWithImage = ({
  children,
  imgSrc,
  alt,
  styles,
}: ContainerWithImageProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(styles?.root, classes.root)}>
      <img className={clsx(styles?.img)} src={imgSrc} alt={alt} />
      {children}
    </div>
  );
};

export default ContainerWithImage;

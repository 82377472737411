import {
  configureStore,
  getDefaultMiddleware,
  Action,
  ThunkAction,
} from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import rootReducer, { RootState } from './modules/rootReducer';
import HandleErrorResponses from './middleware/HandleErrorResponses';
import namePropertyMiddleware from './middleware/namePropertyMiddleware';

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
  HandleErrorResponses,
  namePropertyMiddleware,
] as const;
const preloadedState = {};

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./modules/rootReducer', () => {
    const newRootReducer = require('./modules/rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}
export default store;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export function useAppSelector<TSelected = unknown>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
) {
  return useSelector(selector, equalityFn);
}
export type AsyncState = 'pending' | 'success' | 'failed';

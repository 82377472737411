import React from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Theme,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';

import GoalComponent from './GoalComponent';
import { Goal } from '../../../types/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: 16,
    padding: theme.spacing(2),
    paddingBottom: 0,
    boxShadow:
      '0px 8px 16px 2px rgba(97, 97, 97, 0.1), 0px 16px 32px -22px rgba(97, 97, 97, 0.1);',
    width: '100%',
  },
  container: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  goalRow: {
    marginBottom: theme.spacing(2),
  },
  emptyText: {
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },

  loadingContainer: {
    minHeight: 200,
    minWidth: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface AchievementsProps {
  goals: Goal[];
  loading?: boolean;
  className?: string;
}

const Achievements = (props: AchievementsProps) => {
  const { goals, className, loading } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const loadingContainer = (
    <Box className={classes.loadingContainer}>
      <CircularProgress />
    </Box>
  );

  return (
    <Box className={clsx(classes.root, className)}>
      {loading && loadingContainer}
      {!loading && (
        <>
          <Typography variant={isMobile ? 'h6' : 'h5'}>Achievements</Typography>
          <div className={classes.container}>
            {goals.length === 0 && (
              <Typography variant="body2" className={classes.emptyText}>
                No goals to report right now. Check back soon!
              </Typography>
            )}
            {goals.map((g) => (
              <GoalComponent goal={g} key={g.id} className={classes.goalRow} />
            ))}
          </div>
        </>
      )}
    </Box>
  );
};

export default Achievements;

import React from 'react';
import {
  Table,
  TableFooter,
  TableRow,
  TableCell,
  makeStyles,
  Theme,
  Box,
  TextField,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { MUIDataTableOptions } from 'mui-datatables';
import clsx from 'clsx';

import Pagination from './Pagination';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    borderBottom: 'none',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 'none',
    fontSize: 16,
  },
  select: {
    marginRight: theme.spacing(2),
    minWidth: 140,
    '& > div': {
      color: 'inherit',
      fontSize: 'inherit',

      '& > div': {
        paddingBottom: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  arrowButton: {
    padding: 0,
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(0, 0.5),
    [theme.breakpoints.down('sm')]: {
      width: 35,
      height: 35,
      margin: theme.spacing(0, 1.5),
    },
  },
  bold: {
    fontWeight: 700,
  },
  pagination: {
    color: '#000',
  },
  desktopOnly: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pageControls: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
    },
  },
}));

interface CustomTableFooterProps {
  options: MUIDataTableOptions;
  rowCount?: number;
  page?: number;
  rowsPerPage?: number;
  changeRowsPerPage?: Function;
  changePage?: Function;
}

const CustomTableFooter = (props: CustomTableFooterProps) => {
  const classes = useStyles();
  const {
    options,
    rowCount,
    page,
    rowsPerPage,
    changeRowsPerPage,
    changePage,
  } = props;

  const { pagination } = options;

  if (
    !pagination ||
    !rowsPerPage ||
    !rowCount ||
    page === undefined ||
    !changePage ||
    !changeRowsPerPage
  ) {
    return null;
  }

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    changeRowsPerPage(value);
  };

  const numOfPages = Math.ceil(rowCount / rowsPerPage);

  const shownRows =
    numOfPages > 1 && page === numOfPages - 1
      ? rowCount - rowsPerPage * (numOfPages - 1)
      : Math.min(rowsPerPage, rowCount);

  return (
    <Table>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={1000} className={classes.cell}>
            <Box className={classes.container}>
              <span className={classes.desktopOnly}>
                Showing
                <span className={classes.bold}> {shownRows} </span>
                of {rowCount} records
              </span>
              <Pagination
                changePage={changePage}
                currentPage={page}
                maxShownPages={10}
                className={clsx(classes.pagination, classes.desktopOnly)}
                numOfPages={numOfPages}
              />
              <span className={classes.pageControls}>
                {options.rowsPerPageOptions && (
                  <span>
                    <TextField
                      select
                      value={rowsPerPage}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      className={classes.select}
                      onChange={handleRowsPerPageChange}
                    >
                      {options.rowsPerPageOptions.map((opt) => (
                        <MenuItem value={opt} key={opt}>
                          {`Show ${opt} rows`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </span>
                )}
                <span>
                  <IconButton
                    className={classes.arrowButton}
                    onClick={() => {
                      changePage(page - 1);
                    }}
                    disabled={page === 0}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton
                    className={classes.arrowButton}
                    onClick={() => {
                      changePage(page + 1);
                    }}
                    disabled={page === numOfPages - 1}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </span>
              </span>
            </Box>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default CustomTableFooter;

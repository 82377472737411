import {
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ReplayIcon from '@material-ui/icons/Replay';
import clsx from 'clsx';
import { isEmpty, keyBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { GradientCircularProgress } from 'react-circular-gradient-progress';

import InfoCircle from '../../../assets/info-circle.svg';
import Warning from '../../../assets/warning.svg';
import { RootState } from '../../../redux/modules/rootReducer';
import {
  AvailabilityBlock,
  fetchPreferredTimeBlockOptions,
} from '../../../redux/modules/availability';
import EditableDate from '../../../shared/editableDate';
import AvailabilityMatrix from '../../../components/Matrix/AvailabilityMatrix';
import {
  checkIsPreferenceSelectDisabled,
  DATE_FORMAT,
  toAbsolutePath,
} from '../../../utils/helpers';
import MatrixInfo from '../../../components/Matrix/MatrixInfo';
import { editMatrixBlock } from '../../../shared/lib/availability';
import useSettings from '../../../hooks/useSettings';
import { availabilityValidationSchema } from '../../../validation/validation';
import { Client } from '../../../types/types';
import useAvailabilityActions, {
  InitialValues as FormikInitialValues,
} from '../../../components/Availability/hooks/useAvailabilityActions';
import AvailabilityActionsHeader from '../../../components/Availability/AvailabilityActions/AvailabilityActionsHeader';
import {
  editableDateStyles,
  getBlockMainStyles,
} from '../../../components/Availability/AvailabilityActions/AvailabilityActionsStyles';
import InfoContainer, {
  InfoContainerTypes,
} from '../../../components/InfoContainer';
import CustomLink from '../../../components/CustomLink';
import AvailabilityRecommendations from '../../../components/Availability/AvailabilityRecommendations';
import PreferenceSelect from '../components/PreferenceSelect';
import CustomStyledInfoCard from '../../../components/Availability/AvailabilityActions/CustomStyledInfoCard';
import ActionsControlButton from '../../../components/buttons/ActionsControlButton';
import useStyles from './AvailabilityActionsClientStyles';
import ChangesReviewDialog from './ChangesReviewDialog';
import SucceedDialog from '../../../components/Availability/AvailabilityActions/SucceedDialog';
import AvailableHoursWrapper, {
  AvailabilityHoursStatus,
} from '../../../components/Availability/AvailableHoursWrapper';
import useAvailHoursInfo from '../hooks/useAvailHoursInfo';
import { AVAILABILITY_DATE_PRESENT_FORMAT } from '../../../components/Availability/constants';
import VerticalLabelTimeBlock from '../../../shared/matrix/ui/verticalLabelTimeBlock';

type AvailabilityActionsClientProps = Partial<
  Pick<
    Client,
    | 'schedule'
    | 'weeklyAuthSize'
    | 'region'
    | 'ClientDischargeDate'
    | 'DTDischargeDate'
  >
> & {
  handleSuccess?: () => void;
};

export const AvailabilityStatusColors = {
  [AvailabilityHoursStatus.Bad]: '#f5796e',
  [AvailabilityHoursStatus.Normal]: '#FFD976',
  [AvailabilityHoursStatus.Good]: '#28B20F',
};

const AvailabilityActionsClient = ({
  schedule,
  weeklyAuthSize = 0,
  region,
  ClientDischargeDate,
  DTDischargeDate,
  handleSuccess,
}: AvailabilityActionsClientProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { upcomingAvailabilityMinPeriod } = useSettings();
  const formikRef = useRef<
    FormikProps<FormikInitialValues & { editingStartDate: boolean }>
  >(null);
  const availHoursInfos = useAvailHoursInfo();

  const [reviewChanges, setReviewChanges] = useState(false);
  useEffect(() => {
    dispatch(fetchPreferredTimeBlockOptions());
  }, [dispatch]);

  const preferredTimeBlockOptions = useSelector(
    (state: RootState) => state.availability.preferredTimeBlockOptions
  );

  const {
    status: actionStatus,
    availability,
    loading,
    succeeded,
  } = useSelector((state: RootState) => state.availability.action);

  const timeBlocks = useSelector(
    (state: RootState) => state.adminSettings.timeBlocks
  );

  const statusData = useAvailabilityActions({
    upcomingAvailabilityMinPeriod,
    availability,
  })[actionStatus];

  const hideProgress =
    region === 'OPT' || ClientDischargeDate || DTDischargeDate;

  const getCircleProgress = (blockCount: number) => {
    const weeklyAuthToPercent =
      weeklyAuthSize > 0 ? (100 * blockCount * 3) / weeklyAuthSize : 100;

    return weeklyAuthToPercent > 100 ? 100 : weeklyAuthToPercent;
  };

  useEffect(() => {
    if (!formikRef.current) {
      return;
    }
    formikRef.current.setValues({
      ...formikRef.current.values,
      startDate: statusData.initialValues.startDate,
    });
  }, [upcomingAvailabilityMinPeriod, statusData.initialValues.startDate]);

  return (
    <>
      <AvailabilityActionsHeader
        handleGoBack={statusData.handleClose}
        label="Change My Availability"
      />

      <Divider className={classes.divider} />
      <InfoContainer
        className={classes.temporaryChangeInfo}
        type={InfoContainerTypes.Warning}
      >
        <>
          <Typography variant="body2">
            This will update the standard availability we use for your regular,
            recurring schedule
          </Typography>
          <Typography variant="body2">
            For a temporary change (ie vacation or holiday) please{' '}
            <CustomLink
              label="Contact Scheduling."
              pathname={toAbsolutePath('/contact-scheduling')}
              className={clsx(classes.contactSchedulingLink, classes.mainColor)}
            />
          </Typography>
        </>
      </InfoContainer>
      <AvailabilityRecommendations className={classes.pageMaxWidth} />
      <Divider className={clsx(classes.divider, classes.pageMaxWidth)} />

      <Formik
        initialValues={{ ...statusData.initialValues, editingStartDate: false }}
        validationSchema={availabilityValidationSchema}
        onSubmit={(values) => statusData.onSubmit(values)}
        innerRef={formikRef}
        enableReinitialize
      >
        {({
          values: {
            availabilityBlocks,
            startDate,
            editingStartDate,
            preferredBlock1Start,
            preferredBlock3Start,
            preferredBlock3End,
          },
          errors,
          handleSubmit,
          handleReset,
          setFieldValue,
          submitForm,
        }) => (
          <AvailableHoursWrapper
            availabilityBlocks={availabilityBlocks as AvailabilityBlock[]}
            weeklyAuthSize={weeklyAuthSize}
          >
            {(availabilityHours, availabilityHoursStatus) => {
              const circleProgress = getCircleProgress(
                availabilityHours.weekdayBlocks
              );
              const availHoursInfo = availHoursInfos[availabilityHoursStatus];
              const circleColors = availHoursInfo.circleColors(circleProgress);

              return (
                <Form onSubmit={handleSubmit}>
                  {startDate && (
                    <EditableDate
                      label="Start Date:"
                      value={startDate}
                      onEdit={editingStartDate}
                      onEditStart={() => {
                        setFieldValue('editingStartDate', true);
                      }}
                      valueFormat={DATE_FORMAT}
                      presentFormat={AVAILABILITY_DATE_PRESENT_FORMAT}
                      name="startDate"
                      onChange={(newValue) => {
                        setFieldValue('startDate', newValue);
                        setFieldValue('editingStartDate', false);
                      }}
                      styles={editableDateStyles({
                        maxWidth: theme.spacing(40),
                      })}
                      minDate={statusData.minStartDate}
                      maxDate={moment()
                        .utc()
                        .add(60, 'days')
                        .format(DATE_FORMAT)}
                      shouldDisableDate={(day) => day?.weekday() !== 0}
                    />
                  )}

                  <CustomStyledInfoCard
                    overloadRootStyles={classes.pageMaxWidth}
                    image={InfoCircle}
                  >
                    <Typography variant="body2">
                      You must provide at least{' '}
                      {moment
                        .duration(upcomingAvailabilityMinPeriod, 'week')
                        .humanize({ d: 7, w: 4 })}{' '}
                      prior notice for any availability change.
                    </Typography>
                  </CustomStyledInfoCard>

                  <div className={classes.mainContent}>
                    <div className={classes.editableContent}>
                      <AvailabilityMatrix
                        schedule={schedule}
                        availabilityBlocks={availabilityBlocks}
                        cellSizes={isMobile ? 3.4 : 5}
                        VerticalLabel={({ value }) => (
                          <VerticalLabelTimeBlock
                            value={value}
                            timeBlock={keyBy(timeBlocks, 'id')[value]}
                            styles={{
                              root: classes.matrixVerticalLabel,
                              name: classes.matrixVerticalLabelName,
                              content: classes.matrixVerticalLabelContent,
                            }}
                            periodNameLength={2}
                          />
                        )}
                        getBlockMainStyles={getBlockMainStyles}
                        disableWeekends
                        onCellClick={({ key, isAvailable }) => {
                          setFieldValue(
                            'availabilityBlocks',
                            editMatrixBlock(
                              key,
                              isAvailable,
                              availabilityBlocks
                            )
                          );
                          setFieldValue('editingStartDate', false);
                        }}
                      />
                      <div className={classes.infoAndResetRow}>
                        <MatrixInfo rootClassName={classes.matrixInfo} />
                        <Button
                          startIcon={<ReplayIcon />}
                          className={clsx(
                            classes.textCapitalize,
                            classes.mainColor
                          )}
                          variant="text"
                          onClick={handleReset}
                        >
                          <Typography variant="body2">
                            Reset to current
                          </Typography>
                        </Button>
                      </div>

                      {preferredTimeBlockOptions && (
                        <div
                          className={clsx(
                            classes.pageMaxWidth,
                            classes.preferences
                          )}
                        >
                          <div className={classes.preferenceRule}>
                            <img src={Warning} alt="preference" />
                            <Typography variant="body2">
                              Please provide{' '}
                              <span className={classes.bold}>
                                at least 3 hours
                              </span>{' '}
                              between the last two time preferences.
                            </Typography>
                          </div>
                          <PreferenceSelect
                            label="I cannot start my day before"
                            value={preferredBlock1Start}
                            onChange={(newValue) => {
                              setFieldValue('preferredBlock1Start', newValue);
                            }}
                            options={
                              preferredTimeBlockOptions.block1StartOptions
                            }
                            disabled={checkIsPreferenceSelectDisabled(
                              1,
                              availabilityBlocks
                            )}
                          />
                          <PreferenceSelect
                            label="My child is home from school by"
                            value={preferredBlock3Start}
                            onChange={(newValue) => {
                              setFieldValue('preferredBlock3Start', newValue);
                            }}
                            options={
                              preferredTimeBlockOptions.block3StartOptions
                            }
                            disabled={checkIsPreferenceSelectDisabled(
                              3,
                              availabilityBlocks
                            )}
                            error={!!errors.preferredBlock3End}
                          />
                          <PreferenceSelect
                            label="I cannot accommodate sessions past"
                            value={preferredBlock3End}
                            onChange={(newValue) => {
                              setFieldValue('preferredBlock3End', newValue);
                            }}
                            options={preferredTimeBlockOptions.block3EndOptions}
                            disabled={checkIsPreferenceSelectDisabled(
                              3,
                              availabilityBlocks
                            )}
                            error={!!errors.preferredBlock3End}
                          />
                        </div>
                      )}
                    </div>
                    <div className={classes.dynamicInfo}>
                      {!hideProgress && (
                        <div className={classes.availWeekdayWrapper}>
                          <div
                            className={clsx(
                              classes[availabilityHoursStatus],
                              classes.circleWrapper
                            )}
                          >
                            <GradientCircularProgress
                              emptyColor="#E0E0E0"
                              size={120}
                              strokeWidth={6}
                              progress={circleProgress}
                              startColor={circleColors.startColor}
                              middleColor={circleColors.middleColor}
                              endColor={circleColors.endColor}
                              withSnail
                            >
                              <Typography
                                variant="h5"
                                className={clsx(classes.bold, 'progressText')}
                              >
                                {availabilityHours.weekdayBlocks}
                              </Typography>
                            </GradientCircularProgress>
                          </div>
                          <Typography variant="h6">
                            Available Weekday Blocks
                          </Typography>
                          {availHoursInfo.card}
                        </div>
                      )}
                      <ActionsControlButton
                        disabled={{
                          successButton: loading || !isEmpty(errors),
                          cancelButton: loading,
                        }}
                        handleClick={{
                          successButton: () => setReviewChanges(true),
                          cancelButton: statusData.handleClose,
                        }}
                        label={{
                          successButton: 'Review and Save',
                          cancelButton: 'Cancel',
                        }}
                        styles={{ buttonRoot: classes.pageMaxWidth }}
                      />
                    </div>
                  </div>

                  {reviewChanges && (
                    <ChangesReviewDialog
                      handleClose={() => setReviewChanges(false)}
                      handleSave={submitForm}
                      schedule={schedule}
                      availabilityBlocks={availabilityBlocks}
                      availableHours={availabilityHours.weekdayBlocks}
                      startDate={startDate}
                    />
                  )}
                </Form>
              );
            }}
          </AvailableHoursWrapper>
        )}
      </Formik>

      {succeeded && (
        <SucceedDialog handleClose={handleSuccess}>
          Please allow the scheduling team 5 business days to process this
          change.
        </SucceedDialog>
      )}
    </>
  );
};

export default AvailabilityActionsClient;

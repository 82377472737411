import { useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import { useAuth } from './useAuth';
import usePreviousPathByRoutesAccess from './usePreviousPath';

const LAST_ACTIVITY_KEY = 'lastActivity';

const useActivityTracker = (timeout: number, providerRoutesAccess: boolean) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const shouldSkipTrackActivity = !timeout || !providerRoutesAccess;

  const { signOut } = useAuth();
  const { setPreviousPath } = usePreviousPathByRoutesAccess(
    providerRoutesAccess
  );

  useEffect(() => {
    if (shouldSkipTrackActivity) {
      return;
    }

    const handleReAuthentication = () => {
      if (providerRoutesAccess) {
        const currentPath = window.location.pathname;
        setPreviousPath(currentPath);
        signOut();
      }
    };

    const resetTimer = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(handleReAuthentication, timeout);
    };

    const updateLastActivity = () => {
      const now = Date.now();
      localStorage.setItem(LAST_ACTIVITY_KEY, now.toString());
      resetTimer();
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LAST_ACTIVITY_KEY) {
        resetTimer();
      }
    };

    const throttledHandleActivity = throttle(updateLastActivity, 1000);

    const throttledHandleStorageChange = throttle(handleStorageChange, 1000);

    window.addEventListener('mousemove', throttledHandleActivity);
    window.addEventListener('keydown', throttledHandleActivity);
    window.addEventListener('click', throttledHandleActivity);
    window.addEventListener('scroll', throttledHandleActivity);
    window.addEventListener('storage', throttledHandleStorageChange);

    updateLastActivity();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      window.removeEventListener('mousemove', throttledHandleActivity);
      window.removeEventListener('keydown', throttledHandleActivity);
      window.removeEventListener('click', throttledHandleActivity);
      window.removeEventListener('scroll', throttledHandleActivity);
      window.removeEventListener('storage', throttledHandleStorageChange);
    };
  }, [
    timeout,
    providerRoutesAccess,
    shouldSkipTrackActivity,
    signOut,
    setPreviousPath,
  ]);

  return null;
};

export default useActivityTracker;

import {
  createAsyncThunk,
  createSlice,
  Dispatch,
  isRejected,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import usersApi from '../../api/admin/usersApi';
import { AppThunk, AsyncState } from '../store';
import { NotificationPreference, Relationship, User } from '../../types/types';
import { RootState } from './rootReducer';

export const fetchCurrentUser = createAsyncThunk(
  'users/fetchCurrentUser',
  async () => {
    const response = await usersApi.fetchCurrentUser();
    return response.data;
  }
);

export const setUserClientRelationship = createAsyncThunk(
  'users/setUserClientRelationship',
  async (
    arg: { userId: number; clientId: number; relationshipId: number },
    { rejectWithValue }
  ) => {
    const { userId, clientId, relationshipId } = arg;
    return usersApi
      .updateUserRelationship(userId, clientId, relationshipId)
      .catch((e) => rejectWithValue(e));
  }
);

export const verifyUserClient = createAsyncThunk(
  'users/verifyUserClient',
  async (
    arg: { userId: number; clientId: number; dob: string },
    { rejectWithValue }
  ) => {
    const { userId, clientId, dob } = arg;
    return usersApi
      .verifyUserClient(userId, clientId, dob)
      .catch((e) => rejectWithValue(e));
  }
);

export const fetchUserNotificationPreferences = createAsyncThunk(
  'users/fetchUserNotificationPreferences',
  (userId: number) =>
    usersApi.getUserNotificationPreferences(userId).then((res) => res)
);

export const replaceUserNotificationPreferences = createAsyncThunk(
  'users/replaceUserNotificationPreferences',
  (
    arg: {
      userId: number;
      notificationPreferences: NotificationPreference[];
    },
    { rejectWithValue }
  ) =>
    usersApi
      .replaceUserNotificationPreferences(
        arg.userId,
        arg.notificationPreferences
      )
      .catch((e) => rejectWithValue(e))
);

export interface UsersState {
  currentUser: User | null;
  currentUserLoading: boolean;
  currentUserUpdating: boolean;
  currentUserRequestId: string | undefined;
  relationships: Relationship[];
  relationshipsLoading: boolean;
  verificationState: AsyncState | null;
  relationshipUpdatedState: AsyncState | null;
  notificationPreferences: NotificationPreference[];
  notificationPreferencesLoading: boolean;
  notificationPreferencesError: SerializedError | null;
  saveNotificationPreferencesLoading: boolean;
}

const initialState: UsersState = {
  currentUser: null,
  currentUserLoading: false,
  currentUserUpdating: false,
  currentUserRequestId: undefined,
  relationships: [],
  relationshipsLoading: false,
  verificationState: null,
  relationshipUpdatedState: null,
  notificationPreferences: [],
  notificationPreferencesLoading: false,
  notificationPreferencesError: null,
  saveNotificationPreferencesLoading: false,
};

export const updateCurrentUser = createAsyncThunk<
  Promise<User> | null,
  {
    firstName: string;
    lastName: string;
    mobileNumber: string;
  },
  { state: RootState }
>(
  'users/updateCurrentUser',
  (
    arg: {
      firstName: string;
      lastName: string;
      mobileNumber: string;
    },
    { getState, rejectWithValue }
  ) => {
    const { currentUser } = getState().users;
    const { firstName, lastName, mobileNumber } = arg;

    if (!currentUser) {
      return null;
    }

    return usersApi
      .updateUser(currentUser.id, firstName, lastName, mobileNumber)
      .catch((e) => rejectWithValue(e));
  }
);

export const fetchAndUpdateCurrentUser = createAsyncThunk<
  Promise<User> | null,
  {
    firstName: string;
    lastName: string;
    mobileNumber: string;
  },
  { state: RootState }
>('users/fetchAndUpdateCurrentUser', async (arg, { rejectWithValue }) => {
  try {
    const currentUser = await usersApi
      .fetchCurrentUser()
      .then((res) => res.data);
    const { firstName, lastName, mobileNumber } = arg;

    return await usersApi.updateUser(
      currentUser.id,
      firstName,
      lastName,
      mobileNumber
    );
  } catch (e) {
    return rejectWithValue(e);
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User | null>) => ({
      ...state,
      currentUser: action.payload,
    }),
    setCurrentUserUpdating: (state, action: PayloadAction<boolean>) => ({
      ...state,
      currentUserUpdating: action.payload,
    }),
    setRelationships: (state, action: PayloadAction<Relationship[]>) => ({
      ...state,
      relationships: action.payload,
    }),
    setRelationshipsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      relationshipsLoading: action.payload,
    }),
    setVerificationFailed: (state, action: PayloadAction<boolean>) => ({
      ...state,
      verificationFailed: action.payload,
    }),
    resetVerificationState: (state) => ({
      ...state,
      verificationState: null,
    }),
    resetRelationshipUpdatedState: (state) => ({
      ...state,
      relationshipUpdatedState: null,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state, action) => {
        if (!state.currentUserLoading) {
          return {
            ...state,
            currentUserLoading: true,
            currentUserRequestId: action.meta.requestId,
          };
        }
        return state;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.currentUserLoading &&
          state.currentUserRequestId === requestId
        ) {
          return {
            ...state,
            currentUserLoading: false,
            currentUserRequestId: undefined,
            currentUser: action.payload,
          };
        }
        return state;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.currentUserLoading &&
          state.currentUserRequestId === requestId
        ) {
          return {
            ...state,
            currentUserLoading: false,
            currentUserRequestId: undefined,
            currentUser: null,
          };
        }
        return state;
      })
      .addCase(verifyUserClient.pending, (state) => {
        return {
          ...state,
          verificationState: 'pending',
        };
      })
      .addCase(verifyUserClient.fulfilled, (state) => {
        if (state.verificationState === 'pending') {
          return {
            ...state,
            verificationState: 'success',
          };
        }
        return state;
      })
      .addCase(verifyUserClient.rejected, (state) => {
        if (state.verificationState === 'pending') {
          return {
            ...state,
            verificationState: 'failed',
          };
        }
        return state;
      })
      .addCase(setUserClientRelationship.pending, (state) => {
        return {
          ...state,
          relationshipUpdatedState: 'pending',
        };
      })
      .addCase(setUserClientRelationship.fulfilled, (state) => {
        if (state.relationshipUpdatedState === 'pending') {
          return {
            ...state,
            relationshipUpdatedState: 'success',
          };
        }
        return state;
      })
      .addCase(setUserClientRelationship.rejected, (state) => {
        if (state.relationshipUpdatedState === 'pending') {
          return {
            ...state,
            relationshipUpdatedState: 'failed',
          };
        }
        return state;
      })
      .addCase(fetchUserNotificationPreferences.pending, (state) => {
        return {
          ...state,
          notificationPreferencesLoading: true,
        };
      })
      .addCase(fetchUserNotificationPreferences.rejected, (state, action) => {
        return {
          ...state,
          notificationPreferencesLoading: false,
          notificationPreferencesError: action.error,
        };
      })
      .addCase(fetchUserNotificationPreferences.fulfilled, (state, action) => {
        return {
          ...state,
          notificationPreferencesLoading: false,
          notificationPreferences: action.payload,
        };
      })
      .addCase(
        replaceUserNotificationPreferences.fulfilled,
        (state, action) => {
          return {
            ...state,
            saveNotificationPreferencesLoading: false,
          };
        }
      )
      .addCase(replaceUserNotificationPreferences.pending, (state) => {
        return {
          ...state,
          saveNotificationPreferencesLoading: true,
        };
      })
      .addCase(replaceUserNotificationPreferences.rejected, (state, action) => {
        return {
          ...state,
          saveNotificationPreferencesLoading: false,
          notificationPreferencesError: action.error,
        };
      })
      .addCase(updateCurrentUser.pending, (state, action) => {
        return {
          ...state,
          currentUserUpdating: true,
        };
      })
      .addCase(updateCurrentUser.rejected, (state, action) => {
        return {
          ...state,
          currentUserUpdating: false,
        };
      })
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        return {
          ...state,
          currentUserUpdating: false,
        };
      });
  },
});

export const {
  setCurrentUser,
  setCurrentUserUpdating,
  setRelationships,
  setRelationshipsLoading,
  resetVerificationState,
  resetRelationshipUpdatedState,
} = usersSlice.actions;

export default usersSlice.reducer;

export const fetchRelationships = () => async (dispatch: Dispatch) => {
  dispatch(setRelationshipsLoading(true));
  let response;
  try {
    response = await usersApi.fetchRelationships();
  } catch (e: any) {
    console.log(e);
  }

  if (response && response.data) {
    dispatch(setRelationships(response.data));
  }

  dispatch(setRelationshipsLoading(false));
};

export const handleClientVerification = (
  clientId: number,
  dob: string,
  relationshipId: number
): AppThunk => async (dispatch, getState) => {
  const { currentUser } = getState().users;
  if (currentUser) {
    const userId = currentUser.id;

    const verifyAction = await dispatch(
      verifyUserClient({ userId, clientId, dob })
    );

    if (!isRejected(verifyAction)) {
      await dispatch(
        setUserClientRelationship({ userId, clientId, relationshipId })
      );
    }
  }
};

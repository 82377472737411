import React from 'react';
import { Typography, makeStyles, Theme, Link } from '@material-ui/core';
import { SCH_PHONE_NUMBER } from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  noSessionsText: {
    marginTop: theme.spacing(2.75),
    marginBottom: theme.spacing(2),
  },
}));

const IsPausedHint = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.noSessionsText}>
        You have been temporarily paused from using this feature.
      </Typography>
      <Typography>Please contact scheduling for further assistance.</Typography>
      <br />
      <Typography>
        Call: <Link href={`tel:${SCH_PHONE_NUMBER}`}>{SCH_PHONE_NUMBER}.</Link>
      </Typography>
    </>
  );
};

export default IsPausedHint;

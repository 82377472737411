import {
  Button,
  Divider,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { keyBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';

import AvailabilityHeader from '../../components/Availability/AvailabilityHeader';
import CustomLoading from '../../components/CustomLoading/CustomLoading';
import AvailabilityMatrix from '../../components/Matrix/AvailabilityMatrix';
import MatrixInfo from '../../components/Matrix/MatrixInfo';
import { useStyles as useAvailabilityStyles } from '../../providerPortal/myAvailability/StandardAvailability';
import UpcomingAvailability from '../../components/Availability/UpcomingAvailability';
import {
  Availability,
  AVAILABILITY_ACTION_STATUS,
  AvailabilityLongTermActions,
  AvailabilityShortTermActions,
  fetchAvailability,
  setActionStatus,
} from '../../redux/modules/availability';
import { RootState } from '../../redux/modules/rootReducer';
import { useAppDispatch } from '../../redux/store';
import { AvailabilityStatus } from '../../shared/types';
import { ClientSchedule } from '../../types/types';
import { TIME_FORMAT, TIME_FORMAT_24HR } from '../../utils/helpers';
import PreferencesPopup from '../myProfile/PreferencesPopup';
import UpcomingModifiedAvailability from './UpcomingModifiedAvailability';
import AvailabilityActionsClient from './AvailabilityActionsClient/AvailabilityActionsClient';
import EmptyAvailabilityInfo from '../../components/Availability/EmptyAvailabilityInfo';
import TemporaryAvailabilityActions from '../../components/TemporaryAvailabilityActions/TemporaryAvailabilityActions';
import StyledButtonLink from '../../components/buttons/StyledButtonLink';
import VerticalLabelTimeBlock from '../../shared/matrix/ui/verticalLabelTimeBlock';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  matrixInfoRoot: {
    marginTop: theme.spacing(3),
  },
  myAvailability: {
    padding: theme.spacing(0, 4, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2, 2),
    },
  },
  pageTitle: {
    [theme.breakpoints.down('xs')]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
  },
  title: {
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('xs')]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
  },
  changeAvailabilityText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),

    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px 0`,
    },
  },
  link: {
    fontWeight: 400,
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(0.25),
    lineHeight: theme.spacing(0.125),
    minWidth: 'auto',
  },
  standardAvailability: { flex: 1 },
  preference: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.body2,
    },
    fontSize: theme.spacing(2),
  },
  upcomingPreferences: {
    padding: theme.spacing(2),
  },
  contentWrapper: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  standardAvailabilityPreferences: {
    marginLeft: theme.spacing(1.5),
  },
  rightContainer: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(60),
    },
  },
  emptyAvailability: {
    margin: `${theme.spacing(4)}px auto 0`,
  },
  preferenceValue: {
    borderBottom: '1px dashed',
    fontWeight: 700,
  },
}));

const UserAvailability = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const currentClient = useSelector((state: RootState) => {
    return state.client.currentClient;
  });

  const currentClientLoading = useSelector((state: RootState) => {
    return state.client.currentClientLoading;
  });

  const ltAvailability = useSelector((state: RootState) => {
    return state.availability.ltAvailability;
  });

  const stAvailability = useSelector(
    (state: RootState) => state.availability.stAvailability
  );

  const loadingClientAvailability = useSelector((state: RootState) => {
    return state.availability.loading;
  });

  const AvailabilityActionStatus = useSelector((state: RootState) => {
    return state.availability.action.status;
  });

  const isActiveClient = useSelector(
    (state: RootState) => state.client.currentClient?.isActive
  );

  const availabilityIdentifier = useSelector((state: RootState) => {
    return state.availability.availabilityIdentifier;
  });

  const fetchClientAvailability = useCallback(() => {
    if (!currentClient?.id) {
      return;
    }

    dispatch(
      fetchAvailability({
        clientId: currentClient.id,
        excludeStatuses: [
          AvailabilityStatus.Expired,
          AvailabilityStatus.Discarded,
        ],
      })
    );
  }, [currentClient, dispatch]);

  useEffect(() => {
    fetchClientAvailability();
  }, [currentClient, availabilityIdentifier, fetchClientAvailability]);

  const currentAvailability = ltAvailability.find(
    (avail) => avail.status === AvailabilityStatus.Current
  );

  const upcomingAvailability = ltAvailability.find(
    (avail) => avail.status === AvailabilityStatus.Upcoming
  );

  const clientSchedule = useSelector((state: RootState) => {
    return state.client.currentClient?.schedule;
  });

  const handleAdd = () => {
    dispatch(
      setActionStatus({
        status: AVAILABILITY_ACTION_STATUS.ADD_TEMPORARY,
        availability: currentAvailability,
      })
    );
  };

  if (currentClientLoading || loadingClientAvailability) {
    return <CustomLoading />;
  }

  if (AvailabilityLongTermActions.includes(AvailabilityActionStatus)) {
    return (
      <div className={classes.myAvailability}>
        <AvailabilityActionsClient
          schedule={clientSchedule}
          weeklyAuthSize={currentClient?.weeklyAuthSize}
          region={currentClient?.region}
          ClientDischargeDate={currentClient?.ClientDischargeDate}
          DTDischargeDate={currentClient?.DTDischargeDate}
          handleSuccess={fetchClientAvailability}
        />
      </div>
    );
  }

  if (AvailabilityShortTermActions.includes(AvailabilityActionStatus)) {
    return (
      <div className={classes.myAvailability}>
        <TemporaryAvailabilityActions
          schedule={clientSchedule}
          handleSuccess={fetchClientAvailability}
          type="user"
        />
      </div>
    );
  }

  return (
    currentClient && (
      <div className={classes.myAvailability}>
        <Typography
          variant="h6"
          className={classes.pageTitle}
          color="textSecondary"
        >
          My Availability
        </Typography>
        {!isMobile && <Divider className={classes.divider} />}

        <div className={classes.contentWrapper}>
          <div className={classes.standardAvailability}>
            {currentAvailability && (
              <>
                <StandardAvailability
                  availability={currentAvailability}
                  showBooked={isActiveClient}
                  unavailable={!isActiveClient}
                  schedule={clientSchedule}
                />
                {isActiveClient && (
                  <div className={classes.standardAvailabilityPreferences}>
                    <Preference
                      preferences={currentAvailability}
                      styles={{
                        preference: classes.preference,
                        preferenceValue: classes.preferenceValue,
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {isActiveClient && upcomingAvailability ? (
              <UpcomingAvailability
                availability={upcomingAvailability}
                isEditable={false}
                schedule={clientSchedule}
                preferredTimes={
                  <div className={classes.upcomingPreferences}>
                    <Divider />
                    <Preference
                      preferences={upcomingAvailability}
                      styles={{
                        preference: classes.preference,
                        preferenceValue: classes.preferenceValue,
                      }}
                    />
                  </div>
                }
              />
            ) : (
              <EmptyAvailabilityInfo
                handleButtonClick={() => {
                  dispatch(
                    setActionStatus({
                      status: AVAILABILITY_ACTION_STATUS.ADD,
                      availability: currentAvailability,
                    })
                  );
                }}
                imageSrc={require('../../assets/availabilityPermanentChange.svg')}
                content="To update your standard availability"
                buttonLabel="Permanent Change"
                buttonProps={{ startIcon: <AddIcon /> }}
                styles={{ root: classes.emptyAvailability }}
              />
            )}
          </div>
          <div className={classes.rightContainer}>
            <Typography
              variant="h6"
              className={classes.title}
              color="textSecondary"
            >
              Upcoming Temporary Changes
            </Typography>
            {stAvailability && !!stAvailability.length && (
              <StyledButtonLink className={classes.title} onClick={handleAdd}>
                Add Temporary Change
              </StyledButtonLink>
            )}
            {isActiveClient && !!stAvailability.length && (
              <UpcomingModifiedAvailability stAvailability={stAvailability} />
            )}
            {isActiveClient && !stAvailability.length && (
              <EmptyAvailabilityInfo
                handleButtonClick={handleAdd}
                imageSrc={require('../../assets/availabilityTemporaryChange.svg')}
                content="For vacations, holidays or short school breaks when your schedule changes"
                buttonLabel="Temporary Change"
                buttonProps={{ startIcon: <AddIcon /> }}
                styles={{ root: classes.emptyAvailability }}
              />
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default UserAvailability;

const StandardAvailability = (props: {
  availability: Availability;
  schedule?: ClientSchedule[];
  unavailable: boolean;
  showBooked?: boolean;
}) => {
  const classes = useAvailabilityStyles();

  const { availability, schedule, unavailable, showBooked } = props;

  const { availabilityBlocks } = availability;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const timeBlocks = useSelector(
    (state: RootState) => state.adminSettings.timeBlocks
  );

  const [isPreferencesPopupOpen, setIsPreferencesPopupOpen] = useState(false);

  const onPopupOpen = () => {
    setIsPreferencesPopupOpen(true);
  };

  return (
    <>
      <AvailabilityHeader
        title="Standard Availability"
        classes={{
          root: classes.headerRoot,
          title: classes.headerTitle,
          subTitle: classes.headerSubTitle,
        }}
      />
      <Typography color="textSecondary" variant="body1">
        This is the current availability we are using to plan your recurring
        therapy schedule.{' '}
        <Button className={classes.learnMoreLink} onClick={onPopupOpen}>
          Learn More
        </Button>
      </Typography>
      <AvailabilityMatrix
        cellSizes={isMobile ? 3.5 : 4.5}
        availabilityBlocks={availabilityBlocks}
        schedule={schedule}
        disableWeekends
        styles={{
          root: classes.availabilityMatrix,
        }}
        unavailable={unavailable}
        showBooked={showBooked}
        VerticalLabel={({ value }) => (
          <VerticalLabelTimeBlock
            value={value}
            timeBlock={keyBy(timeBlocks, 'id')[value]}
            styles={{
              content: classes.matrixVerticalLabelContent,
            }}
          />
        )}
      />
      <MatrixInfo rootClassName={classes.matrixInfo} />
      {schedule && (
        <PreferencesPopup
          isOpen={isPreferencesPopupOpen}
          handleClose={() => setIsPreferencesPopupOpen(false)}
          schedule={schedule}
        />
      )}
    </>
  );
};

interface PreferenceProps {
  styles?: Partial<Record<'root' | 'preference' | 'preferenceValue', string>>;
  preferences: Pick<
    Availability,
    'preferredBlock1Start' | 'preferredBlock3Start' | 'preferredBlock3End'
  >;
}

export const Preference = ({ styles, preferences }: PreferenceProps) => {
  const formatPreferenceTime = (preference: string) => {
    return moment(preference, TIME_FORMAT_24HR).format(TIME_FORMAT);
  };

  const timeJSX = (time?: string) => {
    return (
      <span className={styles?.preferenceValue}>
        {time ? formatPreferenceTime(time) : 'N/A'}
      </span>
    );
  };

  return (
    <div className={styles?.root}>
      <Typography className={styles?.preference}>
        I cannot start my day before:{' '}
        {timeJSX(preferences.preferredBlock1Start)}
      </Typography>
      <Typography className={styles?.preference}>
        My child is home from school by:{' '}
        {timeJSX(preferences.preferredBlock3Start)}
      </Typography>
      <Typography className={styles?.preference}>
        I cannot accommodate sessions past:{' '}
        {timeJSX(preferences.preferredBlock3End)}
      </Typography>
    </div>
  );
};

import { Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ReactComponent as FilesIcon } from '../../assets/files.svg';
import StyledButton from '../../components/buttons/StyledButton';
import StyledButtonLink from '../../components/buttons/StyledButtonLink';
import { RootState } from '../../redux/modules/rootReducer';
import SessionCard from '../../components/SessionCard/SessionCard';
import ClientSessionDetails from '../../components/SessionCard/ClientSessionDetails';
import { SelectedBookingOption } from '../../redux/modules/newSessions';
import { getUtcDateFromSTBRSession } from '../../utils/helpers';
import { ProviderWithBookDetails, SessionStatus } from '../../types/types';
import AnalyticsTracker, {
  TrackerNames,
} from '../../analytics/AnalyticsTracker';

const useStyles = makeStyles((theme: Theme) => ({
  container: { marginTop: theme.spacing(3) },
  topContainer: { display: 'flex' },
  sessionItems: { marginLeft: theme.spacing(1) },
  divider: {
    maxWidth: 318,
  },
  buttonContainer: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  subTitle: { fontWeight: 700, marginTop: theme.spacing(2) },
  sessionsContainer: { display: 'flex', flexWrap: 'wrap' },
  sessionCardContainer: { marginRight: 20 },
  sessionCardDivider: { height: 1, width: 327, marginLeft: 22 },
  link: { textDecoration: 'none' },
}));

const SessionsSubmitted = () => {
  const classes = useStyles();
  const [isShowDetails, setShowDetails] = useState(false);

  const selectedBookingOptions = useSelector(
    (state: RootState) => state.newSessions.selectedBookingOptions
  );

  const selectedProvider = useSelector(
    (state: RootState) => state.newSessions.selectedProvider
  );

  const currentClient = useSelector(
    (state: RootState) => state.client.currentClient?.clientName
  );

  return (
    <>
      <div className={classes.container}>
        <AnalyticsTracker name={TrackerNames.AddSessionsResultScreen} />
        <div className={classes.topContainer}>
          <FilesIcon />
          <div className={classes.sessionItems}>
            <Typography>{`${selectedBookingOptions.length} sessions submitted`}</Typography>
            <StyledButtonLink onClick={() => setShowDetails(!isShowDetails)}>
              {isShowDetails ? 'Hide Details' : 'View Details'}
            </StyledButtonLink>
          </div>
        </div>

        {!isShowDetails && <Divider className={classes.divider} />}
      </div>

      {isShowDetails && (
        <div className={classes.sessionsContainer}>
          {selectedBookingOptions.map((rebookOption, index) => {
            return (
              <PendingSession
                key={`${rebookOption.scheduledDate}_${rebookOption.timeBlockId}`}
                session={rebookOption}
                selectedProvider={selectedProvider}
                clientName={currentClient}
              />
            );
          })}
        </div>
      )}

      <Typography variant="body2" className={classes.subTitle}>
        Thank you for your request!
      </Typography>
      <Typography variant="body2">
        We are checking with your provider to confirm which times work for them.
        We will be in touch as soon as we can confirm the sessions.
      </Typography>
      <div className={classes.buttonContainer}>
        <Link to="/schedule" className={classes.link}>
          <StyledButton
            customColor="kyoLightGreen"
            endIcon={<ArrowRightAltIcon />}
          >
            Go to schedule
          </StyledButton>
        </Link>
      </div>
    </>
  );
};

export default SessionsSubmitted;

interface PendingSessionProps {
  session: SelectedBookingOption;
  selectedProvider: ProviderWithBookDetails | null;
  clientName?: string;
}

const PendingSession = (props: PendingSessionProps) => {
  const classes = useStyles();
  const { session, selectedProvider, clientName } = props;

  return (
    <div className={classes.sessionCardContainer}>
      <SessionCard
        key={session.scheduledDate}
        session={{
          segmentStartUtc: getUtcDateFromSTBRSession(session),
          status: SessionStatus.Pending,
        }}
        sessionWith={selectedProvider?.firstName || ''}
        showCancelLink={false}
        userType="Client"
      >
        <ClientSessionDetails
          client={{ clientName: clientName || '' }}
          session={{
            segmentStartUtc: getUtcDateFromSTBRSession(session),
            segmentEndUtc: `${moment(getUtcDateFromSTBRSession(session)).add(
              session?.sessionPlacement.duration,
              'hours'
            )}`,
          }}
          provider={{
            id: selectedProvider?.id || 0,
            lastName: selectedProvider?.lastName || '',
            firstName: selectedProvider?.firstName || '',
            profilePhoto: selectedProvider?.profilePhoto || '',
            covidVaccinatedDate: selectedProvider?.covidVaccinatedDate || '',
            title: selectedProvider?.title || '',
          }}
          hideButtons
        />
      </SessionCard>
      <Divider className={classes.sessionCardDivider} />
    </div>
  );
};

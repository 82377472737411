import React from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import ConfirmationDialogTemplate from '../../components/ConfirmationDialogTemplate';
import ProviderInfo from './ProviderInfo';
import { Provider } from '../../types/types';
import { RootState } from '../../redux/modules/rootReducer';
import { ProviderChatUserType } from '../types';

interface ChatMembersModalProps {
  isOpen: boolean;
  members?: ProviderChatUserType[];
  onClose: () => void;
}

const useStyle = makeStyles((theme) => ({
  modalBodyWrapper: {
    width: 518,
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
  },
}));

const enhanceProvider = (
  team: Provider[] | null,
  provider: ProviderChatUserType
) => {
  if (!team) {
    return provider;
  }

  const fullProviderInfo = find(team, { id: provider.id });

  if (!fullProviderInfo) {
    return provider;
  }

  return {
    ...provider,
    attributes: fullProviderInfo.attributes,
    mobile: fullProviderInfo.mobile,
  };
};

const ChatMembersModal: React.FC<ChatMembersModalProps> = (
  props: ChatMembersModalProps
) => {
  const caseTeam = useSelector((state: RootState) => state.client.caseTeam);
  const { isOpen, members, onClose } = props;
  const classes = useStyle();

  if (!members || !members.length) {
    return null;
  }

  return (
    <ConfirmationDialogTemplate
      open={isOpen}
      handleClose={onClose}
      title={`Channel participants (${members.length})`}
      actions=""
    >
      <Box className={classes.modalBodyWrapper}>
        {members.map((provider, index) => {
          const isLast = members.length === index + 1;
          return (
            <Box key={provider.chatUserId}>
              <ProviderInfo provider={enhanceProvider(caseTeam, provider)} />
              {!isLast && <Divider />}
            </Box>
          );
        })}
      </Box>
    </ConfirmationDialogTemplate>
  );
};

export default ChatMembersModal;

import React from 'react';

interface LinkCompProps {
  Comp: React.ElementType;
  className: string;
  attr: {
    to?: string;
    href?: string;
  };
  children: string;
}

const LinkComp = ({ Comp, className, attr, children }: LinkCompProps) => {
  return (
    <Comp className={className} {...attr}>
      {children}
    </Comp>
  );
};

export default LinkComp;

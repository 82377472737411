import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Rating } from '@material-ui/lab';
import clsx from 'clsx';
import moment from 'moment';

import { ReactComponent as Logo } from '../../../assets/favicon.svg';
import { ReactComponent as RatingEmptyStar } from '../../../assets/ratingEmptyStar.svg';
import { ReactComponent as RatedStar } from '../../../assets/ratedStar.svg';
import FeedbackInfo from './feedback/FeedbackInfo';
import FeedbackTagsSelector from './feedback/FeedbackTagsSelector';
import { RootState } from '../../../redux/modules/rootReducer';
import { UnratedSession } from '../../../types/types';
import { useAppDispatch } from '../../../redux/store';
import { createRating, resetState } from '../../../redux/modules/ratings';
import ConfirmationDialogTemplate, {
  ConfirmationDialogActionsTemplate,
} from '../../../components/ConfirmationDialogTemplate';

const closeButtonSizes = (theme: Theme) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
});

const useStyles = makeStyles((theme: Theme) => ({
  alignCenter: {
    textAlign: 'center',
  },
  noTextTransform: {
    textTransform: 'none',
  },

  dialogPaper: {
    maxWidth: theme.spacing(73.25),
    borderRadius: theme.spacing(1.5),

    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2.25),
    },
  },
  closeIconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 3,
    margin: theme.spacing(3),
    ...closeButtonSizes(theme),
  },
  closeIcon: {
    ...closeButtonSizes(theme),
    color: 'black',
  },
  logoWrapper: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(3),
    top: 0,
    left: 0,
    zIndex: -1,
  },
  logo: {
    width: theme.spacing(44),
    margin: 'auto',
  },
  content: {
    zIndex: 2,
    padding: theme.spacing(0, 4, 4),
    color: theme.palette.kyoGray63.dark,
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2, 2.5),
    },
  },

  providerPhoto: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginTop: theme.spacing(10.5),
    margin: 'auto',
  },
  sessionInfoText: {
    fontSize: theme.spacing(3),
    marginTop: theme.spacing(2),
    maxWidth: theme.spacing(44),
    margin: 'auto',

    '&:nth-child(2)': {
      marginTop: theme.spacing(17),
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(2.5),
      maxWidth: theme.spacing(36.875),
    },
  },

  feedbackLabel: {
    fontSize: theme.spacing(2.25),
    fontWeight: 600,
    margin: theme.spacing(3.75, 0, 3.25),
  },

  actions: {
    marginTop: theme.spacing(4.5),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  notNowButton: {
    color: '#A7A7A7',
  },
  submitButton: {
    backgroundColor: theme.palette.kyoLightGreen.main,
    padding: theme.spacing(1, 5.8),
    borderRadius: theme.spacing(1),
    color: 'white',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: theme.palette.kyoLightGreen.main,
    },
  },

  ratingWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  ratingRoot: {
    height: theme.spacing(5.625),

    '& label:last-of-type span:first-child': {
      marginRight: 0,
    },
  },
  ratingIcon: {
    marginRight: theme.spacing(2),
  },
}));

interface RatingProvidersPopupProps {
  handleClose: (args: { closingWithoutSubmit?: boolean }) => void;
  session: UnratedSession;
}

const RatingProvidersPopup = ({
  handleClose: originalHandleClose,
  session,
}: RatingProvidersPopupProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [rating, setRating] = useState(0);
  const [selectedFeedbackTags, setSelectedFeedbackTags] = useState<number[]>(
    []
  );

  const {
    segmentStartUtc,
    firstName,
    lastName,
    profilePhoto,
    providerId,
    clientId,
    segmentId,
  } = session;

  const feedbackTags = useSelector(
    (state: RootState) => state.feedbackTags.feedbackTags
  );

  const success = useSelector((state: RootState) => state.ratings.success);
  const creating = useSelector((state: RootState) => state.ratings.creating);

  const sessionDate = moment(segmentStartUtc);
  const daysPassed = moment().day() - sessionDate.day();

  const sessionDateInfo = () => {
    switch (daysPassed) {
      case 0: {
        return 'today';
      }
      case 1: {
        return 'yesterday';
      }
      default: {
        return sessionDate.format('[on] dddd');
      }
    }
  };

  const handleSubmit = () => {
    setOpenConfirmDialog(false);
    dispatch(
      createRating({
        clientId,
        providerId,
        segmentId,
        value: rating,
        feedbackTagIds: selectedFeedbackTags,
      })
    );
  };

  const handleClose = () => {
    if (!creating) {
      originalHandleClose({ closingWithoutSubmit: true });
    }
  };

  useEffect(() => {
    if (success) {
      originalHandleClose({ closingWithoutSubmit: false });
      dispatch(resetState());
    }
  }, [dispatch, success, originalHandleClose]);

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  return (
    <>
      <Dialog
        open
        onClose={() => handleClose()}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <IconButton
          className={classes.closeIconButton}
          onClick={() => handleClose()}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <DialogContent className={classes.content}>
          <div className={classes.logoWrapper}>
            <Logo className={classes.logo} />
          </div>

          {profilePhoto && (
            <Avatar
              alt="Provider_Photo"
              src={profilePhoto}
              className={clsx(classes.providerPhoto)}
            />
          )}

          <Typography
            className={clsx(classes.sessionInfoText, classes.alignCenter)}
          >
            How was the session {sessionDateInfo()} with{' '}
            <b>
              {firstName} {lastName}?
            </b>
          </Typography>

          <div className={classes.ratingWrapper}>
            <Rating
              emptyIcon={<RatingEmptyStar />}
              icon={<RatedStar />}
              value={rating}
              name="rating"
              onChange={(_, value) => {
                if (value) {
                  setRating(value);
                }
              }}
              classes={{
                root: classes.ratingRoot,
                icon: classes.ratingIcon,
              }}
            />
          </div>

          <Typography
            className={clsx(classes.feedbackLabel, classes.alignCenter)}
          >
            Give additional feedback (optional)
          </Typography>

          <FeedbackTagsSelector
            selectedTags={selectedFeedbackTags}
            setSelectedTags={setSelectedFeedbackTags}
            tags={feedbackTags}
          />

          <FeedbackInfo />

          <div className={classes.actions}>
            <Button
              className={clsx(classes.notNowButton, classes.noTextTransform)}
              onClick={() => handleClose()}
              disabled={creating}
            >
              Not Now
            </Button>
            <Button
              variant="contained"
              className={clsx(classes.submitButton, classes.noTextTransform)}
              onClick={() => setOpenConfirmDialog(true)}
              disabled={!rating || creating}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmationDialogTemplate
        open={openConfirmDialog}
        title="Are you sure?"
        handleClose={() => setOpenConfirmDialog(false)}
        actions={
          <ConfirmationDialogActionsTemplate
            confirm={handleSubmit}
            cancel={() => {
              setOpenConfirmDialog(false);
            }}
            cancelLabel="No"
            confirmLabel="Yes"
          />
        }
      />
    </>
  );
};

export default RatingProvidersPopup;

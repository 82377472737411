import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import InfoCard from '../InfoCard/InfoCard';
import Warning from '../../assets/warning.svg';

const useStyles = makeStyles((theme: Theme) => ({
  notices: {
    backgroundColor: theme.palette.kyoPastelRed.main,
    width: '100%',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 375,
      marginTop: theme.spacing(1),
    },
  },
  noteText: {
    paddingRight: 24,
  },
  noticeChildren: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

type NoticeProps = {
  notice: string;
  children: React.ReactNode;
};

const Notice = ({ notice, children }: NoticeProps) => {
  const classes = useStyles();

  return (
    <InfoCard
      icon={<img src={Warning} height={24} width={24} alt="Warning icon" />}
      className={classes.notices}
    >
      <Box className={classes.noticeChildren}>
        <Typography variant="body1" className={classes.noteText}>
          {notice}
        </Typography>
        {children}
      </Box>
    </InfoCard>
  );
};

export default Notice;

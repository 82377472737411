import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import BTAvailabilityInfoDialog from './BTAvailabilityInfoDialog';

const useStyles = makeStyles((theme: Theme) => ({
  hintTextContainer: {
    marginTop: theme.spacing(2),
  },
}));

interface ClientCanAddSessionProps {
  description: string;
  assignmentApprovalRequired?: boolean;
  includeEnoughOptionsHint?: boolean;
}

const ClientCanAddSession = ({
  description,
  assignmentApprovalRequired,
  includeEnoughOptionsHint = true,
}: ClientCanAddSessionProps) => {
  const classes = useStyles();

  return (
    <>
      <Typography>
        We can help you book additional sessions or rebook your cancelled
        sessions.
      </Typography>
      {assignmentApprovalRequired && (
        <Typography className={classes.hintTextContainer}>
          <b>You may book only with providers in your current Care Team</b>. For
          more information please contact your BCBA.
        </Typography>
      )}
      <Typography className={classes.hintTextContainer}>
        {description}
      </Typography>
      {includeEnoughOptionsHint && (
        <>
          <Typography className={classes.hintTextContainer}>
            <b>Not seeing enough options?</b> Turn on{' '}
            <b>Show Times Outside My Availability</b> to find therapists and
            times outside of your regular availability.
          </Typography>
          <BTAvailabilityInfoDialog />
        </>
      )}
    </>
  );
};

export default ClientCanAddSession;

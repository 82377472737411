import { Loader } from '@googlemaps/js-api-loader';
import configs from '../configs';

export default class GoogleApiWrapper {
  private static instance: GoogleApiWrapper;

  private static loader = new Loader({
    apiKey: configs.googleApiKey,
  });

  private distanceMatrixService: google.maps.DistanceMatrixService | null = null;

  public static getInstance(): GoogleApiWrapper {
    if (!GoogleApiWrapper.instance) {
      GoogleApiWrapper.instance = new GoogleApiWrapper();
    }
    return GoogleApiWrapper.instance;
  }

  async getDistanceMatrix(params: {
    origins: google.maps.LatLngLiteral[];
    destinations: google.maps.LatLngLiteral[];
  }): Promise<google.maps.DistanceMatrixResponse> {
    if (this.distanceMatrixService === null) {
      const google = await GoogleApiWrapper.loader.load();
      this.distanceMatrixService = new google.maps.DistanceMatrixService();
    }
    return this.distanceMatrixService.getDistanceMatrix({
      ...params,
      travelMode: google.maps.TravelMode.DRIVING,
      avoidFerries: true,
      avoidTolls: true,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
    });
  }
}

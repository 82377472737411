import React from 'react';
import { Typography, makeStyles, useTheme } from '@material-ui/core';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import moment from 'moment';
import clsx from 'clsx';

import {
  MainRequestTypes,
  RequestStatus,
  RequestType,
} from '../../types/types';
import {
  DATE_FORMAT,
  DATE_FORMAT_WEEKDAY_MONTH_YEAR,
  TIME_FORMAT,
  humanizeDuration,
} from '../../utils/helpers';
import LongTermTypeSrc from '../../assets/bookingDetails/longTermType.svg';
import OpenSessionTypeSrc from '../../assets/defaultOpenSessionCalendarIcon.svg';
import ShortTermTypeSrc from '../../assets/bookingDetails/shortTermType.svg';
import TimeSrc from '../../assets/bookingDetails/time.svg';
import DateSrc from '../../assets/bookingDetails/date.svg';
import BookedWithSrc from '../../assets/bookingDetails/bookedWith.svg';
import { BookedOption } from './NewSessions';

interface BookedOptionRow {
  icon: React.ReactNode;
  title: string;
  content: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  statusIcon: {
    borderRadius: '50%',
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
  },
  rowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    minHeight: theme.spacing(7.5),
    padding: theme.spacing(0, 0.5),
    borderBottom: `1px solid ${theme.palette.kyoGray.main}`,
  },
  rowTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.4),
    color: theme.palette.kyoGray63.main,
    ...theme.typography.body2,
  },
  content: {
    flex: 1,
    textAlign: 'right',
    color: theme.palette.kyoGray.dark,
    ...theme.typography.subtitle1,
  },
  timelapseIcon: {
    fontSize: '17.5px',
    transform: 'translateX(-2px)',
  },
}));

const BookedOptionDetails = ({
  bookedOption: {
    type,
    startTime,
    endTime,
    duration,
    status,
    scheduledDate,
    bookingWith,
  },
  classNames,
}: {
  bookedOption: BookedOption;
  classNames?: Partial<Record<'root' | 'row', string>>;
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const requestTypeInfo: Record<MainRequestTypes, BookedOptionRow> = {
    [RequestType.LongTerm]: {
      icon: LongTermTypeSrc,
      title: 'Type',
      content: <>Long Term</>,
    },
    [RequestType.SingleTerm]: {
      icon: ShortTermTypeSrc,
      title: 'Type',
      content: <>Short Term</>,
    },
    [RequestType.MidTerm]: {
      icon: OpenSessionTypeSrc,
      title: 'Type',
      content: (
        <>
          <Typography variant="subtitle1">OpenSession</Typography>
          <Typography variant="caption">
            Contact scheduling to change
          </Typography>
        </>
      ),
    },
    [RequestType.SubOffer]: {
      icon: ShortTermTypeSrc,
      title: 'Type',
      content: <>SubOffer</>,
    },
  };

  const confirmedStatusDetails = {
    color: theme.palette.kyoPastelGreen.main,
    title: 'Confirmed',
  };

  const statusDetails: Record<number, Record<'color' | 'title', string>> = {
    [RequestStatus.PENDING]: {
      color: theme.palette.kyoBurntOrange.main,
      title: 'Pending',
    },
    [RequestStatus.ACCEPTED]: confirmedStatusDetails,
    [RequestStatus.CONFIRMED]: confirmedStatusDetails,
  };

  const rows: BookedOptionRow[] = [
    {
      icon: DateSrc,
      title: 'Date',
      content: (
        <>
          {moment(scheduledDate, DATE_FORMAT).format(
            DATE_FORMAT_WEEKDAY_MONTH_YEAR
          )}
        </>
      ),
    },
    {
      icon: BookedWithSrc,
      title: 'Booked with',
      content: <>{bookingWith}</>,
    },
    {
      icon: TimeSrc,
      title: 'Time',
      content: (
        <>
          {startTime.format(TIME_FORMAT)} - {endTime.format(TIME_FORMAT)}
        </>
      ),
    },
    {
      icon: <TimelapseIcon className={classes.timelapseIcon} />,
      title: 'Duration',
      content: <>{humanizeDuration(duration)}</>,
    },
    {
      icon: (
        <div
          className={classes.statusIcon}
          style={{ backgroundColor: statusDetails[status].color }}
        />
      ),
      title: 'Status',
      content: (
        <Typography style={{ color: statusDetails[status].color }}>
          {statusDetails[status].title}
        </Typography>
      ),
    },
  ];

  if (!bookingWith) {
    rows.splice(1, 1);
  }

  if (type) {
    rows.unshift(requestTypeInfo[type]);
  }

  return (
    <div className={classNames?.root}>
      {rows.map(({ icon, title, content }) => (
        <div key={title} className={clsx(classes.rowWrapper, classNames?.row)}>
          <div className={classes.rowTitle}>
            {typeof icon === 'string' ? <img src={icon} alt="row" /> : icon}
            <Typography>{title}</Typography>
          </div>
          <div className={classes.content}>{content}</div>
        </div>
      ))}
    </div>
  );
};

export default BookedOptionDetails;

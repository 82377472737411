import React from 'react';
import { Popper, PopperProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const ARROW_SIZE = 12;

const useStyles = makeStyles((theme: Theme) => ({
  styledPopper: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'relative',
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius * 2,
    '&$bottom': {
      marginTop: ARROW_SIZE,
    },
    '&$up': {
      marginBottom: ARROW_SIZE,
    },
    '&$left': {
      marginRight: ARROW_SIZE,
    },
    '&$right': {
      marginLeft: ARROW_SIZE,
    },
  },
  arrow: {
    position: 'absolute',
    borderWidth: ARROW_SIZE,
    borderColor: 'transparent',
    borderStyle: 'solid',
    zIndex: 1,
    '&$bottom': {
      borderLeftColor: theme.palette.grey[50],
      top: -ARROW_SIZE,
      marginLeft: ARROW_SIZE * 2,
    },
    '&$up': {
      borderLeftColor: theme.palette.grey[50],
      bottom: -ARROW_SIZE,
      marginLeft: ARROW_SIZE * 2,
    },
    '&$left': {
      borderTopColor: theme.palette.grey[50],
      right: -ARROW_SIZE,
      marginTop: ARROW_SIZE * 2,
    },
    '&$right': {
      borderTopColor: theme.palette.grey[50],
      left: -ARROW_SIZE,
      marginTop: ARROW_SIZE * 2,
    },
  },
  bottom: {},
  up: {},
  left: {},
  right: {},
}));

interface StyledPopperProps extends PopperProps {
  hideArrow?: boolean;
}

const StyledPopper = (props: StyledPopperProps) => {
  const { children, className, placement = 'bottom-end', hideArrow } = props;
  const classes = useStyles();

  const placementDirection = placement.split('-')[0] as
    | 'bottom'
    | 'up'
    | 'left'
    | 'right';

  return (
    <Popper
      className={clsx(
        className,
        classes.styledPopper,
        !hideArrow && classes[placementDirection]
      )}
      {...props}
      placement={placement}
      modifiers={{
        arrow: {
          element: `#styled-popper-arrow-${placementDirection}`,
        },
      }}
    >
      {!hideArrow && (
        <span
          className={clsx(classes.arrow, classes[placementDirection])}
          id={`styled-popper-arrow-${placementDirection}`}
        />
      )}
      {children}
    </Popper>
  );
};

export default StyledPopper;

import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    kyoGreen: PaletteColor;
    kyoLightGreen: PaletteColor;
    kyoTeal: PaletteColor;
    kyoYellow: PaletteColor;
    kyoRed: PaletteColor;
    kyoMagenta: PaletteColor;
    kyoPurple: PaletteColor;
    kyoDarkGreen: PaletteColor;
    kyoDarkestGreen: PaletteColor;
    kyoLightRed: PaletteColor;
    kyoOrange: PaletteColor;
    kyoBlue: PaletteColor;
    kyoBlue1: PaletteColor;
    kyoBlue2: PaletteColor;
    kyoSilver: PaletteColor;
    kyoDollarBill: PaletteColor;
    kyoPastelOrange: PaletteColor;
    kyoOrangeRed: PaletteColor;
    kyoGray: PaletteColor;
    kyoGray2: PaletteColor;
    kyoGray3: PaletteColor;
    kyoGray63: PaletteColor;
    kyoPastelGreen: PaletteColor;
    kyoBurntOrange: PaletteColor;
    kyoSkyBlue: PaletteColor;
    kyoPastelRed: PaletteColor;
    kyoBondiBlue: PaletteColor;
    kyoPink: PaletteColor;
    kyoGray4: PaletteColor;
    kyoLavender: PaletteColor;
  }
  interface PaletteOptions {
    kyoGreen: PaletteColor;
    kyoLightGreen: PaletteColor;
    kyoTeal: PaletteColor;
    kyoYellow: PaletteColor;
    kyoRed: PaletteColor;
    kyoMagenta: PaletteColor;
    kyoPurple: PaletteColor;
    kyoDarkGreen: PaletteColor;
    kyoDarkestGreen: PaletteColor;
    kyoLightRed: PaletteColor;
    kyoOrange: PaletteColor;
    kyoBlue: PaletteColor;
    kyoBlue1: PaletteColor;
    kyoBlue2: PaletteColor;
    kyoSilver: PaletteColor;
    kyoDollarBill: PaletteColor;
    kyoPastelOrange: PaletteColor;
    kyoOrangeRed: PaletteColor;
    kyoGray: PaletteColor;
    kyoGray2: PaletteColor;
    kyoGray3: PaletteColor;
    kyoGray63: PaletteColor;
    kyoPastelGreen: PaletteColor;
    kyoBurntOrange: PaletteColor;
    kyoSkyBlue: PaletteColor;
    kyoPastelRed: PaletteColor;
    kyoBondiBlue: PaletteColor;
    kyoPink: PaletteColor;
    kyoGray4: PaletteColor;
    kyoLavender: PaletteColor;
  }
}

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    // MUIDataTableToolbar: {
    //   root: React.CSSProperties;
    //   titleText: React.CSSProperties;
    // };
    // MUIDataTableHead: {
    //   main: React.CSSProperties;
    // };
    // MUIDataTableHeadCell: {
    //   root: React.CSSProperties;
    //   data: React.CSSProperties;
    // };
    // MUIDataTableBodyCell: {
    //   root: React.CSSProperties;
    // };
    // MUIDataTableSelectCell: {
    //   root: React.CSSProperties;
    //   headerCell: React.CSSProperties;
    // };
    // MUIDataTable: {
    //   tableRoot: React.CSSProperties;
    // };
    MuiPickersToolbar: {
      toolbar: React.CSSProperties;
    };
    MuiPickersToolbarText: {
      toolbarTxt: React.CSSProperties;
      toolbarBtnSelected: React.CSSProperties;
    };
    MuiPickersBasePicker: {
      pickerView: React.CSSProperties;
    };
  }
}

const theme = createMuiTheme({
  palette: {
    kyoGreen: {
      main: '#78D64B',
      light: '#acff7c',
      dark: '#43a414',
      contrastText: '#fff',
    },
    kyoLightGreen: {
      main: '#56bdce',
      light: '#8cf0ff',
      dark: '#0d8d9d',
      contrastText: '#fff',
    },
    kyoTeal: {
      main: '#00c0d3',
      light: '#63f3ff',
      dark: '#008fa2',
      contrastText: '#fff',
    },
    kyoYellow: {
      main: '#fbde40',
      light: '#ffff75',
      dark: '#c4ad00',
      contrastText: '#000',
    },
    kyoRed: {
      main: '#f4364c',
      light: '#ff6f78',
      dark: '#ba0024',
      contrastText: '#fff',
    },
    kyoMagenta: {
      main: '#E31C79',
      light: '#ff5fa8',
      dark: '#ab004d',
      contrastText: '#000',
    },
    kyoPurple: {
      main: '#3C1053',
      light: '#693b80',
      dark: '#1b002a',
      contrastText: '#fff',
    },
    kyoDarkGreen: {
      main: '#005F61',
      light: '#418d8e',
      dark: '#003537',
      contrastText: '#fff',
    },
    kyoDarkestGreen: {
      main: '#07272d',
      light: '#324f56',
      dark: '#000002',
      contrastText: '#fff',
    },
    kyoLightRed: {
      main: '#fa9370',
      light: '#ffc49f',
      dark: '#c36444',
      contrastText: '#fff',
    },
    kyoOrange: {
      main: '#edc8a3',
      light: '#fffbd4',
      dark: '#ba9774',
      contrastText: '#000',
    },
    kyoBlue: {
      main: '#1672ec',
      light: '#69a0ff',
      dark: '#0048b9',
      contrastText: '#fff',
    },
    kyoBlue1: {
      main: '#8ab9f6',
      light: '#e1edfb',
      dark: '#0F56B3',
      contrastText: '#000',
    },
    kyoBlue2: {
      main: '#5096f1',
      light: '#8ac6ff',
      dark: '#0069be',
      contrastText: '#000',
    },
    kyoSilver: {
      main: '#c0c0c0',
      light: '#f3f3f3',
      dark: '#909090',
      contrastText: '#000',
    },
    kyoDollarBill: {
      main: '#87C652',
      light: '#F6FCF1',
      dark: '#446329',
      contrastText: '#000',
    },
    kyoPastelOrange: {
      main: '#FFAD4D',
      light: '#FFFAF3',
      dark: '#99682E',
      contrastText: '#fff',
    },
    kyoOrangeRed: {
      main: '#FF4D4D',
      light: '#FFF2F2',
      dark: '#992E2E',
      contrastText: '#000',
    },
    kyoGray: {
      main: '#E0E0E0',
      light: '#F2F2F2',
      dark: '#616161',
      contrastText: '#000',
    },
    kyoGray2: {
      main: '#E5E5E580',
      light: '#E5E5E54D',
      dark: '#EDEDED',
      contrastText: '#616161',
    },
    kyoGray3: {
      main: '#797F84',
      light: '#E5E5E54D', // same as in kyoGray2
      dark: '#EDEDED', // same as in kyoGray2
      contrastText: '#616161', // same as in kyoGray2
    },
    kyoGray63: {
      main: '#A1A1A1',
      light: '#d0d0d0',
      dark: '#414141',
      contrastText: '#000',
    },
    kyoPastelGreen: {
      main: '#2AB930',
      light: '#FBFFF9',
      dark: '#155d18',
      contrastText: '#000',
    },
    kyoBurntOrange: {
      main: '#F2B05C',
      light: '#f9d8ae',
      dark: '#79582e',
      contrastText: '#000',
    },
    kyoSkyBlue: {
      main: '#3acbf2',
      light: '#D4EAF0',
      dark: '#1C6D7A',
      contrastText: '#000',
    },
    kyoPastelRed: {
      main: '#fcd0cf',
      light: '#FFF4F6',
      dark: '#FF6C6C',
      contrastText: '#000',
    },
    kyoBondiBlue: {
      main: '#24A9D9',
      light: '#CCEAEC',
      dark: '#02989E',
      contrastText: '#000',
    },
    kyoPink: {
      main: '#E4287C',
      light: '#F9D2E4',
      dark: '#E31D79',
      contrastText: '#000',
    },
    kyoGray4: {
      main: '#999A9E',
      light: '#DBDCDD',
      dark: '#999A9E',
      contrastText: '#000',
    },
    kyoLavender: {
      main: '#734F96',
      light: '#E1D6E8',
      dark: '#7E40AA',
      contrastText: '#000',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    h1: {
      fontSize: 96,
      fontWeight: 700,
    },
    h2: {
      fontSize: 60,
      fontWeight: 700,
    },
    h3: {
      fontSize: 48,
      fontWeight: 700,
    },
    h4: {
      fontSize: 32,
      fontWeight: 600,
    },
    h5: {
      fontSize: 24,
      fontWeight: 600,
    },
    h6: {
      fontSize: 20,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontSize: 16,
      fontWeight: 700,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
    overline: {
      fontSize: 10,
      fontWeight: 800,
    },
  },
});

const themeWithOverrides = createMuiTheme(theme, {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
        },
      },
    },
    MUIDataTableToolbar: {
      root: {
        padding: 0,
        color: theme.palette.grey[700],
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
      titleText: {
        '&.MuiTypography-h6': {
          [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
          },
        },
      },
    },
    MUIDataTableHeadCell: {
      root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderBottomWidth: 2,
        '&[aria-sort="ascending"], &[aria-sort="descending"]': {
          borderBottomColor: theme.palette.kyoBlue.main,
        },
        fontSize: 14,
        color: theme.palette.grey[400],
        fontWeight: 700,
      },
      data: {
        fontSize: 14,
        color: theme.palette.grey[400],
        fontWeight: 700,
      },
    },
    MUIDataTableBodyCell: {
      root: {
        overflowWrap: 'anywhere',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        'td&': {
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          borderBottomWidth: 0,
        },
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: theme.palette.kyoBlue.main,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: `${theme.palette.kyoBlue.main}50`,
      },
      barColorPrimary: {
        backgroundColor: theme.palette.kyoBlue.main,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.palette.grey[100],
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: theme.palette.common.black,
      },
      toolbarBtnSelected: {
        color: theme.palette.common.black,
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: 'unset',
        border: `1px solid ${theme.palette.kyoBlue.main}`,
        backgroundColor: fade('#C5DCFA', 0.5),
        '&:hover': {
          backgroundColor: '#C5DCFA',
        },
        '&:focus': {
          backgroundColor: fade('#C5DCFA', 0.5),
        },
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        margin: 'auto',
      },
    },
    MUIDataTableSelectCell: {
      root: {
        display: 'none',
      },
      headerCell: {
        display: 'none',
      },
    },
    MUIDataTable: {
      tableRoot: {
        '@global': {
          tbody: {
            borderBottom: `2px solid ${theme.palette.grey[300]}`,
            '& > tr:first-child > td': {
              borderTopWidth: 0,
            },
          },
        },
      },
    },
  },
});

export const getMenteesTableTheme = (theme: Theme) =>
  createMuiTheme(theme, {
    overrides: {
      MuiTableHead: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: 'unset',
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: theme.palette.kyoGray2.dark,
        },
      },
      MUIDataTableHeadCell: {
        root: {
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            whiteSpace: 'nowrap',
            fontSize: 12,
            letterSpacing: '-0.5px',
          },
        },
      },
      MuiTableCell: {
        root: {
          textAlign: 'center',
          '&.center-cell': {
            textAlign: 'center',
          },
          '&:first-child': {
            width: '23%',
          },
          '&:nth-child(2)': {
            width: '10%',
          },
        },
        head: {
          fontSize: 14,
          color: theme.palette.grey[400],
          fontWeight: 700,
          borderBottomWidth: 0,
          '&:first-child': {
            width: '20%',
          },
        },
      },
    },
  });

export default themeWithOverrides;

import { Middleware, MiddlewareAPI } from 'redux';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { setAlertMessage } from '../modules/alert';

const HandleErrorResponses: Middleware = (api: MiddlewareAPI) => (
  next
) => async (action) => {
  if (isRejectedWithValue(action)) {
    const { status } = action.payload.response || {};

    if (status === 403) {
      api.dispatch(
        setAlertMessage({
          message:
            'You have not permission to perform this action. Please contact support for details',
          autoHideDuration: 6000,
        })
      );
    } else {
      api.dispatch(
        setAlertMessage({
          message:
            'An unexpected error occurred, please contact support to resolve the issue',
          autoHideDuration: 6000,
        })
      );
    }
  }

  next(action);
};

export default HandleErrorResponses;

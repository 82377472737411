import {
  createSlice,
  Dispatch,
  PayloadAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import adminSettingsApi from '../../api/admin/adminSettingsApi';
import timeBlocksApi from '../../api/admin/timeBlocksApi';
import { AttendanceStatusThreshold } from '../../types/types';

export interface AttendanceAward {
  id: number;
  name: string;
  streak: number;
  amount: number;
  changerId: number;
}

export interface AdminSetting {
  key: string;
  value: string | number | number[];
}

export interface SessionPlacement {
  id: number;
  timeBlockId: number;
  startTime: string | null;
  duration: number | null;
}
export interface TimeBlock {
  id: number;
  start: string;
  end: string;
}

export interface KyoClimbSettings {
  id: number;
  name: string;
  minRating: number;
  minImpactHours: number;
  maxCancelRate: number;
  climbCash: number;
  changerId: number | null;
}

export interface AdminSettingsState {
  attendanceAwards: AttendanceAward[];
  attendanceAwardsLoading: boolean;
  attendanceAwardsError: boolean;
  attendanceStatusThresholds?: AttendanceStatusThreshold[];
  settings: AdminSetting[];
  isSettingsLoading: boolean;
  timeBlocks: TimeBlock[];
  isTimeBlocksLoading: boolean;
  kyoClimbSettings: KyoClimbSettings[];
  kyoClimbSettingsLoading: boolean;
  kyoClimbSettingsError: boolean;
}

const initialState: AdminSettingsState = {
  attendanceAwards: [],
  attendanceAwardsLoading: false,
  attendanceAwardsError: false,
  attendanceStatusThresholds: undefined,
  settings: [],
  isSettingsLoading: false,
  timeBlocks: [],
  isTimeBlocksLoading: false,
  kyoClimbSettings: [],
  kyoClimbSettingsLoading: false,
  kyoClimbSettingsError: false,
};

export const fetchAttendanceAwards = createAsyncThunk(
  'adminSettings/fetchAttendanceAwards',
  () => adminSettingsApi.fetchAttendanceAwards()
);

export const fetchAttendanceStatusThresholds = createAsyncThunk(
  'adminSettings/fetchAttendanceStatusThresholds',
  () => adminSettingsApi.fetchAttendanceStatusThresholds()
);

export const fetchKyoClimbSettings = createAsyncThunk(
  'adminSettings/fetchKyoClimbSettings',
  () => adminSettingsApi.fetchKyoClimbSettings()
);

const adminSettingsSlice = createSlice({
  name: 'adminSettings',
  initialState,
  reducers: {
    setSettingsLoading: (
      state,
      action: PayloadAction<boolean>
    ): AdminSettingsState => ({
      ...state,
      isSettingsLoading: action.payload,
    }),
    setSettings: (
      state,
      action: PayloadAction<AdminSetting[]>
    ): AdminSettingsState => ({
      ...state,
      settings: action.payload,
    }),
    timeBlocksLoading: (state) => ({ ...state, isTimeBlocksLoading: true }),
    timeBlocksReceived: (state, action: PayloadAction<TimeBlock[]>) => ({
      ...state,
      isTimeBlocksLoading: false,
      timeBlocks: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttendanceAwards.pending, (state) => {
        state.attendanceAwardsLoading = true;
        state.attendanceAwardsError = false;
      })
      .addCase(fetchAttendanceAwards.fulfilled, (state, action) => {
        state.attendanceAwardsLoading = false;
        state.attendanceAwardsError = false;
        state.attendanceAwards = action.payload;
      })
      .addCase(fetchAttendanceAwards.rejected, (state) => {
        state.attendanceAwardsLoading = false;
        state.attendanceAwardsError = true;
      })
      .addCase(fetchAttendanceStatusThresholds.fulfilled, (state, action) => {
        state.attendanceStatusThresholds = action.payload;
      })
      .addCase(fetchKyoClimbSettings.pending, (state) => {
        state.kyoClimbSettingsLoading = true;
        state.kyoClimbSettingsError = false;
      })
      .addCase(fetchKyoClimbSettings.fulfilled, (state, action) => {
        state.kyoClimbSettingsLoading = false;
        state.kyoClimbSettingsError = false;
        state.kyoClimbSettings = action.payload;
      })
      .addCase(fetchKyoClimbSettings.rejected, (state) => {
        state.kyoClimbSettingsLoading = false;
        state.kyoClimbSettingsError = true;
      });
  },
});

export const {
  setSettings,
  setSettingsLoading,
  timeBlocksLoading,
  timeBlocksReceived,
} = adminSettingsSlice.actions;

export default adminSettingsSlice.reducer;

export const fetchSettings = () => async (dispatch: Dispatch) => {
  dispatch(setSettingsLoading(true));
  let response;
  try {
    response = await adminSettingsApi.fetchSettings();
  } catch (e: any) {
    console.log(e);
  }
  if (response) {
    dispatch(setSettings(response.data));
  }
  dispatch(setSettingsLoading(false));
};

export const fetchTimeBlocks = () => async (dispatch: Dispatch) => {
  dispatch(timeBlocksLoading());
  let response;
  try {
    response = await timeBlocksApi.fetchTimeBlocks();
  } catch (e: any) {
    console.log(e);
  }
  if (response) {
    dispatch(timeBlocksReceived(response));
  }
};

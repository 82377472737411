import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType, Fragment } from 'react';

type StyleProps = {
  columnLength: number;
  rowsLength: number;
  hasHorizontalLabels: boolean;
  hasVerticalLabels: boolean;
};

const labelsJss = {
  display: 'flex',
  '& > div': {
    flex: 1,
  },
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    display: 'grid',
  },
  horizontalLabels: ({ hasVerticalLabels }) => ({
    gridColumnStart: hasVerticalLabels ? 2 : 1,
    ...labelsJss,
  }),
  verticalLabels: ({ hasHorizontalLabels }) => ({
    gridRowStart: hasHorizontalLabels ? 2 : 1,
    ...labelsJss,
    flexDirection: 'column',
  }),
  insideRowsLabel: {
    gridColumn: '1/-1',
    width: '100%',
  },
  matrix: ({ hasHorizontalLabels, hasVerticalLabels, columnLength }) => ({
    gridRowStart: hasHorizontalLabels ? 2 : 1,
    gridColumnStart: hasVerticalLabels ? 2 : 1,
    display: 'grid',
    gridTemplateColumns: `repeat(${columnLength}, 1fr)`,
    placeItems: 'center',
  }),
}));

export type LabelRenderFunc<Value> = ComponentType<{ value: Value }>;
type MatrixRenderFunc<X, Y> = (
  horizontalValue: X,
  verticalValue: Y
) => React.ReactNode;

export type MatrixProps<HorizontalValue, VerticalValue> = {
  render: MatrixRenderFunc<HorizontalValue, VerticalValue>;

  horizontalValues: Array<HorizontalValue>;
  verticalValues: Array<VerticalValue>;

  HorizontalLabel?: LabelRenderFunc<HorizontalValue>;
  VerticalLabel?: LabelRenderFunc<VerticalValue>;
  InsideRowsLabel?: LabelRenderFunc<VerticalValue>;
  useVerticalLabelsInsideRows?: boolean;

  styles?: Partial<{
    root: string;
    horizontalLabels: string;
    verticalLabels: string;
    insideRowsLabel: string;
    matrix: string;
  }>;

  customElements?: React.ReactNode;
};

export const Matrix = <X, Y>(props: MatrixProps<X, Y>) => {
  const {
    horizontalValues,
    verticalValues,
    render = () => null,
    HorizontalLabel,
    VerticalLabel,
    InsideRowsLabel,
    styles,
    useVerticalLabelsInsideRows = false,
    customElements,
  } = props;

  const classes = useStyles({
    columnLength: horizontalValues.length,
    rowsLength: verticalValues.length,
    hasHorizontalLabels: !!HorizontalLabel,
    hasVerticalLabels: !!(VerticalLabel && !useVerticalLabelsInsideRows),
  });
  const generateKey = (value: X | Y, index: number) => `${value}_${index}`;

  const InsideRowsLabelLocal = useVerticalLabelsInsideRows
    ? VerticalLabel
    : InsideRowsLabel;

  return (
    <div className={clsx(styles?.root, classes.root)}>
      {HorizontalLabel && (
        <div className={(styles?.horizontalLabels, classes.horizontalLabels)}>
          {horizontalValues.map((hv, hvi) => (
            <HorizontalLabel value={hv} key={generateKey(hv, hvi)} />
          ))}
        </div>
      )}
      {VerticalLabel && !useVerticalLabelsInsideRows && (
        <div className={clsx(styles?.verticalLabels, classes.verticalLabels)}>
          {verticalValues.map((vv, vvi) => (
            <VerticalLabel value={vv} key={generateKey(vv, vvi)} />
          ))}
        </div>
      )}
      <div className={clsx(styles?.matrix, classes.matrix)}>
        {verticalValues.map((vv) => (
          <Fragment key={generateKey(vv, 0)}>
            {horizontalValues.map((hv, hvi) => {
              return (
                <React.Fragment key={generateKey(hv, hvi)}>
                  {render(hv, vv)}
                </React.Fragment>
              );
            })}
            {InsideRowsLabelLocal && (
              <div
                className={clsx(
                  styles?.insideRowsLabel,
                  classes.insideRowsLabel
                )}
              >
                <InsideRowsLabelLocal value={vv} />
              </div>
            )}
          </Fragment>
        ))}
      </div>
      {customElements}
    </div>
  );
};

export default Matrix;

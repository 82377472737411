import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jiraApi from '../../api/jiraApi';
import { JiraTicketType } from '../../types/types';

export interface JiraState {
  loadingJiraTicketTypes: boolean;
  jiraTicketTypes: JiraTicketType[];
}

const initialState: JiraState = {
  loadingJiraTicketTypes: false,
  jiraTicketTypes: [],
};

export const fetchJiraTicketTypes = createAsyncThunk(
  'jira/fetchJiraTicketTypes',
  async () => {
    return jiraApi.fetchJiraTicketTypes();
  }
);

const jiraSlice = createSlice({
  name: 'jira',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJiraTicketTypes.pending, (state) => {
        state.loadingJiraTicketTypes = true;
      })
      .addCase(fetchJiraTicketTypes.fulfilled, (state, action) => {
        state.jiraTicketTypes = action.payload;
        state.loadingJiraTicketTypes = false;
      })
      .addCase(fetchJiraTicketTypes.rejected, (state) => {
        state.loadingJiraTicketTypes = false;
      });
  },
});

export default jiraSlice.reducer;

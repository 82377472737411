import moment, { Moment } from 'moment';
import { isEqual } from 'lodash';
import { DATE_FORMAT, TIME_FORMAT } from '../../utils/helpers';
import { AvailabilityStatus } from '../../shared/types';
import { Availability } from '../../redux/modules/availability';
import { PREFERRED_BLOCK_MIN_INTERVAL } from '../../utils/constants';
import { ScheduleSegment, SessionStatus } from '../../types/types';
import { AvailabilityByWeek, PageMode } from './types';

export const preferredBlock3EndValidation = (
  block3Start: string | undefined,
  block3End: string | undefined
) => {
  if (!block3Start || !block3End) {
    return true;
  }
  const time1 = moment(block3End, TIME_FORMAT);
  const time2 = moment(block3Start, TIME_FORMAT);
  const difference = time1.diff(time2, 'minutes');

  return difference >= PREFERRED_BLOCK_MIN_INTERVAL * 60;
};

export const findTempAvailabilityByWeek = (
  stAvail: Availability[],
  startOfWeek: Moment
) => {
  const weekStartDate = moment(startOfWeek).format(DATE_FORMAT);

  const tempAvail = stAvail.find((item) => item.startDate === weekStartDate);

  return tempAvail || null;
};

export const findStandardAvailabilityByWeek = (
  ltAvail: Availability[],
  startOfWeek: Moment
) => {
  const weekStartDate = moment(startOfWeek).format(DATE_FORMAT);

  const upcomingAvail = ltAvail.find(
    (item) =>
      item.status === AvailabilityStatus.Upcoming &&
      moment(item.startDate).format(DATE_FORMAT) <= weekStartDate
  );

  if (upcomingAvail) {
    return upcomingAvail;
  }

  const currentAvail = ltAvail.find(
    (item) => item.status === AvailabilityStatus.Current
  );

  if (currentAvail) {
    return currentAvail;
  }

  return null;
};

export const findStandardAvailability = (ltAvail: Availability[]) => {
  const upcomingAvail = ltAvail.find(
    (item) => item.status === AvailabilityStatus.Upcoming
  );

  if (upcomingAvail) {
    return upcomingAvail;
  }

  const currentAvail = ltAvail.find(
    (item) => item.status === AvailabilityStatus.Current
  );

  if (currentAvail) {
    return currentAvail;
  }

  return null;
};

export const findSessionForCellRendering = (sessions: ScheduleSegment[]) => {
  const confirmedSession = sessions.find(
    (session) => session?.status === SessionStatus.Confirmed
  );
  const pendingSession = sessions.find(
    (session) =>
      session &&
      ![
        SessionStatus.Confirmed,
        SessionStatus.Cancelled,
        SessionStatus.TimeOff,
      ].includes(session.status)
  );
  return confirmedSession || pendingSession || sessions[0];
};

type GetMatrixCellStyleArgs = {
  isSmallGrid: boolean;
  isAvailMode: boolean;
  colors?: {
    main: string;
    background: {
      bigGrid: string;
      smallGrid: string;
    };
  };
};

export const getMatrixCellStyle = (args: GetMatrixCellStyleArgs) => {
  const { isSmallGrid, isAvailMode, colors } = args;

  if (isAvailMode || !colors) return undefined;

  const background = isSmallGrid
    ? colors.background.smallGrid
    : colors.background.bigGrid;
  const color = colors.main;

  return { background, color };
};

type IsCellClickableArgs = {
  pageMode: PageMode;
  isSmallGrid: boolean;
  isPast: boolean;
  scheduleLength: number;
};

export const isCellClickable = (args: IsCellClickableArgs) => {
  const { pageMode, isSmallGrid, isPast, scheduleLength } = args;

  const isCancelMode = pageMode === PageMode.Remove;
  const isAvailMode = [PageMode.TempAvail, PageMode.StandardAvail].includes(
    pageMode
  );

  if (pageMode === PageMode.StandardAvail) {
    return false;
  }

  return (
    (isSmallGrid || (isAvailMode && !isPast)) &&
    !isCancelMode &&
    (!!scheduleLength || (isAvailMode && !isPast && !!scheduleLength))
  );
};

export const isChangedAvailability = (
  availability: Availability,
  availabilityByWeek: AvailabilityByWeek
): boolean => {
  const availabilityForCompare: AvailabilityByWeek = {
    blocks: availability.availabilityBlocks,
    originalId: availability.id,
    preferences: {
      preferredBlock1Start: availability.preferredBlock1Start,
      preferredBlock3Start: availability.preferredBlock3Start,
      preferredBlock3End: availability.preferredBlock3End,
    },
    type: availability.type,
  };
  const availabilityWithoutStatus = () => {
    const { status, ...rest } = availabilityByWeek;
    return rest;
  };

  return !isEqual(availabilityForCompare, availabilityWithoutStatus());
};

export const getWeekRange = (date: Moment): Date[] => {
  const weekStart = date.clone().startOf('week').toDate();
  const weekEnd = moment(weekStart).add(6, 'days').toDate();

  return [weekStart, weekEnd];
};

export const getPreferredBlockTime = (
  isDisabled: boolean,
  preferenceTime: string | null | undefined,
  timeBlockOption: string[] | undefined
) =>
  !isDisabled && !preferenceTime && timeBlockOption
    ? timeBlockOption[0]
    : preferenceTime;

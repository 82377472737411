import React from 'react';
import { ReactComponent as OpuOutAllowedBadge } from '../../../assets/optOutAllowedBadge.svg';
import { RequestStatus, SessionStatus } from '../../../types/types';

const OptOutAllowedBadgeIcon = ({
  className,
  statusId,
}: {
  className?: string;
  statusId: SessionStatus | RequestStatus;
}) => {
  return [RequestStatus.PENDING, SessionStatus.Pending].includes(statusId) ? (
    <OpuOutAllowedBadge className={className} />
  ) : (
    <></>
  );
};

export default OptOutAllowedBadgeIcon;

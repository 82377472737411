import { Typography } from '@material-ui/core';
import React from 'react';

interface AvailabilityHeaderProps {
  title: React.ReactNode;
  subTitle?: string;
  action?: React.ReactNode;
  onClick?: () => void;
  classes: Record<'root' | 'title' | 'subTitle', string>;
}

const AvailabilityHeader = ({
  title,
  subTitle,
  action,
  onClick,
  classes,
}: AvailabilityHeaderProps) => {
  return (
    <div
      onClick={onClick}
      className={classes.root}
      onKeyDown={undefined}
      role="button"
      tabIndex={0}
    >
      <div>
        <Typography className={classes.title} color="textSecondary">
          {title}
        </Typography>

        {subTitle && (
          <Typography className={classes.subTitle} color="textSecondary">
            {subTitle}
          </Typography>
        )}
      </div>
      {action}
    </div>
  );
};

export default AvailabilityHeader;

import { makeStyles, Theme } from '@material-ui/core';
import { matrixInfoStyles } from '../../../components/Availability/AvailabilityStyles';

export const maxWidthSize = 48;
const maxWidth = (theme: Theme) => ({
  maxWidth: theme.spacing(maxWidthSize),
});

const useStyles = makeStyles((theme: Theme) => ({
  mainColor: {
    color: theme.palette.kyoBlue.main,
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  editableMatrixInfo: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  matrixInfoSideBorder: {
    height: 'auto',
    backgroundColor: '#DBCE97',
    width: theme.spacing(0.4),
    marginRight: theme.spacing(1.2),
    borderRadius: '20%',
  },
  availabilityMatrix: {
    marginTop: theme.spacing(2),
    ...maxWidth(theme),
  },
  matrixInfo: {
    ...matrixInfoStyles(theme),
    ...maxWidth(theme),
  },
  matrixVerticalLabel: {
    margin: theme.spacing(1, 0),
    minWidth: theme.spacing(6.5),
  },
  matrixVerticalLabelName: {
    ...theme.typography.subtitle2,
  },
  matrixVerticalLabelContent: {
    fontSize: 12,
    fontWeight: 600,
  },
  resetButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    ...maxWidth(theme),
  },
  resetButton: {
    color: theme.palette.kyoBlue.main,
  },
  pageMaxWidth: {
    ...maxWidth(theme),
  },
  cellWrapper: {
    position: 'relative',
  },
  demandImg: {
    position: 'absolute',
    top: -2,
    right: 3,
  },
}));

export default useStyles;

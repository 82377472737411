import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { MUIDataTableOptions } from 'mui-datatables';
import { UserSpecificMatrixCardProps } from '../types';
import { ScheduleSegment } from '../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  cancelBookingSessions,
  cancelScheduledSegments,
  setSessionsToRemove,
} from '../../../redux/modules/sessionRemoval';
import { MyScheduleContext } from './MyScheduleContext';

type Props = {
  CancellationInfo: React.FC;
  sessionsToRemove: Array<ScheduleSegment>;
  removeSessions: () => void;
  SessionMatrixCardComponent: React.FC<UserSpecificMatrixCardProps>;
  SessionsTable: React.FC<{
    data: Array<ScheduleSegment>;
    selectMode: boolean;
    sessionsToRemove: ScheduleSegment[];
    onRowSelection: (segmentId: number, checked: boolean) => void;
    options?: MUIDataTableOptions;
    isSmallScreen: boolean;
  }>;
  SessionToRemoveCard: React.FC<{
    session: ScheduleSegment;
    onKeepSession: () => void;
  }>;
  isSessionCancellable: (session: ScheduleSegment) => boolean;
  onKeepSession: (segmentId: number) => void;
  onSessionsSelect: (segmentIds: Array<number>, checked: boolean) => void;
  confirmRemoveDialogVisible: boolean;
  showConfirmRemoveDialog: () => void;
  hideConfirmRemoveDialog: () => void;
};

const noop = () => {
  /*  */
};

const defaultValue = {
  CancellationInfo: () => null,
  sessionsToRemove: [],
  removeSessions: noop,
  SessionMatrixCardComponent: () => null,
  SessionsTable: () => null,
  SessionToRemoveCard: () => null,
  confirmRemoveDialogVisible: false,
  isSessionCancellable: (session: ScheduleSegment) => false,
  onKeepSession: noop,
  onSessionsSelect: noop,
  showConfirmRemoveDialog: noop,
  hideConfirmRemoveDialog: noop,
};

export const SessionContext = createContext<Props>(defaultValue);

const SessionContextProvider = (props: {
  SessionMatrixCardComponent: React.FC<UserSpecificMatrixCardProps>;
  SessionsTable: React.FC<{
    data: Array<ScheduleSegment>;
    selectMode: boolean;
    sessionsToRemove: ScheduleSegment[];
    onRowSelection: (segmentId: number, checked: boolean) => void;
    options?: MUIDataTableOptions;
    isSmallScreen: boolean;
  }>;
  SessionToRemoveCard: React.FC<{
    session: ScheduleSegment;
    onKeepSession: () => void;
  }>;
  isSessionCancellable: (session: ScheduleSegment) => boolean;
  CancellationInfo: React.FC;
  children: ReactNode;
}) => {
  const {
    SessionMatrixCardComponent,
    SessionToRemoveCard,
    SessionsTable,
    isSessionCancellable,
    CancellationInfo,
    children,
  } = props;

  const { schedule } = useContext(MyScheduleContext);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [confirmRemoveDialogVisible, setConfirmRemoveDialogVisible] = useState(
    false
  );

  const sessionsToRemove = useAppSelector(
    (state) => state.sessionCancellation.selectedSessions
  );

  const removeSessions = useCallback(() => {
    dispatch(cancelScheduledSegments());
    dispatch(cancelBookingSessions());
    navigate('/schedule/remove/result');
  }, [dispatch, navigate]);

  return (
    <SessionContext.Provider
      value={{
        CancellationInfo,
        sessionsToRemove,
        removeSessions,
        SessionMatrixCardComponent,
        SessionsTable,
        SessionToRemoveCard,
        isSessionCancellable,
        onSessionsSelect: (segmentIds, checked) => {
          if (checked) {
            const sessions = schedule.filter((session) =>
              segmentIds.includes(session.segmentId)
            );
            if (!sessions) {
              return;
            }
            dispatch(setSessionsToRemove([...sessionsToRemove, ...sessions]));
          } else {
            dispatch(
              setSessionsToRemove(
                sessionsToRemove.filter(
                  (s) => !segmentIds.includes(s.segmentId)
                )
              )
            );
          }
        },
        onKeepSession: (segmentId) => {
          const newSessionsToRemove = sessionsToRemove.filter(
            (s) => s.segmentId !== segmentId
          );
          dispatch(setSessionsToRemove(newSessionsToRemove));
          if (!newSessionsToRemove.length) {
            setConfirmRemoveDialogVisible(false);
          }
        },
        confirmRemoveDialogVisible,
        showConfirmRemoveDialog: () => setConfirmRemoveDialogVisible(true),
        hideConfirmRemoveDialog: () => setConfirmRemoveDialogVisible(false),
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;

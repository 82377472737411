import { useEffect, useState } from 'react';
import fetchIntercept from 'fetch-intercept';
import { getVersion } from '../api/versionApi';
import configs from '../configs';

const useApiVersionCheck = () => {
  const [version, setVersion] = useState();

  useEffect(() => {
    getVersion()
      .then((data) => {
        setVersion(data.version);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!version) {
      return;
    }

    fetchIntercept.register({
      response: (response) => {
        if (!response.url.includes(configs.apiUrl)) {
          return response;
        }

        if (response.headers.get('x-api-version') !== version) {
          window.location.reload();
        }

        return response;
      },
    });
  }, [version]);
};

export default useApiVersionCheck;

import React from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';
import moment from 'moment';
import { TIME_FORMAT, formatSessionDuration } from '../../utils/helpers';
import { ScheduleSegment } from '../../types/types';

const useStyles = makeStyles((theme: Theme) => ({
  sessionTime: {
    color: theme.palette.kyoGray.contrastText,
  },
}));

interface SessionTimeCellProps {
  session: ScheduleSegment;
}

const SessionTimeCell = (props: SessionTimeCellProps) => {
  const { session } = props;
  const classes = useStyles();

  const startTime = moment(session.segmentStartUtc);
  const endTime = moment(session.segmentEndUtc);

  const formattedDuration = formatSessionDuration(session);

  return (
    <Box>
      <Typography variant="body2" className={classes.sessionTime}>
        {startTime.format(TIME_FORMAT)} - {endTime.format(TIME_FORMAT)} (
        {formattedDuration})
      </Typography>
    </Box>
  );
};

export default SessionTimeCell;

import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StreamChat } from 'stream-chat';
import { RootState } from '../redux/modules/rootReducer';
import chatApi from '../api/chatApi';
import configs from '../configs';
import { useAppDispatch } from '../redux/store';
import {
  resetMessageCounts,
  setUnreadMessageCountPerClient,
} from '../redux/modules/chat';

const getClientIdFromChannelId = (channelId: string) => {
  // example channel id - general_team_11111
  const clientId = channelId.split('_').pop() || '';
  return +clientId;
};

const chatClient = StreamChat.getInstance(configs.chatToken);
const channelFilters = {
  type: 'team',
};
export type ChatEventListener = { unsubscribe: () => void };

export default function useAttainChat() {
  const dispatch = useAppDispatch();
  const [channelId, setChannelId] = useState<string | null>(null);
  const [isChannelListEmpty, setIsChannelListEmpty] = useState(true);

  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const currentClient = useSelector(
    (state: RootState) => state.client.currentClient
  );
  const { id: providerId } =
    useSelector((state: RootState) => state.provider.provider) || {};

  const setClientUnreadCount = useCallback(
    (clientId: number, unreadCount: number) => {
      dispatch(
        setUnreadMessageCountPerClient({ id: clientId, count: unreadCount })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentUser && currentClient && chatClient) {
      setChannelId(`general_team_${currentClient.id}`);
    }
  }, [currentUser, currentClient]);

  useEffect(() => {
    const listeners: ChatEventListener[] = [];

    const initChat = async () => {
      let id = '';
      if (currentUser) {
        id = String(`user_${currentUser.id}`);
      }
      if (providerId) {
        id = String(`provider_${providerId}`);
      }
      const tokenProvider = async () => {
        // make a request to your own backend to get the token
        try {
          return await chatApi.getToken();
        } catch (e: any) {
          console.log(e);
        }
        return '';
      };
      try {
        await chatClient.connectUser(
          {
            id,
          },
          tokenProvider
        );
        const channels = await chatClient.queryChannels(
          channelFilters,
          {},
          { watch: true }
        );

        setIsChannelListEmpty(channels.length === 0);

        channels.forEach((channel) => {
          if (!channel.id) {
            return;
          }

          const clientId = getClientIdFromChannelId(channel.id);
          setClientUnreadCount(clientId, channel.countUnread());

          const channelListener = channel.on((e) => {
            const messageRegex = new RegExp('message*|notification*');

            if (messageRegex.test(e.type)) {
              setClientUnreadCount(clientId, channel.countUnread());
            }
          });

          listeners.push(channelListener);
        });
      } catch (e: any) {
        console.log(e);
      }
    };
    if (currentUser || providerId) {
      console.log('Init chat ...');
      initChat();
    }
    return () => {
      dispatch(resetMessageCounts());
      listeners.forEach((l) => l.unsubscribe());
      chatClient.disconnectUser();
    };
  }, [currentUser, providerId, setClientUnreadCount, dispatch]);

  return {
    chatClient,
    channelId,
    channelFilters,
    isChannelListEmpty,
  };
}

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import minimumAvailExample1 from '../../../assets/minimumAvailExample1.svg';
import minimumAvailExample2 from '../../../assets/minimumAvailExample2.svg';
import minimumAvailExample3 from '../../../assets/minimumAvailExample3.svg';
import InfoIdea from '../../../assets/info-idea.svg';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: theme.spacing(57),
    margin: 'auto',
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.kyoGray.light,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  bold: {
    fontWeight: 700,
  },
  availabilityExampleWrapper: {
    display: 'flex',
    alignItems: 'start',
    gap: theme.spacing(3),
  },
  availabilityExampleContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageWrapper: {
    marginTop: theme.spacing(0.5),
  },
  info: {
    backgroundColor: '#FBFFF9',
    border: '1px solid #82E780',
    borderRadius: theme.spacing(1.5),
    maxWidth: theme.spacing(48),
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(1.5),
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  gotItButton: {
    width: '100%',
    textTransform: 'capitalize',
    color: theme.palette.kyoLightGreen.main,
    borderColor: theme.palette.kyoLightGreen.main,
    marginTop: theme.spacing(2),
  },
}));

interface AllowedAvailabilityProps {
  open: boolean;
  handleClose: () => void;
}

const AllowedAvailability = ({
  open,
  handleClose,
}: AllowedAvailabilityProps) => {
  const classes = useStyles();

  const content = [
    {
      key: 1,
      image: minimumAvailExample1,
      title: 'Five Block 3 (preferred)',
      subTitle:
        'Select five weekday Block 3 during the high demand times of 3P-7P M-F',
    },
    {
      key: 2,
      image: minimumAvailExample2,
      title: 'Three Block 3 and Two Block 2 ',
      subTitle: 'Select three weekday Block 3s and  two weekday Block 2s.',
    },
    {
      key: 3,
      image: minimumAvailExample3,
      title: 'Three Block 3 and Two Block 1',
      subTitle: 'Select three weekday Block 3s and  two weekday Block 1s.',
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ root: classes.dialog, paper: classes.dialogPaper }}
      >
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h6" color="textSecondary">
            Allowed availabilities
          </Typography>
          <IconButton onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography variant="subtitle1">
            The minimum availability you provide must follow one of these
            examples
          </Typography>
          <Divider className={classes.divider} />

          {content.map(({ key, image, title, subTitle }) => (
            <React.Fragment key={key}>
              <div className={classes.availabilityExampleWrapper}>
                <div className={classes.imageWrapper}>
                  <img src={image} alt="availability example" />
                </div>
                <div className={classes.availabilityExampleContent}>
                  <Typography className={classes.bold} variant="caption">
                    {title}
                  </Typography>
                  <Typography
                    className={classes.bold}
                    variant="caption"
                    color="textSecondary"
                  >
                    {subTitle}
                  </Typography>
                </div>
              </div>
              <Divider className={classes.divider} />
            </React.Fragment>
          ))}

          <div className={classes.info}>
            <div className={classes.imageWrapper}>
              <img src={InfoIdea} alt="info icon" />
            </div>
            <div>
              <Typography variant="subtitle2">
                You can provide more availability by adding extra blocks to
                these minimums!
              </Typography>
              <Typography variant="body2">
                This increases your likelihood of being scheduled!
              </Typography>
            </div>
          </div>

          <Button
            className={classes.gotItButton}
            variant="outlined"
            onClick={handleClose}
          >
            Got It
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AllowedAvailability;

import React from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(3.75),
    maxWidth: theme.spacing(40),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    margin: theme.spacing(2, 2, 0),
    textAlign: 'center',
  },
  button: {
    color: theme.palette.kyoLightGreen.main,
    borderColor: theme.palette.kyoLightGreen.main,
    textTransform: 'capitalize',
    marginTop: theme.spacing(2),
  },
}));

export interface EmptyAvailabilityInfoProps {
  handleButtonClick?: () => void;
  buttonProps?: Partial<{
    startIcon: React.ReactNode;
    href: string;
    target: string;
    disabled: boolean;
  }>;
  imageSrc: string;
  content?: React.ReactNode;
  buttonLabel: string;
  styles?: Partial<Record<'root', string>>;
}

const EmptyAvailabilityInfo = ({
  handleButtonClick,
  buttonProps,
  imageSrc,
  content,
  buttonLabel,
  styles,
}: EmptyAvailabilityInfoProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(styles?.root, classes.wrapper)}>
      <img src={imageSrc} alt="empty availability" />
      {content && (
        <Typography
          variant="body1"
          className={classes.content}
          color="textSecondary"
        >
          {content}
        </Typography>
      )}
      <Button
        variant="outlined"
        className={classes.button}
        onClick={handleButtonClick}
        {...buttonProps}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export default EmptyAvailabilityInfo;
